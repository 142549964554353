import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate, CanActivateChild {

  constructor(private userService: UserService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const allowedRoles: [] = route.data.allowedRoles;
    let account = this.userService.getUser();

    const foundRole = allowedRoles.some(role => account.idTokenClaims?.roles?.includes(role));

    if (!foundRole) {
      return this.router.createUrlTree(['/access-denied']);
    }

    return foundRole;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute);
  }
}
