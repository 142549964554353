import { HttpClient } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType } from '@azure/msal-browser';
import { Subscription } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MonitoringService } from './monitoring.service';

interface Account extends AccountInfo {
  idTokenClaims?: {
    oid?: string;
    roles?: string[]
  }
}

@Injectable({
  providedIn: 'root',
})

export class UserService implements OnDestroy {

  private readonly GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

  userChanged = new Subject<Account>();
  currentUser: Account;
  msalSubs: Subscription[] = [];

  constructor(private http: HttpClient, private router: Router, private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService, private monitoringService: MonitoringService) {

    this.msalSubs.push(
      this.msalBroadcastService.msalSubject$
        .pipe(filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.HANDLE_REDIRECT_END))
        .subscribe(() => {
          const account = this.msalService.instance.getAllAccounts().find(Boolean) as Account;
          if (account) {
            this.currentUser = account;
            this.userChanged.next(this.currentUser);
            this.msalService.instance.setActiveAccount(this.currentUser);
            this.monitoringService.setUser(this.currentUser.idTokenClaims.oid);
          }
        })
    );

    this.msalSubs.push(
      this.msalBroadcastService.msalSubject$
        .pipe(filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_FAILURE))
        .subscribe(() => {
          this.router.navigate(['/access-denied']);
        })
    );

    this.msalSubs.push(
      this.msalBroadcastService.msalSubject$
        .pipe(filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGOUT_SUCCESS))
        .subscribe(() => {
          this.currentUser = null;
          this.userChanged.next(null);
          this.monitoringService.clearUser();
        })
    );
  }
  

  isLoggedIn(): boolean {
    return this.currentUser != null ;
  }

  getUser(): Account | null {
    return this.currentUser;
  }

  getProfile(): Observable<any> {
    return this.http.get(this.GRAPH_ENDPOINT);
  }

  getUserRole(): string {
    const index = this.currentUser.idTokenClaims.roles?.findIndex(x => x === 'Admin' || x === 'Analyst' || x === 'Approver' || x === 'Trader' || x === 'Risk');
    if (index >= 0)
      return this.currentUser.idTokenClaims.roles[index];
    return null;
  }

  getUserRegion(): string {
    const index = this.currentUser.idTokenClaims.roles?.findIndex(x => x === 'AGOT' || x === 'EGOT' || x === 'LNG' || x === 'CANADA' || x === 'ALL');
    if (index >= 0)
      return this.currentUser.idTokenClaims.roles[index];
    return null;
  }

  hasIceAccess(): boolean {
    return this.currentUser.idTokenClaims.roles?.findIndex(x => x === 'ICE.Read') >= 0;
  }

  login(): void {
    this.msalService.loginRedirect();
  }

  logout() {
    this.msalService.logout();
  }

  ngOnDestroy(): void {
    this.msalSubs.forEach(s => s.unsubscribe());
  }
}
