<form #settingsForm="ngForm" (ngSubmit)="saveSettings(settingsForm)" *ngIf="settings">
  <div class="em-l-grid em-l-grid--2up ">
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Ship size</mat-label>
        <mat-select [(ngModel)]="settings.ship_info.size" name="shipSize" required>
          <mat-option *ngFor="let size of shipSizes" [value]="size">{{size}}</mat-option>
        </mat-select>
        <mat-hint>m&#179;</mat-hint>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Ship speed</mat-label>
        <input matInput type='number' [(ngModel)]="settings.ship_info.speed" name="shipSpeed" required>
        <mat-hint>Nm/day</mat-hint>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Fillable</mat-label>
        <input matInput type='number' [(ngModel)]="settings.ship_info.fillable" name="shipFillable" required>
        <span matSuffix>%</span>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Boiloff Rate</mat-label>
        <input matInput type='number' [(ngModel)]="settings.ship_info.boiloff_rate" name="shipBoiloffRate" required>
        <mat-hint>%/day</mat-hint>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Additional fuel</mat-label>
        <input matInput type='number' [(ngModel)]="settings.ship_info.additional_fuel" name="shipAdditionalFuel" required>
        <mat-hint>Mt/day</mat-hint>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Suez Canal wait</mat-label>
        <input matInput type='number' [(ngModel)]="settings.canal_info[0].waiting_days" name="suezWaitDays" required>
        <mat-hint>day(s)</mat-hint>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Suez Canal cost</mat-label>
        <input matInput type='number' [(ngModel)]="settings.canal_info[0].cost" name="suezCost" required>
        <mat-hint>$</mat-hint>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Panama Canal wait</mat-label>
        <input matInput type='number' [(ngModel)]="settings.canal_info[1].waiting_days" name="panamaWaitDays" required>
        <mat-hint>day(s)</mat-hint>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Panama Canal cost</mat-label>
        <input matInput type='number' [(ngModel)]="settings.canal_info[1].cost" name="panamaCost" required>
        <mat-hint>$</mat-hint>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Charter cost multiplier</mat-label>
        <input matInput type='number' [(ngModel)]="settings.charter_cost_multiplier" name="charterCostMultiplier" required
               matTooltip="Charter cost multiplier">
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Insurance & piracy prevention</mat-label>
        <input matInput type='number' [(ngModel)]="settings.insurance_piracy_prevention" name="insurancePiracyPrevention" required
               matTooltip="Insurance & piracy prevention">
        <mat-hint>M$</mat-hint>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Departure price multiplier</mat-label>
        <input matInput type='number' [(ngModel)]="settings.departure_price_multiplier" name="departurePriceMultiplier" required
               matTooltip="Departure price multiplier">
        <mat-hint>{{priceMultiplierHub}}</mat-hint>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Departure price add-on</mat-label>
        <input matInput type='number' [(ngModel)]="settings.departure_price_addon" name="departurePriceAddon" required
               matTooltip="Departure price add-on">
        <mat-hint>$/MMBTU</mat-hint>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Month lag</mat-label>
        <mat-select [(ngModel)]="settings.lag_info" name="monthLag" required>
          <mat-option value="0">0</mat-option>
          <mat-option value="1">1</mat-option>
          <mat-option value="2">2</mat-option>
          <mat-option value="LTE1">0 - 1</mat-option>
          <mat-option value="LTE2">0 - 2</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item" style="justify-content: center">
      <mat-checkbox [(ngModel)]="settings.minimize_idling" name="minimizeIdling"
                    matTooltip="Minimize idling">Min. Idle </mat-checkbox>
    </div>
  </div>
  <div class="em-u-margin-top" style="float: right">
    <button class="em-c-btn em-c-btn--primary em-c-btn--small em-u-margin-right-half" type="submit"
            [class.em-c-btn--disabled]="!settingsForm.valid || !settingsForm.dirty" [disabled]="!settingsForm.valid || !settingsForm.dirty">
      Apply
    </button>
  </div>
</form>
