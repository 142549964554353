import { Injectable } from "@angular/core";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root',
})

export class PortfolioVarHelperService {
  private allRegions = [
    {
      display: 'AGOT',
      value: 'AGOT'
    },
    //{
    //  display: 'CANADA',
    //  value: 'CANADA'
    //},
    {
      display: 'EGOT',
      value: 'EGOT'
    },
    {
      display: 'LNG',
      value: 'LNG'
    },
    {
      display: 'Global',
      value: 'GGOT'
    },
  ];

  constructor(private userService: UserService) { }

  getRegionsForUser(): any[] {
    const role = this.userService.getUserRole();
    const region = this.userService.getUserRegion();

    if (role === 'Admin' || role === 'Risk' || region === 'ALL') {
      return [...this.allRegions];
    }

    var regions = [];
    var foundRegion = this.allRegions.find(x => x.display === region);
    if (foundRegion) regions.push({ ...foundRegion });
    regions.push({ ...this.allRegions.find(x => x.display === 'Global') })

    return regions;
  }

}
