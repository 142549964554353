import { OnInit, Renderer2 } from '@angular/core';
import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { ScriptInjectorService } from './services/script-injector.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  title = 'GGOT Market Economics and Risk Analytics';
  showStagingWarning = false;
  showOCP4Warning = false;

  constructor(private scriptService: ScriptInjectorService, private renderer: Renderer2) {  }

  ngOnInit(): void {
    if (environment.version === 'Staging') {
      this.showStagingWarning = true;
    }
    if(environment.version === 'ocp4'){
      this.showOCP4Warning = true;
    }

    this.scriptService.injectScript(this.renderer, 'unity/js/em-unity-1.7.0.min.js');
  }
}
