import { Component, QueryList, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BaseChartDirective } from 'ng2-charts';
import { FileSaverService } from 'ngx-filesaver';
import { jsonToCsv } from '../../helpers/json-to-csv';
import { DateHelperService } from '../../services/date-helper.service';
import { RiskMetricsService } from '../../services/risk-metrics.service';
import { BaseCurvesComponent } from '../base-curves.component';

@Component({
  selector: 'app-uk-curves',
  templateUrl: './uk-curves.component.html',
  styleUrls: ['./uk-curves.component.scss']
})
export class UkCurvesComponent extends BaseCurvesComponent {
  @ViewChildren(BaseChartDirective) charts?: QueryList<BaseChartDirective>;

  constructor(riskMetricsService: RiskMetricsService, dateHelperService: DateHelperService, private fileSaverService: FileSaverService,
    private titleService: Title) {
    super(riskMetricsService, dateHelperService);
    this.titleService.setTitle('Trading Preview UI | UK Macro Curves');
  }

  createChartData(): void {
    this.interestRateChartData.datasets = [];
    this.inflationChartData.datasets = [];

    this.interestRateChartData.datasets.push({
      data: this.curveData.interest_rate?.sonia_historical?.map(x => x * 100),
      pointBackgroundColor: '#3a397b',
      pointHoverBorderColor: '#3a397b',
      borderColor: '#3a397b',
      label: 'Sonia Historical',
    });

    this.inflationChartData.datasets.push({
      data: this.curveData.inflation_rate?.cpih_yoy?.map(x => x * 100),
      pointBackgroundColor: '#3a397b',
      pointHoverBorderColor: '#3a397b',
      borderColor: '#3a397b',
      label: 'YoY CPIH'
    });

    if (this.viewRate === 'forward') {
      this.interestRateChartData.datasets.push({
        data: this.curveData.interest_rate?.uk_ois_fwd_rate?.map(x => x * 100),
        label: 'UK OIS Forward Rate',
        borderDash: [5, 15],
      });
      this.interestRateChartData.datasets.push({
        data: this.curveData.interest_rate?.glc_nominal_fwd_rate?.map(x => x * 100),
        label: 'GLC Nominal Forward Rate',
        borderDash: [5, 15]
      });

      this.inflationChartData.datasets.push({
        data: this.curveData.inflation_rate?.glc_inflation_fwd_rate?.map(x => x * 100),
        label: 'GLC Inflation Forward Rate',
        borderDash: [5, 15]
      });
    }
    else {
      this.interestRateChartData.datasets.push({
        data: this.curveData.interest_rate?.uk_ois_zero_rate?.map(x => x * 100),
        label: 'UK OIS Zero Rate',
        borderDash: [5, 15],
      });
      this.interestRateChartData.datasets.push({
        data: this.curveData.interest_rate?.glc_nominal_zero_rate?.map(x => x * 100),
        label: 'GLC Nominal Zero Rate',
        borderDash: [5, 15],
      });

      this.inflationChartData.datasets.push({
        data: this.curveData.inflation_rate?.glc_inflation_zero_rate?.map(x => x * 100),
        label: 'GLC Inflation Zero Rate',
        borderDash: [5, 15],
      });
    }
    
    this.interestRateChartData.labels = this.curveData.interest_rate?.dates;
    this.interestRateAsOfDate = this.curveData.interest_rate?.as_of_date;

    this.inflationChartData.labels = this.curveData.inflation_rate?.dates;
    this.inflationAsOfDate = this.curveData.inflation_rate?.as_of_date;

    this.charts.forEach(c => c.update());
  }

  get region(): string {
    return 'UK';
  }

  saveInterestRateData(): void {
    const data = {
      date: this.curveData.interest_rate.dates,
      soniaHistorical: this.curveData.interest_rate.sonia_historical,
      ukOisFwd: this.curveData.interest_rate.uk_ois_fwd_rate,
      glcNominalFwd: this.curveData.interest_rate.glc_nominal_fwd_rate,
      ukOisZero: this.curveData.interest_rate.uk_ois_zero_rate,
      glcNominalZero: this.curveData.interest_rate.glc_nominal_zero_rate,
    }
    this.fileSaverService.saveText(jsonToCsv(data), `uk-interestRates${this.interestRateAsOfDate}.csv`);
  }

  saveInflationRateData(): void {
    const data = {
      date: this.curveData.inflation_rate.dates,
      cpihYoy: this.curveData.inflation_rate.cpih_yoy,
      glcInflationFwd: this.curveData.inflation_rate.glc_inflation_fwd_rate,
      glcInflationZero: this.curveData.inflation_rate.glc_inflation_zero_rate
    }
    this.fileSaverService.saveText(jsonToCsv(data), `uk-inflationRates${this.inflationAsOfDate}.csv`);
  }
}
