import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AccountInfo } from '@azure/msal-browser';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  user?: AccountInfo;
  userChangedSubscription: Subscription;
  optionsCalcRelease: boolean = false;
  physicalLNGRelease: boolean = false;

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    this.userChangedSubscription = this.userService.userChanged.subscribe(user => {
      this.user = user;
    });
    if (environment.version === 'Staging' || environment.version === 'Development') {
      this.optionsCalcRelease = true;
    }
    if (environment.version === 'Staging' || environment.version === 'Development') {
      this.physicalLNGRelease = true;
    }
    
  }

  login(): void {
    this.userService.login();
    this.router.navigate(['/']);
  }

  logout(): void {
    this.userService.logout();
  }

  ngOnDestroy(): void {
    this.userChangedSubscription.unsubscribe();
  }
}
