<div class="em-c-alert em-c-alert--error" role="alert" *ngIf="hasError">
  <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#circle-exclamation-mark"></use>
  </svg>
  <div class="em-c-alert__body">
    Pricing distribution data for the specified criteria does not exist.
  </div>
  <div class="em-c-alert__actions">
    <button class="em-c-text-btn em-c-alert--error" (click)="hasError = false">
      Close
    </button>
  </div>
</div>

<h3>
  Implied Market Price Distribution
</h3>
<div class="em-l-grid">
  <div class="em-l-grid__item">
    <div>
      <mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
        <mat-label>Trade date</mat-label>
        <input matInput type="date" [max]="today" [(ngModel)]="tradeDate" (ngModelChange)="fetchChartData()" [disabled]="isPullingData">
      </mat-form-field>
      <mat-form-field class="em-u-padding-top-none em-u-padding-right small-field">
        <mat-label>Hub</mat-label>
        <mat-select [(ngModel)]="selectedHub" (ngModelChange)="fetchChartData(); fetchReferenceChartData();" [disabled]="isPullingData">
          <mat-option *ngFor="let hub of hubs" [value]="hub">{{hub}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="em-u-padding-top-none em-u-padding-right large-field">
        <mat-label>Price Distribution</mat-label>
        <mat-select multiple [(ngModel)]="prices" (ngModelChange)="updatePriceSeries()" [disabled]="isPullingData">
          <mat-option *ngFor="let p of priceOptions" [value]="p">{{p}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="em-u-padding-top-none medium-field">
        <mat-label>Reference date</mat-label>
        <input matInput type="date" [max]="today" [(ngModel)]="referenceDate" (ngModelChange)="fetchReferenceChartData()" [disabled]="isPullingData">
      </mat-form-field>
      <button class="em-c-btn em-c-btn--small em-c-btn--tertiary" (click)="clearReferencePriceSeries()" matTooltip="Clear reference date">
        <div class="em-c-btn__inner">
          <svg class="em-c-icon em-c-icon--tiny em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#x-filled"></use>
          </svg>
        </div>
      </button>
      <button class="em-c-btn em-c-btn--small em-c-btn--tertiary em-u-margin-top" (click)="saveData()" matTooltip="Export data to CSV" [disabled]="hasError" [class.em-c-btn--disabled]="hasError" style="float:right">
        <div class="em-c-btn__inner">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#save-disk"></use>
          </svg>
        </div>
      </button>
    </div>
  </div>

  <div class="em-l-grid__item em-u-margin-bottom">
    <canvas baseChart
            [data]="chartData"
            [options]="lineChartOptions"
            type="line">
    </canvas>
  </div>
</div>
