import { AfterViewInit, Component, OnInit } from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';


 interface CalculationNode {
  name: string;
  children?: CalculationNode[];
}

const TREE_DATA: CalculationNode[] = [
  {
    name: 'Netback ($/mmBTU)',
    children: [
      {
        name: 'Destination Price ($/mmBTU)',
        children: [
          {name: 'Destination Price (Local Currency)'},
          {name: '* (Multiply)'},
          {name: 'FX Rate to $'},
          {name: '* (Multiply)'},
          {name: 'Convert to MMBTU'},
        ]
      },
      {name: '- (Subtract)'},
      {
        name: 'FOB ($/mmBTU)',
        children: [
          {name: 'Purchase Price ($/mmBTU)'},
          {name: '* (Multiply)'},
          {name: 'Purchase Price Multiplier'},
          {name: 'Purchase Addon ($/mmBTU)'},
        ]
      },
      {name: '- (Subtract)'},
      {
        name: 'Shipping Cost ($/mmBTU)',
        children: [
          {
            name: 'Total Shipping Cost (M$)',
            children: [
              {name: 'Port Cost (M$)'},
              {name: 'Insurance and Piracy Prevention (M$)'},
              {name: 'Canal Cost (M$)'},
              {
                name: 'Charter Cost (M$)',
                children: [
                  {name: 'Charter Rate ($/day)'},
                  {name: '* (Multiply)'},
                  {
                    name: 'Travel Days',
                    children: [
                      {
                        name: 'Outbound Fullspeed Days',
                        children: [
                          {name: 'Outbound Sea Distance'},
                          {name: '/ (Divide)'},
                          {name: 'Ship Speed'},
                          {name: '/24'},
                        ]
                      },
                      {name: 'Outbound Idle Days'},
                      {
                        name: 'Heel Travel Days',
                        children: [
                          {name: 'Heel Sea Distance'},
                          {name: '/ (Divide)'},
                          {name: 'Ship Speed'},
                          {name: '/24'},
                        ]
                      },
                      {name: 'Canal Wait Days'},
                    ]
                  },
                  {name: '* (Multiply)'},
                  {name: 'Charter Cost Multiplier'},
                  {name: '/1e6'},
                ]
              },
              {
                name: 'Fuel Cost (M$)',
                children: [
                  {name: 'Fuel Rate ($/MT)'},
                  {name: '* (Multiply)'},
                  {name: 'Additional Fuel Volume (MT/day)'},
                  {name: '* (Multiply)'},
                  {name: 'Travel Days'},
                  {name: '/1e6'},
                ]
              },
              {
                name: 'Boil Off Cost (M$)',
                children: [
                  {
                    name: 'Boil Off Volume (Tbtu)',
                    children: [
                      {name: 'Boiloff Rate Per day'},
                      {name: '* (Multiply)'},
                      {name: 'Ship Size (M3)'},
                      {name: '* (Multiply)'},
                      {name: 'MMBTU per M3'},
                      {name: '* (Multiply)'},
                      {name: 'Travel Days'},
                      {name: '/1e6'},
                    ]
                  },
                  {name: '* (Multiply)'},
                  {name: 'Destination Price ($/mmBTU)'}
                ]
              }
            ]
          },
          {name: '/ (Divide)'},
          {
            name: 'Discharge Volume (Tbtu)',
            children: [
              {
                name: 'Transported Volume (Tbtu)',
                children: [
                  {name: 'Ship Size (M3)'},
                  {name: '* (Multiply)'},
                  {name: 'MMBTU per M3'},
                  {name: '* (Multiply)'},
                  {name: 'Fill Ratio'},
                  {name: '/1e6'},
                ]
              },
              {name: '- (Subtract)'},
              {name: 'Boil Off Volume (Tbtu)'}
            ]
          }
        ]
      }
    ],
  }
];

@Component({
  selector: 'app-netback-calculation',
  templateUrl: './netback-calculation.component.html',
  styleUrls: ['./netback-calculation.component.scss']
})
export class NetbackCalculationComponent implements OnInit, AfterViewInit {
  treeControl = new NestedTreeControl<CalculationNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<CalculationNode>();
  displaySelected: string = 'full';

  constructor() {
    this.dataSource.data = TREE_DATA;
  }

  hasChild = (_: number, node: CalculationNode) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.treeControl.dataNodes = TREE_DATA;
    this.treeControl.expandAll();
  }
}
