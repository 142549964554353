<div class="em-c-loader spinner" *ngIf="isCalculating">
  <img src="unity/images/icon-spinner.svg" />
</div>

<h3>Netback</h3>

<mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
  <mat-label>Trade Date</mat-label>
  <input matInput type="date" [(ngModel)]="tradeDate" [max]="today" (ngModelChange)="tradeDateChange()">
</mat-form-field>

<mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
  <mat-label>Origin</mat-label>
  <mat-select [(ngModel)]="selectedOrigin" (ngModelChange)="updateOrigin()">
    <mat-option *ngFor="let origin of allOrigins" [value]="origin">{{origin}}</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="em-u-padding-top-none em-u-padding-right large-field">
  <mat-label>Destinations</mat-label>
  <mat-select multiple [(ngModel)]="selectedDestinations" (ngModelChange)="updateData()">
    <mat-optgroup [ngClass]="{'em-visible':group.show}"  class="em-optgroup" *ngFor="let group of destinationsTree"><b>{{group.area}}</b>
      <mat-option style="height:1.5em;" class="em-destname-height" *ngFor="let dest of group.names" [value]="dest">{{dest}}</mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>

<mat-form-field class="em-u-padding-top-none medium-field">
  <mat-label>Diff. Hub</mat-label>
  <mat-select [(ngModel)]="diffHub" (ngModelChange)="updateData()"
              matTooltip="Select hub to plot netback difference">
    <mat-option *ngFor="let dest of allDestinations" [value]="dest">{{dest}}</mat-option>
  </mat-select>
</mat-form-field>
<button class="em-c-btn em-c-btn--tertiary em-c-btn--tiny em-u-margin-top" (click)="clearDiffHub()"
        matTooltip="Clear netback diff hub" matTooltipPosition="above" *ngIf="diffHub != null">
  <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#x-filled"></use>
  </svg>
</button>

<div class="em-c-table-object__header">
  <div class="em-c-toolbar ">
    <div class="em-c-toolbar__item">
      <div class="em-c-btn-group ">
        <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="showNetbackSettings = !showNetbackSettings"
                matTooltip="Show/hide settings" matTooltipPosition="above">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#sliders"></use>
          </svg>
        </button>

        <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="calculateNetback()"
                matTooltip="Calculate" matTooltipPosition="above">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#refresh"></use>
          </svg>
        </button>
      </div>
    </div>
    <div class="em-c-toolbar__item em-is-aligned-right">
      <div class="em-c-btn-group ">
        <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="openNetbackCalculationImage()"
                matTooltip="View calculation diagram" matTooltipPosition="above">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#info"></use>
          </svg>
        </button>

        <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="downloadNetback()"
                matTooltip="Download netback as Excel" matTooltipPosition="above" [class.em-c-btn--disabled]="isDownloading" [disabled]="isDownloading">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#save-disk"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>

<div style="display: flex; width: 100%">
  <app-netback-settings *ngIf="showNetbackSettings" [origin]="selectedOrigin"></app-netback-settings>
  <div class="chart-container">
    <canvas baseChart
            [data]="netbackProfitChartData"
            [options]="netbackProfitChartOptions"
            type="line">
    </canvas>
    <canvas class="em-u-margin-top"
            baseChart
            [data]="priceSpreadChartData"
            [options]="priceSpreadChartOptions"
            type="line">
    </canvas>
    <canvas class="em-u-margin-top"
            baseChart
            [data]="shippingDecompChartData"
            [options]="shippingDecompChartOptions"
            type="bar">
    </canvas>
  </div>
</div>

<div>
  <canvas class="em-u-margin-top"
            baseChart
            [data]="netbackLagOneChartData"
            [options]="netbackLagOneChartOptions"
            type="line">
    </canvas>
    <canvas class="em-u-margin-top"
            baseChart
            [data]="netbackLagTwoChartData"
            [options]="netbackLagTwoChartOptions"
            type="line">
    </canvas>
</div>

<table mat-table [dataSource]="dataSource" class="em-c-table em-u-margin-top">
  <ng-container *ngFor="let col of displayedColumns; let i = index " [matColumnDef]="col">
    <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double em-u-text-align-center" [ngClass]="{'em-u-text-align-right': i > 0}" [width]="100/displayedColumns.length + '%'"> {{ col }} </th>
    <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top em-u-text-align-center" [ngClass]="{'em-u-text-align-right': i > 0}">
      {{ isNaN(element[col]) ? element[col] : (!diffHub ? (element[col] | number) : (element[col] - element[diffHub] | number)) }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
