<div class="em-l-grid">
  <div class="em-l-grid__item">
    <span class="em-u-margin-top-double center">
      <svg class="em-c-icon em-c-icon--xlarge">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/48/em-icons.svg#circle-check"></use>
      </svg>
    </span>
    <h2 class="em-u-margin center">Trade <a class="blue-link" routerLink="/trade-blotter/view/{{tradeId}}">#{{ tradeId }}</a> has been successfully submitted for approval.</h2>
  </div>
</div>
