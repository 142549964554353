import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SecurityInfo } from '../models/security-info.model';
import { convertb64toBlob } from '../helpers/convert-to-blob';
import { DateHelperService } from '../services/date-helper.service';

@Injectable({
  providedIn: 'root',
})
export class RiskMetricsService {
  readonly baseUrl = environment.riskMetricsEndpoint;

  private securityInfoCache$: Observable<SecurityInfo[]>;

  constructor(private http: HttpClient) {}

  getSecuritiesInfo(): Observable<SecurityInfo[]> {
    if (!this.securityInfoCache$) {
      const url = `${this.baseUrl}/get-contract-help`;
      this.securityInfoCache$ = this.http
        .get<any>(url)
        .pipe(map((res) => res.contract_help))
        .pipe(shareReplay(1));
    }
    return this.securityInfoCache$;
  }

  getSecurityUnits(code: string): Observable<any> {
    const url = `${this.baseUrl}/get-units`;
    return this.http.get(url, {
      params: new HttpParams().set('security', code),
    });
  }

  getEODPrice(code: string, stripDate: string): Observable<any> {
    const url = `${this.baseUrl}/get-eod-price`;
    return this.http.get(url, {
      params: new HttpParams()
        .set('security', code)
        .set('strip', `${stripDate}-01`),
    });
  }

  getIntradayPrice(code: string, stripDate: string): Observable<any> {
    const url = `${this.baseUrl}/get-intraday-price`;
    return this.http.get(url, {
      params: new HttpParams()
        .set('security', code)
        .set('strip', `${stripDate}-01`),
    });
  }

  getEODPriceSummary(code: string): Observable<any> {
    const url = `${this.baseUrl}/get-eod-strip-price-summary`;
    return this.http
      .get(url, { params: new HttpParams().set('security', code) })
      .pipe(map((res) => res[Object.keys(res)[0]]));
  }

  calculateVaR(inputs: any): Observable<any> {
    const url = `${this.baseUrl}/calculate-var`;
    const data = inputs;
    return this.http.post(url, data);
  }

  getUnitVar(inputs: any): Observable<any> {
    var dhs = new DateHelperService();
    var sdate = dhs.getDateyyyyMMdd(inputs.date_horizon);
    const url = `${this.baseUrl}/calculate-unit-var`;
    return this.http
      .get<any>(url, {
        params: new HttpParams()
          .set('region_office', inputs.region)
          .set('alpha', inputs.alpha)
          .set('currency', inputs.currency)
          .set('date_horizon', sdate),
      })
      .pipe(map((res) => res.unit_var));
  }

  getPortfolioVar(inputs: any): Observable<any[]> {
    var dhs = new DateHelperService();
    var sdate = dhs.getDateyyyyMMdd(inputs.trade_date);
    const url = `${this.baseUrl}/get-portfolio-var`;
    return this.http
      .get<any>(url, {
        params: new HttpParams()
          .set('region_office', inputs.region)
          .set('trade_date', sdate),
      })
      .pipe(map((res) => res.portfolio_var));
  }

  getPortfolioVarFile(inputs: any): Observable<any> {
    var dhs = new DateHelperService();
    var sdate = dhs.getDateyyyyMMdd(inputs.trade_date);
    const url = `${this.baseUrl}/download-portfolio-var`;
    return this.http
      .get<any>(url, {
        params: new HttpParams()
          .set('region_office', inputs.region)
          .set('trade_date', sdate),
      })
      .pipe(
        map((res) => {
          return {
            fileName: res.file_name,
            blob: convertb64toBlob(res.result[0]),
          };
        })
      );
  }

  getVolatilityTable(inputs: any): Observable<any> {
    var dhs = new DateHelperService();
    var sdate = dhs.getDateyyyyMMdd(inputs.trade_date);
    const url = `${this.baseUrl}/get-volatility`;
    return this.http
      .get<any>(url, {
        params: new HttpParams()
          .set('region_office', inputs.region)
          .set('trade_date', sdate)
          .set('vol_type', inputs.vol_type),
      })
      .pipe(map((res) => res.volatility));
  }

  getLotSize(code: string, stripDate: string): Observable<any> {
    const url = `${this.baseUrl}/get-lot-to-quantity`;
    return this.http
      .get<any>(url, {
        params: new HttpParams()
          .set('security', code)
          .set('strip', `${stripDate}-01`),
      })
      .pipe(map((res) => res.quantity));
  }

  getMacroCurvesByRegion(region: string, date: string): Observable<any> {
    var dhs = new DateHelperService();
    var sdate = dhs.getDateyyyyMMdd(date);
    const url = `${this.baseUrl}/get-region-macro-curves`;
    return this.http.get<any>(url, {
      params: new HttpParams()
        .set('macro_curve_region', region)
        .set('as_of_date', sdate),
    });
  }

  getMacroCurvesFXDates(): Observable<any> {
    const url = `${this.baseUrl}/get-macro-curves-fx-dates`;
    return this.http.get<any>(url).pipe(map((res) => res.dates));
  }

  getMacroCurvesFX(date: string): Observable<any> {
    var dhs = new DateHelperService();
    var sdate = dhs.getDateyyyyMMdd(date);
    const url = `${this.baseUrl}/get-macro-curves-fx`;
    return this.http.get<any>(url, {
      params: new HttpParams().set('as_of_date', sdate),
    });
  }

  getMacroCurveDatesByRegion(region: string): Observable<any> {
    const url = `${this.baseUrl}/get-macro-curves-dates`;
    return this.http
      .get<any>(url, {
        params: new HttpParams().set('macro_curve_region', region),
      })
      .pipe(map((res) => res.dates));
  }

  getMarketImpliedHubs(): Observable<any> {
    const url = `${this.baseUrl}/get-market-implied-contracts`;
    return this.http.get<any>(url).pipe(map((res) => res.contracts));
  }

  getMarketImpliedPriceDistribution(
    date: string,
    hub: string
  ): Observable<any> {
    var dhs = new DateHelperService();
    var sdate = dhs.getDateyyyyMMdd(date);
    const url = `${this.baseUrl}/get-market-implied-price-distribution`;
    return this.http.get<any>(url, {
      params: new HttpParams().set('trade_date', sdate).set('contract', hub),
    });
  }

  getMarketImpliedVolSurface(date: string, hub: string): Observable<any> {
    var dhs = new DateHelperService();
    var sdate = dhs.getDateyyyyMMdd(date);
    const url = `${this.baseUrl}/get-market-implied-vol-surface`;
    return this.http.get<any>(url, {
      params: new HttpParams().set('trade_date', sdate).set('contract', hub),
    });
  }

  getMarketImpliedStrips(date: string): Observable<any> {
    var dhs = new DateHelperService();
    var sdate = dhs.getDateyyyyMMdd(date);
    const url = `${this.baseUrl}/get-market-implied-strips`;
    return this.http.get<any>(url, {
      params: new HttpParams().set('trade_date', sdate),
    });
  }

  getMarketImpliedDeltaStrike(
    date: string,
    hub: string,
    strip: string
  ): Observable<any> {
    var dhs = new DateHelperService();
    var sdate = dhs.getDateyyyyMMdd(date);
    const url = `${this.baseUrl}/get-market-implied-vol-delta-strike`;
    return this.http.get<any>(url, {
      params: new HttpParams()
        .set('trade_date', sdate)
        .set('contract', hub)
        .set('strip', strip),
    });
  }

  getOptionsCalculate(inputs: any) {
    const url = `${this.baseUrl}/calculate-options`;
    return this.http.post<any>(url, inputs);
  }

  getPipelineFuturesPrices(inputs: any) {
    const url = `${this.baseUrl}/get_pipeline_futures_prices`;
    return this.http.post<any>(url, inputs);
  }

  getTransitionDefaults(inputs: any) {
    const url = `${this.baseUrl}/get-price-path-transition-defaults`;
    return this.http.post(url, inputs);
  }

  getPricePaths(inputs: any) {
    const url = `${this.baseUrl}/get-price-paths`;
    return this.http.post(url, inputs);
  }

  downloadPipelineFuturesPrices(inputs: any) {
    const url = `${this.baseUrl}/download_pipeline_futures_prices`;
    return this.http.post<any>(url, inputs).pipe(
      map((res) => {
        return {
          fileName: res.file_name,
          blob: convertb64toBlob(res.result[0]),
        };
      })
    );
  }

  downloadPricePaths(inputs: any) {
    const url = `${this.baseUrl}/download-price-paths`;
    return this.http.post<any>(url, inputs).pipe(
      map((res) => {
        return {
          fileName: res.file_name,
          blob: convertb64toBlob(res.result[0]),
        };
      })
    );
  }

  getCorrPricePaths(inputs: any) {
    const url = `${this.baseUrl}/get-corr-price-paths`;
    return this.http.post(url, inputs);
  }

  downloadCorrPricePaths(inputs: any) {
    const url = `${this.baseUrl}/download-corr-price-paths`;
    return this.http.post<any>(url, inputs).pipe(
      map((res) => {
        return {
          fileName: res.file_name,
          blob: convertb64toBlob(res.result[0]),
        };
      })
    );
  }

  calculatePhysicalLNG(inputs: any) {
    const url = `${this.baseUrl}/calculate-deal-economics`;
    return this.http.post<any>(url, inputs);
  }

  getPhysicalDealInitData() {
    const url = `${this.baseUrl}/get-physical-deal-init-data`;
    return this.http.get<any>(url);
  }

  getDownloadPhysicalDeal(inputs: any): Observable<any> {
    const url = `${this.baseUrl}/download-deal-data-excel`;
    return this.http.post<any>(url, inputs).pipe(
      map((res) => {
        return {
          fileName: res.file_name,
          blob: convertb64toBlob(res.result[0]),
        };
      })
    );
  }

  getDesDesDownloadExcel(inputs: any): Observable<any> {
    const url = `${this.baseUrl}/download-desdes-deal-data-excel`;
    return this.http.post<any>(url, inputs).pipe(
      map((res) => {
        return {
          fileName: res.file_name,
          blob: convertb64toBlob(res.result[0]),
        };
      })
    );
  }

  getTcoDownloadExcel(inputs: any): Observable<any> {
    const url = `${this.baseUrl}/download-tco-deal-data-excel`;
    return this.http.post<any>(url, inputs).pipe(
      map((res) => {
        return {
          fileName: res.file_name,
          blob: convertb64toBlob(res.result[0]),
        };
      })
    );
  }

  calculatePhysicalLNGDesdes(inputs: any) {
    const url = `${this.baseUrl}/calculate-deal-economics-desdes`;
    return this.http.post<any>(url, inputs);
  }

  getPhysicalDealInitDataDesdes() {
    const url = `${this.baseUrl}/get-physical-deal-init-data-desdes`;
    return this.http.get<any>(url);
  }

  calculatePhysicalLNGTco(inputs: any) {
    const url = `${this.baseUrl}/calculate-deal-economics-tco`;
    return this.http.post<any>(url, inputs);
  }

  getPhysicalDealInitDataTco() {
    const url = `${this.baseUrl}/get-physical-deal-init-data-tco`;
    return this.http.get<any>(url);
  }

  getFileDownload(inputs: any): Observable<string[]> {
    const url = `${this.baseUrl}/file-download`;
    return this.http.get<any>(url, {
      params: new HttpParams({ fromObject: inputs }),
    });
  }
}
