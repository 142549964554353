export class TradeSummaryDesdes {
    seller: string;
    buyer: string;
    dischargeAt: string;
    dischargeType: string = "DES / DES";
    dischargeDate: string;
    vesselType: string;
    netRealisationPerUnit: number = 0.0;
    netRealisationAmount: number = 0.0;
}

export class DealEconomicsBreakdownDesdes {
    terminalCostUsd: number;
    cargoLoadedUsd: number;
    cargoDischargedUsd: number;
}

/* Inputs */

export class PhysicalLngInputDesdes {
    // Purchase and sales stuff. 
    prch_lng_price: number;
    prch_misc_cost: number;
    prch_volume_mmbtu: number;
    prch_tolerance: number;
    prch_tolerance_percent: number;
    sals_lng_price: number;
    sals_misc_cost: number;
    sals_volume_mmbtu: number;
    sals_tolerance: number;
    sals_tolerance_percent: number;
    // Other stuff. 
    load_port: string;
    discharge_port: string;
    discharge_date: string;
    include_discharge_port_cost: boolean;
    vessel_type: string;

    TestLoadDefaults(){
        this.prch_lng_price = 15.0;
        this.prch_misc_cost = 20000.0;
        this.prch_volume_mmbtu = 3204636.33;
        this.prch_tolerance_percent = -3;
        this.sals_lng_price = 50.0;
        this.sals_misc_cost = 30000.0;
        this.sals_volume_mmbtu = 3084298.53;
        this.sals_tolerance_percent = 2;
        this.load_port = 'United States Cameron';
        this.discharge_port = 'United Kingdom South Hook';
        this.discharge_date = '2023-03-01T06:00';
        this.vessel_type = 'Steam Turbine';
        this.include_discharge_port_cost = false;
        }
}
