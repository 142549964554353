export class TradeSummaryTco {
    deal_type: string = "TCO";
    owner: string;
    charterer: string;
    charter_out_beg_date: string;
    charter_out_end_date: string;
    net_realisation_amount: number = 0.0;
}

export class VolumeBalancesTco {
    heel_onboard_at_charter_out_beg__mmbtu: number;
    heel_onboard_at_charter_out_end__mmbtu: number;
    heel_onboard_at_charter_out_beg__m3: number;
    heel_onboard_at_charter_out_end__m3: number;
}

export class DealEconomicsTco {
    // charter-IN
    charter_in_cost: number;
    charter_in_fuel_cost: number;
    charter_in_other_cost: number;
    // charter-OUT
    charter_out_revenue: number;
    charter_out_idle_boiloff_fuel_cost: number;
    charter_out_other_cost: number;
    owner_ballast_bonus_and_misc_revenue: number;
    // other items
    heel_arb: number;
    net_charter_cost_revenue_uplift: number;
    net_boiloff_fuel_cost_revenue_uplift: number;
    net_savings_misc_cost_revenue_uplift: number;
    // net realization
    net_realization: number = 0.0;
}


export class LngPrices {
    sale_des_price: number;
    heel_sale_price:  number;
    heel_purchase_price: number;
    purchase_fob_price: number;
    
    TestDefaults(){
        this.sale_des_price = 50.0;
        this.heel_sale_price = this.sale_des_price;
        this.heel_purchase_price = this.sale_des_price;
        this.purchase_fob_price = 0.0;
    }
}

export class FuelPrices {
    hfo: number;
    mdo: number;

    TestDefaults(){
        this.hfo = 550.0;
        this.mdo = 750.0;
    }
}

export class VesselInfo {
    vessel_name?:string;
    vessel_type?:string;
    capacity?: number;
    in_charter_rate?:number;
    boiloffrate_laden?:number;
    boiloffrate_ballast?:number;
    constructor(){
    }
}

export class CharterInDatesPricesCosts {
    charter_in_beg_date: string;
    charter_in_end_date: string;
    charter_in_total_full_days_calculated: number;

    charter_in_rate: number;

    charter_in_cooldown_cost: number;
    charter_in_cargo_insurance_cost: number;
    charter_in_other_insurance_cost: number;
    charter_in_misc_cost: number;

    TestDefaults(){
        this.charter_in_beg_date = "2023-02-16T06:00";
        this.charter_in_end_date = "2023-03-01T06:00";
        // this.charter_in_total_full_days_calculated = 0.0;    
        this.charter_in_rate = 150000.0;
        this.charter_in_cooldown_cost = 5000.0;
        this.charter_in_cargo_insurance_cost = 50000.0;
        this.charter_in_other_insurance_cost = 250.0;
        this.charter_in_misc_cost = 0.01;
    }
}

export class CharterOutDatesPricesCosts {
    charter_out_beg_date: string;
    charter_out_end_date: string;
    charter_out_total_full_days_calculated: number;
    charter_out_total_full_days_override: number;

    charter_out_rate: number;

    charter_out_misc_revenue: number;
    
    charter_out_cooldown_cost: number;
    charter_out_cargo_insurance_cost: number;
    charter_out_other_insurance_cost: number;
    charter_out_misc_cost: number;

    TestDefaults(){
        this.charter_out_beg_date = "2023-02-20T06:00";
        this.charter_out_end_date = "2023-02-25T06:00";
        // this.charter_out_total_full_days_calculated = 0.0;
        this.charter_out_rate = 120000.0;
        this.charter_out_misc_revenue = 12345.0;
        this.charter_out_cooldown_cost = 10000.0;
        this.charter_out_cargo_insurance_cost = 50000.0;
        this.charter_out_other_insurance_cost = 250.0;
        this.charter_out_misc_cost = 0.01;
    }
}

export class LogisticsTco {
    heel_onboard_at_charter_out_beg: number;
    heel_onboard_at_charter_out_end: number;

    bor_idle_overriden: number;

    include_bunker_operation_for_reliq: string;
    fixed_costs_barges_etc: number;
    days_at_anchorage_for_bunker: number;

    do_positioning_bonus: boolean;
    positioning_bonus_details: PositioningBonusDetails;

    do_repositioning_bonus: boolean;
    repositioning_bonus_details: PositioningBonusDetails;

    constructor() { 
        this.positioning_bonus_details = new PositioningBonusDetails();
        this.repositioning_bonus_details = new PositioningBonusDetails();
    }
    /**
     * borIdleOverridenValueChanged
     */
    public borIdleOverridenValueChanged(val: number) {
        if (val === null) {
            this.bor_idle_overriden = 0.0;
        }
    }
    TestDefaults(){
        this.heel_onboard_at_charter_out_beg = 4000;
        this.heel_onboard_at_charter_out_end = 3000;    
        // this.bor_idle_overriden = 0.0;    
        this.include_bunker_operation_for_reliq = "No";
        this.fixed_costs_barges_etc = 25000.0;
        this.days_at_anchorage_for_bunker = 2.35;
    
        this.do_positioning_bonus = false;
        this.positioning_bonus_details.TestDefaults();
    
        this.do_repositioning_bonus = false;
        this.repositioning_bonus_details.TestDefaults();
    
    }
}

export class PositioningBonusDetails {
    charter_rate: number;
    fuel_rate: number;
    charter_rate_display: number;
    fuel_rate_display: number;
    fuel_type_agreed: string;
    speed: number;
    lumpsum: number;

    TestDefaults(){
        this.charter_rate = 1.0;
        this.charter_rate_display = 100.0;
        this.fuel_rate = 1.0;
        this.fuel_rate_display = 100.0;
        this.fuel_type_agreed = "HFO";
        this.speed = 15.0;
        // this.lumpsum = 0.0;
    }
}

/* Inputs */

export class PhysicalLngInputTco {
    lng_prices: LngPrices;
    fuel_prices: FuelPrices;
    // vessel_info: VesselInfo;
    vessel_name: string;
    charter_in_dates_prices_costs: CharterInDatesPricesCosts;
    charter_out_dates_prices_costs: CharterOutDatesPricesCosts;
    logistics: LogisticsTco;

    constructor(){
        this.lng_prices = new LngPrices();
        this.fuel_prices = new FuelPrices();
        // this.vessel_name = "148 ST Vessel";
        this.charter_in_dates_prices_costs = new CharterInDatesPricesCosts();
        this.charter_out_dates_prices_costs = new CharterOutDatesPricesCosts();
        this.logistics = new LogisticsTco();
    }
    TestLoadDefaults(){
        this.lng_prices.TestDefaults();
        this.fuel_prices.TestDefaults();
        this.vessel_name = "148 ST Vessel";
        this.charter_in_dates_prices_costs.TestDefaults();
        this.charter_out_dates_prices_costs.TestDefaults();
        this.logistics.TestDefaults();
    }
}

