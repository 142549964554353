import { Component, OnInit } from '@angular/core';
import { DateHelperService } from '../../../services/date-helper.service';
import { Title } from '@angular/platform-browser';
import { RiskMetricsService } from '../../../services/risk-metrics.service';
import { finalize } from 'rxjs/operators';
import { PhysicalLngInputDesdes, DealEconomicsBreakdownDesdes, TradeSummaryDesdes } from './physical-lng-desdes.model';
import { FileSaverService } from 'ngx-filesaver';
import { PhysicalLngDesDesSessionService } from './physical-lng-desdes.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-physical-lng-desdes',
  templateUrl: './physical-lng-desdes.component.html',
  styleUrls: ['./physical-lng-desdes.component.scss']
})
export class PhysicalLngDesdesComponent implements OnInit {

  constructor(private riskMetricsService: RiskMetricsService, private titleService: Title, 
    private dateHelperService: DateHelperService, private fileSaverService: FileSaverService,
    private localStore: PhysicalLngDesDesSessionService ) {
    this.titleService.setTitle('Trading Preview UI | Physical LNG DES-DES');
    this.lngInputsDesdes = new PhysicalLngInputDesdes();
  }

  tabRefName: string;
  deleteTheTab: Subject<string> = new Subject();

  selectedSeller: string = "Seller";
  selectedBuyer: string = "Buyer";
  initialized:Boolean;
  visibleIn:boolean = true;
  lngInputsDesdes : PhysicalLngInputDesdes;
  tradeSummaryDesdes: TradeSummaryDesdes;
  dealEconomicsBreakdownDesdes: DealEconomicsBreakdownDesdes;

  prchLngPrice: number;
  prchMiscCost: number;
  prchVolumeMmbtu: number;
  prchTolerance: number;
  salsLngPrice: number;
  salsMiscCost: number;
  salsVolumeMmbtu: number;
  salsTolerance: number;

  selectedLoadingPort: string;
  selectedDischargePort: string;
  selectedDischargeDate: string;
  includeDischargePortCost: string = "No";
  selectedVesselType: string;

  apiPorts = ["United States Cameron", "United States Kenai", "United Kingdom South Hook"];
  apiVesselTypes = ["", "Steam Turbine", "TFDE", "MEGI/XDF", "QFlex"]

  ngOnInit(): void {
    var localStoredString = JSON.parse(this.localStore.getData(this.tabRefName));
    if(localStoredString) {
      this.initLoad();
      this.onVesselTypeSelect();
      
      this.loadDataFromStore(localStoredString);
    }
    else {
      this.lngInputsDesdes.TestLoadDefaults();
      this.tradeSummaryDesdes = new TradeSummaryDesdes();
      this.selectedDischargeDate = this.dateHelperService.getDateyyyyMMdd(this.lngInputsDesdes.discharge_date).substring(0,10);
      this.dealEconomicsBreakdownDesdes = new DealEconomicsBreakdownDesdes();
  
      this.initLoad();
      this.onVesselTypeSelect();

      this.saveDataToStore();
    }
  }

  saveDataToStore(): void{
    var localStoredObj = {
      'selectedSeller': JSON.stringify(this.selectedSeller),
      'selectedBuyer': JSON.stringify(this.selectedBuyer),
      'lngInputsDesdes': JSON.stringify(this.lngInputsDesdes),
      'selectedDischargeDate': JSON.stringify(this.selectedDischargeDate),
      'includeDischargePortCost': JSON.stringify(this.includeDischargePortCost),
      'dealEconomicsBreakdownDesdes': JSON.stringify(this.dealEconomicsBreakdownDesdes),
      'tradeSummaryDesdes': JSON.stringify(this.tradeSummaryDesdes),
    }
    this.localStore.saveData(this.tabRefName, JSON.stringify(localStoredObj));
  }

  loadDataFromStore(localStoredString: string): void{
    this.selectedSeller = JSON.parse(localStoredString['selectedSeller']);
    this.selectedBuyer = JSON.parse(localStoredString['selectedBuyer']);
    this.lngInputsDesdes = JSON.parse(localStoredString['lngInputsDesdes']);
    this.includeDischargePortCost = JSON.parse(localStoredString['includeDischargePortCost']);
    this.selectedDischargeDate = JSON.parse(localStoredString['selectedDischargeDate']);
    this.tradeSummaryDesdes = JSON.parse(localStoredString['tradeSummaryDesdes']);
    this.dealEconomicsBreakdownDesdes = JSON.parse(localStoredString['dealEconomicsBreakdownDesdes'])
  }
  
  initLoad(): void{
    this.initialized = true;
    this.riskMetricsService.getPhysicalDealInitDataDesdes().pipe(finalize(()=>{
      this.initialized = false;
    })).subscribe(
      res => {
        this.apiPorts = JSON.parse(res.all_port_installations);
        this.apiVesselTypes = JSON.parse(res.all_vessel_types);
      }
    )
  }

  onVesselTypeSelect(){
    var selectedVesselType = this.apiVesselTypes.filter(x => x == this.lngInputsDesdes.vessel_type);
    if(selectedVesselType.length == 1){
      this.selectedVesselType = selectedVesselType[0];
    }
  }

  setSummaryDates(){
    this.selectedDischargeDate = this.dateHelperService.getDateyyyyMMdd(this.lngInputsDesdes.discharge_date).substring(0,10);
  }

  calculatePhysicalLNGDesdes(){
    var inputs = this.getInputsForCalculation();
    this.initialized = true;
    this.riskMetricsService.calculatePhysicalLNGDesdes(inputs).pipe(finalize(() =>  {
      this.initialized = false;
    })).subscribe(
      res => {
        this.dealEconomicsBreakdownDesdes.cargoLoadedUsd = res.cargo_loaded;
        this.dealEconomicsBreakdownDesdes.cargoDischargedUsd = res.cargo_discharged;
        this.dealEconomicsBreakdownDesdes.terminalCostUsd = res.terminal_cost;
        this.tradeSummaryDesdes.netRealisationAmount = res.net_realization;
        this.tradeSummaryDesdes.netRealisationPerUnit = res.unit_net_realization;

        this.saveDataToStore();
    });
  }

  private getInputsForCalculation() {
    var inputs = JSON.parse(JSON.stringify(this.lngInputsDesdes));
    var dischargeDate = new Date(inputs.discharge_date);
    inputs.discharge_date = this.dateHelperService.dateToString(dischargeDate);
    inputs.include_discharge_port_cost = (this.includeDischargePortCost == "Yes");
    inputs.prch_tolerance = inputs.prch_tolerance_percent / 100
    inputs.sals_tolerance = inputs.sals_tolerance_percent / 100
    return inputs;
  }

  downloadPhysicalDesDesDeal() {
    var inputs = this.getInputsForCalculation();
    this.initialized = true;
    this.riskMetricsService.getDesDesDownloadExcel(inputs).pipe(finalize(() =>  {
      this.initialized = false;
    })).subscribe(
      res => {
        this.fileSaverService.save(res.blob, res.fileName)
    });
  }

  deleteTab(){
    this.deleteTheTab.next(this.tabRefName);
    this.localStore.removeData(this.tabRefName);
  }
}
