import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RiskMetricsService } from '../services/risk-metrics.service';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss']
})
export class FileDownloadComponent implements OnInit {

  fileToken = "";
  isLoading = true;
  errorMessage = "";
  redirectResponse = null

  constructor(private route:ActivatedRoute, private riskMetricsService: RiskMetricsService) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.fileToken = params['file-token'];
        
        let inputs = {
          file_token: this.fileToken
        };
        this.riskMetricsService.getFileDownload(inputs).subscribe(res => {
          this.isLoading = false;
          this.redirectResponse = res;
          if(Object.keys(this.redirectResponse).indexOf("sas_url")!=-1) {
            window.location.href = this.redirectResponse.sas_url;
          }
          else {
            this.errorMessage = "Unauthorized User";
          }


          console.log(res)
        }, fail => {
          if(fail.status===401) {
            this.errorMessage = fail.error;
          }
          else {
            this.errorMessage = "Unauthorized User";
          }
          this.isLoading = false;
        });

      }
    );

  }

}
