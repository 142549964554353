import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AzureDevOpsService } from '../services/azure-devops.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  feedbackTypes = [
    { type: 'feature', text: 'Request a feature' },
    { type: 'bug', text: 'Report a bug' },
    { type: 'comment', text: 'Leave a comment' }
  ];

  isSubmitting = false;
  showAlert = false;
  hasError = false;
  wasSuccess = false;

  createdItem: any;

  constructor(private adoService: AzureDevOpsService) { }

  ngOnInit(): void {
  }

  submit(form: NgForm): void {
    this.showAlert = false;
    this.hasError = false;
    this.wasSuccess = false;

    if (form.invalid) { return; }

    let adoRequest: Observable<any>;

    switch (form.value.type) {
      case 'feature':
        adoRequest = this.adoService.createPBI(`[feature] ${form.value.title}`, form.value.description);
        break;
      case 'bug':
        adoRequest = this.adoService.createBug(form.value.title, form.value.description);
        break;
      case 'comment':
        adoRequest = this.adoService.createPBI(`[comment] ${form.value.title}`, form.value.description);
        break;
      default:
        return;
    }
    this.isSubmitting = true;

    adoRequest
      ?.pipe(finalize(() => {
        this.isSubmitting = false;
        this.showAlert = true;
      })).subscribe(res => {
        this.createdItem = res;
        this.wasSuccess = true;
      }, () => this.hasError = true);
  }
}
