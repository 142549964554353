import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, forkJoin } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { Trade } from '../../models/trade.model';
import { TradeBlotterService } from '../../services/trade-blotter.service';
import { UserService } from '../../services/user.service';
import { TradeBlotterComponent } from '../trade-blotter.component';

@Component({
  selector: 'app-view-trades',
  templateUrl: './view-trades.component.html',
  styleUrls: ['./view-trades.component.scss']
})
export class ViewTradesComponent implements OnInit {

  isProcessing = false;
  showError = false;
  showSuccess = false;

  tradeIds = [];

  selectedTradeId: number;
  newTradeId: number;

  isReadOnly = false;

  tradeInfo: Trade = {};

  @ViewChild(TradeBlotterComponent) blotter: TradeBlotterComponent;

  constructor(private tradeBlotterService: TradeBlotterService, private userService: UserService, private titleService: Title,
    private router: Router, private route: ActivatedRoute) {
    this.titleService.setTitle('Trading Preview UI | Trade Blotter: View existing trades');
  }

  ngOnInit(): void {
    combineLatest([
      this.tradeBlotterService.getDealsByRegion(),
      this.route.params
    ]).subscribe(res => {
      this.tradeIds = res[0].sort((a, b) => b.deal_id - a.deal_id);

      if (res[1].id) {
        this.selectedTradeId = +res[1].id;

        if (this.tradeIds.some(x => x.deal_id === this.selectedTradeId)) {
          this.loadTrade();
        }
        else {
          this.router.navigate(['/not-found']);
        }
      }
      else {
        this.selectedTradeId = this.tradeIds.find(Boolean)?.deal_id;
        if (this.selectedTradeId) {
          this.navigateToTrade();
        }
      }
    });
  }

  navigateToTrade(): void {
    this.router.navigate([`/trade-blotter/view/${this.selectedTradeId}`]);
  }

  loadTrade(): void {
    this.showError = false;
    this.showSuccess = false;

    if (this.selectedTradeId) {
      this.tradeBlotterService.getDeal(this.selectedTradeId).subscribe(res => {
        this.tradeInfo = res;
        this.isReadOnly = this.userService.getUser().idTokenClaims.oid !== res.traderId;
      });
    }
  }

  canExecute(): boolean {
    return !this.isReadOnly && !this.isProcessing && this.blotter?.validate();
  }

  execute(): void {
    this.blotter.updateStatus('EXECUTED');
    this.updateDealStatus();
  }

  finalize(): void {
    this.blotter.updateStatus('FINALIZED');
    this.updateDealStatus();
  }

  abandon(): void {
    this.blotter.updateStatus('ABANDONED');
    this.updateDealStatus();
  }

  resubmit(): void {
    this.isProcessing = true;

    this.blotter.updateStatus('PENDING');
    this.tradeBlotterService.updateDeal(this.blotter.tradeInfo)
      .pipe(finalize(() => this.isProcessing = false))
      .pipe(switchMap(tradeId => {
        if (this.blotter.attachedSupportingFile) {
          let file = this.tradeBlotterService.uploadSupportingFile(this.selectedTradeId, this.blotter.attachedSupportingFile);
          let submit = this.tradeBlotterService.submitDealForApproval(this.selectedTradeId);
          return forkJoin(file, submit);
        }
        else {
          return this.tradeBlotterService.submitDealForApproval(this.selectedTradeId);
        }
      })).subscribe(
        () => this.router.navigate(['/trade-blotter/new/success'], { state: { tradeId: this.selectedTradeId } }),
        () => this.router.navigate(['/error'])
      );
  }

  abandonAndResubmit(): void {
    this.abandon();
    this.tradeBlotterService.createDeal(this.blotter.tradeInfo)
      .pipe(finalize(() => this.isProcessing = false))
      .pipe(switchMap(tradeId => {
        this.newTradeId = +tradeId;
        if (this.blotter.attachedSupportingFile) {
          let file = this.tradeBlotterService.uploadSupportingFile(this.newTradeId, this.blotter.attachedSupportingFile);
          let submit = this.tradeBlotterService.submitDealForApproval(this.newTradeId);
          return forkJoin(file, submit)
        }
        else {
          return this.tradeBlotterService.submitDealForApproval(this.newTradeId);
        }
      })).subscribe(
        () => this.router.navigate(['/trade-blotter/new/success'], { state: { tradeId: this.newTradeId } }),
        () => this.router.navigate(['/error'])
      );
  }

  checkTradeStatus(status: string): boolean {
    return this.blotter?.tradeInfo.status === status;
  }

  copyAsNew(): void {
    const copyTrade = { ...this.tradeInfo };
    copyTrade.status = 'NEW';
    this.router.navigate(['/trade-blotter/new'], { state: { tradeInfo: copyTrade } });
  }

  private updateDealStatus(): void {
    this.isProcessing = true;
    this.showError = false;
    this.showSuccess = false;

    this.tradeBlotterService.updateDealStatus(this.blotter.tradeInfo.id, this.blotter.tradeInfo.status)
      .pipe(finalize(() => this.isProcessing = false)).subscribe(
        () => {
          this.showSuccess = true;
        },
        () => this.showError = true
      );
  }
}
