import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import { SecurityInfo } from 'src/app/models/security-info.model';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { RiskMetricsService } from 'src/app/services/risk-metrics.service';
import { PortfolioCustomVarService } from 'src/app/services/portfolio-var-custom.service';
import { ActivatedRoute, Router } from '@angular/router';

import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatTable } from '@angular/material/table';

import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { FileSaverService } from 'ngx-filesaver';
import { ChartConfiguration } from 'chart.js';
import { ChartSettings } from '../../helpers/chart-settings';
import { BaseChartDirective } from 'ng2-charts';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Trade } from '../../models/trade.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'agot',
    templateUrl: './p-var-custom-agot.component.html',
    styleUrls: ['./p-var-custom-agot.component.scss']
  })

//export class PortfolioVarCustomAGOT implements OnInit, AfterViewInit, OnDestroy {
export class PortfolioVarCustomAGOT {

  @Input() isReadOnly: boolean = false;
  @Input() tradeInfo: Trade = {};

  attachedSupportingFile: File;

  constructor(private riskMetricsService: RiskMetricsService, private portfolioCustomVarService:  PortfolioCustomVarService, private router: Router, private fileSaverService: FileSaverService, private titleService: Title, private dateHelperService: DateHelperService,
    private notificationService: MatSnackBar) {
    this.titleService.setTitle('Trading Preview UI | Data Shopping');
  }
  /** control for the selected hub for multi-selection */
  public hubsMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public hubsMultiFilterCtrl: FormControl = new FormControl();

  /** list of hubs filtered by search keyword */
  public filteredHubsMulti: ReplaySubject<SecurityInfo[]> = new ReplaySubject<SecurityInfo[]>(1);

  @ViewChild('multiSelect') multiSelect: MatSelect;
  @ViewChildren(BaseChartDirective) charts?: QueryList<BaseChartDirective>;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  firstRun = false;
  isCalculating = false;
  showRecalcWarning = false;
  
  dataSource = new MatTableDataSource();
  displayedColumns: string[];
  dataColumns: string[];

  asOfDate: string;
  shipFrom: string;
  minAsOfDate: string;
  maxAsOfDate: string;
  shipTo: string;
  paymentDays: number = 25;
  securityInfo: SecurityInfo[] = [];
  distinctHubs: SecurityInfo[] = [];

  local_asOfDate: string;
  local_shipFrom: string;
  local_shipTo: string;
  local_paymentDays: number = 25;
  local_distinctHubs: [];

  forwardCurvesData: ChartConfiguration['data'];
  forwardCurvesOptions: ChartConfiguration['options'];

  calculatedEODVar: string = '---';
  initialized: boolean = true;
  isSubmitting = false;

  //Databricks job
  run_id: string = 'None';
  number_in_job: string = 'None';

  isNaN(value: any): boolean {
    return isNaN(value);
  }

  attachedFileChange(files: FileList) {
    this.attachedSupportingFile = files.length > 0 ? files.item(0) : null;
    if (this.attachedSupportingFile.size > 10485760) {
      this.attachedSupportingFile = null;
      this.notificationService.open('Supporting file size must be smaller than 10MB in size.', 'Dismiss');
    }
  }

  calculatePortfolioVarAgot(): void {
    this.isSubmitting = true;
    if (this.attachedSupportingFile) {
      this.isSubmitting = true;
      let file = this.attachedSupportingFile;
      this.notificationService.open('Status', 'Calculating Portfolio VaR. Please be patient.', {'duration': 3000, 'verticalPosition': 'top'});
      this.isCalculating = true;
      this.portfolioCustomVarService.calculateCustomPortfolioAgotVar(this.attachedSupportingFile).subscribe(
        res => {
          this.calculatedEODVar = res.calculatedEODVar
          this.notificationService.open('Notification', 'VaR was calculated successfully.', {'duration': 5000, 'verticalPosition': 'top'});
          this.isSubmitting = false;
          this.isCalculating = false;
        },
        (err: HttpErrorResponse) => {
          if (err.status === 400) {
            this.notificationService.open('Bad Request', 'There was an issue with the request. Please ensure that you are providing a valid CSV file with the correct format.', {'duration': 5000, 'verticalPosition': 'top'});
          }
          else if (err.status === 415) {
            this.notificationService.open('Unsupported Media Type', 'Only CSV files are supported. Please select a CSV file and try again.', {'duration': 5000, 'verticalPosition': 'top'});
          }
          else if (err.status === 422) {
            this.notificationService.open('Unprocessable Entity', 'The file extension was not found. Please select a file with a CSV extension.', {'duration': 5000, 'verticalPosition': 'top'});
          }
          else if (err.status === 413) {
            this.notificationService.open('Request Entity Too Large', 'The file is too large to be processed by the webserver. Contact developer frank.ceballos@exxonmobil.com', {'duration': 5000, 'verticalPosition': 'top'});
          }
          else if (err.status === 429) {
            this.notificationService.open('Too Many Request', 'The server is busy. Please try again later.', {'duration': 10000, 'verticalPosition': 'top'});
          }
          else {
            this.notificationService.open('Unknown Error', 'An unexpected error has occured. Please contact developer frank.ceballos@exxonmobil.com', {'duration': 10000, 'verticalPosition': 'top'});
          }
          this.isCalculating = false;
          this.isSubmitting = false;
          this.calculatedEODVar = '---';
        }
        
      )
    }
    else {
      this.notificationService.open('Notification', 'No file has been attached. Please select a file.', {'duration': 5000, 'verticalPosition': 'top'})
      this.isSubmitting = false;
  }
  }

  submit(): void {
    this.isSubmitting = true;
    this.calculatedEODVar = '---';
    if (this.attachedSupportingFile) {
      this.isSubmitting = true;
      let file = this.attachedSupportingFile;
      this.notificationService.open('Status', 'A request to generate the portfolio VaR report is being submitted.', {'duration': 5000, 'verticalPosition': 'top'});
      this.portfolioCustomVarService.generateCustomPortfolioAgotReport(this.attachedSupportingFile).subscribe(
        res => {
          console.log(res.run_id);
          this.notificationService.open('Success', 'The request was successful. An email with the report will be sent in about 10 minutes.', {'duration': 10000, 'verticalPosition': 'top'});
          this.isSubmitting = false;
        },
        (err: HttpErrorResponse) => {

          if (err.status === 400) {
            this.notificationService.open('Bad Request', 'There was an issue with the request. Please ensure that you are providing a valid CSV file with the correct format.', {'duration': 5000, 'verticalPosition': 'top'});
          }
          else if (err.status === 415) {
            this.notificationService.open('Unsupported Media Type', 'Only CSV files are supported. Please select a CSV file and try again.', {'duration': 5000, 'verticalPosition': 'top'});
          }
          else if (err.status === 422) {
            this.notificationService.open('Unprocessable Entity', 'The file extension was not found. Please select a file with a CSV extension.', {'duration': 5000, 'verticalPosition': 'top'});
          }
          else if (err.status === 413) {
            this.notificationService.open('Request Entity Too Large', 'The file is too large to be processed by the webserver. Contact developer frank.ceballos@exxonmobil.com', {'duration': 5000, 'verticalPosition': 'top'});
          }
          else if (err.status === 429) {
            this.notificationService.open('Too Many Request', 'The server is busy. Please try again later.', {'duration': 10000, 'verticalPosition': 'top'});
          }
          else {
            this.notificationService.open('Unknown Error', 'An unexpected error has occured. Please contact developer frank.ceballos@exxonmobil.com', {'duration': 10000, 'verticalPosition': 'top'});
          }
          this.isSubmitting = false;
        }
      )
    }
    else {
      this.notificationService.open('Notification', 'No file has been attached. Please select a file.', {'duration': 5000, 'verticalPosition': 'top'})
      this.isSubmitting = false;
  }
  }

  validate(): boolean {
    return true;
  }

}