<div class="em-l-grid em-l-grid--2up">
  <div class="em-l-grid__item ">
    <mat-card>
      <mat-card-content>
        <div class="em-l-grid em-l-grid--2up ">
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Trade Date</mat-label>
              <input matInput type="date" [(ngModel)]="tradeInfo.date" required [disabled]="!isNewPendingOrRejected() || isReadOnly">
            </mat-form-field>
          </div>
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Status</mat-label>
              <input matInput [(ngModel)]="tradeInfo.status" disabled>
            </mat-form-field>
          </div>
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Approver</mat-label>
              <mat-select [(ngModel)]="tradeInfo.approverId" required [disabled]="!isNewPendingOrRejected() || isReadOnly">
                <mat-option *ngFor="let approver of approvers" [value]="approver.oid">{{approver.last_name}}, {{approver.first_name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Tora Strategy</mat-label>
              <mat-select [(ngModel)]="tradeInfo.toraStrategyId" [disabled]="!canEditStrategyQuorum() || isReadOnly">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let strategy of toraStrategies" [value]="strategy.tora_strat_number">{{strategy.tora_strat_number}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Quorum Contract #</mat-label>
              <input matInput [(ngModel)]="tradeInfo.quorumContractId" [disabled]="!canEditStrategyQuorum() || isReadOnly">
            </mat-form-field>
          </div>
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Trade Type</mat-label>
              <mat-select [(ngModel)]="tradeInfo.tradeType" required [disabled]="!isNewPendingOrRejected() || isReadOnly">
                <mat-option value="Geo Arb">Geo Arbitrage</mat-option>
                <mat-option value="Time Arb">Time Arbitrage</mat-option>
                <mat-option value="Differential Management">Differential Management</mat-option>
                <mat-option value="Flat Price">Flat Price</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="em-l-grid__item">
    <mat-card>
      <mat-card-content>
        <div class="em-l-grid em-l-grid--2up ">
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Current VaR</mat-label>
              <input matInput currencyMask class="em-u-text-align-right" step="any" [(ngModel)]="tradeInfo.currentVar" required [disabled]="!canEditPricing() || isReadOnly" />
              <span matPrefix>$ </span>
            </mat-form-field>
          </div>
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Expected Max VaR</mat-label>
              <input matInput currencyMask class="em-u-text-align-right" step="any" [(ngModel)]="tradeInfo.maxVar" required [disabled]="!isNewPendingOrRejected() || isReadOnly" />
              <span matPrefix>$ </span>
            </mat-form-field>
          </div>
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Projected Profit</mat-label>
              <input matInput currencyMask class="em-u-text-align-right" step="any" [(ngModel)]="tradeInfo.projectedProfit" required [disabled]="!isNewPendingOrRejected() || isReadOnly" />
              <span matPrefix>$ </span>
            </mat-form-field>
          </div>
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Stop Loss</mat-label>
              <input matInput currencyMask class="em-u-text-align-right" step="any" [(ngModel)]="tradeInfo.stopLoss" required [disabled]="!isNewPendingOrRejected() || isReadOnly" />
              <span matPrefix>$ </span>
            </mat-form-field>
          </div>
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Risk/Reward Ratio</mat-label>
              <input matInput class="em-u-text-align-right" step="any" [value]="tradeInfo.projectedProfit / tradeInfo.stopLoss | number" disabled />
            </mat-form-field>
          </div>
          <div class="em-l-grid__item em-u-padding-top-none em-u-padding-bottom-none">
            <div class="em-l-grid em-l-grid--2up ">
              <div class="em-l-grid__item">
                <mat-form-field>
                  <mat-label>Hold Length</mat-label>
                  <input matInput currencyMask class="em-u-text-align-right" step="any" [(ngModel)]="tradeInfo.holdLength" required [disabled]="!isNewPendingOrRejected() || isReadOnly" />
                </mat-form-field>
              </div>
              <div class="em-l-grid__item">
                <mat-form-field>
                  <mat-select [(ngModel)]="tradeInfo.holdLengthUnit" [disabled]="!isNewPendingOrRejected() || isReadOnly">
                    <mat-option value="days">day(s)</mat-option>
                    <mat-option value="weeks">week(s)</mat-option>
                    <mat-option value="months">month(s)</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="em-l-grid__item width-100">
    <mat-card>
      <mat-card-content>
        <div class="em-l-grid em-l-grid--2up ">
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Reasons for Entry</mat-label>
              <textarea matInput maxlength="2000" [(ngModel)]="tradeInfo.entryReason" [disabled]="!isNewPendingOrRejected() || isReadOnly"></textarea>
            </mat-form-field>
          </div>
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Reasons for Exit</mat-label>
              <textarea matInput maxlength="2000" [(ngModel)]="tradeInfo.exitReason" [disabled]="!isNewPendingOrRejected() || isReadOnly"></textarea>
            </mat-form-field>
          </div>
          <div class="em-l-grid__item width-100">
            <div class="em-c-field unity-file-upload-override">
              <div class="em-c-field__body">
                <svg class="em-c-icon em-c-icon--large em-c-field__block-icon">
                  <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#upload"></use>
                </svg>
                <input type="file" name="file[]" id="file" class="em-c-file-upload" placeholder="Placeholder" (change)="attachedFileChange($event.target.files)" [disabled]="!isNewPendingOrRejected() || isReadOnly"
                       accept="image/*,.doc,.docx,.log,.rtf,.tex,.txt,.wpd,.wps,.csv,.dat,.ppt,.pptx,'.psd',.eps,.pdf,.xls,.xlsx,.zip,.rar, .json"/>
                <ul class="em-c-field__list">
                  <li class="em-c-field__item">Drag supporting file here</li>
                  <li class="em-c-field__item em-c-field__item--small">Or click to choose file</li>
                  <li class="em-c-field__item">
                    {{ attachedSupportingFile?.name ?? tradeInfo.supportingFileName }}
                    <button class="em-c-btn em-c-btn--small em-c-btn--tertiary" *ngIf="tradeInfo.supportingFileName" (click)="downloadFile()">
                      <div class="em-c-btn__inner">
                        <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
                          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#download-cloud"></use>
                        </svg>
                      </div>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="em-c-table-object__header">
  <div class="em-c-toolbar " *ngIf="isNewPendingOrRejected() && !isReadOnly">
    <div class="em-c-toolbar__item">
      <div class="em-c-btn-group ">
        <span class="em-c-tooltip em-c-tooltip--icon em-js-tooltip em-u-margin-none">
          <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="addLeg()">
            <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#plus"></use>
            </svg>
          </button>
          <span class="em-c-tooltip__content toolbar-tooltip">
            Add leg
          </span>
        </span>
        <span class="em-c-tooltip em-c-tooltip--icon em-js-tooltip em-u-margin-none">
          <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="updateIntradayPrices()" [disabled]="!canPullLiveData" [class.em-c-btn--disabled]="!canPullLiveData">
            <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#refresh"></use>
            </svg>
          </button>
          <span class="em-c-tooltip__content toolbar-tooltip">
            Pull intraday price
          </span>
        </span>
        <div *ngIf="intradayPriceRefreshTime" class="em-u-margin-top-half">
          Updated: {{ intradayPriceRefreshTime | date : 'short'}}
        </div>
        <div *ngIf="showPriceWarning">
          <svg class="em-c-icon em-c-icon--small em-u-margin-half">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#info"></use>
          </svg>
        </div>
        <div *ngIf="showPriceWarning" class="em-u-margin-top-half">
          Unavailable intraday price(s) have been replaced with EOD price.
        </div>
      </div>
    </div>
  </div>
</div>

<table mat-table [dataSource]="tradeInfo.legs" class="em-c-table">
  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> Security Code </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field appearance="outline" class="xlarge-field em-u-margin-top em-u-margin-right">
        <input type="text" matInput [(ngModel)]="element.securityInfo" (ngModelChange)="securityValueChanged($event, element)" [matAutocomplete]="auto" [disabled]="!isNewPendingOrRejected() || isReadOnly">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [panelWidth]="350">
          <mat-option *ngFor="let info of filteredSecurityInfo" [value]="info">
            {{info.hub}} {{info.type}} ({{info.security}})
          </mat-option>
        </mat-autocomplete>
        <mat-hint class="mat-error" *ngIf="checkSecurity(element.code)">Select a valid security.</mat-hint>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> Position </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field floatLabel="never" appearance="outline" class="medium-field em-u-margin-top em-u-margin-right">
        <mat-select [(ngModel)]="element.position" [disabled]="!isNewPendingOrRejected() || isReadOnly">
          <mat-option value="long">Long</mat-option>
          <mat-option value="short">Short</mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="lot">
    <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> Lot </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field appearance="outline" class="medium-field em-u-margin-top em-u-margin-right">
        <input class="em-u-text-align-right" matInput type="number" [(ngModel)]="element.lot"  min="1" [disabled]="!isNewPendingOrRejected() || isReadOnly">
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="intraday-price">
    <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double">Price</th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field appearance="outline" class="medium-field em-u-margin-top em-u-margin-right">
        <input matInput type="number" class="em-u-text-align-right" [(ngModel)]="element.intraday_price" step="any" [disabled]="!canEditPricing() || isReadOnly">
        <span matPrefix>{{element.currency}}</span>
        <mat-hint class="mat-error" *ngIf="element.intraday_price === undefined">Input a price value.</mat-hint>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="tenor-start">
    <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> Tenor Start </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field appearance="outline" class="large-field em-u-margin-top em-u-margin-right">
        <input matInput type="month" [(ngModel)]="element.tenor_start" [min]="element.min_strip_date" [max]="element.max_strip_date" (ngModelChange)="startStripChanged(element)" [disabled]="!isNewPendingOrRejected() || isReadOnly">
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="tenor-end">
    <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> Tenor End </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field appearance="outline" class="large-field em-u-margin-top">
        <input matInput type="month" [(ngModel)]="element.tenor_end" [min]="element.min_strip_date" [max]="element.max_strip_date" (ngModelChange)="endStripChanged(element)" [disabled]="!isNewPendingOrRejected() || isReadOnly">
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> </th>
    <td mat-cell *matCellDef="let element" class="center">
      <button class="em-c-btn em-c-btn--tertiary" (click)="deleteLeg(element)" tabindex="-1">
        <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#trashcan"></use>
        </svg>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
</table>
