<div class="em-c-alert em-c-alert--error" role="alert" *ngIf="hasError">
  <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#circle-exclamation-mark"></use>
  </svg>
  <div class="em-c-alert__body">
    Implied volatility data for the specified criteria does not exist.
  </div>
  <div class="em-c-alert__actions">
    <button class="em-c-text-btn em-c-alert--error" (click)="hasError = false">
      Close
    </button>
  </div>
</div>
<h3>
  Implied Market Volatility Surface
</h3>
<div class="em-l-grid">
  <div class="em-l-grid__item">
    <div>
      <mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
        <mat-label>Trade date</mat-label>
        <input matInput type="date" [(ngModel)]="tradeDate" (ngModelChange)="fetchChartData()" [disabled]="isPullingData">
      </mat-form-field>
      <mat-form-field class="em-u-padding-top-none em-u-padding-right small-field">
        <mat-label>Hub</mat-label>
        <mat-select [(ngModel)]="selectedHub" (ngModelChange)="fetchChartData()" [disabled]="isPullingData">
          <mat-option *ngFor="let hub of hubs" [value]="hub">{{hub}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="em-u-padding-top-none em-u-padding-right large-field" matTooltipPosition='right' matTooltip="DC = Delta Call, DP = Delta Put, ATM = At the Money">
        <mat-label>Volatility</mat-label>
        <mat-select multiple [(ngModel)]="volatilities" (ngModelChange)="updateVolatilitySeries()" [disabled]="isPullingData">
          <mat-option *ngFor="let v of volatilityOptions" [value]="v">{{v}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button class="em-c-btn em-c-btn--small em-c-btn--tertiary em-u-margin-top" (click)="saveData()" matTooltip="Export data to CSV" [disabled]="hasError" [class.em-c-btn--disabled]="hasError">
        <div class="em-c-btn__inner">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#save-disk"></use>
          </svg>
        </div>
      </button>
    </div>
  </div>

  <div class="em-l-grid__item">
    <canvas baseChart
            [data]="volatilityChartData"
            [options]="lineChartOptions"
            type="line">
    </canvas>
  </div>

  <div class="em-l-grid__item">
    <div>
      <mat-form-field class="em-u-padding-top-none em-u-padding-right small-field">
        <mat-label>Strip date</mat-label>
        <mat-select [(ngModel)]="selectedStrip" (ngModelChange)="fetchStrikDeltaData()" [disabled]="isPullingData">
          <mat-option *ngFor="let s of volatilityData?.strip" [value]="s">{{s}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button class="em-c-btn em-c-btn--small em-c-btn--tertiary em-u-margin-top" (click)="saveDeltaStrikeData()" matTooltip="Export data to CSV" [disabled]="hasError" [class.em-c-btn--disabled]="hasError">
        <div class="em-c-btn__inner">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#save-disk"></use>
          </svg>
        </div>
      </button>
    </div>
    <div class="em-l-grid__item">
      <canvas baseChart
              [data]="volatilityDeltaStrikeChartData"
              [options]="deltaStrikeChartOptions"
              [legend]="false"
              type="line">
      </canvas>
    </div>
  </div>
