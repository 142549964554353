<div mat-dialog-actions style="float: right">
  <button class="em-c-btn em-c-btn--small em-c-btn--tertiary" mat-button mat-dialog-close tabindex="-1">
    <div class="em-c-btn__inner">
      <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#x-filled"></use>
      </svg>
    </div>
  </button>
</div>
<div class="em-l-grid em-l-grid--2up ">
  <!--<label class="em-c-input-group em-c-radio--container em-u-margin-right" for="netback-calc-radio-full">
    <input id="netback-calc-radio-full" type="radio" name="netback-calc-radio" value="full" class="em-c-input-group__control em-js-radio-trigger" [(ngModel)]="displaySelected">
    <span class="em-c-radio--checkmark"></span>
    <span class="em-c-input-group__text">Full</span>
  </label>
  <label class="em-c-input-group em-c-radio--container " for="netback-calc-radio-piece">
    <input id="netback-calc-radio-piece" type="radio" name="netback-calc-radio" value="piece" class="em-c-input-group__control em-js-radio-trigger" [(ngModel)]="displaySelected">
    <span class="em-c-radio--checkmark"></span>
    <span class="em-c-input-group__text">Piece</span>
  </label>-->
</div>

<mat-tree *ngIf="displaySelected === 'piece'" [dataSource]="dataSource" [treeControl]="treeControl" class="calculation-tree em-u-margin-top">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      {{node.name}}
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div class="mat-tree-node">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        {{node.name}}
      </div>
      <div [class.calculation-tree-invisible]="!treeControl.isExpanded(node)"
          role="group">
        <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>

<object *ngIf="displaySelected === 'full'" data="assets/netback-computation.svg"></object>
