import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import { DateHelperService } from '../services/date-helper.service';
import { RiskMetricsService } from '../services/risk-metrics.service';
import { MatTableDataSource } from '@angular/material/table';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ChartSettings } from '../helpers/chart-settings';
import { MatSelect } from '@angular/material/select';
import { FileSaverService } from 'ngx-filesaver';
import { ChartData, ChartOptions, ChartType, Legend } from "chart.js";

@Component({
  selector: 'app-corr-price-paths',
  templateUrl: './corr-price-paths.component.html',
  styleUrls: ['./corr-price-paths.component.scss']
})
export class CorrPricePathsComponent implements OnInit {

  @ViewChild('multiSelect') multiSelect: MatSelect;
  @ViewChildren(BaseChartDirective) charts?: QueryList<BaseChartDirective>;

  corrPricePathsData: ChartData;
  corrPricePathsOptions: ChartOptions ;
  fileSimulInputExcel: number[];
  
  dataSource = new MatTableDataSource();
  dataColumns: string[];
  nextWeekdayStr: string;
  businessDays: number;

  region: string;
  currency: string;
  alphaCI: string;
  dateHorizon: string;

  showCalculatingError = false;
  isCalculating = false;
  refreshTime: Date;
  displayedColumns: string[] = [];

  asOfDate: string;
  endDate: string;
  minAsOfDate: string;
  maxAsOfDate: string;
  hubs: string[] = [];
  selectedHubs: string[] = [];
  curves: string[] = [];
  selectedCurves: string[] = [];  
  datasourceMeanRevRate = new MatTableDataSource();
  datasourceCorrMatrix = new MatTableDataSource();
  displayedColumns_CorrMatrix: string[] = ['Ticker'];
  displayedColumns_MeanRevRate: string[] = ['Ticker', 'theta', 'half_life'];
  initialized = true;


  constructor(private riskMetricsService: RiskMetricsService, private dateHelperService: DateHelperService, private fileSaverService: FileSaverService, private titleService: Title) {
    this.titleService.setTitle('Trading Preview UI | Correlated Price Paths');
  }

  ngOnInit(): void {
    this.fileSimulInputExcel = [];
    // listen for search field value changes
    this.initializeChartSettings();
  }

  tradeDateChange(){
    var today = new Date(this.dateHelperService.getDateddMMyyyy(this.asOfDate)); 
    this.minAsOfDate = this.dateHelperService.getDateyyyyMMdd(this.dateHelperService.getNextMonth(today).toLocaleDateString('en-US')).substring(0, 7);
  }

  check_column_to_add(col){
    var lowerCaseCol = col.toLowerCase()
    for (const hub of this.selectedHubs) {
      for (const curve of this.selectedCurves) {
        var hub_curve = hub + "_" + curve;
        if (lowerCaseCol.indexOf(hub_curve.toLowerCase()) >= 0){
          return true
        }  
      }
    }
    return false
  }
  
  populateTimeSeriesChart(data, displayedColumns): void {
    this.corrPricePathsData.datasets = [];
    for (const col of displayedColumns) {
      if (this.check_column_to_add(col) == true){ 
          const symbolData = data.map(x => x[col]);
          this.corrPricePathsData.datasets.push({
            data: symbolData,
            label: col,
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            cubicInterpolationMode: 'monotone'
          });
        }
      }
      this.corrPricePathsData.labels = data.map(x => x['Trade Date']);
      this.charts.first.render();
  }

  formatDataToMatTable(data_from_api){
    var data = [];
    var idx=0;
    while(data_from_api[idx]){
      data.push(data_from_api[idx]);
      idx++;
    }
    return data;
  }

  getCorrPricePathsData(){
    var inputs = {};
    inputs["simulInputExcelData"] = this.fileSimulInputExcel

    console.log(inputs)
    this.displayedColumns = []
    this.initialized = false;

    this.riskMetricsService.getCorrPricePaths(inputs)
    .pipe(finalize(() =>  {
      this.initialized = true;
    })).subscribe(
      res => {
        var res_inputs = res['inputs'];  // Inputs parsed from Excel. Use these to update UI. 
        this.asOfDate = res_inputs['as_of_date'];
        this.endDate = res_inputs['end_date'];
        this.hubs = res_inputs['symbols'];
        this.selectedHubs = res_inputs['symbols'];
        this.selectedCurves = ['p10', 'p50', 'p90', 'path0'];
        this.datasourceMeanRevRate.data = this.formatDataToMatTable(res_inputs['mean_rev_rate']);
        this.datasourceCorrMatrix.data = this.formatDataToMatTable(res_inputs['corr_matrix']);
        this.displayedColumns_CorrMatrix = ['Ticker'];  // Reset CorrMatrix table. 
        for (const col of this.hubs) {
          this.displayedColumns_CorrMatrix.push(col);
        }

        var res_results = res['results'];
        this.curves = res_results['curves'];
        var res_for_display = res_results['data'];
        const data = [];
        var idx=0;
        while(res_for_display[idx]){
          data.push(res_for_display[idx]);
          idx++;
        }
        this.dataSource.data = data;
        this.dataColumns = Object.keys(this.dataSource.data.find(Boolean)); 
        for (const col of this.dataColumns) {
          if (this.check_column_to_add(col) == true || col == 'Trade Date'){
            this.displayedColumns.push(col)
          }
        }
        this.populateTimeSeriesChart(data, this.displayedColumns);
    });    
  }

  exportToExcel(){
    var inputs = {};
    inputs["simulInputExcelData"] = this.fileSimulInputExcel

    this.initialized = false;
    this.riskMetricsService.downloadCorrPricePaths(inputs)
      .pipe(finalize(() => this.initialized = true))
      .subscribe(res => this.fileSaverService.save(res.blob, res.fileName));
  }

  initializeChartSettings(){
    this.corrPricePathsData = { datasets: [] };
    this.corrPricePathsOptions = {
      responsive: true,
      scales: {
        x: {
          title: {
            text: 'Strip',
            display: true
          }, ...ChartSettings.defaultTickSettings, ...ChartSettings.defaultGridSettings
        },
        y: {
          title: {
            text: '$',
            display: true
          }, ...ChartSettings.defaultGridSettings
        }
      },
      aspectRatio: 3,
      elements: {
        line: {
          borderWidth: 2
        }
      },
      plugins: {
        legend: {
          position: 'right'
        }
      }
    };
  }

  updateHubOrCurve(){
    this.displayedColumns = []            
    for (const col of this.dataColumns) {
      if (this.check_column_to_add(col) == true || col == 'Trade Date'){
        this.displayedColumns.push(col)
      }
    }
    this.populateTimeSeriesChart(this.dataSource.data, this.displayedColumns);
  }

  isNaN(value: any): boolean {
    return isNaN(value);
  }

  uploadSimulInputExcel(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length < 1) {
      return;
    }
    let file = fileList.item(0);
    file.stream().getReader().read().then(resp => {this.fileSimulInputExcel = [].slice.call(resp['value'])});
    // Reset the page. 
    this.asOfDate = null;
    this.endDate = null;
    this.hubs = [];
    this.selectedHubs = [];
    this.selectedCurves = [];
    this.datasourceMeanRevRate = new MatTableDataSource();
    this.datasourceCorrMatrix = new MatTableDataSource();
    this.displayedColumns_MeanRevRate = ['Ticker', 'theta', 'half_life'];
    this.displayedColumns_CorrMatrix = ['Ticker'];
    this.initializeChartSettings();
    this.dataSource = new MatTableDataSource();
  }
}
