<div class="em-c-alert em-c-alert--error" role="alert" *ngIf="showDownloadError">
  <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#circle-exclamation-mark"></use>
  </svg>
  <div class="em-c-alert__body">
    There was an error downloading the Portfolio Var file. The file may not exist for the selected day and region or you may not have permission to access it.
  </div>
  <div class="em-c-alert__actions">
    <button class="em-c-text-btn em-c-alert--error" (click)="showDownloadError=false">
      Close
    </button>
  </div>
</div>
<div class="em-c-loader spinner" *ngIf="isCalculating">
  <img src="unity/images/icon-spinner.svg" />
</div>
<div class="em-l-grid">
  <div class="em-l-grid__item">
    <div>
      <mat-form-field class="em-u-padding-top-none em-u-padding-right small-field">
        <mat-label>Region</mat-label>
        <mat-select [(ngModel)]="region" (ngModelChange)="getPortfolioVaR()">
          <mat-option *ngFor="let r of availableRegions" [value]="r.value">{{r.display}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
        <mat-label>Trade Date</mat-label>
        <input matInput type="date" [(ngModel)]="tradeDate" (ngModelChange)="getPortfolioVaR()" [max]="today">
      </mat-form-field>

      <ng-container *ngIf="lastUpdated">
        Updated: {{ lastUpdated | date : 'short'}}
      </ng-container>
    </div>
  </div>
</div>
<div class="em-c-toolbar em-u-align-center em-u-width-100">
  <div class="em-c-toolbar__item em-c-toolbar__item--stretch">
    <div class="em-c-search__body">
      <input type="search" id="search" class="em-c-search__input" placeholder="Filter rows..." [(ngModel)]="filter" (ngModelChange)="filterChange()" />
      <button class="em-c-btn em-c-btn--medium" (click)="clearFilter()">
        <div class="em-c-btn__inner">
          <svg *ngIf="!filter" class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/24/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#filter"></use>
          </svg>
          <svg *ngIf="filter" class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/24/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#x-filled"></use>
          </svg>
        </div>
      </button>
    </div>
  </div>
  <div class="em-c-toolbar__item em-is-aligned-right" *ngIf="region !== 'GGOT'">
    <button class="em-c-btn em-c-btn--secondary" (click)="downloadPortfolioVarFile()" [disabled]="isDownloading" [class.em-c-btn--disabled]="isDownloading"
            matTooltip="Download Excel file" matTooltipPosition="above">
      <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#save-disk"></use>
      </svg>
    </button>
  </div>
  <div class="em-c-toolbar__item em-is-aligned-right">
    <button class="em-c-btn em-c-btn--secondary" (click)="getPortfolioVaR()" [disabled]="isCalculating" [class.em-c-btn--disabled]="isCalculating" matTooltip="Refresh portfolio var" matTooltipPosition="above">
      <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#refresh"></use>
      </svg>
    </button>
  </div>
</div>
<h3 class="em-u-margin-top" *ngIf="!isCalculating && pvData.length == 0">No data available for the selected region and date.</h3>
<app-portfolio-var-table *ngFor="let data of pvData" [portfolioVarData]="data"></app-portfolio-var-table>
