import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { finalize } from 'rxjs/operators';
import { ChartSettings } from '../../../helpers/chart-settings';
import { DateHelperService } from '../../../services/date-helper.service';
import { GlobalArbService } from '../../../services/global-arb.service';

@Component({
  selector: 'app-freight-price-curves',
  templateUrl: './freight-price-curves.component.html',
  styleUrls: ['./freight-price-curves.component.scss']
})
export class FreightPriceCurvesComponent implements OnInit {
  routes = [];
  selectedRoutes = [];

  tradeDate: string;

  isLoading = false;
  allData: any;
  chartData: ChartConfiguration['data'] = { datasets: [] };
  lineChartOptions: ChartConfiguration['options'] = {
    scales: {
      x: {
        ...ChartSettings.defaultTickSettings,
        ...ChartSettings.defaultGridSettings
      },
      y: {
        title: {
          text: '$/day',
          display: true
        },
        ...ChartSettings.defaultGridSettings
      }
    }
  };

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  constructor(private globalArbService: GlobalArbService, private dateHelperService: DateHelperService, private titleService: Title) {
    this.titleService.setTitle('Trading Preview UI | Fuel Price Curve');
  }

  ngOnInit() {
    this.tradeDate = this.dateHelperService.getDateyyyyMMdd(this.dateHelperService.getLastWeekday(new Date()).toLocaleDateString('en-CA'));
    this.fetchChartData();
  }

  fetchChartData(): void {
    this.globalArbService.getPriceCurves(this.tradeDate, 'Charter')
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(res => {
        this.allData = res;
        this.routes = Object.keys(res).splice(1);
        this.selectedRoutes = [...this.routes];
        this.chartData.labels = res.strips;
        this.plotChartData();
      });
  }

  plotChartData(): void {
    this.chartData.datasets = [];

    this.selectedRoutes.forEach(r => {
      this.chartData.datasets.push({
        data: this.allData[r].prices,
        label: r
      });
    });

    this.chart.update();
  }
}
