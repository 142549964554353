<h3>
    BaseCase: FOB-DES
</h3>

<ul class="lists_class">
  <li *ngFor="let tabItem of physicalLngTabs" [ngClass]="{'em-u-li-selected': tabItem.current}" class="link_class em-c-btn em-c-btn--secondary em-c-btn--small" (click)="tabSelected(tabItem)" (dblclick)="onTabDblClick(tabItem)" >
    <span *ngIf="!tabItem.dblClicked">{{tabItem.tabName}}</span>
    <input type="text" [(ngModel)]="changedTabName" id="inpTabName" *ngIf="tabItem.dblClicked" size="8" style="width:100px;height:20px" (blur)="onTabBlur(tabItem)" (keyup.enter)="onTabBlur(tabItem)">
  </li>
  <li class="link_class">
    <button type="button" class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="add()" matTooltip="Add Tab" matTooltipPosition="right" style="height: 26px;margin-bottom: 3px;">
      <svg class="em-c-icon em-c-icon--small em-c-btn__icon" data-em-icon-path="unity/images/em-icons.svg">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#plus"></use>
      </svg>
    </button>
  </li>
</ul>

<div *ngIf="showTabInput" class="em-l-grid--1up em-l-fixed-width">
  <div class="em-l-grid__item">
    <mat-form-field>
        <mat-label>Tab Name</mat-label>
        <input matInput type="text" [(ngModel)]="tabName" (keyup.enter)="next()" id="inputTabName">
    </mat-form-field>
    <div class="em-l-grid--2up">
      <div class="em-l-grid__item">
        <button (click)="next()">Create</button>
      </div>
      <div class="em-l-grid__item">
        <button (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div>
  <ng-template #maincontainer>
  </ng-template>
</div>