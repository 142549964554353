export class PhysicalLngCalculateDesDesMain {
    public tabName: string;
    public current:boolean;
    public dblClicked:boolean;
    public uniqueKey:string;

    constructor(tabname: string, selected:boolean){
      this.tabName = tabname; 
      this.current = selected;
      this.uniqueKey = Math.random().toString(36).slice(2, 9);
    }
  }
  
