<h3>
  Trade ID: {{tradeId}}
  <svg class="em-c-icon em-c-icon--medium icon-green" *ngIf="blotter?.tradeInfo.status === 'APPROVED'">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#circle-check"></use>
  </svg>
  <svg class="em-c-icon em-c-icon--medium icon-red" *ngIf="blotter?.tradeInfo.status === 'REJECTED'">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#circle-x"></use>
  </svg>
</h3>
<h3>
  Trader: {{blotter?.tradeInfo.traderName}}
</h3>

<app-trade-blotter [tradeInfo]="tradeInfo" [isReadOnly]="true"></app-trade-blotter>

<div class="em-l-grid em-l-grid--halves">
  <div class="em-l-grid__item em-u-margin-left">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Comment</mat-label>
      <textarea matInput maxlength="2000" [(ngModel)]="blotter?.tradeInfo.comment" [disabled]="!canApproveOrReject()" placeholder="Leave a comment"></textarea>
    </mat-form-field>
  </div>
</div>

<button class="em-c-btn em-c-btn--primary em-u-margin-left" (click)="approve()" [disabled]="!canApproveOrReject()" [class.em-c-btn--disabled]="!canApproveOrReject()">
  Approve
</button>
<button class="em-c-btn em-c-btn--secondary em-u-margin-left" (click)="reject()" [disabled]="!canApproveOrReject()" [class.em-c-btn--disabled]="!canApproveOrReject()">
  Reject
</button>

