import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { FeedbackComponent } from './feedback/feedback.component';
import { NetbackComponent } from './global-arb/netback/netback.component';
import { FreightPriceCurvesComponent } from './global-arb/price-curves/freight-price-curves/freight-price-curves.component';
import { FuelPriceCurvesComponent } from './global-arb/price-curves/fuel-price-curves/fuel-price-curves.component';
import { NatGasPriceCurvesComponent } from './global-arb/price-curves/nat-gas-price-curves/nat-gas-price-curves.component';
import { PriceDistributionComponent } from './implied-market/price-distribution/price-distribution.component';
import { VolatilitySurfaceComponent } from './implied-market/volatility-surface/volatility-surface.component';
import { EuropeCurvesComponent } from './macro-curves/europe-curves/europe-curves.component';
import { FxCurvesComponent } from './macro-curves/fx-curves/fx-curves.component';
import { UkCurvesComponent } from './macro-curves/uk-curves/uk-curves.component';
import { UsCurvesComponent } from './macro-curves/us-curves/us-curves.component';
import { PortfolioVarComponent } from './portfolio-var/portfolio-var.component';
import { RoleGuardService } from './services/role-guard.service';
import { AccessDeniedComponent } from './status-pages/access-denied/access-denied.component';
import { ErrorComponent } from './status-pages/error/error.component';
import { LogoutComponent } from './status-pages/logout/logout.component';
import { NotFoundComponent } from './status-pages/not-found/not-found.component';
import { ApproveTradeComponent } from './trade-blotter/approve-trade/approve-trade.component';
import { NewTradeSuccessComponent } from './trade-blotter/new-trade/new-trade-success/new-trade-success.component';
import { NewTradeComponent } from './trade-blotter/new-trade/new-trade.component';
import { ViewTradesComponent } from './trade-blotter/view-trades/view-trades.component';
import { TradeableInstrumentsComponent } from './tradeable-instruments/tradeable-instruments.component';
import { UnitVarComponent } from './unit-var/unit-var.component';
import { VarCalcComponent } from './var-calc/var-calc.component';
import { VolatilityTableComponent } from './volatility-table/volatility-table.component';
import { GasConverterComponent } from './tools/gas-converter/gas-converter.component';
import { OptionsCalculatorMainComponent } from './options-calculator/options-calculator-main.component';
import { CurveDownloadComponent } from './tools/curve-download/curve-download.component';
import { PortfolioVarCustomAGOT } from './portfolio-var-custom/agot/p-var-custom-agot.component';
import { PhysicalLngFobDesMainComponent } from './global-arb/physical-lng-flow/fobdes/physical-lng-fobdes-main.component';
import { PhysicalLngDealCaseFobDesMainComponent } from './global-arb/physical-lng-flow/fobdes/physical-lng-dealcase-fobdes-main.component';
import { PhysicalLngDesDesMainComponent } from './global-arb/physical-lng-flow/desdes/physical-lng-desdes-main.component';
import { PhysicalLngDealCaseDesDesMainComponent } from './global-arb/physical-lng-flow/desdes/physical-lng-dealcase-desdes-main.component';
import { PhysicalLngTcoMainComponent } from './global-arb/physical-lng-flow/tco/physical-lng-tco-main.component';
import { PhysicalLngDealCaseTcoMainComponent } from './global-arb/physical-lng-flow/tco/physical-lng-dealcase-tco-main.component';
import { PhysicalLngSummaryPageComponent } from './global-arb/physical-lng-flow/summary/physical-lng-summary-page.component';
import { FileDownloadComponent } from './file-download/file-download.component';
import { PricePathsComponent } from './price-paths/price-paths.component';
import { CorrPricePathsComponent } from './corr-price-paths/corr-price-paths.component';


const routes: Routes = [
  {
    path: '', redirectTo: 'optionscalculator', pathMatch: 'full'
  },
  {
    path: '', canActivateChild: [MsalGuard], children: [
      { path: 'var-calculator', component: VarCalcComponent },
      { path: 'unit-var', component: UnitVarComponent },
      { path: 'price-paths', component: PricePathsComponent},
      { path: 'corr-price-paths', component: CorrPricePathsComponent},
      { path: 'volatility', component: VolatilityTableComponent },
      { path: 'optionscalculator', component: OptionsCalculatorMainComponent },
      { path: 'portfolio-var', component: PortfolioVarComponent, canActivate: [RoleGuardService], data: { allowedRoles: ['ALL', 'AGOT', 'EGOT', 'LNG', 'CANADA', 'Risk', 'Admin'] } },
      {
        path: 'trade-blotter', canActivateChild: [RoleGuardService], data: { allowedRoles: ['AGOT', 'ALL'] }, children: [
          { path: '', redirectTo: 'new', pathMatch: 'full' },
          {
            path: 'new', canActivateChild: [RoleGuardService], data: { allowedRoles: ['Admin', 'Trader'] }, children: [
              { path: '', component: NewTradeComponent },
              { path: 'success', component: NewTradeSuccessComponent, }
            ]
          },
          {
            path: 'view', canActivateChild: [RoleGuardService], data: { allowedRoles: ['Admin', 'Trader', 'Analyst'] }, children: [
              { path: '', component: ViewTradesComponent },
              { path: ':id', component: ViewTradesComponent },
            ]
          },
          { path: 'approve/:id', component: ApproveTradeComponent, canActivate: [RoleGuardService], data: { allowedRoles: ['Admin', 'Approver'] } }
        ]
      },
      { path: 'tradeable-instruments', component: TradeableInstrumentsComponent },
      {
        path: 'macro-curves', children: [
          { path: '', redirectTo: 'us-curves', pathMatch: 'full' },
          { path: 'us-curves', component: UsCurvesComponent },
          { path: 'uk-curves', component: UkCurvesComponent },
          { path: 'europe-curves', component: EuropeCurvesComponent },
          { path: 'fx-curves', component: FxCurvesComponent },
        ]
      },
      {
        path: 'market-implied', children: [
          { path: 'price-distribution', component: PriceDistributionComponent },
          { path: 'volatility-surface', component: VolatilitySurfaceComponent },
        ]
      },
      {
        path: 'global-arb', children: [
          { path: 'netback', component: NetbackComponent },
          { path: 'physical-lng-flow', children: [
            { path: 'physical-lng-fobdes', component: PhysicalLngFobDesMainComponent },
            { path: 'physical-lng-dealcase-fobdes', component: PhysicalLngDealCaseFobDesMainComponent },
            { path: 'physical-lng-desdes', component: PhysicalLngDesDesMainComponent },
            { path: 'physical-lng-dealcase-desdes', component: PhysicalLngDealCaseDesDesMainComponent },
            { path: 'physical-lng-tco', component: PhysicalLngTcoMainComponent },  
            { path: 'physical-lng-dealcase-tco', component: PhysicalLngDealCaseTcoMainComponent },  
            { path: 'physical-lng-summary', component: PhysicalLngSummaryPageComponent },
          ]
          },
          {
            path: 'price-curves', children: [
              { path: '', redirectTo: 'natural-gas', pathMatch: 'full' },
              { path: 'natural-gas', component: NatGasPriceCurvesComponent },
              { path: 'freight', component: FreightPriceCurvesComponent },
              { path: 'fuel', component: FuelPriceCurvesComponent },
            ]
          },
        ]
      },
      { path: 'feedback', component: FeedbackComponent },
      { path: 'tools', children:[
        { path:'gas-converter', component: GasConverterComponent },
        { path:'curve-download', component: CurveDownloadComponent }
      ]},
      { path: 'portfolio-var-custom', children:[
        { path:'agot', component: PortfolioVarCustomAGOT }
      ]},
      { path: 'download', component: FileDownloadComponent },
    ]
  },
  {
    path: 'access-denied', component: AccessDeniedComponent
  },
  {
    path: 'error', component: ErrorComponent
  },
  {
    path: 'logout', component: LogoutComponent
  },
  {
    path: 'not-found', component: NotFoundComponent
  },
  {
    path: '**', redirectTo: '/not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
