export class SecurityInfo {
  security?: string;
  hub?: string;
  type?: string;
  region_office?: string;
  exchange?: string;

  constructor(security?: string, hub?:string, type?:string, region_office?:string, exchange?:string) {
    this.security = security;
    this.hub = hub;
    this.type = type;
    this.region_office = region_office;
    this.exchange = exchange;
  }

  clone(): SecurityInfo {
    return Object.assign(new SecurityInfo(), this);
  }

  toString(): string {
    return `${this.hub} ${this.type} (${this.security})`;
  }
}
