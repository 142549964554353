<div class="em-c-loader spinner" *ngIf="isSubmitting">
  <img src="unity/images/icon-spinner.svg" />
</div>

<div class="em-c-alert" [class.em-c-alert--error]="hasError" [class.em-c-alert--success]="wasSuccess" role="alert" *ngIf="showAlert">
  <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#circle-exclamation-mark"></use>
  </svg>
  <div class="em-c-alert__body">
    <ng-container *ngIf="hasError">Error submitting feedback. Please try again.</ng-container>    
    <ng-container *ngIf="wasSuccess">
      Thank you for your feedback!
      <p>Your submission has been added to the UIS backlog as item <a class="em-c-text-link" [href]="createdItem?._links.html.href" target="_blank">#{{ createdItem?.id }}</a>.</p>
    </ng-container>    
  </div>
  <div class="em-c-alert__actions">
    <button class="em-c-text-btn" [class.em-c-alert--error]="hasError" [class.em-c-alert--success]="wasSuccess" (click)="hasError = false">
      Close
    </button>
  </div>
</div>

<form #feedbackForm="ngForm" (ngSubmit)="submit(feedbackForm)">
  <div class="em-l-grid em-l-linelength-container em-u-margin-top em-u-align-center">
    <h3 class="em-u-align-center">Send us your feedback!</h3>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>What would you like to do?</mat-label>
        <mat-select ngModel="type" name="type" required>
          <mat-option *ngFor="let t of feedbackTypes" [value]="t.type">{{t.text}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput ngModel name="title" required>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea rows="10" matInput ngModel name="description" required></textarea>
      </mat-form-field>
    </div>
    <div class="em-c-btn-group ">
      <button class="em-c-btn em-c-btn--primary" type="submit">
        <span class="em-c-btn__text">Submit</span>
      </button>
      <button class="em-c-btn em-c-btn--secondary" type="reset" (click)="feedbackForm.reset()">
        <span class="em-c-btn__text">Clear</span>
      </button>
    </div>
  </div>
</form>
