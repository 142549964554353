import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptInjectorService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  public injectScript(renderer: Renderer2, src: string, type: string = null): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = type ?? 'text/javascript';
    script.src = src;
    renderer.appendChild(this.document.body, script);
    return script;
  }

}
