import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import { SecurityInfo } from 'src/app/models/security-info.model';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { RiskMetricsService } from 'src/app/services/risk-metrics.service';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { FileSaverService } from 'ngx-filesaver';
import { ChartConfiguration } from 'chart.js';
import { ChartSettings } from '../../helpers/chart-settings';
import { BaseChartDirective } from 'ng2-charts';


@Component({
    selector: 'curve-download',
    templateUrl: './curve-download.component.html',
    styleUrls: ['./curve-download.component.scss']
  })

export class CurveDownloadComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private riskMetricsService: RiskMetricsService, private fileSaverService: FileSaverService, private titleService: Title, private dateHelperService: DateHelperService) {
    this.titleService.setTitle('Trading Preview UI | Data Shopping');
  }
  /** control for the selected hub for multi-selection */
  public hubsMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public hubsMultiFilterCtrl: FormControl = new FormControl();

  /** list of hubs filtered by search keyword */
  public filteredHubsMulti: ReplaySubject<SecurityInfo[]> = new ReplaySubject<SecurityInfo[]>(1);

  @ViewChild('multiSelect') multiSelect: MatSelect;
  @ViewChildren(BaseChartDirective) charts?: QueryList<BaseChartDirective>;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  dataSource = new MatTableDataSource();
  displayedColumns: string[];
  dataColumns: string[];

  asOfDate: string;
  shipFrom: string;
  minAsOfDate: string;
  maxAsOfDate: string;
  shipTo: string;
  paymentDays: number = 25;
  securityInfo: SecurityInfo[] = [];
  distinctHubs: SecurityInfo[] = [];

  local_asOfDate: string;
  local_shipFrom: string;
  local_shipTo: string;
  local_paymentDays: number = 25;
  local_distinctHubs: [];

  forwardCurvesData: ChartConfiguration['data'];
  forwardCurvesOptions: ChartConfiguration['options'];

  initialized: boolean = true;

  loadLocals(): void {
    this.local_asOfDate = sessionStorage.getItem('curveDownload:asOfDate');
    this.local_shipFrom = sessionStorage.getItem('curveDownload:shipFrom');
    this.local_shipTo = sessionStorage.getItem('curveDownload:shipTo');
    parseInt(sessionStorage.getItem('curveDownload:paymentDays'), this.local_paymentDays);
    this.local_distinctHubs = JSON.parse(sessionStorage.getItem('curveDownload:distinctHubs'));
  }

  saveLocals(): void{
    sessionStorage.setItem('curveDownload:asOfDate', this.asOfDate);
    sessionStorage.setItem('curveDownload:shipFrom', this.shipFrom);
    sessionStorage.setItem('curveDownload:shipTo', this.shipTo);
    sessionStorage.setItem('curveDownload:paymentDays', this.paymentDays.toString());
    var selHubs = this.getSelectedHubs();
    sessionStorage.setItem('curveDownload:distinctHubs', JSON.stringify(selHubs));
  }

  assignLocals(): void{
    this.loadLocals();
    if(this.local_asOfDate){
      this.asOfDate = this.local_asOfDate;
    }
    if(this.local_shipFrom){
      this.shipFrom = this.local_shipFrom;
    }
    if(this.local_shipTo){
      this.shipTo = this.local_shipTo;
    }
    //this.paymentDays = parseInt(sessionStorage.getItem('curveDownload:paymentDays')); 
    this.paymentDays = this.local_paymentDays; 
  }

  ngOnInit(): void {
    var today = this.dateHelperService.getLastWeekday(new Date());
    this.asOfDate = this.dateHelperService.getDateyyyyMMdd(today.toLocaleDateString('en-US')); 
    this.minAsOfDate = this.dateHelperService.getDateyyyyMMdd(this.dateHelperService.getNextMonth(today).toLocaleDateString('en-US')).substring(0, 7);
    this.shipFrom = this.minAsOfDate;
    today.setFullYear(today.getFullYear(), today.getMonth() + 6);
    this.shipTo = this.dateHelperService.getDateyyyyMMdd(this.dateHelperService.getNextMonth(today).toLocaleDateString('en-US')).substring(0, 7);
    this.assignLocals();

    this.initialized = false;
    this.riskMetricsService.getSecuritiesInfo()
      .pipe(finalize(() => {
        this.initialized = true;
      }))
      .subscribe(res => {
        this.securityInfo = res.map(x => new SecurityInfo(x.security, x.hub, x.type, x.region_office)).sort((a, b) => a.hub.localeCompare(b.hub));
        this.distinctHubs = this.securityInfo.filter( (thing, i, arr) => arr.findIndex(t => t.hub === thing.hub) === i);
        this.filteredHubsMulti.next(this.distinctHubs.slice());

        /* Set Default Selected */
        if(this.local_distinctHubs){
          var local_set = this.distinctHubs.map(x => (this.local_distinctHubs.find(y => (y == x.hub))!=null ? x : null)).filter(z => z != null);
          this.hubsMultiCtrl.setValue(local_set);
        } else {
          this.hubsMultiCtrl.setValue([this.distinctHubs[7], this.distinctHubs[20]]);
        }
      });

    // listen for search field value changes
    this.hubsMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterHubsMulti();
    });
    this.initializeChartSettings();
  }

  tradeDateChange(){
    var today = new Date(this.dateHelperService.getDateddMMyyyy(this.asOfDate)); 
    this.minAsOfDate = this.dateHelperService.getDateyyyyMMdd(this.dateHelperService.getNextMonth(today).toLocaleDateString('en-US')).substring(0, 7);
  }

  populateTimeSeriesChart(data, displayedColumns): void {
    this.forwardCurvesData.datasets = [];
    for (const col of displayedColumns) {
      if (col != "Trade Date" && col != "Strip" && col != "Payment Day" && col != "days" ) {
          const symbolData = data.map(x => x[col]);
          this.forwardCurvesData.datasets.push({
            data: symbolData,
            label: col,
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
          });
        };
      }
      this.forwardCurvesData.labels = data.map(x => x['Strip']);
      this.charts.first.render();
  }

  
  private initializeChartSettings(): void 
  {
    this.forwardCurvesData = { datasets: [] };
    this.forwardCurvesOptions = {
      scales: {
        x: {
          title: {
            text: 'Strip',
            display: true
          },
          ...ChartSettings.defaultTickSettings,
          ...ChartSettings.defaultGridSettings
        },
        y: {
          title: {
            text: '$',
            display: true
          },
          ...ChartSettings.defaultGridSettings
        }
      },
      aspectRatio: 4,
      elements: {
        line: {
          borderWidth: 2
        }
      },
      plugins: {
        title: {
          display: true,
          text: 'Forward Curves'
        }
      }
    };
  }

  getPipelineData(){
    var inputs = {};
    inputs["trade_date"] = this.asOfDate;
    inputs["month_begin"] = this.shipFrom + "-01";
    inputs["month_end"] = this.shipTo + "-01";
    inputs["payment_day"] = this.paymentDays;
    inputs["hubs"] = this.getSelectedHubs();
    this.saveLocals();

    this.initialized = false;
    this.riskMetricsService.getPipelineFuturesPrices(inputs)
    .pipe(finalize(() =>  {
      this.initialized = true;
    })).subscribe(
      res => {
        const data = [];
        var idx=0;
        while(res[idx]){
          data.push(res[idx]);
          idx++;
        }
        this.dataSource.data = data;
        this.displayedColumns = Object.keys(this.dataSource.data.find(Boolean));
        this.dataColumns = this.displayedColumns.slice(2); 
        
        this.populateTimeSeriesChart(data, this.displayedColumns);
    });    
  }

  getSelectedHubs(){
    var selected = [];
    var selectedHubs = this.multiSelect.options.filter(x => x.selected).map(y => y.value);
    selectedHubs.forEach(secInfo =>{
      selected.push(secInfo.hub);
    });
    return selected;
  }

  exportToExcel(){
    var inputs = {};
    inputs["trade_date"] = this.asOfDate;
    inputs["month_begin"] = this.shipFrom + "-01";
    inputs["month_end"] = this.shipTo + "-01";
    inputs["payment_day"] = this.paymentDays;
    inputs["hubs"] = this.getSelectedHubs();
    this.saveLocals();

    this.initialized = false;
    this.riskMetricsService.downloadPipelineFuturesPrices(inputs)
      .pipe(finalize(() => this.initialized = true))
      .subscribe(res => this.fileSaverService.save(res.blob, res.fileName));
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  
  /**
   * Sets the initial value after the filteredHubs are loaded initially
   */
  protected setInitialValue() {
    this.filteredHubsMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (a: SecurityInfo, b: SecurityInfo) => a && b && a.hub === b.hub;
      });
  }

  protected filterHubsMulti() {
    if (!this.distinctHubs) {
      return;
    }
    // get the search keyword
    let search = this.hubsMultiFilterCtrl.value;
    if (!search) {
      this.filteredHubsMulti.next(this.distinctHubs.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the hubs
    this.filteredHubsMulti.next(
      this.distinctHubs.filter(sinfo => sinfo.hub.toLowerCase().indexOf(search) > -1)
    );
  }

  isNaN(value: any): boolean {
    return isNaN(value);
  }
}