import { Component, OnInit } from '@angular/core';
import { DateHelperService } from '../../../services/date-helper.service';
import { Title } from '@angular/platform-browser';
import { RiskMetricsService } from '../../../services/risk-metrics.service';
import { finalize } from 'rxjs/operators';
import { FileSaverService } from 'ngx-filesaver';
import { PhysicalLngInputTco, DealEconomicsTco, VesselInfo, TradeSummaryTco, VolumeBalancesTco } from './physical-lng-tco.model';
import { PhysicalLngTcoSessionService } from './physical-lng-tco.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-physical-lng-tco',
  templateUrl: './physical-lng-tco.component.html',
  styleUrls: ['./physical-lng-tco.component.scss']
})
export class PhysicalLngTcoComponent implements OnInit {

  constructor(private riskMetricsService: RiskMetricsService, private titleService: Title, 
    private dateHelperService: DateHelperService, private fileSaverService: FileSaverService,
    private localStore: PhysicalLngTcoSessionService) {
    this.titleService.setTitle('Trading Preview UI | Physical LNG TCO');
    this.lngInputsTco = new PhysicalLngInputTco();
  }

  tabRefName: string;
  deleteTheTab: Subject<string> = new Subject();

  selectedSeller: string = "Owner";
  selectedBuyer: string = "Charterer";
  initialized: Boolean;
  visibleIn: boolean = true;
  lngInputsTco: PhysicalLngInputTco;
  tradeSummaryTco: TradeSummaryTco;
  dealEconomicsTco: DealEconomicsTco;
  volumeBalancesTco: VolumeBalancesTco;
  charterInTotalFullDays: number;
  charterOutTotalFullDays: number;
  netRealisationAmount: number;

  vesselSize: number;
  vesselType: string;
  vesselBorIdle: number;
  vesselInCharterRate: number = 0.0;

  charterOutBegDate: string;
  charterOutEndDate: string;

  doPositioningBonus: string = "No";
  doRepositioningBonus: string = "No";

  apiVesselTypes: VesselInfo[] = [
    // { "vessel_name": "Beidou Star",    "vessel_type": "TFDE",    "capacity": 171825.317,    "in_charter_rate": 104000.0},
    // { "vessel_name": "Beidou Star + Reliq",    "vessel_type": "TFDE",    "capacity": 171825.317,    "in_charter_rate": 104000.0 },
    // { "vessel_name": "Cool Racer",    "vessel_type": "MEGI/XDF",    "capacity": 174042.0,    "in_charter_rate": 93000.0 },
    { "vessel_name": "148 ST Vessel",    "vessel_type": "Steam Turbine",    "capacity": 147384.169,    "in_charter_rate": 0, "boiloffrate_laden": 0.0015, "boiloffrate_ballast": 0.0015 }];
  apiVesselSpeeds = []
  
  ngOnInit(): void {
    var localStoredString = JSON.parse(this.localStore.getData(this.tabRefName));
    if(localStoredString) {
      this.initLoad();
      this.onVesselNameSelect();

      this.loadDataFromStore(localStoredString);
    }
    else {
      this.lngInputsTco.TestLoadDefaults();
      this.tradeSummaryTco = new TradeSummaryTco();
      // this.tradeSummaryTco.net_realisation_amount = 0.0;
      this.setSummaryDates();
      this.netRealisationAmount = 0.0;
      this.dealEconomicsTco = new DealEconomicsTco();
      this.volumeBalancesTco = new VolumeBalancesTco();
  
      this.initLoad();
      this.onVesselNameSelect();

      this.saveDataToStore();  
    }
  }

  saveDataToStore(): void{
    var localStoredObj = {
      'selectedSeller': JSON.stringify(this.selectedSeller),
      'selectedBuyer': JSON.stringify(this.selectedBuyer),
      'charterOutBegDate': JSON.stringify(this.charterOutBegDate),
      'charterOutEndDate': JSON.stringify(this.charterOutEndDate),
      'dealEconomicsTco': JSON.stringify(this.dealEconomicsTco),
      'volumeBalancesTco': JSON.stringify(this.volumeBalancesTco),
      'lngInputsTco': JSON.stringify(this.lngInputsTco),
      'vesselSize': JSON.stringify(this.vesselSize),
      'vesselType': JSON.stringify(this.vesselType),
      'vesselInCharterRate': JSON.stringify(this.vesselInCharterRate),
      'charterInTotalFullDays': JSON.stringify(this.charterInTotalFullDays),
      'charterOutTotalFullDays': JSON.stringify(this.charterOutTotalFullDays),
      'vesselBorIdle': JSON.stringify(this.vesselBorIdle),
      'doPositioningBonus': JSON.stringify(this.doPositioningBonus),
      'doRepositioningBonus': JSON.stringify(this.doRepositioningBonus),
    }
    this.localStore.saveData(this.tabRefName, JSON.stringify(localStoredObj));
  }

  loadDataFromStore(localStoredString: string): void{
    this.selectedSeller = JSON.parse(localStoredString['selectedSeller']);
    this.selectedBuyer = JSON.parse(localStoredString['selectedBuyer']);
    this.charterOutBegDate = JSON.parse(localStoredString['charterOutBegDate']);
    this.charterOutEndDate = JSON.parse(localStoredString['charterOutEndDate']);
    this.dealEconomicsTco = JSON.parse(localStoredString['dealEconomicsTco']);
    this.volumeBalancesTco = JSON.parse(localStoredString['volumeBalancesTco']);
    this.lngInputsTco = JSON.parse(localStoredString['lngInputsTco']);
    this.vesselSize = JSON.parse(localStoredString['vesselSize']);
    this.vesselType = JSON.parse(localStoredString['vesselType']);
    this.vesselInCharterRate = JSON.parse(localStoredString['vesselInCharterRate']);
    this.charterInTotalFullDays = JSON.parse(localStoredString['charterInTotalFullDays']);
    this.charterOutTotalFullDays = JSON.parse(localStoredString['charterOutTotalFullDays']);
    this.vesselBorIdle = JSON.parse(localStoredString['vesselBorIdle']);
    this.doPositioningBonus = JSON.parse(localStoredString['doPositioningBonus']);
    this.doRepositioningBonus = JSON.parse(localStoredString['doRepositioningBonus']);
  }
  
  initLoad(): void{
    this.initialized = true;
    this.riskMetricsService.getPhysicalDealInitDataTco().pipe(finalize(()=>{
      this.initialized = false;
    })).subscribe(
      res => {
        this.apiVesselTypes = JSON.parse(res.all_vessel_data);
        this.apiVesselSpeeds = JSON.parse(res.vessel_speeds);
      }
    )
  }

  setSummaryDates(){
    this.charterOutBegDate = this.dateHelperService.getDateyyyyMMdd(this.lngInputsTco.charter_out_dates_prices_costs.charter_out_beg_date).substring(0,10);
    this.charterOutEndDate = this.dateHelperService.getDateyyyyMMdd(this.lngInputsTco.charter_out_dates_prices_costs.charter_out_end_date).substring(0,10);
  }

  onVesselNameSelect(){
    var selectedVessel = this.apiVesselTypes.filter(x => x.vessel_name == this.lngInputsTco.vessel_name);
    if(selectedVessel.length == 1){
      this.vesselSize = selectedVessel[0].capacity;
      this.vesselType = selectedVessel[0].vessel_type;
      this.vesselBorIdle = 100 * selectedVessel[0].boiloffrate_laden;  // float -> %
      // this.lngInputsTco.charter_in_dates_prices_costs.charter_in_rate = selectedVessel[0].in_charter_rate;
      if(selectedVessel[0].in_charter_rate > 0) {
        this.vesselInCharterRate = selectedVessel[0].in_charter_rate;
      }
    }
  }

  // setSummaryDates(){
  //   this.selectedDischargeDate = this.dateHelperService.getDateyyyyMMdd(this.lngInputsTco.discharge_date).substring(0,10);
  // }

  calculatePhysicalLNGTco(){
    var inputs = this.getInputsForCalculation();
    this.initialized = true;
    this.riskMetricsService.calculatePhysicalLNGTco(inputs).pipe(finalize(() =>  {
      this.initialized = false;
    })).subscribe(
      res => {
        this.dealEconomicsTco = res.deal_economics;
        this.volumeBalancesTco = res.volume_balances;
        this.charterInTotalFullDays = res.charter_in_total_full_days_calculated;
        this.charterOutTotalFullDays = res.charter_out_total_full_days_calculated;
        this.netRealisationAmount = res.deal_economics.net_realization;

        this.saveDataToStore();
    });
  }

  private getInputsForCalculation() {
    var inputs = JSON.parse(JSON.stringify(this.lngInputsTco));

    var charterInBegDate = new Date(inputs.charter_in_dates_prices_costs.charter_in_beg_date);
    inputs.charter_in_dates_prices_costs.charter_in_beg_date = this.dateHelperService.dateToString(charterInBegDate);
    var charterInEndDate = new Date(inputs.charter_in_dates_prices_costs.charter_in_end_date);
    inputs.charter_in_dates_prices_costs.charter_in_end_date = this.dateHelperService.dateToString(charterInEndDate);

    var charterOutBegDate = new Date(inputs.charter_out_dates_prices_costs.charter_out_beg_date);
    inputs.charter_out_dates_prices_costs.charter_out_beg_date = this.dateHelperService.dateToString(charterOutBegDate);
    var charterOutEndDate = new Date(inputs.charter_out_dates_prices_costs.charter_out_end_date);
    inputs.charter_out_dates_prices_costs.charter_out_end_date = this.dateHelperService.dateToString(charterOutEndDate);

    inputs.logistics.do_positioning_bonus = this.doPositioningBonus == "Yes";
    inputs.logistics.do_repositioning_bonus = this.doRepositioningBonus == "Yes";
    inputs.logistics.positioning_bonus_details.charter_rate = inputs.logistics.positioning_bonus_details.charter_rate_display/100;
    inputs.logistics.positioning_bonus_details.fuel_rate = inputs.logistics.positioning_bonus_details.fuel_rate_display/100;
    inputs.logistics.repositioning_bonus_details.charter_rate = inputs.logistics.repositioning_bonus_details.charter_rate_display/100;
    inputs.logistics.repositioning_bonus_details.fuel_rate = inputs.logistics.repositioning_bonus_details.fuel_rate_display/100;
    return inputs;
  }

  downloadPhysicalTcoDeal() {
    var inputs = this.getInputsForCalculation();
    this.initialized = true;
    this.riskMetricsService.getTcoDownloadExcel(inputs).pipe(finalize(() =>  {
      this.initialized = false;
    })).subscribe(
      res => {
        this.fileSaverService.save(res.blob, res.fileName)
    });
  }
 
  deleteTab(){
    this.deleteTheTab.next(this.tabRefName);
    this.localStore.removeData(this.tabRefName);
  }

}
