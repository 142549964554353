import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Chart } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { FileSaverModule } from 'ngx-filesaver';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FooterComponent } from './footer/footer.component';
import { NetbackCalculationComponent } from './global-arb/netback/netback-calculation/netback-calculation.component';
import { NetbackSettingsComponent } from './global-arb/netback/netback-settings/netback-settings.component';
import { NetbackComponent } from './global-arb/netback/netback.component';
import { FreightPriceCurvesComponent } from './global-arb/price-curves/freight-price-curves/freight-price-curves.component';
import { FuelPriceCurvesComponent } from './global-arb/price-curves/fuel-price-curves/fuel-price-curves.component';
import { NatGasCurveInfoComponent } from './global-arb/price-curves/nat-gas-price-curves/nat-gas-curve-info/nat-gas-curve-info.component';
import { NatGasPriceCurvesComponent } from './global-arb/price-curves/nat-gas-price-curves/nat-gas-price-curves.component';
import { HeaderComponent } from './header/header.component';
import { PriceDistributionComponent } from './implied-market/price-distribution/price-distribution.component';
import { VolatilitySurfaceComponent } from './implied-market/volatility-surface/volatility-surface.component';
import { EuropeCurvesComponent } from './macro-curves/europe-curves/europe-curves.component';
import { FxCurvesComponent } from './macro-curves/fx-curves/fx-curves.component';
import { UkCurvesComponent } from './macro-curves/uk-curves/uk-curves.component';
import { UsCurvesComponent } from './macro-curves/us-curves/us-curves.component';
import { MaterialsModule } from './materials.module';
import { MsalAuthModule } from './msal-auth.module';
import { NegativeParensPipe } from './pipes/negative-parens.pipe';
import { PortfolioVarTableComponent } from './portfolio-var/portfolio-var-table/portfolio-var-table.component';
import { PortfolioVarComponent } from './portfolio-var/portfolio-var.component';
import { ApplicationInsightsErrorHandler } from './services/monitoring.service';
import { AccessDeniedComponent } from './status-pages/access-denied/access-denied.component';
import { ErrorComponent } from './status-pages/error/error.component';
import { LogoutComponent } from './status-pages/logout/logout.component';
import { NotFoundComponent } from './status-pages/not-found/not-found.component';
import { ApproveTradeComponent } from './trade-blotter/approve-trade/approve-trade.component';
import { NewTradeSuccessComponent } from './trade-blotter/new-trade/new-trade-success/new-trade-success.component';
import { NewTradeComponent } from './trade-blotter/new-trade/new-trade.component';
import { TradeBlotterComponent } from './trade-blotter/trade-blotter.component';
import { ViewTradesComponent } from './trade-blotter/view-trades/view-trades.component';
import { TradeableInstrumentsComponent } from './tradeable-instruments/tradeable-instruments.component';
import { UnitVarComponent } from './unit-var/unit-var.component';
import { VarCalcComponent } from './var-calc/var-calc.component';
import { VolatilityTableComponent } from './volatility-table/volatility-table.component';
import { GasConverterComponent } from './tools/gas-converter/gas-converter.component';
import { GasConverterPriceComponent } from './tools/gas-converter/gas-converter-price.component';
import { OptionsCalculatorComponent } from './options-calculator/options-calculator.component';
import { OptionsCalcItem } from './options-calculator/options-calc-item.component';
import { OptionsCalculatorMainComponent } from './options-calculator/options-calculator-main.component';
import { CurveDownloadComponent } from './tools/curve-download/curve-download.component';
import { PortfolioVarCustomAGOT } from './portfolio-var-custom/agot/p-var-custom-agot.component';
import { PhysicalLngFobDesMainComponent } from './global-arb/physical-lng-flow/fobdes/physical-lng-fobdes-main.component';
import { PhysicalLngFobdesComponent } from './global-arb/physical-lng-flow/fobdes/physical-lng-fobdes.component';
import { PhysicalLngDealCaseFobDesMainComponent } from './global-arb/physical-lng-flow/fobdes/physical-lng-dealcase-fobdes-main.component';
import { PhysicalLngDesDesMainComponent } from './global-arb/physical-lng-flow/desdes/physical-lng-desdes-main.component';
import { PhysicalLngDealCaseDesDesMainComponent } from './global-arb/physical-lng-flow/desdes/physical-lng-dealcase-desdes-main.component';
import { PhysicalLngDesdesComponent } from './global-arb/physical-lng-flow/desdes/physical-lng-desdes.component';
import { PhysicalLngTcoMainComponent } from './global-arb/physical-lng-flow/tco/physical-lng-tco-main.component';
import { PhysicalLngDealCaseTcoMainComponent } from './global-arb/physical-lng-flow/tco/physical-lng-dealcase-tco-main.component';
import { PhysicalLngTcoComponent } from './global-arb/physical-lng-flow/tco/physical-lng-tco.component';
import { PhysicalLngSummaryPageComponent } from './global-arb/physical-lng-flow/summary/physical-lng-summary-page.component';
import { AccountingPipe } from './pipes/number-accounting.pipe';
import { FileDownloadComponent } from './file-download/file-download.component';
import { PricePathsComponent } from './price-paths/price-paths.component';
import { CorrPricePathsComponent } from './corr-price-paths/corr-price-paths.component';

Chart.defaults.font.family = "'EMprintRegular', 'HelveticaNeue', sans-serif";
Chart.defaults.interaction = { intersect: false, axis: 'x', mode: 'nearest', includeInvisible: false };
Chart.defaults.elements.line.tension = 0.5;
Chart.defaults.plugins.legend.labels.boxHeight = 0;

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  decimal: ".",
  precision: 0,
  prefix: "",
  suffix: "",
  thousands: ","
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    VarCalcComponent,
    UnitVarComponent,
    TradeableInstrumentsComponent,
    TradeBlotterComponent,
    LogoutComponent,
    AccessDeniedComponent,
    NewTradeComponent,
    ViewTradesComponent,
    ApproveTradeComponent,
    NotFoundComponent,
    ErrorComponent,
    NewTradeSuccessComponent,
    VolatilityTableComponent,
    PortfolioVarComponent,
    NegativeParensPipe,
    UsCurvesComponent,
    UkCurvesComponent,
    EuropeCurvesComponent,
    FxCurvesComponent,
    PortfolioVarTableComponent,
    PriceDistributionComponent,
    VolatilitySurfaceComponent,
    NetbackComponent,
    NetbackSettingsComponent,
    NetbackCalculationComponent,
    FeedbackComponent,
    NatGasPriceCurvesComponent,
    FuelPriceCurvesComponent,
    FreightPriceCurvesComponent,
    NatGasCurveInfoComponent,
    GasConverterComponent,
    GasConverterPriceComponent,
    OptionsCalculatorComponent,
    OptionsCalcItem,
    OptionsCalculatorMainComponent,
    CurveDownloadComponent,
    PortfolioVarCustomAGOT,
    PhysicalLngFobdesComponent,
    PhysicalLngFobDesMainComponent,
    PhysicalLngDealCaseFobDesMainComponent,
    PhysicalLngDesdesComponent,
    PhysicalLngDesDesMainComponent,
    PhysicalLngDealCaseDesDesMainComponent,
    PhysicalLngTcoComponent,
    PhysicalLngTcoMainComponent,
    PhysicalLngDealCaseTcoMainComponent,
    PhysicalLngSummaryPageComponent,
    AccountingPipe,
    FileDownloadComponent,
    PricePathsComponent,
    CorrPricePathsComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CurrencyMaskModule,
    FileSaverModule,
    FormsModule,
    HttpClientModule,
    NgChartsModule,
    MaterialsModule,
    MsalAuthModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ApplicationInsightsErrorHandler
    },
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: CustomCurrencyMaskConfig
    }
  ],
  bootstrap: [AppComponent], entryComponents: [OptionsCalcItem]
})
export class AppModule { }
