import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import { DateHelperService } from '../services/date-helper.service';
import { RiskMetricsService } from '../services/risk-metrics.service';

@Component({
  selector: 'app-unit-var',
  templateUrl: './unit-var.component.html',
  styleUrls: ['./unit-var.component.scss']
})
export class UnitVarComponent implements OnInit {

  nextWeekdayStr: string;
  businessDays: number;

  region: string;
  currency: string;
  alphaCI: string;
  dateHorizon: string;

  showCalculatingError = false;
  isCalculating = false;
  refreshTime: Date;

  monthlyUnitVaRData = [];

  displayedColumns: string[];

  constructor(private riskMetricsService: RiskMetricsService, private dateHelperService: DateHelperService, private titleService: Title) {
    this.titleService.setTitle('Trading Preview UI | Unit VaR');
  }

  ngOnInit(): void {
    this.region = 'AGOT';
    this.currency = 'USD';
    this.alphaCI = '.95';

    const nextWeekday = this.dateHelperService.getNextWeekday(new Date());

    this.dateHorizon = this.dateHelperService.getDateyyyyMMdd(nextWeekday.toLocaleDateString('en-CA', { timeZone: 'America/New_York' }));
    this.nextWeekdayStr = this.dateHorizon;
    this.businessDays = 1;

    this.calculateUnitVaR();
  }

  calculateUnitVaR(): void {
    this.isCalculating = true;
    this.showCalculatingError = false;
    const params = {
      region: this.region,
      alpha: this.alphaCI,
      currency: this.currency,
      date_horizon: this.dateHorizon,
    }
    this.riskMetricsService.getUnitVar(params)
      .pipe(finalize(() => this.isCalculating = false))
      .subscribe(
        res => {
          if (res.length === 0) {
            this.showCalculatingError = true;
          }
          else {
            this.monthlyUnitVaRData = res;
            this.displayedColumns = Object.keys(this.monthlyUnitVaRData.find(Boolean));
          }
        },
        () => this.showCalculatingError = true);
  }

  regionChanged(): void {
    switch (this.region) {
      case 'EGOT':
        this.currency = 'EURO';
        break;
      case 'LNG':
      case 'AGOT':
      default:
        this.currency = 'USD';
    }
    this.calculateUnitVaR();
  }

  currencyChanged(): void {
    this.calculateUnitVaR();
  }

  getVaRValue(row: any, col: string): string {
    return row[col];
  }

  timeHorizonChanged() {
    const dateHorizonArr = this.dateHorizon.split('-');
    const today = new Date();
    // have to do this to strip out time zones and current hours
    this.businessDays = this.dateHelperService.getWeekdaysBetween(
      new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      new Date(+dateHorizonArr[0], +dateHorizonArr[1] - 1, +dateHorizonArr[2]));

    this.calculateUnitVaR();
  }
}
