import { OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { FileSaverService } from 'ngx-filesaver';
import { SecurityInfo } from '../models/security-info.model';
import { Trade } from '../models/trade.model';
import { RiskMetricsService } from '../services/risk-metrics.service';
import { TradeBlotterService } from '../services/trade-blotter.service';
import { UserService } from '../services/user.service';
import { DateHelperService } from '../services/date-helper.service';

@Component({
  selector: 'app-trade-blotter',
  templateUrl: './trade-blotter.component.html',
  styleUrls: ['./trade-blotter.component.scss']
})
export class TradeBlotterComponent implements OnInit, OnChanges {

  @Input() isReadOnly: boolean = false;
  @Input() tradeInfo: Trade = {};
  securityInfo: SecurityInfo[] = [];
  filteredSecurityInfo: SecurityInfo[] = [];

  intradayPriceRefreshTime: Date;
  showPriceWarning = false;
  canPullLiveData = false;

  attachedSupportingFile: File;

  defaultLeg = { securityInfo: new SecurityInfo('NG', 'Henry Hub', 'Flat'), position: 'long', lot: 1, intraday_price: 0.00 };

  toraStrategies = [];
  approvers = [];

  @ViewChild(MatTable) table: MatTable<any>;

  constructor(private riskMetricsService: RiskMetricsService, private tradeBlotterService: TradeBlotterService, private userService: UserService,
    private fileSaverService: FileSaverService, private notificationService: MatSnackBar, private dateHelperService: DateHelperService) { }

  ngOnInit(): void {
    this.initLegs();
    this.tradeInfo.date = this.dateHelperService.getDateyyyyMMdd(new Date().toLocaleDateString('en-CA', { timeZone: 'America/New_York' }));
    this.tradeInfo.status = 'NEW';
    this.tradeInfo.toraStrategyId = null;
    this.tradeInfo.traderId = this.userService.getUser().idTokenClaims.oid;
    this.tradeInfo.holdLengthUnit = 'days';

    this.canPullLiveData = this.userService.hasIceAccess();

    this.riskMetricsService.getSecuritiesInfo().subscribe(res => {
      this.securityInfo = res.map(x => new SecurityInfo(x.security, x.hub, x.type, x.region_office)).sort((a, b) => a.hub.localeCompare(b.hub));
      this.filteredSecurityInfo = this.securityInfo;
    });

    this.tradeBlotterService.getApprovers().subscribe(res => this.approvers = res.sort((a, b) => a.last_name.localeCompare(b.last_name)));
    this.tradeBlotterService.getToraStrategies().subscribe(res => this.toraStrategies = res.sort((a, b) => b.tora_strat_number - a.tora_strat_number));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tradeInfo) {
       //must do this in order to get the object toString implementation to work, otherwise uses the object prototype toString
      this.tradeInfo.legs?.forEach(leg => leg.securityInfo = new SecurityInfo(leg.securityInfo.security, leg.securityInfo.hub, leg.securityInfo.type));
      this.attachedSupportingFile = null;
    }
  }

  initLegs(): void {
    this.tradeInfo.legs = [];
    this.updatePriceAndUnit(this.defaultLeg);
    this.tradeInfo.legs.push(this.defaultLeg);
    this.refreshTable();
  }

  addLeg(): void {
    const copy = { ...this.tradeInfo.legs[this.tradeInfo.legs.length - 1] };
    this.tradeInfo.legs.push(copy);
    this.refreshTable();
  }

  deleteLeg(leg: any) {
    if (this.tradeInfo.legs.length > 1) {
      const index = this.tradeInfo.legs.indexOf(leg);

      if (index > -1) {
        this.tradeInfo.legs.splice(index, 1);
        this.refreshTable();
      }
    }
  }

  refreshTable() {
    this.table?.renderRows();
  }

  updateIntradayPrices() {
    this.intradayPriceRefreshTime = null;
    this.showPriceWarning = false;

    this.tradeInfo.legs.forEach(leg => {
      this.riskMetricsService.getIntradayPrice(leg.securityInfo.security, leg.tenor_start).subscribe(res => {
        leg.intraday_price = res.intraday_price;

        if (res.time_stamp) {
          var timeStamp = new Date(res.time_stamp);

          if (!this.intradayPriceRefreshTime || timeStamp > this.intradayPriceRefreshTime) {
            this.intradayPriceRefreshTime = timeStamp;
          }
        }

        if (res.warning) {
          this.showPriceWarning = true;
        }
      });
    });
  }


  checkSecurity(securityInfo: any) {
    return typeof securityInfo === 'string';
  }

  securityValueChanged(changeValue, leg) {
    if (typeof changeValue === 'string') {
      this.filteredSecurityInfo = this.securityInfo.filter(x =>
        x.toString().toLowerCase().includes(changeValue.toLowerCase()));
    }
    else {
      // reset filtered security information to full list after security selection
      this.filteredSecurityInfo = this.securityInfo;
      this.updatePriceAndUnit(leg);
    }
  }

  attachedFileChange(files: FileList) {
    this.attachedSupportingFile = files.length > 0 ? files.item(0) : null;

    if (this.attachedSupportingFile.size > 10485760) {
      this.attachedSupportingFile = null;
      this.notificationService.open('Supporting file size must be smaller than 10MB in size.', 'Dismiss');
    }
  }

  startStripChanged(leg: any) {
    if (leg.tenor_end < leg.tenor_start) {
      leg.tenor_end = leg.tenor_start;
    }

    this.updatePrice(leg);
  }

  endStripChanged(leg: any) {
    if (leg.tenor_end < leg.tenor_start) {
      leg.tenor_start = leg.tenor_end;
    }

    this.updatePrice(leg);
  }

  validate(): boolean {
    if (!this.tradeInfo.date || !this.tradeInfo.approverId || !this.tradeInfo.tradeType ||
      !this.tradeInfo.currentVar || !this.tradeInfo.maxVar || !this.tradeInfo.projectedProfit ||
      !this.tradeInfo.stopLoss || !this.tradeInfo.holdLength || this.tradeInfo.legs.length < 1) {
      return false;
    }

    return true;
  }

  updateStatus(status: string) {
    this.tradeInfo.status = status;
  }

  isNewPendingOrRejected(): boolean {
    return this.tradeInfo?.status === 'NEW' || this.tradeInfo?.status === 'PENDING' || this.tradeInfo?.status === 'REJECTED';
  }

  canEditStrategyQuorum(): boolean {
    return this.tradeInfo?.status === 'EXECUTED' || this.tradeInfo?.status === 'APPROVED' || this.isNewPendingOrRejected();
  }

  canEditPricing(): boolean {
    return this.tradeInfo?.status === 'APPROVED' || this.isNewPendingOrRejected();
  }

  getDisplayedColumns(): string[] {
    if (this.isNewPendingOrRejected() && !this.isReadOnly) {
      return ['code', 'position', 'lot', 'intraday-price', 'tenor-start', 'tenor-end', 'delete'];
    }
    else {
      return ['code', 'position', 'lot', 'intraday-price', 'tenor-start', 'tenor-end'];
    }
  }

  downloadFile(): void {
    this.tradeBlotterService.downloadSupportingFile(this.tradeInfo.id).subscribe(blob => 
      this.fileSaverService.save(blob, this.tradeInfo.supportingFileName));
  }

  private updatePriceAndUnit(leg: any): void {
    this.riskMetricsService.getEODPriceSummary(leg.securityInfo.security).subscribe(res => {
      leg.min_strip_date = res.reduce((a, b) => a.STRIP < b.STRIP ? a : b).STRIP.substring(0, 7);
      leg.max_strip_date = res.reduce((a, b) => a.STRIP > b.STRIP ? a : b).STRIP.substring(0, 7);

      leg.tenor_start = leg.min_strip_date;
      leg.tenor_end = leg.min_strip_date;

      this.updatePrice(leg);
    });

    this.updateUnits(leg);
  }

  private updatePrice(leg: any): void {
    if (this.canPullLiveData) {
      this.riskMetricsService.getIntradayPrice(leg.securityInfo.security, leg.tenor_start).subscribe(
        res => leg.intraday_price = res.intraday_price,
        () => leg.intraday_price = null
      );
    }
    else {
      this.riskMetricsService.getEODPrice(leg.securityInfo.security, leg.tenor_start).subscribe(
        res => leg.intraday_price = res.end_of_date_price,
        () => leg.intraday_price = null
      );
    }
  }

  private updateUnits(leg: any): void {
    this.riskMetricsService.getSecurityUnits(leg.securityInfo.security).subscribe(res => {
      leg.currency = res.currency_symbol;
      // Removed unit as it's no longer being used with switch to lot
      // leg.unit = res.unit;
    });
  }
}
