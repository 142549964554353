import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Trade } from '../../models/trade.model';
import { TradeBlotterService } from '../../services/trade-blotter.service';
import { UserService } from '../../services/user.service';
import { TradeBlotterComponent } from '../trade-blotter.component';

@Component({
  selector: 'app-approve-trade',
  templateUrl: './approve-trade.component.html',
  styleUrls: ['./approve-trade.component.scss']
})
export class ApproveTradeComponent implements OnInit {

  tradeId: number;
  tradeInfo: Trade;

  @ViewChild(TradeBlotterComponent) blotter: TradeBlotterComponent;

  constructor(private route: ActivatedRoute, private router: Router, private tradeBlotterService: TradeBlotterService, private userService: UserService,
    private titleService: Title) {
    this.titleService.setTitle('Trading Preview UI | Trade Blotter: Approve trade');
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.tradeId = params.id;
      this.loadTrade();
    });
  }

  loadTrade(): void {
    if (this.tradeId) {
      this.tradeBlotterService.getDeal(this.tradeId).subscribe(
        res => {
          if (res.approverId != this.userService.getUser().idTokenClaims.oid) {
            this.router.navigate(['/access-denied']);
          }
          else {
            this.tradeInfo = res;
          }
        },
        err => {
          this.router.navigate(['/not-found']);
        });
    }
  }

  canApproveOrReject(): boolean {
    return this.blotter?.tradeInfo.status === 'PENDING';
  }

  approve(): void {
    this.tradeBlotterService.updateDealStatus(this.tradeId, 'APPROVED')
      .pipe(switchMap(() => this.tradeBlotterService.updateDeal({ id: this.tradeId, comment: this.blotter.tradeInfo.comment })))
      .subscribe(
        () => this.blotter.tradeInfo.status = 'APPROVED',
        () => this.router.navigate(['/error'])
      );
  }

  reject(): void {
    this.tradeBlotterService.updateDealStatus(this.tradeId, 'REJECTED')
      .pipe(switchMap(() => this.tradeBlotterService.updateDeal({ id: this.tradeId, comment: this.blotter.tradeInfo.comment })))
      .subscribe(
        () => this.blotter.tradeInfo.status = 'REJECTED',
        () => this.router.navigate(['/error'])
      );
  }
}
