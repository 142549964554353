import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { finalize } from 'rxjs/operators';
import { ChartSettings } from '../../../helpers/chart-settings';
import { GlobalArbService } from '../../../services/global-arb.service';
import { DateHelperService } from '../../../services/date-helper.service';

@Component({
  selector: 'app-fuel-price-curves',
  templateUrl: './fuel-price-curves.component.html',
  styleUrls: ['./fuel-price-curves.component.scss']
})
export class FuelPriceCurvesComponent implements OnInit {

  isLoading = false;

  tradeDate: string;

  chartData: ChartConfiguration['data'] = { datasets: [] };
  lineChartOptions: ChartConfiguration['options'] = {
    scales: {
      x: {
        ...ChartSettings.defaultTickSettings,
        ...ChartSettings.defaultGridSettings
      },
      y: {
        title: {
          text: '$/MT',
          display: true
        },
        ...ChartSettings.defaultGridSettings
      }
    }
  };

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  constructor(private globalArbService: GlobalArbService, private titleService: Title, private dateHelperService: DateHelperService) {
    this.titleService.setTitle('Trading Preview UI | Fuel Price Curve');
  }

  ngOnInit(): void {
    this.tradeDate = this.dateHelperService.getDateyyyyMMdd(new Date().toLocaleDateString('en-CA'));
    this.fetchChartData();
  }

  fetchChartData(): void {
    this.globalArbService.getPriceCurves(this.tradeDate, 'Fuel')
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(res => this.plotData(res));
  }

  plotData(data: any) {
    this.chartData.datasets = [];
    this.chartData.labels = data.strips;

    Object.keys(data).splice(1).forEach(key => this.chartData.datasets.push({
      data: data[key].prices,
      label: key
    }));

    this.chart.update();
  }
  
}
