import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { FileSaverService } from 'ngx-filesaver';
import { finalize } from 'rxjs/operators';
import { ChartSettings } from '../../helpers/chart-settings';
import { jsonToCsv } from '../../helpers/json-to-csv';
import { DateHelperService } from '../../services/date-helper.service';
import { RiskMetricsService } from '../../services/risk-metrics.service';

@Component({
  selector: 'app-volatility-surface',
  templateUrl: './volatility-surface.component.html',
  styleUrls: ['./volatility-surface.component.scss']
})
export class VolatilitySurfaceComponent implements OnInit {
  tradeDate: string;
  selectedHub: string;
  selectedStrip: string;

  volatilityOptions = ['10DP', '25DP', '35DP', '50D', '35DC', '25DC', '10DC', 'ATM'];
  volatilities = ['10DP', '10DC', 'ATM',];

  hubs: [];

  volatilityData: any;

  isPullingData = false;
  hasError = false;

  volatilityChartData: ChartConfiguration['data'] = { datasets: [] };
  volatilityDeltaStrikeChartData: ChartConfiguration['data'] = { datasets: [] };

  lineChartOptions: ChartConfiguration['options'] = {
    scales: {
      x: {
        ...ChartSettings.defaultTickSettings,
        ...ChartSettings.defaultGridSettings,
        title: {
          display: true,
          text: 'Maturity'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Implied Volatility'
        },
        ...ChartSettings.defaultGridSettings,
        min: 0
      },
    },
  };

  deltaStrikeChartOptions: ChartConfiguration['options'] = {};

  @ViewChildren(BaseChartDirective) charts?: QueryList<BaseChartDirective>;

  constructor(private riskMetricsService: RiskMetricsService, private fileSaverService: FileSaverService,
    private dateHelperService: DateHelperService, private titleService: Title) {
    this.titleService.setTitle('Trading Preview UI | Implied Market Volatility Surface');
  }

  ngOnInit(): void {
    this.setDeltaStrikeChartOptions([], [], '');
    this.tradeDate = this.dateHelperService.getDateyyyyMMdd(this.dateHelperService.getLastWeekday(new Date()).toLocaleDateString('en-CA'));
    this.selectedHub = 'Henry';
    this.riskMetricsService.getMarketImpliedHubs().subscribe(res => {
      this.hubs = res;
      this.fetchChartData();
    });
  }

  fetchChartData(): void {
    this.hasError = false;
    this.isPullingData = true;
    this.riskMetricsService.getMarketImpliedVolSurface(this.tradeDate, this.selectedHub)
      .pipe(finalize(() => this.isPullingData = false))
      .subscribe(
        res => {
          this.volatilityData = res;
          this.updateVolatilitySeries();
          this.selectedStrip = this.volatilityData.strip.find(Boolean);
          if (this.selectedStrip) {
            this.fetchStrikDeltaData();
          }
        },
        err => {
          this.hasError = true;
          this.volatilityChartData.datasets = [];
          this.volatilityDeltaStrikeChartData.datasets = [];

          this.charts.get(0).update();
          this.charts.get(1).update();
        }
      );
  }

  fetchStrikDeltaData(): void {
    this.riskMetricsService.getMarketImpliedDeltaStrike(this.tradeDate, this.selectedHub, this.selectedStrip).subscribe(
      res => {
        this.volatilityDeltaStrikeChartData.datasets = [];

        var deltaStrike = res.delta.map((x, i) => {
          return {
            delta: x,
            strike: res.strike[i],
            iv: res.implied_vol[i]
          }
        });

        this.volatilityDeltaStrikeChartData.datasets.push(
          {
            data: deltaStrike,
            pointBackgroundColor: '#0c69b0',
            pointHoverBorderColor: '#0c69b0',
            borderColor: '#0c69b0',
            parsing: {
              xAxisKey: 'delta',
              yAxisKey: 'iv'
            }
          },
        );

        this.setDeltaStrikeChartOptions(res.delta, res.strike.map(x => parseFloat(x).toFixed(2)), res.unit);

        this.charts.get(1).update();
      },
      err => {
        this.hasError = true;

        this.volatilityDeltaStrikeChartData.datasets = [];
        this.charts.get(1).update();
      });
  }

  updateVolatilitySeries(): void {
    this.volatilityChartData.datasets = [];

    this.volatilities.forEach(p => {
      this.volatilityChartData.datasets.push({
        data: this.volatilityData[p].implied_vol.slice(0, 24),
        label: p
      });
    });

    this.volatilityChartData.labels = this.volatilityData.strip.slice(0, 24);

    this.charts.get(0).update();
  }

  saveData(): void {
    const data = { date: this.volatilityChartData.labels };
    this.volatilityOptions.forEach(p => {
      data[p] = this.volatilityData[p]?.implied_vol;
    });

    this.fileSaverService.saveText(jsonToCsv(data), `pd-${this.tradeDate}-${this.selectedHub}.csv`);
  }

  saveDeltaStrikeData(): void {
    const dataSetData = this.volatilityDeltaStrikeChartData.datasets[0].data as any[];
    const data = {
      iv: dataSetData.map(x => x.iv),
      delta: dataSetData.map(x => x.delta),
      strike: dataSetData.map(x => x.strike)
    };
    this.fileSaverService.saveText(jsonToCsv(data), `delta-strike-iv-${this.tradeDate}-${this.selectedHub}-${this.selectedStrip}.csv`);
  }

  private setDeltaStrikeChartOptions(deltaLabels: [], strikeLabels: [], strikeUnit: string): void {
    this.deltaStrikeChartOptions = {
      scales: {
        xDelta: {
          title: {
            text: 'Delta',
            display: true
          },
          labels: deltaLabels,
          ...ChartSettings.defaultGridSettings,
        },
        xStrike: {
          title: {
            text: `Strike (${strikeUnit.split('/')[0]})`,
            display: true
          },
          labels: strikeLabels,
          ...ChartSettings.defaultGridSettings,
        },
        y: {
          title: {
            display: true,
            text: 'Implied Volatility'
          },
          ...ChartSettings.defaultGridSettings,
          min: 0
        }
      },
      plugins: {
        tooltip: {
          bodyAlign: 'right',
          displayColors: false,
          callbacks: {
            label: (context) => {
              const rawData = context.raw as any;
              return [
                `IV: ${parseFloat(rawData.iv).toFixed(2)}`,
                `Delta: ${parseFloat(rawData.delta).toFixed(2)}`,
                `Strike: ${parseFloat(rawData.strike).toFixed(2)}`];
            },
            title: () => ''
          }
        }
      }
    };
  }
}
