import { Component, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { OptionsCalculateItem, OptionsCalculateMain } from './options-calculator.model';
import { OptionsCalculatorComponent } from './options-calculator.component';
import { SessionService } from './options-calculator.service';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-options-calculatormain',
  templateUrl: './options-calculator-main.component.html',
  styleUrls: ['./options-calculator-main.component.scss']
})

export class OptionsCalculatorMainComponent implements OnInit {

  showTabInput: boolean;
  tabName: string;
  expandedIndex = 0;
  changedTabName: string;

  optionCalcs:number = 0;
  @ViewChild('maincontainer', { read: ViewContainerRef }) container: ViewContainerRef;

  input = OptionsCalculatorComponent;
  optioncalcTabs: OptionsCalculateMain[] = [];

  constructor(private titleService: Title, private localStore: SessionService) {
    this.titleService.setTitle('Trading Preview UI | Options Calculator');
  }

  ngOnInit(): void {
    var allTabs = this.localStore.getData("ALL::TABS::") || '';
    if(allTabs.length>0){
      var firstTab = allTabs.split('\t');
      firstTab.forEach(tabName =>{
        var localOptionString = this.localStore.getData(tabName) || '';
        if(localOptionString.length>0){
          var tabItem = new OptionsCalculateMain(tabName, false);
          tabItem.optionCalcItems = JSON.parse(localOptionString);
          this.optioncalcTabs.push(tabItem);
        } else {
          this.defaultOptionsCalcItem();
        }
      });
    } else {
      this.defaultOptionsCalcItem();
      this.localStore.saveData("ALL::TABS::", "Default");
    }
    setTimeout( () => {
      this.tabSelected(this.optioncalcTabs[0]);
    }, 300);
  }

  defaultOptionsCalcItem(){
    var defaultItem = new OptionsCalculateMain("Default", false);
    var calcItem = new OptionsCalculateItem();
    calcItem.tabRefName ="Default";
    defaultItem.optionCalcItems.push(calcItem);
    this.optioncalcTabs.push(defaultItem);
  }

  cancel(){
    this.showTabInput = false;
  }

  next() {
    this.showTabInput = false;
    this.optioncalcTabs.forEach(x => x.current = false);
    var nextItem = new OptionsCalculateMain(this.tabName, true);
    var calcItem = new OptionsCalculateItem();
    calcItem.tabRefName = this.tabName;
    nextItem.optionCalcItems.push(calcItem);
    this.optioncalcTabs.push(nextItem);
    var len = this.optioncalcTabs.length;
    this.container.clear();
    var component = this.container.createComponent<OptionsCalculatorComponent>(this.input);
    component.instance.optionCalcItems =  this.optioncalcTabs[len-1].optionCalcItems;
    component.instance.deleteTheTab.subscribe( v => {
      this.deleteTab(v);
      this.allTabsPersist();
    });

    this.allTabsPersist();
  }

  deleteTab(tabkey){
    var deleted = false;
    if(this.optioncalcTabs.length > 1){
      for (var i = this.optioncalcTabs.length - 1; i >= 0; --i) {
        for(var j = 0; j < this.optioncalcTabs[i].optionCalcItems.length; j++){
          if (this.optioncalcTabs[i].optionCalcItems[j].uniqueKey == tabkey) {
            this.optioncalcTabs.splice(i,1);
            deleted = true;
            break;
          }
        }
        if(!deleted && this.optioncalcTabs[i].tabName == tabkey){
          this.optioncalcTabs.splice(i,1);
        }
      }
      if(this.optioncalcTabs.length>0){
        this.tabSelected(this.optioncalcTabs[0])
      }
    }
  }

  allTabsPersist(){
    var allTabs = this.optioncalcTabs.map(tab =>{
      return tab.tabName;
    });
    this.localStore.saveData("ALL::TABS::", allTabs.join('\t'));
  }

  onTabDblClick(tabItem: OptionsCalculateMain){
    if (tabItem.current){
      tabItem.dblClicked = true;
      this.changedTabName = tabItem.tabName;
      setTimeout(() => {
        let elementReference = document.querySelector('#inpTabName');
        if (elementReference instanceof HTMLInputElement) {
          elementReference.focus();
          elementReference.select();
        }
      }, 100);
    }
  }

  onTabBlur(tabItem: OptionsCalculateMain){
    let elementReference = document.querySelector('#inpTabName');
    if (elementReference instanceof HTMLInputElement) {
      var oldTabName = tabItem.tabName;
      tabItem.tabName = this.changedTabName;
      tabItem.optionCalcItems.forEach(oci =>{
        oci.tabRefName = this.changedTabName;
      });
      this.localStore.saveData(this.changedTabName, JSON.stringify(tabItem.optionCalcItems));
      this.localStore.removeData(oldTabName);
      this.allTabsPersist();
      tabItem.dblClicked = false;
    }
  }

  add() {
    this.tabName="Default " + (++this.optionCalcs);
    this.next();
  }

  tabSelected(tabItem: OptionsCalculateMain) {
    if (!tabItem.current){
      /* Session Storage Get */
      if(tabItem){
        var localOptionString = this.localStore.getData(tabItem.tabName) || '';
        if(localOptionString.length>0){
          tabItem.optionCalcItems = JSON.parse(localOptionString);
        }
      }
      this.optioncalcTabs.forEach(x => {
        x.current = (x.tabName == tabItem.tabName);
      });
      this.container.clear();
      var component = this.container.createComponent<OptionsCalculatorComponent>(this.input);
      component.instance.optionCalcItems = tabItem.optionCalcItems;
      component.instance.localTabRefName = tabItem.tabName;
      component.instance.deleteTheTab.subscribe( v => {
        this.deleteTab(v);
        this.allTabsPersist();
      });
      component.instance.isReady = true;
    }
  }

}
