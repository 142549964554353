import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import { DateHelperService } from '../services/date-helper.service';
import { RiskMetricsService } from '../services/risk-metrics.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-volatility-table',
  templateUrl: './volatility-table.component.html',
  styleUrls: ['./volatility-table.component.scss']
})
export class VolatilityTableComponent implements OnInit {

  region = 'AGOT';
  tradeDate: string;
  volType: any;
  today: string;
  filter: string;

  isCalculating = false;
  showCalculatingError = false;
  threshold = 75;

  dataSource = new MatTableDataSource();

  displayedColumns: string[];
  dataColumns: string[];

  constructor(private riskMetricsService: RiskMetricsService, private dateHelperService: DateHelperService,
    private userService: UserService, private titleService: Title) {
    this.titleService.setTitle('Trading Preview UI | Volatility');
  }

  ngOnInit(): void {
    this.region = this.userService.getUserRegion();
    if (!this.region || this.region === 'ALL' || this.region === 'CANADA') this.region = 'AGOT';
    const lastWeekday = this.dateHelperService.getLastWeekday(new Date());
    this.tradeDate = this.dateHelperService.getDateyyyyMMdd(lastWeekday.toLocaleDateString('en-CA'));
    this.today = this.dateHelperService.getDateyyyyMMdd(new Date().toLocaleDateString('en-CA'));
    this.volType = ['ewma', 'sma15','sma60','implied'];

    this.updateVolatility();
  }

  updateVolatility(): void {
    this.showCalculatingError = false;
    this.isCalculating = true;
    var inputs = {
      region: this.region,
      trade_date: this.tradeDate,
      vol_type: this.volType
    }
    this.riskMetricsService.getVolatilityTable(inputs)
      .pipe(finalize(() => this.isCalculating = false))
      .subscribe(
        res => {
          this.dataSource.data = res;
          this.displayedColumns = Object.keys(res.find(Boolean));
          this.dataColumns = this.displayedColumns.slice(2);
        },
        () => {
          this.dataSource.data = [];
          this.showCalculatingError = true;
        });
  }

  getVolValue(element: any, col: string): number {
    return parseFloat(element[col]);
  }

  shouldHighlight(element: any, col: string): boolean {
    return this.getVolValue(element, col) >= this.threshold / 100;
  }

  isNumber(value): boolean {
    return !Number.isNaN(value);
  }

  filterChange(): void {
    this.dataSource.filter = this.filter;
  }

  clearFilter(): void {
    this.filter = '';
    this.filterChange();
  }
}
