export class GasConvertPrices{

  constructor(
    public price: number,
    public currency: string,
    public unit: string) {
     
    }
}


