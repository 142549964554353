import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { finalize } from 'rxjs/operators';
import { ChartSettings } from '../../../helpers/chart-settings';
import { LinearData, LinearDataPair } from '../../../models/linear-data.model';
import { GlobalArbService } from '../../../services/global-arb.service';
import { NatGasCurveInfoComponent } from './nat-gas-curve-info/nat-gas-curve-info.component';
import { DateHelperService } from '../../../services/date-helper.service';

@Component({
  selector: 'app-nat-gas-price-curves',
  templateUrl: './nat-gas-price-curves.component.html',
  styleUrls: ['./nat-gas-price-curves.component.scss']
})
export class NatGasPriceCurvesComponent implements OnInit {

  tradeDate: string;
  today: string;

  hubs: string[];

  isLoading: boolean;
  allData: any;
  chartData: ChartConfiguration['data'] = { datasets: [] };
  lineChartOptions: ChartConfiguration['options'];

  lineInfo: LinearDataPair[] = [];

  unit: string = 'USD/MMBtu';
  allUnits = {
    'USD/MMBtu': '$/MMBTU',
    'EURO/MWH': '€/MWH',
    'GBp/Therm': 'GBp/Therm'
  }

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  constructor(private globalArbService: GlobalArbService, private notificationService: MatSnackBar, private dialogService: MatDialog, private titleService: Title, private dateHelperService: DateHelperService) {
    this.titleService.setTitle('Trading Preview UI | Natural Gas Price Curve');
  }

  ngOnInit(): void {
    this.today = this.dateHelperService.getDateyyyyMMdd(new Date().toLocaleDateString('en-CA'));
    this.tradeDate = this.dateHelperService.getDateyyyyMMdd(new Date().toLocaleDateString('en-CA'));
    this.fetchChartData();
  }

  fetchChartData(): void {
    this.isLoading = true;

    this.globalArbService.getPriceCurves(this.tradeDate, 'NG', this.unit)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(res => {
        this.allData = res;
        this.hubs = Object.keys(res).splice(1);
        this.chartData.labels = this.allData.strips;
        if (this.lineInfo.length === 0) {
          this.lineInfo.push({ data1: { key: this.hubs.find(Boolean), percentFactor: 100, shiftFactor: 0 } });
        }
        this.generateChartDatasets();
      }, err => {
        this.allData = null;
        this.hubs = [];

        //clear chart
        this.chartData.datasets = [];
        this.chart.update();
      });

    this.refreshChartSettings();
  }

  generateChartDatasets(): void {
    this.chartData.datasets = [];

    this.lineInfo.forEach(d => {
      if (!d.data2) {
        if (!this.verifyLinearData(d.data1)) return;

        this.chartData.datasets.push({
          data: this.allData[d.data1.key].prices.map(p => p * d.data1.percentFactor/100 + d.data1.shiftFactor),
          label: this.generateLabel(d.data1)
        })
      } else {
        if (!this.verifyLinearData(d.data1) || !this.verifyLinearData(d.data2)) return;

        this.chartData.datasets.push({
          data: this.allData[d.data1.key].prices.map((p, index) =>
            (p * d.data1.percentFactor/100 + d.data1.shiftFactor) -
            (this.allData[d.data2.key].prices[index] * d.data2.percentFactor/100 + d.data2.shiftFactor)),
          label: `${this.generateLabel(d.data1)} - ${this.generateLabel(d.data2)}`
        });
      }
    });

    this.chart.update();
  }

  openCurveDialog(): void {
    if (this.hubs?.length == 0) {
      this.notificationService.open('No data for this date exists.', 'Dismiss');
      return;
    }

    const dialogRef = this.dialogService.open(NatGasCurveInfoComponent, {
      height: '650px',
      minWidth: '412px',
      data: { hubs: this.hubs, lineInfo: this.lineInfo }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.lineInfo = result;
        this.generateChartDatasets();
      }
    });
  }

  private verifyLinearData(data: LinearData): boolean {
    //verifies that the key is truthy and the factors are not null / undefined while allowing for 0
    return !!data.key && data.percentFactor != null && data.shiftFactor != null;
  }

  private generateLabel(data: LinearData): string {
    var label = data.key;
    if (data.percentFactor != 100) {
      label += ` * ${data.percentFactor}%`;
    }
    if (data.shiftFactor) {
      label += ` + ${data.shiftFactor}`;
    }
    return label;
  }

  private refreshChartSettings(): void {
    this.lineChartOptions = {
      scales: {
        x: {
          ...ChartSettings.defaultTickSettings,
          ...ChartSettings.defaultGridSettings
        },
        y: {
          title: {
            text: this.allUnits[this.unit],
            display: true
          },
          ...ChartSettings.defaultGridSettings
        }
      }
    };
  }
}
