<div class="em-c-alert em-c-alert--error" role="alert" *ngIf="errorMessage!=''">
    <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#circle-exclamation-mark"></use>
    </svg>
    <div class="em-c-alert__body">
        {{ errorMessage }}
    </div>
</div>
<div class="em-c-loader spinner" *ngIf="isLoading">
    <img src="unity/images/icon-spinner.svg" />
</div>