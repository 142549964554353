import { Injectable } from '@angular/core';
import { GasConvertPrices } from '../tools/gas-converter/gas-converter.model';

@Injectable({
  providedIn: 'root'
})
export class UnitConverterService {
  
  constructor() { }

  ddCurList = ['USDct', 'MUSD', 'EUR', 'EURct', 'GBP', 'MEUR', 'p', 'USD'];
  ddMetricsList = ['MWh', 'kTherm', 'bcm', 'MBTU', 'GWh', 'boe', 'BTU', 'cm', '(LNG)', 'GCF', 'GJ', 'J', 'kcal', 'kcf', 'kcm', 'kWh', 'm³', 'MCF', 'mcm', 'mcm(LNG)', 'MJ', 'Mt(LNG)', 'PJ', 't(LNG)', 'TCF', 'Therm', 'TJ', 'toe', 'TWh' ];
  metrics = {
    "cm":[
      {"cm": 1}, 
      {"cf": 35.3147}, 
      {"BTU": 37524.08}, 
      {"Therm": 0.3752408}, 
      {"Wh":10997.23}, 
      {"(LNG)":0.00072464},
      {"cal":9462237},
      {"boe":0.00647}, 
      {"J":39590000},
      {"toe":0.000946}, 
      {"MBTU":37524.08 / 1000000}, 
      {"kTherm": 0.3752408 / 1000}, 
      {"MWh":10997.23 / 1000000}
    ]};

  currencyConvert = [
    {"USDct": 103.07153}, 
    {"MUSD": 0.00},  
    {"EUR": 1.00},  
    {"EURct": 100.00},  
    {"GBP": 0.87640},  
    {"MEUR": 0.00},  
    {"p": 87.6400},  
    {"USD": 1.03072}];

  ToNumber(val: number): number {
    return parseFloat(val.toFixed(4));
  }

  // Calculate from one unit to another
  getMetric(fromUnit: string, toUnit: string): number{
    var fromVal = 0;
    var toVal = 0;
    this.metrics["cm"].forEach(element => {
      if(element[fromUnit] != undefined){
        fromVal = element[fromUnit];
      }if(element[toUnit] != undefined){
        toVal = element[toUnit];
      }
    });
    if(fromVal != 0 && toVal != 0){
      return toVal / fromVal;
    }
    return 0;
  }

  getCurrencyConvert(fromCur: string, toCur:string): number {
    var fromCurrency = 0;
    var toCurrency = 0;
    this.currencyConvert.forEach(element =>{
      if(element[fromCur] != undefined){
        fromCurrency = element[fromCur];
      }if(element[toCur] != undefined){
        toCurrency = element[toCur];
      }
    });
    if(fromCurrency != 0 && toCurrency != 0){
      return fromCurrency / toCurrency;
    }
    return 0;
  }

  ToPrice(p1: GasConvertPrices, p2: GasConvertPrices): number {
    var m = this.getMetric(p1.unit, p2.unit);
    var p = this.getCurrencyConvert(p1.currency, p2.currency);
    return this.ToNumber(p1.price / m / p);
  }

}
