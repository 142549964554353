import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class AzureDevOpsService {

  private readonly baseUrl = 'https://dev.azure.com/em-uit/UIS/_apis/wit/workitems';

  constructor(private http: HttpClient) { }

  createBug(title: string, description: string): Observable<any> {
    const url = `${this.baseUrl}/$Bug`;

    return this.http.post(url, this.createBody('bug', title, description), this.generateHttpOptions());
  }

  createPBI(title: string, description: string): Observable<any> {
    const url = `${this.baseUrl}/$Product Backlog Item`;

    return this.http.post(url, this.createBody('pbi', title, description), this.generateHttpOptions());
  }

  private createBody(type: string, title: string, description: string) {
    const body = [
      {
        op: 'add',
        path: '/fields/System.Title',
        value: `MERA: ${title}`
      },
      {
        op: 'add',
        path: '/fields/System.AreaPath',
        value: 'UIS\\GGOT\\Feedback'
      },
      {
        op: "add",
        path: "/fields/System.AssignedTo",
        value: "hao.huang@exxonmobil.com",
      },
    ];

    if (type.toLowerCase() === 'bug') {
      body.push(
        {
          op: 'add',
          path: '/fields/Microsoft.VSTS.TCM.ReproSteps',
          value: description
        },
      );
    }
    else {
      body.push(
        {
          op: 'add',
          path: '/fields/System.Description',
          value: description
        },
      );
    }

    return body;
  }

  private generateHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json-patch+json',
      }),
      params: new HttpParams().append('api-version', '6.0'),
    };
  }
}
