<div class="em-l-grid">
  <div class="em-l-grid__item">
    <div>
      <mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
        <mat-label>As of date</mat-label>
        <input matInput type="date" [(ngModel)]="asOfDate" (ngModelChange)="fetchChartData()"
               [min]="minAsOfDate" [max]="maxAsOfDate">
      </mat-form-field>

      <mat-button-toggle-group [(ngModel)]="viewRate" (ngModelChange)="onViewRateChanged()">
        <mat-button-toggle value="forward">Forward</mat-button-toggle>
        <mat-button-toggle value="zero">Zero</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="em-l-grid__item em-u-margin-bottom">
    <h3>
      UK Interest Rate: {{ interestRateAsOfDate ?? '-'}}
      <button class="em-c-btn em-c-btn--small em-c-btn--tertiary" (click)="saveInterestRateData()" matTooltip="Export data to CSV">
        <div class="em-c-btn__inner">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#save-disk"></use>
          </svg>
        </div>
      </button>
    </h3>
    <canvas baseChart
            [data]="interestRateChartData"
            [options]="chartOptions"
            type="line"
            height="350">
    </canvas>
  </div>
  <div class="em-l-grid__item em-u-margin-bottom">
    <h3>
      UK Inflation Rate: {{ inflationAsOfDate ?? '-'}}
      <button class="em-c-btn em-c-btn--small em-c-btn--tertiary" (click)="saveInflationRateData()" matTooltip="Export data to CSV">
        <div class="em-c-btn__inner">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#save-disk"></use>
          </svg>
        </div>
      </button>
    </h3>
    <canvas baseChart
            [data]="inflationChartData"
            [options]="chartOptions"
            type="line"
            height="350">
    </canvas>
  </div>
</div>
