<h3>Forward Curve Manager</h3>
<div class="em-c-loader spinner" *ngIf="!initialized">
  <img src="unity/images/icon-spinner.svg" />
</div>
<div style="display: flex;">
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Trade Date</mat-label>
        <input matInput type="date" [(ngModel)]="asOfDate" (change)="tradeDateChange()">
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
        <mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
            <mat-label>Strip From</mat-label>
            <input matInput type="month" [(ngModel)]="shipFrom" [min]="minAsOfDate">
          </mat-form-field>
    </div>
    <div class="em-l-grid__item">
        <mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
            <mat-label>Strip To</mat-label>
            <input matInput type="month" [(ngModel)]="shipTo" [min]="minAsOfDate">
          </mat-form-field>
    </div>
    <div class="em-l-grid__item">
        <mat-form-field>
            <mat-label>Payment Days (after delivery)</mat-label>
            <input matInput type="number" step="1" [(ngModel)]="paymentDays">
          </mat-form-field>
    </div>
</div>

<div style="display: flex;">
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>Select Hubs</mat-label>
        <mat-select [formControl]="hubsMultiCtrl" placeholder="Hubs" [multiple]="true" #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="hubsMultiFilterCtrl"
              placeholderLabel="Find Hubs..."
              noEntriesFoundLabel="no matching hub found"
            ></ngx-mat-select-search>
          </mat-option>
            <mat-option *ngFor="let secInfo of filteredHubsMulti | async" [value]="secInfo">
                {{secInfo.hub}}
            </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item" style="display:table;">
      <span *ngFor="let sinfo of hubsMultiCtrl?.value" class="em-l-ul-inline">
        <span>{{sinfo.hub}}</span>
      </span>
    </div>
</div>

<div class="em-c-table-object__header">
  <div class="em-c-toolbar ">
    <div class="em-c-toolbar__item">
      <div class="em-c-btn-group ">
        <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="getPipelineData()" 
                matTooltip="Refresh" matTooltipPosition="above">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#refresh"></use>
          </svg>
        </button>
      </div>
    </div>
    <div class="em-c-toolbar__item em-is-aligned-right">
      <div class="em-c-btn-group ">
        <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="exportToExcel()" 
                matTooltip="Save table data" matTooltipPosition="above">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#save-disk"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>

<div>
  <canvas class="em-u-margin-top"
            baseChart
            [data]="forwardCurvesData"
            [options]="forwardCurvesOptions"
            type="line">
  </canvas>
</div>

<table mat-table [dataSource]="dataSource" class="em-c-table em-u-margin-top">
  <ng-container *ngFor="let col of displayedColumns; let i = index " [matColumnDef]="col">
    <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double em-u-text-align-center" [ngClass]="{'em-u-text-align-right': i > 0}" [width]="100/displayedColumns.length + '%'"> {{ col }} </th>
    <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top em-u-text-align-center" [ngClass]="{'em-u-text-align-right': i > 0}">
      {{ isNaN(element[col]) ? element[col] : (element[col] | number) }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="text-align: center;"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>