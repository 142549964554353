import { Injectable, OnInit } from "@angular/core";
import { ChartConfiguration } from "chart.js";
import { ChartSettings } from "../helpers/chart-settings";
import { DateHelperService } from "../services/date-helper.service";
import { RiskMetricsService } from "../services/risk-metrics.service";

@Injectable()
export abstract class BaseCurvesComponent implements OnInit {

  viewRate: string = 'forward';

  minAsOfDate: string;
  maxAsOfDate: string;
  asOfDate: string;

  curveData: any;

  interestRateChartData: ChartConfiguration['data'] = { datasets: [] };
  inflationChartData: ChartConfiguration['data'] = { datasets: [] };

  interestRateAsOfDate: string;
  inflationAsOfDate: string;

  chartOptions: ChartConfiguration['options'] = { ...ChartSettings.percentLineChartOptions };

  constructor(protected riskMetricsService: RiskMetricsService, protected dateHelperService: DateHelperService) {
  }

  ngOnInit(): void {
    this.getMacroCurveDates();
  }

  getMacroCurveDates(): void {
    this.riskMetricsService.getMacroCurveDatesByRegion(this.region).subscribe(res => {
      this.minAsOfDate = res[0];
      this.maxAsOfDate = res[res.length - 1];
      this.asOfDate = this.maxAsOfDate;

      this.fetchChartData();
    }, err => {
      const lastWeekday = this.dateHelperService.getDateyyyyMMdd(this.dateHelperService.getLastWeekday(new Date()).toLocaleDateString('en-CA'));
      this.asOfDate = lastWeekday;
      this.minAsOfDate = lastWeekday;
      this.maxAsOfDate = lastWeekday;
      this.fetchChartData();
    });
  }

  fetchChartData(): void {
    this.riskMetricsService.getMacroCurvesByRegion(this.region, this.asOfDate).subscribe(res => {
      this.curveData = res;
      this.createChartData();
    });
  }

  onViewRateChanged(): void {
    this.createChartData();
  }

  abstract get region(): string;

  abstract createChartData(): void;

  abstract saveInterestRateData(): void;

  abstract saveInflationRateData(): void;
}
