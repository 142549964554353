import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { convertb64toBlob } from '../helpers/convert-to-blob';
import { Trade } from '../models/trade.model';

@Injectable({
  providedIn: 'root',
})

export class TradeBlotterService {

  readonly baseUrl = environment.tradeBlotterEndpoint;

  private approversCache$: Observable<any[]>;
  private toraStratsCache$: Observable<any[]>;

  constructor(private http: HttpClient) { }

  createDeal(tradeData: Trade): Observable<any> {
    const url = `${this.baseUrl}/deals`;

    const data = {
      create_date: tradeData.date,
      approver_id: tradeData.approverId,
      tora_strat_number: tradeData.toraStrategyId,
      quorum_contract_number: tradeData.quorumContractId,
      trade_type: tradeData.tradeType,

      current_var: tradeData.currentVar,
      max_var: tradeData.maxVar,
      projected_profit: tradeData.projectedProfit,
      stop_loss: tradeData.stopLoss,
      hold_length: tradeData.holdLength,
      hold_length_units: tradeData.holdLengthUnit,

      entry_reason: tradeData.entryReason,
      exit_reason: tradeData.exitReason,

      legs_json: JSON.stringify(tradeData.legs)
    };

    return this.http.post<any>(url, data).pipe(map(res => res.deal_id));
  }

  getDeal(tradeId: number): Observable<Trade> {
    const url = `${this.baseUrl}/deals/${tradeId}`;
    return this.http.get<any>(url).pipe(map(res => this.mapResponseToTrade(res)));
  }

  updateDeal(tradeData: Trade): Observable<any> {
    const url = `${this.baseUrl}/deals/${tradeData.id}`;

    const data = {
      create_date: tradeData.date,
      approver_id: tradeData.approverId,
      tora_strat_number: tradeData.toraStrategyId,
      quorum_contract_number: tradeData.quorumContractId,
      trade_type: tradeData.tradeType,
      status: tradeData.status,

      current_var: tradeData.currentVar,
      max_var: tradeData.maxVar,
      projected_profit: tradeData.projectedProfit,
      stop_loss: tradeData.stopLoss,
      hold_length: tradeData.holdLength,
      hold_length_units: tradeData.holdLengthUnit,

      entry_reason: tradeData.entryReason,
      exit_reason: tradeData.exitReason,

      legs_json: JSON.stringify(tradeData.legs),

      comment: tradeData.comment
    };

    return this.http.put<any>(url, data).pipe(map(res => this.mapResponseToTrade(res)));
  }

  submitDealForApproval(tradeId: number): Observable<any> {
    const url = `${this.baseUrl}/submit-for-approval`;
    return this.http.get(url, { params: new HttpParams().set('deal_id', tradeId) });
  }

  updateDealStatus(tradeId: number, status: string): Observable<any> {
    const url = `${this.baseUrl}/update-deal-status`;
    return this.http.put(url, {}, { params: new HttpParams().set('deal_id', tradeId).set('status', status) });
  }

  getApprovers(): Observable<any[]> {
    if (!this.approversCache$) {
      const url = `${this.baseUrl}/approvers`;
      this.approversCache$ = this.http.get<any>(url).pipe(map(res => res.result)).pipe(shareReplay(1));
    }
    return this.approversCache$;
  }

  getUserDeals(): Observable<any[]> {
    const url = `${this.baseUrl}/deals`;
    return this.http.get<any>(url).pipe(map(res => res.result));
  }

  getDealsByRegion(): Observable<any[]> {
    const url = `${this.baseUrl}/deals-by-region`;
    return this.http.get<any>(url).pipe(map(res => res.result));
  }

  getToraStrategies(): Observable<any[]> {
    if (!this.toraStratsCache$) {
      const url = `${this.baseUrl}/tora-strategies`;
      this.toraStratsCache$ = this.http.get<any>(url).pipe(map(res => res.result)).pipe(shareReplay(1));
    }
    return this.toraStratsCache$;
  }

  uploadSupportingFile(tradeId: number, file: File): Observable<any> {
    const url = `${this.baseUrl}/upload-file`;
    var formData = new FormData();
    formData.append('file', file);
    return this.http.post(url, formData, { params: new HttpParams().set('deal_id', tradeId)});
  }

  downloadSupportingFile(tradeId: number): Observable<Blob> {
    const url = `${this.baseUrl}/download-file`;
    return this.http.get<any>(url, { params: new HttpParams().set('deal_id', tradeId) }).pipe(map(res => convertb64toBlob(res.result[0])));
  }

  getUserInformation(): Observable<any> {
    const url = `${this.baseUrl}/who-am-i`;
    return this.http.get<any[]>(url);
  }

  private mapResponseToTrade(res: any): Trade {
    return {
      id: res.deal_id,
      date: res.create_date,
      status: res.status,
      traderId: res.trader?.oid,
      traderName: res.trader?.full_name,
      approverId: res.approver?.oid,
      approverName: res.approver?.full_name,
      toraStrategyId: res.tora_strat_number,
      quorumContractId: res.quorum_contract_number,
      tradeType: res.trade_type,
      currentVar: res.current_var,
      maxVar: res.max_var,
      projectedProfit: res.projected_profit,
      stopLoss: res.stop_loss,
      holdLength: res.hold_length,
      holdLengthUnit: res.hold_length_units,
      entryReason: res.entry_reason,
      exitReason: res.exit_reason,
      supportingFileName: res.file_name,
      legs: res.legs_json,
      comment: res.comment
    };
  }
}
