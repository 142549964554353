<div class="em-c-alert em-c-alert--error" role="alert" *ngIf="showError">
  <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#circle-exclamation-mark"></use>
  </svg>
  <div class="em-c-alert__body">
    There was an error in updating the details of this trade. Please try again.
  </div>
  <div class="em-c-alert__actions">
    <button class="em-c-text-btn em-c-alert--error" (click)="showError = false">
      Close
    </button>
  </div>
</div>
<div class="em-c-alert em-c-alert--success" role="alert" *ngIf="showSuccess">
  <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#circle-check"></use>
  </svg>
  <div class="em-c-alert__body">
    The trade details have been successfully updated.
  </div>
  <div class="em-c-alert__actions">
    <button class="em-c-text-btn em-c-alert--success" (click)="showSuccess=false">
      Close
    </button>
  </div>
</div>
<mat-form-field class="em-u-margin-left">
  <mat-label>Trade number</mat-label>
  <mat-select [(ngModel)]="selectedTradeId" (ngModelChange)="navigateToTrade()">
    <mat-option *ngFor="let trade of tradeIds" [value]="trade.deal_id">{{trade.deal_id}}</mat-option>
  </mat-select>
</mat-form-field>
<h4 class="em-u-margin-left">
  Trader: {{ tradeInfo.traderName }}
</h4>
<div *ngIf="selectedTradeId">
  <app-trade-blotter [tradeInfo]="tradeInfo" [isReadOnly]="isReadOnly"></app-trade-blotter>

  <div class="em-l-grid em-l-grid--halves">
    <div class="em-l-grid__item em-u-margin-left">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Comment</mat-label>
        <textarea matInput maxlength="2000" disabled [value]="tradeInfo.comment"></textarea>
      </mat-form-field>
    </div>
  </div>

  <button class="em-c-btn em-c-btn--primary em-u-margin-left" (click)="abandonAndResubmit()" [disabled]="!canExecute()" [class.em-c-btn--disabled]="!canExecute()" *ngIf="checkTradeStatus('PENDING')">
    Resubmit updates
  </button>
  <button class="em-c-btn em-c-btn--primary em-u-margin-left" (click)="resubmit()" [disabled]="!canExecute()" [class.em-c-btn--disabled]="!canExecute()" *ngIf="checkTradeStatus('REJECTED')">
    Resubmit for Approval
  </button>
  <button class="em-c-btn em-c-btn--primary em-u-margin-left" (click)="execute()" [disabled]="!canExecute()" [class.em-c-btn--disabled]="!canExecute()" *ngIf="checkTradeStatus('APPROVED')">
    Execute
  </button>
  <button class="em-c-btn em-c-btn--primary em-u-margin-left" (click)="finalize()" [disabled]="!canExecute()" [class.em-c-btn--disabled]="!canExecute()" *ngIf="checkTradeStatus('EXECUTED')">
    Finalize
  </button>
  <button class="em-c-btn em-c-btn--secondary em-u-margin-left" (click)="abandon()" [disabled]="!canExecute()" [class.em-c-btn--disabled]="!canExecute()" *ngIf="checkTradeStatus('APPROVED') || checkTradeStatus('REJECTED')">
    Abandon
  </button>
  <button class="em-c-btn em-c-btn--secondary em-u-margin-left" (click)="copyAsNew()">
    Copy to new trade
  </button>
</div>
