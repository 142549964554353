import { QueryList, ViewChildren } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FileSaverService } from 'ngx-filesaver';
import { finalize } from 'rxjs/operators';
import { PortfolioVarHelperService } from '../services/portfolio-var-helper.service';
import { RiskMetricsService } from '../services/risk-metrics.service';
import { UserService } from '../services/user.service';
import { PortfolioVarTableComponent } from './portfolio-var-table/portfolio-var-table.component';
import { DateHelperService } from '../services/date-helper.service';

@Component({
  selector: 'app-portfolio-var',
  templateUrl: './portfolio-var.component.html',
  styleUrls: ['./portfolio-var.component.scss']
})
export class PortfolioVarComponent implements OnInit {

  region: string;
  tradeDate: string;
  filter: string;
  today: string;

  isCalculating = false;
  isDownloading = false;
  showDownloadError = false;

  pvData: any[];
  lastUpdated;

  availableRegions = [];

  @ViewChildren(PortfolioVarTableComponent) tables: QueryList<PortfolioVarTableComponent>;
  constructor(private riskMetricsService: RiskMetricsService, private fileSaverService: FileSaverService,
    private portfolioVarHelper: PortfolioVarHelperService, private userService: UserService, private titleService: Title,
    private dateHelperService: DateHelperService) {
    this.titleService.setTitle('Trading Preview UI | Portfolio Var')
  }

  ngOnInit(): void {
    this.availableRegions = this.portfolioVarHelper.getRegionsForUser();
    this.region = this.userService.getUserRegion();
    if (!this.availableRegions.map(x => x.value).includes(this.region)) {
      this.region = this.availableRegions[0].value;
    }

    this.today = this.dateHelperService.getDateyyyyMMdd(new Date().toLocaleDateString('en-CA'));
    this.tradeDate = this.today;

    this.getPortfolioVaR();
  }

  getPortfolioVaR(): void {
    this.isCalculating = true;
    const params = {
      region: this.region,
      trade_date: this.tradeDate
    }

    this.riskMetricsService.getPortfolioVar(params)
      .pipe(finalize(() => this.isCalculating = false))
      .subscribe(
        res => {
          const portfolioVarData = res.filter(x => x.length > 0);
          this.lastUpdated = portfolioVarData.find(Boolean)?.find(Boolean)?.last_update;

          this.pvData = portfolioVarData;
        },
        err => {
          this.pvData = [];
          this.lastUpdated = '';
        });
  }

  downloadPortfolioVarFile(): void {
    this.showDownloadError = false;
    this.isDownloading = true;
    const params = {
      region: this.region,
      trade_date: this.tradeDate
    }
    this.riskMetricsService.getPortfolioVarFile(params)
      .pipe(finalize(() => this.isDownloading = false)).subscribe(res => {
        this.fileSaverService.save(res.blob, res.fileName);
      },
        err => {
          this.showDownloadError = true;
        }
      );
  }

  filterChange(): void {
    this.tables.forEach(t => t.filter(this.filter));
  }

  clearFilter(): void {
    this.filter = '';
    this.filterChange();
  }
}
