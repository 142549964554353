import { Component, QueryList, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BaseChartDirective } from 'ng2-charts';
import { FileSaverService } from 'ngx-filesaver';
import { jsonToCsv } from '../../helpers/json-to-csv';
import { DateHelperService } from '../../services/date-helper.service';
import { RiskMetricsService } from '../../services/risk-metrics.service';
import { BaseCurvesComponent } from '../base-curves.component';

@Component({
  selector: 'app-us-curves',
  templateUrl: './us-curves.component.html',
  styleUrls: ['./us-curves.component.scss']
})
export class UsCurvesComponent extends BaseCurvesComponent {
  @ViewChildren(BaseChartDirective) charts?: QueryList<BaseChartDirective>;

  constructor(riskMetricsService: RiskMetricsService, dateHelperService: DateHelperService, private fileSaverService: FileSaverService, 
    private titleService: Title) {
    super(riskMetricsService, dateHelperService);
    this.titleService.setTitle('Trading Preview UI | US Macro Curves');
  }

  createChartData(): void {
    this.interestRateChartData.datasets = [];
    this.inflationChartData.datasets = [];

    this.interestRateChartData.datasets.push({
      data: this.curveData.interest_rate?.fed_funds_rate_historical?.map(x => x * 100),
      pointBackgroundColor: '#3a397b',
      pointHoverBorderColor: '#3a397b',
      borderColor: '#3a397b',
      label: 'Fed Funds Rate Historical',
    });

    this.inflationChartData.datasets.push({
      data: this.curveData.inflation_rate?.yoy_cpi?.map(x => x * 100),
      pointBackgroundColor: '#3a397b',
      pointHoverBorderColor: '#3a397b',
      borderColor: '#3a397b',
      label: 'YoY CPI'
    });

    if (this.viewRate === 'forward') {
      this.interestRateChartData.datasets.push({
        data: this.curveData.interest_rate?.sofr_fwd_rate?.map(x => x * 100),
        label: 'SOFR Forward Rate',
        borderDash: [5, 15]
      });

      this.inflationChartData.datasets.push({
        data: this.curveData.inflation_rate?.implied_inflation_fwd_rate?.map(x => x * 100),
        label: 'Implied Inflation Forward Rate',
        borderDash: [5, 15]
      });
    }
    else {
      this.interestRateChartData.datasets.push({
        data: this.curveData.interest_rate?.sofr_zero_rate?.map(x => x * 100),
        label: 'SOFR Zero Rate',
        borderDash: [5, 15]
      });

      this.inflationChartData.datasets.push({
        data: this.curveData.inflation_rate?.implied_inflation_zero_rate?.map(x => x * 100),
        label: 'Implied Inflation Zero Rate',
        borderDash: [5, 15]
      });
    }

    this.interestRateChartData.labels = this.curveData.interest_rate?.dates;
    this.interestRateAsOfDate = this.curveData.interest_rate?.as_of_date;

    this.inflationChartData.labels = this.curveData.inflation_rate?.dates;
    this.inflationAsOfDate = this.curveData.inflation_rate?.as_of_date;

    this.charts.forEach(c => c.update());
  }

  get region(): string {
    return 'US';
  }

  saveInterestRateData(): void {
    const data = {
      date: this.curveData.interest_rate.dates,
      fedRateHistorical: this.curveData.interest_rate.fed_funds_rate_historical,
      sofrForward: this.curveData.interest_rate.sofr_fwd_rate,
      sofrZero: this.curveData.interest_rate.sofr_zero_rate,
    }
    this.fileSaverService.saveText(jsonToCsv(data), `us-interestRates${this.interestRateAsOfDate}.csv`);
  }

  saveInflationRateData(): void {
    const data = {
      date: this.curveData.inflation_rate.dates,
      yoyCpi: this.curveData.inflation_rate.yoy_cpi,
      impliedFwdInflation: this.curveData.inflation_rate.implied_inflation_fwd_rate,
      impliedZeroInflation: this.curveData.inflation_rate.implied_inflation_zero_rate,
    }
    this.fileSaverService.saveText(jsonToCsv(data), `us-inflationRates${this.inflationAsOfDate}.csv`);
  }
}
