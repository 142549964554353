import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { convertb64toBlob } from '../helpers/convert-to-blob';
import { Trade } from '../models/trade.model';
import { eodVar } from '../portfolio-var-custom/models/response';

@Injectable({
  providedIn: 'root',
})

export class PortfolioCustomVarService {

  readonly baseUrl = environment.riskMetricsEndpoint;

  private approversCache$: Observable<any[]>;
  private toraStratsCache$: Observable<any[]>;

  constructor(private http: HttpClient) { }


  getUserDeals(): Observable<any[]> {
    const url = `${this.baseUrl}/deals`;
    return this.http.get<any>(url).pipe(map(res => res.result));
  }  

  generateCustomPortfolioAgotReport(file: File): Observable<any> {
    const url = `${this.baseUrl}/custom-portfolio-var/generate-agot-report`;
    var formData = new FormData();
    formData.append('file', file);
    return this.http.post(url, formData);
  }

  downloadSupportingFile(tradeId: number): Observable<Blob> {
    const url = `${this.baseUrl}/download-file`;
    return this.http.get<any>(url, { params: new HttpParams().set('deal_id', tradeId) }).pipe(map(res => convertb64toBlob(res.result[0])));
  }

  calculateCustomPortfolioAgotVar(file: File): Observable<eodVar> {
    const url = `${this.baseUrl}/custom-portfolio-var/calculate-agot-eod-var`;
    var formData = new FormData();
    formData.append('file', file);
    return this.http.post(url, formData);
  }


  private mapResponseToTrade(res: any): Trade {
    return {
      id: res.deal_id,
      date: res.create_date,
      status: res.status,
      traderId: res.trader?.oid,
      traderName: res.trader?.full_name,
      approverId: res.approver?.oid,
      approverName: res.approver?.full_name,
      toraStrategyId: res.tora_strat_number,
      quorumContractId: res.quorum_contract_number,
      tradeType: res.trade_type,
      currentVar: res.current_var,
      maxVar: res.max_var,
      projectedProfit: res.projected_profit,
      stopLoss: res.stop_loss,
      holdLength: res.hold_length,
      holdLengthUnit: res.hold_length_units,
      entryReason: res.entry_reason,
      exitReason: res.exit_reason,
      supportingFileName: res.file_name,
      legs: res.legs_json,
      comment: res.comment
    };
  }
}
