<h3>Natural Gas Price Curve</h3>
<div>
  <mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
    <mat-label>Trade date</mat-label>
    <input matInput type="date" [(ngModel)]="tradeDate" (ngModelChange)="fetchChartData()" [max]="today">
  </mat-form-field>

  <button class="em-c-btn em-c-btn--small em-u-margin-right" style="vertical-align: middle" (click)="openCurveDialog()" matTooltip="Add/Update price curve(s)" [class.em-c-btn--disabled]="isLoading" [disabled]="isLoading">
    <div class="em-c-btn__inner">
      <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#chart-line"></use>
      </svg>
    </div>
  </button>

  <mat-button-toggle-group aria-label="Unit" [(ngModel)]="unit" (ngModelChange)="fetchChartData()">
    <mat-button-toggle *ngFor="let item of allUnits | keyvalue" [value]="item.key">{{item.value}}</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div style="display: flex; width: 100%">
  <div class="chart-container">
    <canvas baseChart
            [data]="chartData"
            [options]="lineChartOptions"
            type="line">
    </canvas>
  </div>
</div>
