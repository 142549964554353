import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-new-trade-success',
  templateUrl: './new-trade-success.component.html',
  styleUrls: ['./new-trade-success.component.scss']
})
export class NewTradeSuccessComponent implements OnInit {

  tradeId;

  constructor(private route: ActivatedRoute, private titleService: Title) {
    this.titleService.setTitle('Trading Preview UI | Trade Blotter: Trade submitted!');
}

  ngOnInit(): void {
    this.route.paramMap.pipe(map(() => window.history.state))
      .subscribe(params => {
        this.tradeId = params.tradeId;
      });
  }

}
