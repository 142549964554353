<div class="em-c-alert em-c-alert--error" role="alert" *ngIf="showCalculatingError">
  <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#circle-exclamation-mark"></use>
  </svg>
  <div class="em-c-alert__body">
    There was an error in fetching the Unit VaR values at this time. Please try refreshing.
  </div>
  <div class="em-c-alert__actions">
    <button class="em-c-text-btn em-c-alert--error" (click)="showCalculatingError=false">
      Close
    </button>
  </div>
</div>
<div class="em-c-loader spinner" *ngIf="isCalculating">
  <img src="unity/images/icon-spinner.svg" />
</div>
<div class="em-l-grid">
  <div class="em-l-grid__item">
    <div>
      <mat-form-field class="em-u-padding-top-none em-u-padding-right small-field">
        <mat-label>Confidence level</mat-label>
        <mat-select [(ngModel)]="alphaCI" (ngModelChange)="calculateUnitVaR()">
          <mat-option value=".90">90%</mat-option>
          <mat-option value=".95">95%</mat-option>
          <mat-option value=".99">99%</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
        <mat-label>Time horizon</mat-label>
        <input matInput type="date" [(ngModel)]="dateHorizon" (ngModelChange)="timeHorizonChanged()" [min]="nextWeekdayStr">
      </mat-form-field>

      <mat-form-field class="em-u-padding-top-none small-field">
        <mat-label>Business days</mat-label>
        <input matInput type="number" class="em-u-text-align-right" [(ngModel)]="businessDays" disabled>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="em-c-table-object ">
  <div class="em-c-table-object__header">
    <div class="em-c-toolbar ">
      <div class="em-c-toolbar__item">
        <div class="em-c-field em-c-field--inline em-c-field--small">
          <div class="em-c-field__body">
            <select class="em-c-select" [(ngModel)]="region" (ngModelChange)="regionChanged()">
              <option value="AGOT">AGOT</option>
              <option value="EGOT">EGOT</option>
              <option value="LNG">LNG</option>
            </select>
          </div>
        </div>
      </div>

      <div class="em-c-toolbar__item em-u-margin-left-none">
        <div class="em-c-field em-c-field--inline em-c-field--small">
          <div class="em-c-field__body">
            <select class="em-c-select" [(ngModel)]="currency" (ngModelChange)="currencyChanged()">
              <option value="USD">USD</option>
              <option value="EURO">EUR</option>
              <option value="GBP">GBP</option>
            </select>
          </div>
        </div>
      </div>

      <div class="em-c-toolbar__item em-is-aligned-right">
        <div class="em-c-btn-group ">
          <div *ngIf="refreshTime" class="em-u-margin-top-half">
            Updated: {{ refreshTime | date : 'short'}}
          </div>
          <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="calculateUnitVaR()" [disabled]="isCalculating" [class.em-c-btn--disabled]="isCalculating"
                  matTooltip="Refresh VaR" matTooltipPosition="above">
            <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#refresh"></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>

  <table mat-table [dataSource]="monthlyUnitVaRData" class="em-c-table">
    <ng-container *ngFor="let col of displayedColumns; let i = index " [matColumnDef]="col">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double em-u-text-align-center" [ngClass]="{'em-u-text-align-right': i > 2}"> {{ col }} </th>
      <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top em-u-text-align-center" [ngClass]="{'em-u-text-align-right': i > 2}">
        {{ getVaRValue(element, col) }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
