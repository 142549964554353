<button class="em-c-btn em-c-btn--small em-u-margin-right-half em-u-margin-bottom-half" (click)="addLineInfo()" matTooltip="Add price curve">
  <div class="em-c-btn__inner">
    <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#plus-filled"></use>
    </svg>
  </div>
</button>
<button class="em-c-btn em-c-btn--small em-u-margin-bottom-half" (click)="clearLineInfo()" matTooltip="Remove all curves">
  <div class="em-c-btn__inner">
    <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#trashcan-empty"></use>
    </svg>
  </div>
</button>

<div style="height: 515px; overflow: auto">
  <ng-container *ngFor="let line of lineInfo; let i = index">
    <div style="display: flex; align-items: center">
      <mat-form-field class="xsmall-field">
        <mat-label>Hub</mat-label>
        <mat-select [(ngModel)]="line.data1.key">
          <mat-option *ngFor="let hub of data.hubs" [value]="hub">{{hub}}</mat-option>
        </mat-select>
      </mat-form-field>
      <svg class="em-c-icon em-c-icon--small em-u-margin-left-half em-u-margin-right-half">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#x-filled"></use>
      </svg>
      <mat-form-field class="xxsmall-field">
        <mat-label>%</mat-label>
        <input matInput type='number' [(ngModel)]="line.data1.percentFactor">
      </mat-form-field>
      <svg class="em-c-icon em-c-icon--small em-u-margin-left-half em-u-margin-right-half">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#plus-filled"></use>
      </svg>
      <mat-form-field class="xxsmall-field">
        <mat-label>Shift</mat-label>
        <input matInput type='number' [(ngModel)]="line.data1.shiftFactor">
      </mat-form-field>
      <div style="display: flex; align-items: center" *ngIf="line.data2">
        <svg class="em-c-icon em-c-icon--small em-u-margin-left-half em-u-margin-right-half">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#minus-filled"></use>
        </svg>
        <mat-form-field class="xsmall-field">
          <mat-label>Hub</mat-label>
          <mat-select [(ngModel)]="line.data2.key">
            <mat-option *ngFor="let hub of data.hubs" [value]="hub">{{hub}}</mat-option>
          </mat-select>
        </mat-form-field>
        <svg class="em-c-icon em-c-icon--small em-u-margin-left-half em-u-margin-right-half">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#x-filled"></use>
        </svg>
        <mat-form-field class="xxsmall-field">
          <mat-label>%</mat-label>
          <input matInput type='number' [(ngModel)]="line.data2.percentFactor">
        </mat-form-field>
        <svg class="em-c-icon em-c-icon--small em-u-margin-left-half em-u-margin-right-half">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#plus-filled"></use>
        </svg>
        <mat-form-field class="xxsmall-field">
          <mat-label>Shift</mat-label>
          <input matInput type='number' [(ngModel)]="line.data2.shiftFactor">
        </mat-form-field>
      </div>
      <button class="em-c-btn em-c-btn--small em-c-btn--tertiary em-u-margin-bottom-half" (click)="addSpreadData(i)" matTooltip="Add spread" *ngIf="!line.data2">
        <div class="em-c-btn__inner">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#more-horizontal"></use>
          </svg>
        </div>
      </button>
      <button class="em-c-btn em-c-btn--small em-c-btn--tertiary em-u-margin-bottom-half" (click)="deleteLineInfo(i)" matTooltip="Delete curve">
        <div class="em-c-btn__inner">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#trashcan"></use>
          </svg>
        </div>
      </button>
    </div>
  </ng-container>

</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="lineInfo" class="em-c-btn em-c-btn--small em-u-margin-right-half">
    <span class="em-c-btn__text">Apply</span>
  </button>
  <button mat-button mat-dialog-close class="em-c-btn em-c-btn--small">
    <span class="em-c-btn__text">Cancel</span>
  </button>

</div>
