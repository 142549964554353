import { AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-portfolio-var-table',
  templateUrl: './portfolio-var-table.component.html',
  styleUrls: ['./portfolio-var-table.component.scss']
})
export class PortfolioVarTableComponent implements AfterViewInit {

  @Input()
  portfolioVarData: any[];

  pvDataSource: MatTableDataSource<any>;

  defaultColumns = ['trade_type', 'tradebook', 'regional_office', 'strategy', 'intraday_var', 'book_cvar', 'program_cvar', 'regional_cvar', 'var_change_wrt_prior_bd'];
  displayedColumns = [];

  @ViewChild(MatSort) sort: MatSort;

  constructor(private cd: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    var ds = new MatTableDataSource();
    ds.data = this.portfolioVarData;
    ds.sort = this.sort;

    this.pvDataSource = ds;

    this.displayedColumns = [...this.defaultColumns];
    const firstRow = this.portfolioVarData.find(Boolean);
    this.defaultColumns.forEach(col => {
      if (!(col in firstRow)) {
        this.displayedColumns.splice(this.displayedColumns.indexOf(col), 1);
      }
    });

    //Must run change detection as data is being set outside of ngInit
    this.cd.detectChanges();
  }

  isNumber(value): boolean {
    return Number.isInteger(value);
  }

  filter(filter: string) {
    this.pvDataSource.filter = filter;
  }
}
