import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { FileSaverService } from 'ngx-filesaver';
import { finalize } from 'rxjs/operators';
import { ChartSettings } from '../../helpers/chart-settings';
import { jsonToCsv } from '../../helpers/json-to-csv';
import { DateHelperService } from '../../services/date-helper.service';
import { RiskMetricsService } from '../../services/risk-metrics.service';

@Component({
  selector: 'app-price-distribution',
  templateUrl: './price-distribution.component.html',
  styleUrls: ['./price-distribution.component.scss']
})
export class PriceDistributionComponent implements OnInit {

  tradeDate: string;
  referenceDate: string;
  selectedHub: string;
  today: string = this.dateHelperService.getDateyyyyMMdd(new Date().toLocaleDateString('en-CA'));

  priceOptions = ['Future', 'P10', 'P15', 'P20', 'P25', 'P30', 'P35', 'P40', 'P45',
    'P50', 'P55', 'P60', 'P65', 'P70', 'P75', 'P80', 'P85', 'P90'];
  prices = ['Future', 'P10', 'P90'];

  hubs: [];

  priceDistData: any;
  refPriceDistData: any;

  isPullingData = false;
  hasError = false;

  chartData: ChartConfiguration['data'] = { datasets: [] };
  lineChartOptions: ChartConfiguration['options'] = {};

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  constructor(private riskMetricsService: RiskMetricsService, private fileSaverService: FileSaverService,
    private dateHelperService: DateHelperService, private titleService: Title) {
    this.titleService.setTitle('Trading Preview UI | Implied Market Price Distribution');
  }

  ngOnInit(): void {
    this.tradeDate = this.dateHelperService.getDateyyyyMMdd(this.dateHelperService.getLastWeekday(new Date()).toLocaleDateString('en-CA'));
    this.selectedHub = 'Henry';
    this.riskMetricsService.getMarketImpliedHubs().subscribe(res => {
      this.hubs = res;
      this.fetchChartData();
    });
  }

  fetchChartData(): void {
    this.fetchPriceDistributionData(this.tradeDate, this.selectedHub, this.setPriceDistData.bind(this));
  }

  fetchReferenceChartData(): void {
    this.fetchPriceDistributionData(this.referenceDate, this.selectedHub, this.setRefPriceDistData.bind(this));
  }

  private fetchPriceDistributionData(date: string, hub: string, callback: Function) {
    if (!date || !hub) return;

    this.hasError = false;
    this.isPullingData = true;
    this.riskMetricsService.getMarketImpliedPriceDistribution(date, hub)
      .pipe(finalize(() => this.isPullingData = false))
      .subscribe(
        res => {
          callback(res);
          this.updatePriceSeries();
        },
        err => {
          this.hasError = true;
          callback(null);
          this.updatePriceSeries();
        }
      );
  }

  private setPriceDistData(result: any): void {
    this.priceDistData = result;
  }

  private setRefPriceDistData(result: any): void {
    this.refPriceDistData = result;
  }

  updatePriceSeries(): void {
    this.chartData.datasets = [];

    if (this.priceDistData) {
      this.prices.forEach((p, i) => {
        let color = ChartSettings.colors[i * 3 % ChartSettings.colors.length];
        this.chartData.datasets.push({
          data: this.priceDistData[p].implied_price,
          label: p,
          pointBackgroundColor: color,
          pointHoverBorderColor: color,
          borderColor: color,
        });
      });

      this.chartData.labels = this.priceDistData.strip;
    }

    if (this.refPriceDistData) {
      var firstMainPrice = this.priceDistData.strip.find(Boolean);
      var firstRefPrice = this.refPriceDistData.strip.find(Boolean);
      var buffer = [];
      var sliceIndex = 0;

      // Finds data that needs to be shaved or padded if the dates don't match up
      if (firstMainPrice < firstRefPrice) {
        let index = this.priceDistData.strip.indexOf(firstRefPrice);
        buffer = new Array(index);
        buffer.fill(null);
      }
      else if (firstMainPrice > firstRefPrice) {
        sliceIndex = this.refPriceDistData.strip.indexOf(firstMainPrice);
      }

      this.prices.forEach((p, i) => {
        let color = ChartSettings.colors[i * 3 % ChartSettings.colors.length];
        this.chartData.datasets.push({
          data: [...buffer, ...this.refPriceDistData[p].implied_price.slice(sliceIndex)],
          label: `${p} (ref)`,
          borderDash: [5, 15],
          pointBackgroundColor: color,
          pointHoverBorderColor: color,
          borderColor: color,
        });
      });
    }

    this.lineChartOptions = {
      scales: {
        x: {
          ...ChartSettings.defaultTickSettings,
          ...ChartSettings.defaultGridSettings
        },
        y: {
          title: {
            text: this.priceDistData?.unit,
            display: true
          },
          ...ChartSettings.defaultGridSettings
        }
      },
    }

    this.chart.update();
  }

  clearReferencePriceSeries(): void {
    this.referenceDate = null;
    this.setRefPriceDistData(null);
    this.updatePriceSeries();
  }

  saveData(): void {
    const data = { date: this.chartData.labels };
    this.priceOptions.forEach(p => {
      data[p] = this.priceDistData[p].implied_price;
    });

    this.fileSaverService.saveText(jsonToCsv(data), `pd-${this.tradeDate}-${this.selectedHub}.csv`);
  }
}
