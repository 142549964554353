<div class="em-l-grid em-l-grid--2up app-pricebox">
  <!-- P1 -->
    <div class="em-l-grid__item app-pricebox-header app-pricebox-header-tl-radius"><b>{{PriceBoxName}}</b></div>
    <div class="em-l-grid__item app-pricebox-header app-pricebox-header-tr-radius"><b>Currency / Unit</b></div>

    <div class="em-l-grid__item">
        <input [options]="{ prefix: '', thousands: ',', decimal: '.',precision:2, allowNegative:true }" currencyMask [(ngModel)]="p1r1.price" name="p1r1" (ngModelChange)="calcR1()" [disabled]="isDisabled" />
    </div>
    <div class="em-l-grid__item ovalign ovmargin">
        EUR / MWh
    </div>
    <div class="em-l-grid__item">
        <input [options]="{ prefix: '', thousands: ',', decimal: '.',precision:2, allowNegative:true }" currencyMask [(ngModel)]="p1r2.price" name="p1r2" (ngModelChange)="calcR2()" [disabled]="isDisabled" />
    </div>
    <div class="em-l-grid__item ovalign ovmargin">
        GBp / Therm
    </div>

    <div class="em-l-grid__item">
        <input [options]="{ prefix: '', thousands: ',', decimal: '.',precision:2, allowNegative:true }" currencyMask [(ngModel)]="p1r3.price" name="p1r3" (ngModelChange)="calcR3()" [disabled]="isDisabled" />
    </div>
    <div class="em-l-grid__item ovalign ovmargin">
        USD / MMBTU
    </div>

</div>  
