
<div class="el-m-grid">
  <div style="display: flex;" class="app-gasconverter-head">
    <div class="em-l-grid em-l-grid--5up em-static-matrix">
        <div class="em-l-grid__item"><mat-label></mat-label></div>
        <div class="em-l-grid__item"><mat-label>Therm</mat-label></div>
        <div class="em-l-grid__item"><mat-label>MMBTU</mat-label></div>
        <div class="em-l-grid__item"><mat-label>MT</mat-label></div>
        <div class="em-l-grid__item"><mat-label>MWh</mat-label></div>

        <div class="em-l-grid__item">
            <mat-label>MWh</mat-label>
        </div>
        <div class="em-l-grid__item">
            <mat-label><input type="text" value="34.129576" disabled /></mat-label>
        </div>
        <div class="em-l-grid__item">
            <mat-label><input type="text" value="3.412138" disabled /></mat-label>
        </div>
        <div class="em-l-grid__item">
            <mat-label><input type="text" value="65.89E-09" disabled /></mat-label>
        </div>
        <div class="em-l-grid__item"><mat-label>1</mat-label></div>
        
        <div class="em-l-grid__item">
            <mat-label>MT</mat-label>
        </div>
        <div class="em-l-grid__item">
            <mat-label><input type="text" value="517,832,300" disabled /></mat-label>
        </div>
        <div class="em-l-grid__item">
            <mat-label><input type="text" value="51,783,230" disabled /></mat-label>
        </div>
        <div class="em-l-grid__item"><mat-label>1</mat-label></div>
        <div class="em-l-grid__item">
            <mat-label><input type="text" value="15,185,697" disabled /></mat-label>
        </div>

        <div class="em-l-grid__item">
            <mat-label>MMBTU</mat-label>
        </div>
        <div class="em-l-grid__item">
            <mat-label><input type="text" value="10.00" disabled /></mat-label>
        </div>
        <div class="em-l-grid__item"><mat-label>1</mat-label></div>
        <div class="em-l-grid__item">
            <mat-label><input type="text" value="19.31E-09" disabled /></mat-label>
        </div>
        <div class="em-l-grid__item">
            <mat-label><input type="text" value="0.29307" disabled /></mat-label>
        </div>

        <div class="em-l-grid__item">
            <mat-label>Therm</mat-label>
        </div>
        <div class="em-l-grid__item"><mat-label>1</mat-label></div>
        <div class="em-l-grid__item">
            <mat-label><input type="text" value="0.10" disabled /></mat-label>
        </div>
        <div class="em-l-grid__item">
            <mat-label><input type="text" value="1.931E-09" disabled /></mat-label>
        </div>
        <div class="em-l-grid__item">
            <mat-label><input type="text" value="0.02930" disabled /></mat-label>
        </div>
    </div>
  </div>
    <div class="em-textcenter">
      <mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
        <mat-label>FX Futures Strip</mat-label>
        <input matInput type="month" [(ngModel)]="asOfDate" (ngModelChange)="monthSelectChange()"
               [min]="minAsOfDate" [max]="maxAsOfDate">
      </mat-form-field>

      <div *ngIf="isCalculating" style="width: 500px;float: right;">
        <div class="em-c-progress-bar em-c-progress-bar--indeterminate">
          <div class="em-c-progress-bar__label">
            <span class="em-u-margin-left-half"></span>
          </div>
          <div class="em-c-progress-bar__bar"> <span class="em-c-progress-bar--indeterminate__value"></span></div>
        </div>
      </div>
    </div>
    <div style="clear:both"></div>
    <div style="display: flex;" class="app-gasconverter-head" #gasHeaderDiv>

        <div class="em-l-grid em-l-grid--4up app-gasconverter-settings">
            <div class="em-l-grid__item"><mat-label>&nbsp;</mat-label></div>
            <div class="em-l-grid__item"><mat-label>EUR</mat-label></div>
            <div class="em-l-grid__item"><mat-label>GBP</mat-label></div>
            <div class="em-l-grid__item"><mat-label>USD</mat-label></div>

            <div class="em-l-grid__item">
                <mat-label>USD</mat-label>
            </div>
            <div class="em-l-grid__item">
                <input class="" min="0" step=".01" type='number'  [(ngModel)]="usd2euro" name="usd2euro" (ngModelChange)="usd2euroChange()" />
            </div>
        
            <div class="em-l-grid__item">
                <input class="" min="0" step=".01" type='number' [(ngModel)]="usd2gbp" name="usd2gbp" (ngModelChange)="gbp2usd=ToNumber(1/usd2gbp);OnCurrencyUSDChange()" />
            </div>
            <div class="em-l-grid__item"><input type="number" value="1" disabled="disabled"></div>

            <div class="em-l-grid__item">
                <mat-label>GBP</mat-label>
            </div>
            <div class="em-l-grid__item">
                <input class="" min="0" step=".01" type='number' [(ngModel)]="gbp2euro" name="gbp2euro" disabled />
            </div>
            <div class="em-l-grid__item"><input type="number" value="1" disabled="disabled"></div>
            <div class="em-l-grid__item">
                <input class="" min="0" step=".01" type='number' [(ngModel)]="gbp2usd" name="gbp2usd" (ngModelChange)="gbp2usdChange()" />
            </div>
            
            <div class="em-l-grid__item">
                <mat-label>EUR</mat-label>
            </div>
            <div class="em-l-grid__item"><input type="number" value="1" disabled="disabled"></div>
            <div class="em-l-grid__item">
                <input class="" min="0" step=".01" type='number' [(ngModel)]="euro2gbp" name="euro2gbp" disabled />
            </div>
            <div class="em-l-grid__item">
                <input class="" min="0" step=".01" type='number' [(ngModel)]="euro2usd" name="euro2usd" (ngModelChange)="euro2usdChange()" /> 
            </div>
        </div>
        
    </div>


    <div style="display: flex;justify-content:center;">
        <app-gasconverter-prices class="app-pricebox" [priceBoxName]="'Price 1'" [p1row1]="p1row1" (childToParent)="childPriceToParent()" [refreshCurrencyCalc]="refreshNow" [calcRowNumber]="calcCurrentRow"></app-gasconverter-prices> 
        <app-gasconverter-prices class="app-pricebox" [priceBoxName]="'Price 2'" [p1row1]="p2row1" (childToParent)="childPriceToParent()" [refreshCurrencyCalc]="refreshNow" [calcRowNumber]="calcCurrentRow"></app-gasconverter-prices> 
        <app-gasconverter-prices class="app-pricebox" [priceBoxName]="'Price 1 - Price 2'" [p1row1]="p3row1" [refreshPrice2MinusP1]="refreshPrice2MinusP1" [refreshCurrencyCalc]="refreshNow" [calcRowNumber]="calcCurrentRow" [isDisabled]="true"></app-gasconverter-prices> 
    </div>
</div>
