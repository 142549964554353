import { Component, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatTableDataSource } from '@angular/material/table';
import { PhysicalLngSessionService } from './physical-lng-session-service.service';
import {TallyItem} from './physical-lng-summary-page.model';

@Component({
  selector: 'app-physical-lng-summary-page',
  templateUrl: './physical-lng-summary-page.component.html',
  styleUrls: ['./physical-lng-summary-page.component.scss']
})
export class PhysicalLngSummaryPageComponent implements OnInit {

  overall_pnl: number = 0.0;
  overall_pnl_dealcase: number = 0.0;
  uplift: number = 0.0;
  displayedColumns_pnl_tally: string[] = ['Item', 'PnL ($)'];
  datasource_pnl_tally: TallyItem[] = [];
  datasource_pnl_tally_dealcase: TallyItem[] = [];

  constructor(private titleService: Title, private localStore: PhysicalLngSessionService) {
    this.titleService.setTitle('Trading Preview UI | Physical LNG SUMMARY PAGE');
  }

  ngOnInit(): void {    
    this.overall_pnl = this.calculate_total_pnl();
    this.overall_pnl_dealcase = this.calculate_total_pnl_dealcase();
    this.uplift = this.overall_pnl_dealcase - this.overall_pnl;
  }

  // BaseCase PnL calculation.
  calculate_total_pnl(){
    return this.calculate_total_pnl_fobdes() + this.calculate_total_pnl_desdes() + this.calculate_total_pnl_tco();
  }

  calculate_total_pnl_fobdes(){
    var total_pnl = 0.0;

    var allTabs = this.localStore.getData("ALL::LNG_FOBDES_TABS::") || '';
    if(allTabs.length>0){
      const allTabNames = allTabs.split('\t');
      allTabNames.forEach(tabName =>{
        const localStoredString = this.localStore.getData(tabName) || '';
        const tmp0 = JSON.parse(localStoredString);
        const tmp1 = JSON.parse(tmp0['tradeSummary']);
        const pnl = Number(tmp1['netRealisationAmount']);
        total_pnl += pnl;

        var tally_item = new TallyItem();
        tally_item.item_name = tabName;
        tally_item.pnl = pnl;
        this.datasource_pnl_tally.push(tally_item);
      });
    }

    return total_pnl;
  }

  calculate_total_pnl_desdes() {
    var total_pnl = 0.0;

    var allTabs = this.localStore.getData("ALL::LNG_DESDES_TABS::") || '';
    if(allTabs.length>0){
      const allTabNames = allTabs.split('\t');
      allTabNames.forEach(tabName =>{
        const localStoredString = this.localStore.getData(tabName) || '';
        const tmp0 = JSON.parse(localStoredString);
        const tmp1 = JSON.parse(tmp0['tradeSummaryDesdes']);
        const pnl = tmp1["netRealisationAmount"];
        total_pnl += pnl;

        var tally_item = new TallyItem();
        tally_item.item_name = tabName;
        tally_item.pnl = pnl;
        this.datasource_pnl_tally.push(tally_item);
      });
    }

    return total_pnl;
  }

  calculate_total_pnl_tco() {
    var total_pnl = 0.0;

    var allTabs = this.localStore.getData("ALL::LNG_TCO_TABS::") || '';
    if(allTabs.length>0){
      const allTabNames = allTabs.split('\t');
      allTabNames.forEach(tabName =>{
        const localStoredString = this.localStore.getData(tabName) || '';
        const tmp0 = JSON.parse(localStoredString);
        const tmp1 = JSON.parse(tmp0['dealEconomicsTco']);
        const pnl = tmp1['net_realization'];
        total_pnl += pnl;

        var tally_item = new TallyItem();
        tally_item.item_name = tabName;
        tally_item.pnl = pnl;
        this.datasource_pnl_tally.push(tally_item);
      });
    }

    return total_pnl;
  }

  // DealCase PnL calculation.
  calculate_total_pnl_dealcase(){
    return this.calculate_total_pnl_dealcase_fobdes() + this.calculate_total_pnl_dealcase_desdes() + this.calculate_total_pnl_dealcase_tco();
  }

  calculate_total_pnl_dealcase_fobdes(){
    var total_pnl = 0.0;

    var allTabs = this.localStore.getData("ALL::LNG_DEALCASE_FOBDES_TABS::") || '';
    if(allTabs.length>0){
      const allTabNames = allTabs.split('\t');
      allTabNames.forEach(tabName =>{
        const localStoredString = this.localStore.getData(tabName) || '';
        const tmp0 = JSON.parse(localStoredString);
        const tmp1 = JSON.parse(tmp0['tradeSummary']);
        const pnl = Number(tmp1['netRealisationAmount']);
        total_pnl += pnl;

        var tally_item = new TallyItem();
        tally_item.item_name = tabName;
        tally_item.pnl = pnl;
        this.datasource_pnl_tally_dealcase.push(tally_item);
      });
    }

    return total_pnl;
  }

  calculate_total_pnl_dealcase_desdes() {
    var total_pnl = 0.0;

    var allTabs = this.localStore.getData("ALL::LNG_DEALCASE_DESDES_TABS::") || '';
    if(allTabs.length>0){
      const allTabNames = allTabs.split('\t');
      allTabNames.forEach(tabName =>{
        const localStoredString = this.localStore.getData(tabName) || '';
        const tmp0 = JSON.parse(localStoredString);
        const tmp1 = JSON.parse(tmp0['tradeSummaryDesdes']);
        const pnl = tmp1["netRealisationAmount"];
        total_pnl += pnl;

        var tally_item = new TallyItem();
        tally_item.item_name = tabName;
        tally_item.pnl = pnl;
        this.datasource_pnl_tally_dealcase.push(tally_item);
      });
    }

    return total_pnl;
  }

  calculate_total_pnl_dealcase_tco() {
    var total_pnl = 0.0;

    var allTabs = this.localStore.getData("ALL::LNG_DEALCASE_TCO_TABS::") || '';
    if(allTabs.length>0){
      const allTabNames = allTabs.split('\t');
      allTabNames.forEach(tabName =>{
        const localStoredString = this.localStore.getData(tabName) || '';
        const tmp0 = JSON.parse(localStoredString);
        const tmp1 = JSON.parse(tmp0['dealEconomicsTco']);
        const pnl = tmp1['net_realization'];
        total_pnl += pnl;

        var tally_item = new TallyItem();
        tally_item.item_name = tabName;
        tally_item.pnl = pnl;
        this.datasource_pnl_tally_dealcase.push(tally_item);
      });
    }

    return total_pnl;
  }

}

