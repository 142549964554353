<div class="em-l-grid">
  <div class="em-l-grid__item">
    <span>
      <svg class="em-c-icon em-c-icon--xlarge">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/48/em-icons.svg#lock"></use>
      </svg>
    </span>
    <h2>Access Denied</h2>
    <p>
      Sorry, you do not have access to the page you tried navigating to. Please ensure that your account is assigned to the appropriate app role and then sign-out and sign-in again.
    </p>
  </div>
</div>
