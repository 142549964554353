import { Component, QueryList, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BaseChartDirective } from 'ng2-charts';
import { FileSaverService } from 'ngx-filesaver';
import { jsonToCsv } from '../../helpers/json-to-csv';
import { DateHelperService } from '../../services/date-helper.service';
import { RiskMetricsService } from '../../services/risk-metrics.service';
import { BaseCurvesComponent } from '../base-curves.component';

@Component({
  selector: 'app-europe-curves',
  templateUrl: './europe-curves.component.html',
  styleUrls: ['./europe-curves.component.scss']
})
export class EuropeCurvesComponent extends BaseCurvesComponent {

  @ViewChildren(BaseChartDirective) charts?: QueryList<BaseChartDirective>;

  constructor(riskMetricsService: RiskMetricsService, dateHelperService: DateHelperService, private fileSaverService: FileSaverService,
    private titleService: Title) {
    super(riskMetricsService, dateHelperService);
    this.titleService.setTitle('Trading Preview UI | European Macro Curves');
  }

  createChartData(): void {
    this.interestRateChartData.datasets = [];
    this.inflationChartData.datasets = [];

    this.interestRateChartData.datasets.push({
      data: this.curveData.interest_rate?.estr_historical?.map(x => x * 100),
      pointBackgroundColor: '#3a397b',
      pointHoverBorderColor: '#3a397b',
      borderColor: '#3a397b',
      label: 'ESTR Historical',
    });

    this.inflationChartData.datasets.push({
      data: this.curveData.inflation_rate?.euro_area_hicp_yoy?.map(x => x * 100),
      pointBackgroundColor: '#3a397b',
      pointHoverBorderColor: '#3a397b',
      borderColor: '#3a397b',
      label: 'YoY HICP'
    });

    if (this.viewRate === 'forward') {
      this.interestRateChartData.datasets.push({
        data: this.curveData.interest_rate?.euro_area_aaa_bonds_inst_fwd_rate?.map(x => x * 100),
        label: 'AAA Bonds Forward Rate',
        borderDash: [5, 15]
      });
      this.interestRateChartData.datasets.push({
        data: this.curveData.interest_rate?.euro_area_all_bonds_inst_fwd_rate?.map(x => x * 100),
        label: 'All Bonds Forward Rate',
        borderDash: [5, 15]
      });

      this.inflationChartData.datasets.push({
        data: this.curveData.inflation_rate?.euro_zone_inflation_inst_fwd_rate?.map(x => x * 100),
        label: 'Inflation Forward Rate',
        borderDash: [5, 15]
      });
    }
    else {
      this.interestRateChartData.datasets.push({
        data: this.curveData.interest_rate?.euro_area_aaa_bonds_zero_rate?.map(x => x * 100),
        label: 'AAA Bonds Zero Rate',
        borderDash: [5, 15],
      });
      this.interestRateChartData.datasets.push({
        data: this.curveData.interest_rate?.euro_area_all_bonds_zero_rate?.map(x => x * 100),
        label: 'All Bonds Zero Rate',
        borderDash: [5, 15],
      });

      this.inflationChartData.datasets.push({
        data: this.curveData.inflation_rate?.euro_zone_inflation_zero_rate?.map(x => x * 100),
        label: 'Inflation Zero Rate',
        borderDash: [5, 15],
      });
    }

    this.interestRateChartData.labels = this.curveData.interest_rate?.dates;
    this.interestRateAsOfDate = this.curveData.interest_rate?.as_of_date;

    this.inflationChartData.labels = this.curveData.inflation_rate?.dates;
    this.inflationAsOfDate = this.curveData.inflation_rate?.as_of_date;

    this.charts.forEach(c => c.update());
  }

  get region(): string {
    return 'EU';
  }

  saveInterestRateData(): void {
    const data = {
      date: this.curveData.interest_rate.dates,
      estrHistorical: this.curveData.interest_rate.estr_historical,
      aaaBondsFwd: this.curveData.interest_rate.euro_area_aaa_bonds_inst_fwd_rate,
      allBondsFwd: this.curveData.interest_rate.euro_area_all_bonds_inst_fwd_rate,
      aaaBondsZero: this.curveData.interest_rate.euro_area_aaa_bonds_zero_rate,
      allBondsZero: this.curveData.interest_rate.euro_area_all_bonds_zero_rate,
    }
    this.fileSaverService.saveText(jsonToCsv(data), `eu-interestRates${this.interestRateAsOfDate}.csv`);
  }

  saveInflationRateData(): void {
    const data = {
      date: this.curveData.inflation_rate.dates,
      hicpYoy: this.curveData.inflation_rate.euro_area_hicp_yoy,
      euroInflationFwd: this.curveData.inflation_rate.euro_zone_inflation_inst_fwd_rate,
      euroInflationZero: this.curveData.inflation_rate.euro_zone_inflation_zero_rate,
    }
    this.fileSaverService.saveText(jsonToCsv(data), `eu-inflationRates${this.inflationAsOfDate}.csv`);
  }
}
