  <div class="em-c-loader spinner" *ngIf="initialized">
    <img src="unity/images/icon-spinner.svg" />
  </div>
<div class="em-l-grid em-l-grid--1up em-options-calc">
    <div class="em-l-grid__item em-l-close-button">
        <button title="delete" class="em-c-btn em-c-btn--tertiary" (click)="deleteItem()" tabindex="-1">
            <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#trashcan"></use>
            </svg>
        </button>
    </div>
    <div style="clear:both"></div>
    <div class="em-l-grid__item">
        <div class="em-l-grid em-l-grid--2up">
            <div class="em-l-grid__item">
                <mat-form-field>
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="selectedType">
                        <mat-option value="European">European</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="em-l-grid__item">
                <mat-form-field>
                    <mat-label>Call/Put</mat-label>
                    <mat-select [(ngModel)]="optionCalcItem.selectedCallPut" (ngModelChange)="getOptionsCalc()">
                        <mat-option selected value="Call">Call</mat-option>
                        <mat-option value="Put">Put</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="em-l-grid__item">
        <div class="em-l-grid em-l-grid--2up">
            <div class="em-l-grid__item em-al-right">
                <mat-form-field>
                    <mat-label>Strike Price</mat-label>
                    <input matInput type="number" step=".01" [(ngModel)]="optionCalcItem.strikePrice" (ngModelChange)="getOptionsCalc()">
                </mat-form-field>
            </div>
            <div class="em-l-grid__item em-al-right">
                <mat-form-field>
                    <mat-label>Forward Price</mat-label>
                    <input type="number" step=".01" matInput [(ngModel)]="optionCalcItem.forwardPrice" (ngModelChange)="getOptionsCalc()" >
                </mat-form-field>
            </div> 
        </div>
    </div>  

    <div class="em-l-grid__item">
        <div class="em-l-grid em-l-grid--2up">
            <div class="em-l-grid__item">
            <mat-form-field>
                <mat-label>Expiry</mat-label>
                <input matInput type="date" [(ngModel)]="optionCalcItem.expiryDate" (ngModelChange)="expiryDateChange()">
            </mat-form-field>
            </div>
            <div class="em-l-grid__item">
            <mat-form-field>
                <mat-label>Delivery</mat-label>
                <input matInput type="date" [(ngModel)]="optionCalcItem.deliveryDate" readonly disabled>
            </mat-form-field>
            </div>
        </div>
    </div>
   
    <div class="em-l-grid__item">
        <div class="em-l-grid em-l-grid--2up">
            <div class="em-l-grid__item">
                <mat-form-field>
                    <mat-label>Interest Rate (%)</mat-label>
                    <input type="number" step=".01" matInput [(ngModel)]="optionCalcItem.usdDepo" (ngModelChange)="getOptionsCalc()" >
                </mat-form-field>
            </div>
            <div class="em-l-grid__item">
                <mat-form-field>
                    <mat-label>Ann. Volatility {{optionCalcItem.selectedModel=='Black76'?'(%)':'($)'}}</mat-label>
                    <input type="number" step="{{optionCalcItem.selectedModel=='Black76'?'.01':'1'}}" matInput [(ngModel)]="optionCalcItem.annualVolatility" (ngModelChange)="getOptionsCalc()" >
                </mat-form-field>
            </div>
        </div>  
    </div>

    <div class="em-l-grid__item em-ov-divprices">
        <mat-label style="padding:2px;background-color: #e5e5e5;"><b>Results</b></mat-label>
        <div class="em-l-grid em-l-grid--2up">
            <div class="em-l-grid__item">
                <mat-label title="Estimated fair price one should pay for this option.">Price
                    <mat-icon class="mat-icon em-al-icon-help">help_outline</mat-icon>
                </mat-label>
            </div>
            <div class="em-l-grid__item em-al-right"><mat-label>{{optionResult.price | number: '0.4-4'}}</mat-label></div>
        </div>
        <div class="em-l-grid em-l-grid--2up" *ngIf="donotshow">
            <div class="em-l-grid__item"><mat-label title="Value to be realized if the option could be exercised immediately.">Intrinsic
                <mat-icon class="mat-icon em-al-icon-help">help_outline</mat-icon>
            </mat-label></div>
            <div class="em-l-grid__item em-al-right"><mat-label>{{optionResult.payoff | number: '0.4-4'}}</mat-label></div>
        </div>
        <div class="em-l-grid em-l-grid--2up">
            <div class="em-l-grid__item"><mat-label title="Change in value of the option with respect to a change in price of the underlying.">Delta
                <mat-icon class="mat-icon em-al-icon-help">help_outline</mat-icon>
            </mat-label></div>
            <div class="em-l-grid__item em-al-right"><mat-label>{{optionResult.delta | number: '0.4-4'}}</mat-label></div>
        </div>
        <div class="em-l-grid em-l-grid--2up">
            <div class="em-l-grid__item"><mat-label title="Change in the delta of an option with respect to a change in price of the underlying.">Gamma
                <mat-icon class="mat-icon em-al-icon-help">help_outline</mat-icon>
            </mat-label></div>
            <div class="em-l-grid__item em-al-right"><mat-label>{{optionResult.gamma | number: '0.4-4'}}</mat-label></div>
        </div>
        <div class="em-l-grid em-l-grid--2up">
            <div class="em-l-grid__item"><mat-label title="Change in value of the option with respect to a change of 1 percentage point in the volatility of the underlying.">Vega
                <mat-icon class="mat-icon em-al-icon-help">help_outline</mat-icon>
            </mat-label></div>
            <div class="em-l-grid__item em-al-right"><mat-label>{{optionResult.vega | number: '0.4-4'}}</mat-label></div>
        </div>
        <div class="em-l-grid em-l-grid--2up">
            <div class="em-l-grid__item"><mat-label title="Change in value of the option with respect to the passage of 1 calendar day, with all else remaining the same.">Theta
                <mat-icon class="mat-icon em-al-icon-help">help_outline</mat-icon>
            </mat-label></div>
            <div class="em-l-grid__item em-al-right"><mat-label>{{optionResult.theta | number: '0.4-4'}}</mat-label></div>
        </div>
        <div class="em-l-grid em-l-grid--2up">
            <div class="em-l-grid__item"><mat-label title="Change in the value of the option with respect to a change of 1 percentage point in interest rate.">Rho
                <mat-icon class="mat-icon em-al-icon-help">help_outline</mat-icon>
            </mat-label></div>
            <div class="em-l-grid__item em-al-right"><mat-label>{{optionResult.rho | number: '0.4-4'}}</mat-label></div>
        </div>
    </div>
</div>    
    
