import { Component, OnInit } from '@angular/core';
import { DateHelperService } from '../../../services/date-helper.service';
import { Title } from '@angular/platform-browser';
import { RiskMetricsService } from '../../../services/risk-metrics.service';
import { finalize } from 'rxjs/operators';
import { ShippingCost, DealEconomics, PhysicalLngInputFobdes, TradeSummary, VesselInfo, VolumeBalances, ComputedDates } from './physical-lng-fobdes.model';
import { internals } from '@azure/msal-browser';
import { FileSaverService } from 'ngx-filesaver';
import { PhysicalLngFobDesSessionService } from './physical-lng-fobdes.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-physical-lng-fobdes',
  templateUrl: './physical-lng-fobdes.component.html',
  styleUrls: ['./physical-lng-fobdes.component.scss']
})
export class PhysicalLngFobdesComponent implements OnInit {

  constructor(private riskMetricsService: RiskMetricsService, private titleService: Title, 
    private dateHelperService: DateHelperService, private fileSaverService: FileSaverService,
    private localStore: PhysicalLngFobDesSessionService) {
    this.titleService.setTitle('Trading Preview UI | Physical LNG FOB-DES');
    this.lngInputs = new PhysicalLngInputFobdes();
  }

  tabRefName: string;
  deleteTheTab: Subject<string> = new Subject();

  selectedSeller: string = "Seller";
  selectedBuyer: string = "Buyer";
  initialized:Boolean;
  visibleIn:boolean = true;
  lngInputs : PhysicalLngInputFobdes;
  tradeSummary: TradeSummary;
  dealEconomics: DealEconomics;
  volumeBalances: VolumeBalances;
  shippingCost: ShippingCost;
  computeDates: ComputedDates;
  vesselSize: number;
  vesselType: string;
  vesselBORLaden: number;
  vesselBORBallast: number;
  vesselBORIdle: number;

  selectedLoadingPort: string;
  selectedFOB: string = "FOB";
  selectedLoadingDate: string;
  selectedDischargePort: string;
  selectedDES: string = "DES";
  lngPurchaseFOB1: string;
  lngPurchaseFOB2: string;
  selectedDischargeDate: string;
  positioningBonus: string = "No";
  repositioningBonus: string = "No";
  apiPorts = ["United States Cameron", "United States Kenai", "United Kingdom South Hook"];
  // apiVesselTypes: VesselInfo[] = [];
  apiVesselTypes: VesselInfo[] = [
  // { "vessel_name": "Beidou Star",    "vessel_type": "TFDE",    "capacity": 171825.317,    "in_charter_rate": 104000.0},
  // { "vessel_name": "Beidou Star + Reliq",    "vessel_type": "TFDE",    "capacity": 171825.317,    "in_charter_rate": 104000.0 },
  // { "vessel_name": "Cool Racer",    "vessel_type": "MEGI/XDF",    "capacity": 174042.0,    "in_charter_rate": 93000.0 },
  { "vessel_name": "148 ST Vessel",    "vessel_type": "Steam Turbine",    "capacity": 147384.169,    "in_charter_rate": 0, "boiloffrate_laden": 0.0015, "boiloffrate_ballast": 0.0015 }];
  apiVesselSpeeds = []
  rotationTotalFullCharterDays: number = 0;

  ngOnInit(): void {
    var localStoredString = JSON.parse(this.localStore.getData(this.tabRefName));
    if(localStoredString) {
      this.initLoad();
      this.onVesselNameSelect();
  
      this.loadDataFromStore(localStoredString);
    }
    else {
      this.lngInputs.TestLoadDefaults();
      this.tradeSummary = new TradeSummary();
      this.tradeSummary.netRealisationAmount = 0.0;
      this.setSummaryDates();
      this.dealEconomics = new DealEconomics();
      this.shippingCost = new ShippingCost();
      this.volumeBalances = new VolumeBalances();
      this.computeDates = new ComputedDates();
  
      this.initLoad();
      this.onVesselNameSelect();
  
      this.saveDataToStore();  
    }
  }

  saveDataToStore(): void{
    var localStoredObj = {
      'selectedSeller': JSON.stringify(this.selectedSeller),
      'selectedBuyer': JSON.stringify(this.selectedBuyer),
      'lngInputs': JSON.stringify(this.lngInputs),
      'selectedLoadingDate': JSON.stringify(this.selectedLoadingDate),
      'selectedDischargeDate': JSON.stringify(this.selectedDischargeDate),
      'tradeSummary': JSON.stringify(this.tradeSummary),
      'volumeBalances': JSON.stringify(this.volumeBalances),
      'shippingCost': JSON.stringify(this.shippingCost),
      'dealEconomics': JSON.stringify(this.dealEconomics),
      'computeDates': JSON.stringify(this.computeDates),
      'rotationTotalFullCharterDays': JSON.stringify(this.rotationTotalFullCharterDays),
      'vesselSize': JSON.stringify(this.vesselSize),
      'vesselType': JSON.stringify(this.vesselType),
      'vesselBORLaden': JSON.stringify(this.vesselBORLaden),
      'vesselBORBallast': JSON.stringify(this.vesselBORBallast),
    }
    this.localStore.saveData(this.tabRefName, JSON.stringify(localStoredObj));
  }

  loadDataFromStore(localStoredString: string): void{
    this.selectedSeller = JSON.parse(localStoredString['selectedSeller']);
    this.selectedBuyer = JSON.parse(localStoredString['selectedBuyer']);
    this.lngInputs = JSON.parse(localStoredString['lngInputs']);
    this.selectedLoadingDate = JSON.parse(localStoredString['selectedLoadingDate']);
    this.selectedDischargeDate = JSON.parse(localStoredString['selectedDischargeDate']);
    this.tradeSummary = JSON.parse(localStoredString['tradeSummary']);
    this.volumeBalances = JSON.parse(localStoredString['volumeBalances']);
    this.shippingCost = JSON.parse(localStoredString['shippingCost']);
    this.dealEconomics = JSON.parse(localStoredString['dealEconomics']);
    this.computeDates = JSON.parse(localStoredString['computeDates']);
    this.rotationTotalFullCharterDays = JSON.parse(localStoredString['rotationTotalFullCharterDays']);
    this.vesselSize = JSON.parse(localStoredString['vesselSize']);
    this.vesselType = JSON.parse(localStoredString['vesselType']);
    this.vesselBORLaden = JSON.parse(localStoredString['vesselBORLaden']);
    this.vesselBORBallast = JSON.parse(localStoredString['vesselBORBallast']);
  }
  
  initLoad(): void{
    this.initialized = true;
    this.riskMetricsService.getPhysicalDealInitData().pipe(finalize(()=>{
      this.initialized = false;
    })).subscribe(
      res => {
        this.apiPorts = JSON.parse(res.installation);
        this.apiVesselTypes = JSON.parse(res.vessel_data);
        this.apiVesselSpeeds = JSON.parse(res.vessel_speeds);
      }
    )
  }

  onVesselNameSelect(){
    var selectedVessel = this.apiVesselTypes.filter(x => x.vessel_name == this.lngInputs.vessel_logistics.vessel_name);
    if(selectedVessel.length == 1){
      this.vesselSize = selectedVessel[0].capacity;
      this.vesselType = selectedVessel[0].vessel_type;
      this.lngInputs.vessel_logistics.charter_rate = selectedVessel[0].in_charter_rate;
      this.vesselBORLaden = 100 * selectedVessel[0].boiloffrate_laden;  // float -> %
      this.vesselBORBallast = 100 * selectedVessel[0].boiloffrate_ballast;  // float -> %
      // this.vesselBORIdle = selectedVessel[0].boiloffrate_laden;
    }
  }

  setSummaryDates(){
    this.selectedLoadingDate = this.dateHelperService.getDateyyyyMMdd(this.lngInputs.deal_dates.loading_date).substring(0,10);
    this.selectedDischargeDate = this.dateHelperService.getDateyyyyMMdd(this.lngInputs.deal_dates.discharge_date).substring(0,10);
  }

  calculatePhysicalLNG(){
    var inputs = this.getInputsForCalculation();
    this.initialized = true;
    this.riskMetricsService.calculatePhysicalLNG(inputs).pipe(finalize(() =>  {
      this.initialized = false;
    })).subscribe(
      res => {
        this.dealEconomics.cargoDischargedM3 = res.cargo_discharged_sold;
        this.dealEconomics.cargoLoadedM3 = res.cargo_loaded_bought;
        this.dealEconomics.costOfCapitalM3 = res.cost_of_capital;
        this.tradeSummary.netRealisationAmount = res.net_realization;
        this.dealEconomics.shippingCostUsd = res.shipping_cost_excluding_boil_off;

        this.dealEconomics.cargoDischargedMMBtu = res.cost_per_unit_deal_economics.cargo_discharged_sold;
        this.dealEconomics.cargoLoadedMMBtu = res.cost_per_unit_deal_economics.cargo_loaded_bought;
        this.dealEconomics.slotCostPremiumMMBtu = res.cost_per_unit_deal_economics.slot_cost;
        this.dealEconomics.shippingCostPerMMBtu = res.cost_per_unit_deal_economics.shipping_cost_excluding_boil_off;
        this.dealEconomics.costOfCapitalMMBtu = res.cost_per_unit_deal_economics.cost_of_capital;

        this.tradeSummary.netRealisationPerUnit = res.net_realization_unit_cost;

        this.shippingCost.boilOffCostUsd = res.shipping_cost_breakdown.boiloff;
        this.shippingCost.charterCostUsd = res.shipping_cost_breakdown.charter;
        this.shippingCost.portsCanalChargeM3 = res.shipping_cost_breakdown.port_and_canal_cost;
        this.shippingCost.coolDownCostUsd = res.shipping_cost_breakdown.cooldown;
        this.shippingCost.fuelCostUsd = res.shipping_cost_breakdown.fuel;
        this.shippingCost.insuranceM3 = res.shipping_cost_breakdown.insurance;
        this.shippingCost.miscAndBunkerCostUsd = res.shipping_cost_breakdown.misc_and_bunker;

        this.shippingCost.totalShippingCostUsd = res.shipping_cost_breakdown.boiloff
            + res.shipping_cost_breakdown.charter
            + res.shipping_cost_breakdown.port_and_canal_cost
            + res.shipping_cost_breakdown.cooldown
            + res.shipping_cost_breakdown.fuel
            + res.shipping_cost_breakdown.insurance
            + res.shipping_cost_breakdown.misc_and_bunker;

        this.shippingCost.boilOffCostPerMMBtu = res.shipping_cost_per_unit.boiloff;
        this.shippingCost.charterCostPerMMBtu = res.shipping_cost_per_unit.charter;
        this.shippingCost.coolDownCostPerMMBtu = res.shipping_cost_per_unit.cooldown;
        this.shippingCost.fuelCostPerMMBtu = res.shipping_cost_per_unit.fuel;
        this.shippingCost.insuranceMMBtu = res.shipping_cost_per_unit.insurance;
        this.shippingCost.miscAndBunkerCostPerMMBtu = res.shipping_cost_per_unit.misc_and_bunker;
        this.shippingCost.portsCanalChargeMMBtu = res.shipping_cost_per_unit.port_and_canal_cost;

        this.shippingCost.totalShippingCostPerMMBtu = res.shipping_cost_per_unit.boiloff
            + res.shipping_cost_per_unit.charter
            + res.shipping_cost_per_unit.cooldown
            + res.shipping_cost_per_unit.fuel
            + res.shipping_cost_per_unit.insurance
            + res.shipping_cost_per_unit.misc_and_bunker
            + res.shipping_cost_per_unit.port_and_canal_cost;

        this.volumeBalances.dischargeQtyMMBtu = res.volume_balances.discharge_quantity_mmbtu;
        this.volumeBalances.heelBoughtMMBtu = res.volume_balances.heel_bought_mmbtu;
        this.volumeBalances.heelSoldMMBtu = res.volume_balances.heel_sold_mmbtu;
        this.volumeBalances.loadQuantityMMBtu = res.volume_balances.load_quantity_mmbtu;
        this.volumeBalances.dischargeQtyM3 = res.volume_balances.discharge_quantity_m3;
        this.volumeBalances.heelBoughtM3 = res.volume_balances.heel_bought_m3;
        this.volumeBalances.heelSoldM3 = res.volume_balances.heel_sold_m3;
        this.volumeBalances.loadQuantityM3 = res.volume_balances.load_quantity_m3;

        this.rotationTotalFullCharterDays = res.rotation_total_full_charter_days;
        
        this.computeDates.discharge_earliest = (res.dates.discharge_earliest).substring(0,16);
        this.computeDates.discharge_latest = (res.dates.discharge_latest).substring(0,16);
        this.computeDates.loading_earliest = (res.dates.loading_earliest).substring(0,16);
        this.computeDates.loading_latest = (res.dates.loading_latest).substring(0,16);
        this.computeDates.positioning_latest = (res.dates.positioning_latest).substring(0,16);
        this.computeDates.repositioning_earliest = (res.dates.repositioning_earliest).substring(0,16);

        this.saveDataToStore();
      });
  }

  private getInputsForCalculation() {
    var inputs = JSON.parse(JSON.stringify(this.lngInputs));
    var loadingDate = new Date(inputs.deal_dates.loading_date);
    inputs.deal_dates.loading_date = this.dateHelperService.dateToString(loadingDate);
    var dischargeDate = new Date(inputs.deal_dates.discharge_date);
    inputs.deal_dates.discharge_date = this.dateHelperService.dateToString(dischargeDate);
    if (inputs.deal_dates.respositioning_date_input != "") {
      var respositioning_date_input = new Date(inputs.deal_dates.respositioning_date_input);
      inputs.deal_dates.respositioning_date_input = this.dateHelperService.dateToString(respositioning_date_input);      
    } 
    else {
      inputs.deal_dates.respositioning_date_input = ""
    }
    if (inputs.deal_dates.positioning_date != "") {
      var positioning_date = new Date(inputs.deal_dates.positioning_date);
      inputs.deal_dates.positioning_date = this.dateHelperService.dateToString(positioning_date);      
    } 
    else {
      inputs.deal_dates.positioning_date = ""
    }
    
    inputs.vessel_logistics.positioning_bonus = this.positioningBonus == "Yes";
    inputs.vessel_logistics.ballast_repositioning_bonus = this.repositioningBonus == "Yes";
    return inputs;
  }

  downloadPhysicalDeal() {
    var inputs = this.getInputsForCalculation();
    this.initialized = true;
    this.riskMetricsService.getDownloadPhysicalDeal(inputs).pipe(finalize(() =>  {
      this.initialized = false;
    })).subscribe(
      res => {
        this.fileSaverService.save(res.blob, res.fileName)
    });
  }

  deleteTab(){
    this.deleteTheTab.next(this.tabRefName);
    this.localStore.removeData(this.tabRefName);
  }
}
