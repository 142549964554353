export function jsonToCsv(json: any): string {
  const keys = Object.keys(json);
  let csv = keys.join(',').toUpperCase() + '\r\n';

  for (let i = 0; i < json[keys[0]].length; i++) {
    let tuple = [];
    for (let j = 0; j < keys.length; j++) {
      tuple.push(json[keys[j]][i]);
    }
    csv += tuple.join(',') + '\r\n';
  }

  return csv;
}
