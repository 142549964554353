<h3>
  FX
</h3>
<div class="em-l-grid">
  <div class="em-l-grid__item">
    <div>
      <mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
        <mat-label>As of date</mat-label>
        <input matInput type="date" [(ngModel)]="asOfDate" (ngModelChange)="fetchChartData()"
               [min]="minAsOfDate" [max]="maxAsOfDate">
      </mat-form-field>
      <button class="em-c-btn em-c-btn--small em-c-btn--tertiary" (click)="saveFxData()" matTooltip="Export data to CSV">
        <div class="em-c-btn__inner">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#save-disk"></use>
          </svg>
        </div>
      </button>
    </div>
  </div>
  <div class="em-l-grid__item">

    <canvas baseChart
            [data]="fxChartData"
            [options]="lineChartOptions"
            type="line">
    </canvas>
  </div>
</div>
