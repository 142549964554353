import { AfterViewInit, Component, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GasConvertPrices } from './gas-converter.model';
import { UnitConverterService } from '../../services/unit-converter.service';
import { DateHelperService } from '../../services/date-helper.service';
import { RiskMetricsService } from '../../services/risk-metrics.service';

@Component({
    selector: 'gas-converter',
    templateUrl: './gas-converter.component.html',
    styleUrls: ['./gas-converter.component.scss']
  })

export class GasConverterComponent implements OnInit, AfterViewInit {

  refreshPrice2MinusP1: boolean = false;

  usd2euro = 0.9702;
  usd2gbp = 0.8503;
  euro2gbp = 0.8764;
  euro2usd = this.unitConverterService.ToNumber(1/this.usd2euro);
  gbp2euro = this.unitConverterService.ToNumber(1/this.euro2gbp);
  gbp2usd = this.unitConverterService.ToNumber(1/this.usd2gbp);

  p1row1 = new GasConvertPrices(97, 'EUR', 'MWh'); 
  p2row1 = new GasConvertPrices(66.1900, 'EUR', 'MWh'); 
  p3row1 = new GasConvertPrices(66.19-97, 'EUR', 'MWh');
  
  currencies = [];
  refreshNow = false;
  calcCurrentRow = 0;
  dateHorizon: string;
  nextWeekdayStr: string;
  dateLeftMargin = 0;

  minAsOfDate: string;
  maxAsOfDate: string;
  asOfDate: string;

  isCalculating: boolean;
  fxCurveData:{ "fx_eur": {"dates":["2018-01-01", "2018-02-01"], "eur_futures":[], "eur_historical":[]}, 
  "fx_gbp":{"gbp_futures":[], "gbp_historical":[]} };

  constructor(private riskMetricsService: RiskMetricsService, private dateHelperService: DateHelperService,private titleService: Title,private unitConverterService: UnitConverterService) {
      this.titleService.setTitle('Trading Preview UI | Unit Converter');
    }

  ngOnInit(): void {
    this.getMacroCurveDates();
  }

  ngAfterViewInit() {
    var $this = this;
    setTimeout(function(){
      $this.dateLeftMargin = $this.getDateLeftMargin;
    }, 10)
    
  }
  
  getMacroCurveDates(): void {
    this.isCalculating = true;
    this.riskMetricsService.getMacroCurvesFXDates().subscribe(res => {
      this.minAsOfDate = res[0].substring(0, 7);
      this.maxAsOfDate = res[res.length - 1].substring(0, 7);
      this.asOfDate = this.maxAsOfDate.substring(0, 7);

      this.fetchFXData();
    }, err => {
      const lastWeekday = this.dateHelperService.getLastWeekday(new Date()).toLocaleDateString('en-CA').substring(0, 7);
      this.asOfDate = lastWeekday;
      this.minAsOfDate = lastWeekday;
      this.maxAsOfDate = lastWeekday;
      this.fetchFXData();
    });
  }

  fetchFXData(): void {
    var apiDataDate = this.dateHelperService.getLastWeekday(new Date()).toLocaleDateString('en-CA');
    this.isCalculating = true;
    this.riskMetricsService.getMacroCurvesFX(apiDataDate).subscribe(res => { 
      this.isCalculating = false;
      this.fxCurveData = res;
      this.monthSelectChange();
    });
  }

  monthSelectChange(): void {
    if(this.fxCurveData.fx_eur?.dates.length > 0){
      this.minAsOfDate = this.fxCurveData.fx_eur?.dates[0].substring(0, 7);
      this.maxAsOfDate = this.fxCurveData.fx_eur?.dates[this.fxCurveData.fx_eur?.dates.length - 1].substring(0, 7);

      var dateIdx = -1;
      var selectedMonth = this.asOfDate + "-01";
      this.fxCurveData.fx_eur?.dates.forEach((element, idx) => {
        if(element == selectedMonth){
          dateIdx = idx;
        }
      });
      if(dateIdx > -1){
        var today = new Date();
        var selectedDate = new Date(selectedMonth);
        if(selectedDate < today){
          this.euro2usd = this.ToNumber(this.fxCurveData.fx_eur?.eur_historical[dateIdx]);
          this.gbp2usd = this.ToNumber(this.fxCurveData.fx_gbp?.gbp_historical[dateIdx]);
        } else {
          this.euro2usd = this.ToNumber(this.fxCurveData.fx_eur?.eur_futures[dateIdx]);
          this.gbp2usd = this.ToNumber(this.fxCurveData.fx_gbp?.gbp_futures[dateIdx]);
        }
        this.usd2euro = this.ToNumber(1/this.euro2usd);
        this.usd2gbp=this.ToNumber(1/this.gbp2usd);
        this.usd2euroChange();
      }
    }
  }
  
  public get getDateLeftMargin() {
    var lm = (this.gasHeaderDiv.nativeElement.offsetWidth - 1140) / 2;
    lm = lm < 0 ? 0 : lm;
    return lm; 
  }

  onResize(event){
    this.dateLeftMargin = this.getDateLeftMargin;
  }

  ToNumber(val: number): number {
    if(isNaN(val)){
      return 0;
    }
    return parseFloat(val.toFixed(4));
  }

  childPriceToParent(){
    this.p3row1.price = this.unitConverterService.ToNumber(this.p1row1.price - this.p2row1.price);
    this.refreshPrice2MinusP1 = !this.refreshPrice2MinusP1;
  }

  euro2usdChange(): void{
    this.usd2euro=this.unitConverterService.ToNumber(1/this.euro2usd);
    this.eurogbpCalc();
    this.OnCurrencyEURChange();
  }

  gbp2usdChange(): void{
    this.usd2gbp=this.unitConverterService.ToNumber(1/this.gbp2usd);
    this.eurogbpCalc();
    this.OnCurrencyGBPChange();
  }

  usd2euroChange(): void{
    this.euro2usd=this.unitConverterService.ToNumber(1/this.usd2euro);
    this.eurogbpCalc();
    this.OnCurrencyUSDChange();
  }
  
  eurogbpCalc(): void{
    this.euro2gbp=this.unitConverterService.ToNumber(this.euro2usd/this.gbp2usd);
    this.gbp2euro=this.unitConverterService.ToNumber(this.gbp2usd/this.euro2usd);
  }
  
  OnCurrencyEURChange(): void {
    this.unitConverterService.currencyConvert = 
    [
      {'EUR': 1},      {'GBP' : this.euro2gbp},
      {'p' : this.euro2gbp * 100},
      {'USD' : this.euro2usd}
    ];
    this.calcCurrentRow = 1;
    this.refreshNow = !this.refreshNow;
  }

  OnCurrencyGBPChange(): void {
    this.unitConverterService.currencyConvert = 
    [
      {'EUR': this.gbp2euro},
      {'GBP' : 1},
      {'p' : 100},
      {'USD' : this.gbp2usd}
    ];
    this.calcCurrentRow = 2
    this.refreshNow = !this.refreshNow;
  }

  OnCurrencyUSDChange(): void {
    this.unitConverterService.currencyConvert = 
    [
      {'EUR': this.usd2euro},
      {'GBP' : this.usd2gbp},
      {'p' : this.usd2gbp * 100},
      {'USD' : 1}
    ];
    this.calcCurrentRow = 3;
    this.refreshNow = !this.refreshNow;
  }

  @ViewChild('gasHeaderDiv')
  gasHeaderDiv: ElementRef;
}