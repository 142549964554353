<!-- <h5> Deal Type: DES-DES </h5> -->
<div class="em-c-loader spinner" *ngIf="initialized">
    <img src="unity/images/icon-spinner.svg" />
  </div>
<div class="em-c-table-object__header">
    <div class="em-c-toolbar ">
      <div class="em-c-toolbar__item">
        <div class="em-c-btn-group ">
            <!-- "Calculate" button -->
            <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="calculatePhysicalLNGDesdes()"
                    matTooltip="Calculate" matTooltipPosition="right">
                <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#refresh"></use>
                </svg>
            </button>
            <!-- "Download Excel" button -->
            <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="downloadPhysicalDesDesDeal()"
                    matTooltip="Download as Excel" matTooltipPosition="right">
                <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#save-disk"></use>
                </svg>
            </button>
            <!-- "Delete Tab" button -->
            <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="deleteTab()"
                    matTooltip="Delete" matTooltipPosition="right">
                <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#trashcan"></use>
                </svg>
            </button>
        </div>
      </div>

    </div>
  </div>
  
<div class="em-l-grid em-l-grid--1up">
    <div class="flex-container">
        <!-- OUTPUT Sections -->
        <div class="em-l-grid__item flex-child em-physical-lng-desdes-class">
            <!-- Trade Summary -->
            <div class="em-l-grid__item em-l-bgblackwhite em-l-setheight">
                <div class="">
                    Trade Summary
                </div>
            </div>

            <div class="em-l-grid__item">
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Seller</mat-label>
                            <input matInput type="text" [(ngModel)]="selectedSeller">
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Buyer</mat-label>
                            <input matInput type="text" [(ngModel)]="selectedBuyer">
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="em-l-grid__item">
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Discharge at</mat-label>
                            <input matInput type="text" [ngModel]="lngInputsDesdes.discharge_port" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" value="DES / DES" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="date" [(ngModel)]="selectedDischargeDate" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Vessel Type</mat-label>
                            <input matInput type="text" [(ngModel)]="lngInputsDesdes.vessel_type" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label><b>Net Realisation ($/MMBtu)</b></mat-label>
                            <b><input matInput type="text" [ngModel]="tradeSummaryDesdes.netRealisationPerUnit | currency" readonly></b>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label><b>Net Realisation</b></mat-label>
                            <b><input matInput type="text" [ngModel]="tradeSummaryDesdes.netRealisationAmount | currency" readonly></b>
                        </mat-form-field>
                    </div>
                </div>
            </div>


            <!--Deal Economics Breakdown-->
            <div class="em-l-grid__item">
                <div class="em-l-bgblackwhite">
                    Deal Economics Breakdown
                </div>
            </div> 
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>Terminal Cost</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomicsBreakdownDesdes.terminalCostUsd | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>	
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                    </div>
                    
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>Cargo Loaded/Bought</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomicsBreakdownDesdes.cargoLoadedUsd | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>	
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>Cargo Discharged/Sold</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomicsBreakdownDesdes.cargoDischargedUsd | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <!-- INPUT Sections -->
        <div class="em-l-grid__item flex-child em-physical-lng-desdes-class" *ngIf="visibleIn">
            
            <!-- PURCHASE Economics -->
            <div class="em-l-grid__item">
                <div class="em-l-bgblackwhite">
                    PURCHASE Economics
                </div>
            </div>
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Purchase/DES Price</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>$/ MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="lngInputsDesdes.prch_lng_price">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!--2.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Miscellaneous Costs</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="lngInputsDesdes.prch_misc_cost">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!--3.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Purchase Volume</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="lngInputsDesdes.prch_volume_mmbtu">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!--4.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Tolerance</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>%</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="lngInputsDesdes.prch_tolerance_percent">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            
            <!-- SALE Economics -->
            <div class="em-l-grid__item">
                <div class="em-l-bgblackwhite">
                    SALE Economics
                </div>
            </div>
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Sale/DES Price</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>$/ MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="lngInputsDesdes.sals_lng_price">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!--2.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Miscellaneous Costs</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="lngInputsDesdes.sals_misc_cost">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!--3.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Sale Volume</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="lngInputsDesdes.sals_volume_mmbtu">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!--4.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Tolerance</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>%</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="lngInputsDesdes.sals_tolerance_percent">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            
            <div class="em-l-grid__item">
                <!-- Route / Dates / Ship Size -->
                <div class="em-l-bgblackwhite">
                    Route / Dates / Ship Size
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <!-- Load Port -->
                    <div class="em-l-grid__item">
                        <mat-label>Load Port</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>                            
                            <mat-select [(ngModel)]="lngInputsDesdes.load_port">
                                <mat-option value=""></mat-option>
                                <mat-option *ngFor="let portname of apiPorts" [value]="portname">{{portname}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- Discharge Port -->
                    <div class="em-l-grid__item">
                        <mat-label>Discharge Port</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputsDesdes.discharge_port">
                                <mat-option value=""></mat-option>
                                <mat-option *ngFor="let portname of apiPorts" [value]="portname">{{portname}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- Discharge Date -->
                    <div class="em-l-grid__item">
                        <mat-label>Discharge Date</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput (change)="setSummaryDates()" type="datetime-local" [(ngModel)]="lngInputsDesdes.discharge_date">
                        </mat-form-field>
                    </div>
                    <!-- Vessel Size  -->
                    <div class="em-l-grid__item">
                        <mat-label>Vessel Type (to determine port cost)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputsDesdes.vessel_type" (ngModelChange)="onVesselTypeSelect()">
                                <mat-option *ngFor="let vesselData of apiVesselTypes" [value]="vesselData">{{vesselData}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- Include Port Cost? -->
                    <div class="em-l-grid__item">
                        <mat-label>Include Port Cost?</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="includeDischargePortCost">
                                <mat-option value="No">No</mat-option>
                                <mat-option value="Yes">Yes</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                
            </div>
        </div>
    </div>
</div>
