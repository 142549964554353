<div class="em-l em-l--two-column-fixed">
  <div class="em-l__secondary">
    <app-header></app-header>
  </div>
  <div class="em-l__main">
    <div class=" em-c-alert em-c-alert--warning" role="alert" *ngIf="showStagingWarning">
      <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#warning"></use>
      </svg>
      <div class="em-c-alert__body">
        This is the staging version of MERA. If you intended to access the production version, please navigate to
        <a href="https://goto/MERA">goto/MERA</a>.
      </div>
      <div class="em-c-alert__actions">
        <button class="em-c-text-btn em-c-alert--warning" (click)="showStagingWarning = false">
          Close
        </button>
      </div>
    </div>

    <div class=" em-c-alert em-c-alert--warning" role="alert" *ngIf="showOCP4Warning">
      <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#warning"></use>
      </svg>
      <div class="em-c-alert__body">
        After Feb 24th 2023 this version of MERA will be deprecated. Please access <a href="https://goto/MERA">goto/MERA</a> to use the current version of the application.
      </div>
      <div class="em-c-alert__actions">
        <button class="em-c-text-btn em-c-alert--warning" (click)="showOCP4Warning = false">
          Close
        </button>
      </div>
    </div>

    <main role="main" class="em-u-margin-top-double">
      <div class="em-l-container main">
        <router-outlet></router-outlet>
      </div>
    </main>

    <app-footer></app-footer>
  </div>
</div>
