<!-- <h5> Deal Type: TCO </h5> -->
<div class="em-c-loader spinner" *ngIf="initialized">
    <img src="unity/images/icon-spinner.svg" />
  </div>
<div class="em-c-table-object__header">
    <div class="em-c-toolbar ">
      <div class="em-c-toolbar__item">
        <div class="em-c-btn-group ">
            <!-- "Calculate" button -->
            <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="calculatePhysicalLNGTco()"
                    matTooltip="Calculate" matTooltipPosition="right">
                <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#refresh"></use>
                </svg>
            </button>
            <!-- "Download Excel" button -->
            <button class="em-c-btn em-c-btn--secondary em-c-btn--small"  (click)="downloadPhysicalTcoDeal()"
                    matTooltip="Download as Excel" matTooltipPosition="right">
                <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#save-disk"></use>
                </svg>
            </button>
            <!-- "Delete Tab" button -->
            <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="deleteTab()"
                    matTooltip="Delete" matTooltipPosition="right">
                <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#trashcan"></use>
                </svg>
            </button>

        </div>
      </div>

    </div>
  </div>
  
<div class="em-l-grid em-l-grid--1up">
    <div class="flex-container">
        <!-- OUTPUT Sections -->
        <div class="em-l-grid__item flex-child em-physical-lng-tco-class">
            <!-- Trade Summary -->
            <div class="em-l-grid__item em-l-bgblackwhite em-l-setheight">
                <div class="">
                    Trade Summary
                </div>
            </div>

            <div class="em-l-grid__item">
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Owner</mat-label>
                            <input matInput type="text" [(ngModel)]="selectedSeller">
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Charterer</mat-label>
                            <input matInput type="text" [(ngModel)]="selectedBuyer">
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Charter-Out Dates</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Starts On</mat-label>
                            <input matInput type="date" [(ngModel)]="charterOutBegDate" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Ends On</mat-label>
                            <input matInput type="date" [(ngModel)]="charterOutEndDate" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Net Realisation</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Net Realisation</mat-label>
                            <b><input matInput type="text" [ngModel]="dealEconomicsTco.net_realization | currency" readonly></b>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <!--VOLUME BALANCES-->
            <div class="em-l-grid__item">
                <div class="em-l-bgblackwhite">
                    Volume Balances
                </div>
            </div>

            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <!-- <mat-label>Volume Balances (MMBtu)</mat-label> -->
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>M³</mat-label>
                    </div>
                </div>
            </div>
            <!--1.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Heel On-Board at Start of Charter-Out</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="volumeBalancesTco.heel_onboard_at_charter_out_beg__mmbtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <!-- <input matInput type="text" [ngModel]="volumeBalances.heelBoughtM3 | number : '1.1-1'" readonly> -->
                            <input matInput type="text" [ngModel]="volumeBalancesTco.heel_onboard_at_charter_out_beg__m3 | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!--2.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Heel On-Board at End of Charter-Out</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="volumeBalancesTco.heel_onboard_at_charter_out_end__mmbtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="volumeBalancesTco.heel_onboard_at_charter_out_end__m3 | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <!--Deal Economics Breakdown-->
            <div class="em-l-grid__item">
                <div class="em-l-bgblackwhite">
                    Deal Economics Breakdown
                </div>
            </div>

            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Charter-in Cost</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomicsTco.charter_in_cost | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>Charter-Out Revenue</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomicsTco.charter_out_revenue | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Charter-in Fuel Cost</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomicsTco.charter_in_fuel_cost | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>Charter-Out Idle Boil-Off & Fuel (incl. TCI)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomicsTco.charter_out_idle_boiloff_fuel_cost | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Charter-in Other Costs</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomicsTco.charter_in_other_cost | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>Charter-Out Other Costs (still incurred by Exxon as owner)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomicsTco.charter_out_other_cost | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>Owner's Ballast Bonus & Misc Revenue</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomicsTco.owner_ballast_bonus_and_misc_revenue | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Heel Arb</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomicsTco.heel_arb | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                </div>
            </div>

            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Net Charter Cost/Revenue Uplift</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomicsTco.net_charter_cost_revenue_uplift | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                </div>
            </div>

            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Net Boil-Off/Fuel Cost/Revenue Uplift</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomicsTco.net_boiloff_fuel_cost_revenue_uplift | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                </div>
            </div>

            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Net Savings & Misc Cost/Revenue Uplift</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomicsTco.net_savings_misc_cost_revenue_uplift | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                </div>
            </div>

        </div>

        <!-- INPUT Sections -->
        <div class="em-l-grid__item flex-child em-physical-lng-tco-class" *ngIf="visibleIn">
            <!--LNG PRICES-->
            <div>
                <div class="em-l-grid__item">
                    <div class="em-l-bgblackwhite">
                        LNG Prices
                    </div>
                </div>
                <div class="em-l-grid__item em-al-right">
                    <div class="em-l-grid em-l-grid--4up">
                        <div class="em-l-grid__item">
                            <mat-label>Sale/DES Price</mat-label>
                        </div>
                        <div class="em-l-grid__item">
                            <mat-label>$/ MMBtu</mat-label>
                        </div>
                        <div class="em-l-grid__item">
                            <mat-form-field>
                                <input matInput type="number" [(ngModel)]="lngInputsTco.lng_prices.sale_des_price">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="em-l-grid__item em-al-right">
                    <div class="em-l-grid em-l-grid--4up">
                        <div class="em-l-grid__item">
                            <mat-label>Heel Purchase Price</mat-label>
                        </div>
                        <div class="em-l-grid__item">
                            <mat-label>$/ MMBtu</mat-label>
                        </div>
                        <div class="em-l-grid__item">
                            <mat-form-field>
                                <input matInput type="number" [(ngModel)]="lngInputsTco.lng_prices.heel_purchase_price">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="em-l-grid__item em-al-right">
                    <div class="em-l-grid em-l-grid--4up">
                        <div class="em-l-grid__item">
                            <mat-label>Heel Sale Price</mat-label>
                        </div>
                        <div class="em-l-grid__item">
                            <mat-label>$/ MMBtu</mat-label>
                        </div>
                        <div class="em-l-grid__item">
                            <mat-form-field>
                                <input matInput type="number" [(ngModel)]="lngInputsTco.lng_prices.heel_sale_price">
                            </mat-form-field>
                        </div>
                    </div>
                </div>    
            </div>

            <!--FUEL PRICES-->
            <div>
                <div class="em-l-grid__item">
                    <div class="em-l-bgblackwhite">
                        Fuel Prices
                    </div>
                </div>
                <!-- HFO Price -->
                <div class="em-l-grid__item em-al-right">
                    <div class="em-l-grid em-l-grid--4up">
                        <div class="em-l-grid__item">
                            <mat-label>HFO Price</mat-label>
                        </div>
                        <div class="em-l-grid__item">
                            <mat-label>$/ t</mat-label>
                        </div>
                        <div class="em-l-grid__item">
                            <mat-form-field>
                                <input matInput type="number" [(ngModel)]="lngInputsTco.fuel_prices.hfo">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <!-- MGO Price -->
                <div class="em-l-grid__item em-al-right">
                    <div class="em-l-grid em-l-grid--4up">
                        <div class="em-l-grid__item">
                            <mat-label>MGO Price</mat-label>
                        </div>
                        <div class="em-l-grid__item">
                            <mat-label>$/ t</mat-label>
                        </div>
                        <div class="em-l-grid__item">
                            <mat-form-field>
                                <input matInput type="number" [(ngModel)]="lngInputsTco.fuel_prices.mdo">
                            </mat-form-field>
                        </div>
                    </div>
                </div>    
            </div>

            <!-- Vessel -->
            <div>
                <div class="em-l-bgblackwhite">
                    Vessel
                </div>
                <div class="em-l-grid__item">
                    <mat-form-field>
                        <mat-label>Vessel</mat-label>
                        <mat-select [(ngModel)]="lngInputsTco.vessel_name" (ngModelChange)="onVesselNameSelect()">
                            <mat-option *ngFor="let vesselData of apiVesselTypes" [value]="vesselData.vessel_name">{{vesselData.vessel_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Size (100%)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">m³</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input class="em-al-right" disabled type="number" [(ngModel)]="vesselSize">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Ship Type</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right"></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input class="em-al-right" disabled type="text" [(ngModel)]="vesselType">
                    </div>
                </div>    
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">In-Charter Rate</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right"></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input class="em-al-right" disabled type="text" [(ngModel)]="vesselInCharterRate">
                    </div>
                </div>    
            </div>

            <!-- Charter-In Dates & Price -->
            <div>
                <div class="em-l-bgblackwhite">
                    <b>Charter-In</b> Dates & Price
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label>Start of Charter-In Period</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="datetime-local" [(ngModel)]="lngInputsTco.charter_in_dates_prices_costs.charter_in_beg_date">
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label>End of Charter-In Period</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="datetime-local" [(ngModel)]="lngInputsTco.charter_in_dates_prices_costs.charter_in_end_date">
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label>TOTAL FULL CHARTER DAYS</mat-label>
                    </div>
                    <div class="em-l-grid__item em-al-right">
                        <mat-label>days</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input class="em-al-right" disabled type="number" [(ngModel)]="charterInTotalFullDays">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Charter-In Rate</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$/day</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input class="em-al-right" type="number" [(ngModel)]="lngInputsTco.charter_in_dates_prices_costs.charter_in_rate">
                    </div>
                </div>    
            </div>

            <!-- Charter-In Other Costs -->
            <div>
                <div class="em-l-bgblackwhite">
                    Charter-In Other Costs
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Cool-Down Charge</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.charter_in_dates_prices_costs.charter_in_cooldown_cost">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Cargo Insurance</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.charter_in_dates_prices_costs.charter_in_cargo_insurance_cost">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Other Insurance</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.charter_in_dates_prices_costs.charter_in_other_insurance_cost">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Miscellaneous Cost</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.charter_in_dates_prices_costs.charter_in_misc_cost">
                    </div>
                </div>    
            </div>

            <!-- Charter-Out Dates & Price -->
            <div>
                <div class="em-l-bgblackwhite">
                    <b>Charter-Out</b> Dates & Price
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label>Start of Charter-Out Period</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="datetime-local" [(ngModel)]="lngInputsTco.charter_out_dates_prices_costs.charter_out_beg_date">
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label>End of Charter-Out Period</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="datetime-local" [(ngModel)]="lngInputsTco.charter_out_dates_prices_costs.charter_out_end_date">
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label>TOTAL FULL CHARTER DAYS</mat-label>
                    </div>
                    <div class="em-l-grid__item em-al-right">
                        <mat-label>days</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input class="em-al-right" disabled type="number" [(ngModel)]="charterOutTotalFullDays">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label>or Charter Period Override</mat-label>
                    </div>
                    <div class="em-l-grid__item em-al-right">
                        <mat-label>days</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input class="em-al-right" type="text" [(ngModel)]="lngInputsTco.charter_out_dates_prices_costs.charter_out_total_full_days_override">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label>Charter-Out Rate</mat-label>
                    </div>
                    <div class="em-l-grid__item em-al-right">
                        <mat-label>$/day</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input class="em-al-right" type="text" [(ngModel)]="lngInputsTco.charter_out_dates_prices_costs.charter_out_rate">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label>Miscellaneous Revenue</mat-label>
                    </div>
                    <div class="em-l-grid__item em-al-right">
                        <mat-label>$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input class="em-al-right" type="text" [(ngModel)]="lngInputsTco.charter_out_dates_prices_costs.charter_out_misc_revenue">
                    </div>
                </div>
            </div>

            <!-- Charter-Out Other Costs -->
            <div>
                <div class="em-l-bgblackwhite">
                    Charter-Out Other Costs
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Cool-Down Charge</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.charter_out_dates_prices_costs.charter_out_cooldown_cost">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Cargo Insurance</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.charter_out_dates_prices_costs.charter_out_cargo_insurance_cost">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Other Insurance</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.charter_out_dates_prices_costs.charter_out_other_insurance_cost">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Miscellaneous Cost</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.charter_out_dates_prices_costs.charter_out_misc_cost">
                    </div>
                </div>    
            </div>

            <!-- Logistics -->
            <div>
                <div class="em-l-bgblackwhite">
                    Logistics
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label>Heel Onboard at Start of Charter-Out</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">m³</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.logistics.heel_onboard_at_charter_out_beg">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label>Heel Onboard at End of Charter-Out (Exxon's Option)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">m³</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.logistics.heel_onboard_at_charter_out_end">
                    </div>
                </div>            
            </div>

            <!-- Boil-Off -->
            <div>
                <div class="em-l-grid__item">
                    Boil-Off:
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">BOR Idle</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">From Ship Data (%/day)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input disabled class="em-al-right" type="text" [ngModel]="vesselBorIdle | number : '1.1-3'">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">OVERRIDE BOR Idle</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">(%/day)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="number" [(ngModel)]="lngInputsTco.logistics.bor_idle_overriden"
                        (ngModelChange)="lngInputsTco.logistics.borIdleOverridenValueChanged($event)">
                    </div>
                </div>
            </div>

            <!-- Include Bunker for Reliq -->
            <div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label><b>Include Bunker for Reliq?</b></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputsTco.logistics.include_bunker_operation_for_reliq">
                                <mat-option value="No">No</mat-option>
                                <mat-option value="Yeosu">Yeosu</mat-option>
                                <mat-option value="HongKong">HongKong</mat-option>
                                <mat-option value="Singapore">Singapore</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="em-l-grid__item">
                        <mat-label>Fixed Costs / Barges, etc</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.logistics.fixed_costs_barges_etc">
                    </div>

                    <div class="em-l-grid__item">
                        <mat-label>Days at anchorage for bunkering</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Days</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.logistics.days_at_anchorage_for_bunker">
                    </div>
                </div>    
            </div>

            <!--Positioning Bonus-->
            <div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label><b>Positioning Bonus</b></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="doPositioningBonus">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Charter Rate</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">%</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.logistics.positioning_bonus_details.charter_rate_display">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Fuel Rate</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">%</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.logistics.positioning_bonus_details.fuel_rate_display">
                    </div>
                </div>		
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Fuel Agreed (LNG or HFO)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right"></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputsTco.logistics.positioning_bonus_details.fuel_type_agreed">
                                <mat-option value="LNG">LNG</mat-option>
                                <mat-option value="HFO">HFO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Speed</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">knots</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputsTco.logistics.positioning_bonus_details.speed">
                                <mat-option *ngFor="let vessel_speed of apiVesselSpeeds" [value]="vessel_speed">{{vessel_speed}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">or Lump-Sum</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">US$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.logistics.positioning_bonus_details.lumpsum">
                    </div>
                </div>    
            </div>

            <!--Ballast (Repositioning) Bonus-->
            <div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label><b>Ballast (Repositioning) Bonus</b></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="doRepositioningBonus">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Charter Rate</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">%</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.logistics.repositioning_bonus_details.charter_rate_display">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Fuel Rate</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">%</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.logistics.repositioning_bonus_details.fuel_rate_display">
                    </div>
                </div>		
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Fuel Agreed (LNG or HFO)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right"></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputsTco.logistics.repositioning_bonus_details.fuel_type_agreed">
                                <mat-option value="LNG">LNG</mat-option>
                                <mat-option value="HFO">HFO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Speed</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">knots</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputsTco.logistics.repositioning_bonus_details.speed">
                                <mat-option *ngFor="let vessel_speed of apiVesselSpeeds" [value]="vessel_speed">{{vessel_speed}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">or Lump-Sum</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">US$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputsTco.logistics.repositioning_bonus_details.lumpsum">
                    </div>
                </div>    
            </div>

        </div>
    </div>
</div>
