<h3>Long-Term Price Paths</h3>
<div class="em-c-loader spinner" *ngIf="!initialized">
  <img src="unity/images/icon-spinner.svg" />
</div>

<div class="price-paths-inputs em-u-padding em-u-margin-bottom-double">
  <div style="display: flex" id="price-path-filters">
    <div class="em-l-grid__item">
      <mat-form-field>
        <mat-label>As of Date</mat-label>
        <input
          matInput
          type="date"
          [(ngModel)]="asOfDate"
          (change)="tradeDateChange()"
        />
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field
        class="em-u-padding-top-none em-u-padding-right medium-field"
      >
        <mat-label>End month</mat-label>
        <input
          matInput
          type="month"
          [(ngModel)]="endMonth"
          [min]="minAsOfDate"
        />
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field
        class="em-u-padding-top-none em-u-padding-right medium-field"
      >
        <mat-label>Long Term Curve</mat-label>
        <mat-select [(ngModel)]="selectedCPYear">
          <mat-option value="CP23">CP23</mat-option>
          <mat-option value="CUSTOM">CUSTOM</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="em-l-grid__item">
      <mat-form-field
        class="em-u-padding-top-none em-u-padding-right medium-field"
      >
        <mat-label>Select Hubs</mat-label>
        <mat-select
          multiple
          [(ngModel)]="selectedHubs"
          (ngModelChange)="filterCurvesByCategory(displayedCategory)"
        >
          <mat-option *ngFor="let hub of hubs" [value]="hub">{{
            hub
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div
    *ngIf="selectedCPYear === 'CUSTOM'"
    style="display: flex"
    class="em-u-margin-bottom"
  >
    <div class="em-l-grid__item">
      <mat-label>Upload Long Term Curve</mat-label>
      <input
        type="file"
        (change)="uploadLongCurve($event)"
        placeholder="Upload file"
        accept=".csv, .xlsx"
      />
    </div>
  </div>

  <mat-expansion-panel class="em-u-margin-bottom-double">
    <mat-expansion-panel-header>
      <mat-panel-title>Advanced settings</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="advanced-settings-wrapper">
      <div class="em-c-loader spinner" *ngIf="!transitionDefaultsFetched">
        <img src="unity/images/icon-spinner.svg" />
      </div>
      <ng-container *ngFor="let hub of selectedHubs">
        <div style="display: flex" class="advanced-settings-row">
          <div class="em-l-grid__item" style="width: 10rem">
            <h6>{{ hub }}</h6>
          </div>
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Transition start</mat-label>
              <input
                matInput
                [disabled]="!transitionDefaultsFetched"
                [(ngModel)]="transitionInfo[hub].from_date"
                type="date"
              />
            </mat-form-field>
          </div>
          <div class="em-l-grid__item">
            <mat-form-field>
              <mat-label>Transition length (months)</mat-label>
              <input
                matInput
                [disabled]="!transitionDefaultsFetched"
                [(ngModel)]="transitionInfo[hub].length_in_months"
                type="number"
              />
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </div>

    <mat-checkbox
      class="em-u-margin-half em-u-margin-bottom"
      color="primary"
      [(ngModel)]="useVariableHalfLife"
    >
      Use variable half-life
    </mat-checkbox>
  </mat-expansion-panel>

  <div class="em-c-btn-group em-u-margin-top justify-flex-end">
    <button
      class="em-c-btn em-c-btn--secondary em-c-btn--small left-button"
      (click)="exportToExcel()"
    >
      Export price paths
    </button>
    <button
      class="em-c-btn em-c-btn--secondary em-c-btn--small right-button"
      [matMenuTriggerFor]="menu"
      aria-label="Menu for export options"
    >
      <svg
        class="em-c-icon em-c-icon--small em-c-btn__icon"
        data-em-icon-path="unity/images/em-icons.svg"
      >
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="unity/images/em-icons.svg#triangle-down-filled"
        ></use>
      </svg>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <div
        class="menu-header em-u-margin-top-half em-u-margin-left"
        (click)="$event.stopPropagation()"
      >
        Transposition
      </div>
      <p
        mat-menu-item
        disableRipple
        class="em-u-margin-none heightless no-hover"
      >
        <mat-radio-group
          [(ngModel)]="exportTransposed"
          (click)="$event.stopPropagation()"
          aria-label="Select an option"
          class="radio-vertical"
        >
          <mat-radio-button [value]="false" color="primary"
            >Path IDs along row</mat-radio-button
          >
          <mat-radio-button [value]="true" color="primary"
            >Path IDs along column</mat-radio-button
          >
        </mat-radio-group>
      </p>
      <mat-divider class="em-u-margin-half em-u-margin-bottom"></mat-divider>
      <div
        class="menu-header em-u-margin-top-half em-u-margin-left"
        (click)="$event.stopPropagation()"
      >
        Granularity
      </div>
      <p
        mat-menu-item
        disableRipple
        class="em-u-margin-none heightless no-hover"
      >
        <mat-radio-group
          [(ngModel)]="granularity"
          (click)="$event.stopPropagation()"
          aria-label="Select an option"
          class="radio-vertical"
        >
          <mat-radio-button value="monthly" color="primary"
            >Monthly</mat-radio-button
          >
          <mat-radio-button value="yearly" color="primary"
            >Yearly</mat-radio-button
          >
        </mat-radio-group>
      </p>
    </mat-menu>
    <button
      class="em-c-btn em-c-btn--primary em-c-btn--small"
      (click)="getPricePathsData()"
    >
      Plot price paths
    </button>
  </div>
</div>

<div class="em-c-table-object__header prices-plot em-u-margin-top-double">
  <div class="em-c-toolbar">
    <div class="em-c-toolbar__item" *ngIf="dataColumns?.length > 0">
      <button
        class="em-c-btn em-c-btn--secondary em-c-btn--small"
        [matMenuTriggerFor]="displayOptionsMenu"
        aria-label="Menu for export options"
      >
        Filter plotted paths
      </button>
      <mat-menu #displayOptionsMenu="matMenu">
        <mat-radio-group
          [(ngModel)]="displayedCategory"
          (ngModelChange)="filterCurvesByCategory($event)"
          (click)="$event.stopPropagation()"
          aria-label="Select an option"
          class="radio-vertical"
        >
          <p mat-menu-item [matMenuTriggerFor]="indexMenu" disableRipple>
            <mat-radio-button value="custom">Custom select</mat-radio-button>
          </p>
          <p mat-menu-item disableRipple>
            <mat-radio-button value="all">All</mat-radio-button>
          </p>
          <p mat-menu-item disableRipple>
            <mat-radio-button value="futures">Futures</mat-radio-button>
          </p>
          <p mat-menu-item disableRipple>
            <mat-radio-button value="long">Long term</mat-radio-button>
          </p>
          <p mat-menu-item disableRipple>
            <mat-radio-button value="mean">Mean</mat-radio-button>
          </p>
          <p mat-menu-item disableRipple>
            <mat-radio-button value="p">P10, P50, P90</mat-radio-button>
          </p>
          <p mat-menu-item disableRipple>
            <mat-radio-button value="Sample 1">Sample 1</mat-radio-button>
          </p>
          <p mat-menu-item disableRipple>
            <mat-radio-button value="Sample 2">Sample 2</mat-radio-button>
          </p>
          <mat-menu #indexMenu="matMenu">
            <ng-container *ngFor="let hub of selectedHubs">
              <button mat-menu-item [matMenuTriggerFor]="subMenu">
                {{ hub }}
              </button>
              <mat-menu #subMenu="matMenu">
                <ng-container *ngFor="let curve of getHubsCurves(hub)">
                  <p mat-menu-item class="em-u-margin-none">
                    <mat-checkbox
                      [checked]="getChecked(curve)"
                      role="menuitemcheckbox"
                      [color]="'primary'"
                      (click)="$event.stopPropagation()"
                      (change)="toggleCurve(curve)"
                      >{{ curve }}</mat-checkbox
                    >
                  </p>
                </ng-container>
              </mat-menu>
            </ng-container>
          </mat-menu>
        </mat-radio-group>
      </mat-menu>
    </div>
  </div>

  <div>
    <canvas
      class="em-u-margin-top"
      baseChart
      [data]="pricePathsData"
      [options]="pricePathsOptions"
      [type]="'line'"
      [legend]="true"
    >
    </canvas>
  </div>

  <!-- <table mat-table [dataSource]="dataSource" class="em-c-table em-u-margin-top">
    <ng-container
      *ngFor="let col of displayedColumns; let i = index"
      [matColumnDef]="col"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        class="em-c-table__header-cell header-cell em-u-padding-top-double em-u-text-align-center"
        [ngClass]="{ 'em-u-text-align-right': i > 0 }"
        [width]="100 / displayedColumns.length + '%'"
      >
        {{ col }}
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="em-c-table__cell em-u-padding-top em-u-text-align-center"
        [ngClass]="{ 'em-u-text-align-right': i > 0 }"
      >
        {{ isNaN(element[col]) ? element[col] : (element[col] | number) }}
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
      style="text-align: center"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table> -->
</div>
