export function convertb64toBlob(base64data: string): Blob {
  var byteString = atob(base64data.split(',')[1]);
  var typeString = base64data.split(',')[0].split(':')[1].split(';')[0];

  var dataArray = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    dataArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([dataArray], { type: typeString });
}
