import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LinearDataPair } from '../../../../models/linear-data.model';

@Component({
  selector: 'app-nat-gas-curve-info',
  templateUrl: './nat-gas-curve-info.component.html',
  styleUrls: ['./nat-gas-curve-info.component.scss']
})
export class NatGasCurveInfoComponent implements OnInit {

  lineInfo: LinearDataPair[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { hubs: string[], lineInfo: LinearDataPair[] }) { }

  ngOnInit(): void {
    this.lineInfo = JSON.parse(JSON.stringify(this.data.lineInfo));
  }

  addLineInfo(): void {
    this.lineInfo.push({
      data1: { key: this.data.hubs?.find(Boolean), percentFactor: 100, shiftFactor: 0 }});
  }

  clearLineInfo(): void {
    this.lineInfo = [];
  }

  deleteLineInfo(index: number): void {
    this.lineInfo.splice(index, 1);
  }

  addSpreadData(index: number): void {
    this.lineInfo[index].data2 = { key: null, percentFactor: 100, shiftFactor: 0 }
  }
}
