import { Component, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PhysicalLngCalculateDesDesMain } from './physical-lng-desdes-main.model';
import { PhysicalLngDesdesComponent } from './physical-lng-desdes.component';
import { PhysicalLngDesDesSessionService } from './physical-lng-desdes.service';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-physical-lng-dealcase-desdes-main',
  templateUrl: './physical-lng-dealcase-desdes-main.component.html',
  styleUrls: ['./physical-lng-desdes-main.component.scss']
})

export class PhysicalLngDealCaseDesDesMainComponent implements OnInit {

  showTabInput: boolean;
  tabName: string;
  expandedIndex = 0;
  changedTabName: string;

  physicalLngCalcs:number = 0;
  @ViewChild('maincontainer', { read: ViewContainerRef }) container: ViewContainerRef;

  input = PhysicalLngDesdesComponent;
  physicalLngTabs: PhysicalLngCalculateDesDesMain[] = [];

  constructor(private titleService: Title, private localStore: PhysicalLngDesDesSessionService) {
    this.titleService.setTitle('Trading Preview UI | Physical LNG DES-DES');
  }

  ngOnInit(): void {
    var allTabs = this.localStore.getData("ALL::LNG_DEALCASE_DESDES_TABS::") || '';
    if(allTabs.length>0){
      var firstTab = allTabs.split('\t'); //[ZZ] The name "firstTab" is misleading. It is actually the list of tab names. 
      this.physicalLngCalcs = firstTab.length;
      firstTab.forEach(tabName =>{
        var localStoredString = this.localStore.getData(tabName) || '';
        if(localStoredString.length>0){
          var tabItem = new PhysicalLngCalculateDesDesMain(tabName, false);
          this.physicalLngTabs.push(tabItem);
        } else {
          this.defaultPhysicalLngItem();
        }
      });
    } else {
      this.defaultPhysicalLngItem();
      this.localStore.saveData("ALL::LNG_DEALCASE_DESDES_TABS::", "DealCase_DesDes");
    }
    setTimeout( () => {
      this.tabSelected(this.physicalLngTabs[0]);
    }, 300);
  }

  defaultPhysicalLngItem(){
    var defaultItem = new PhysicalLngCalculateDesDesMain("DealCase_DesDes", false);
    this.physicalLngTabs.push(defaultItem);
  }

  cancel(){
    this.showTabInput = false;
  }

  next() {
    this.showTabInput = false;
    this.physicalLngTabs.forEach(x => x.current = false);
    var nextItem = new PhysicalLngCalculateDesDesMain(this.tabName, true);
    nextItem.tabName = this.tabName;
    this.physicalLngTabs.push(nextItem);

    var len = this.physicalLngTabs.length;
    this.container.clear();
    var component = this.container.createComponent<PhysicalLngDesdesComponent>(this.input);
    component.instance.tabRefName = this.tabName;
    component.instance.deleteTheTab.subscribe( v => {
      this.deleteTab(v);
      this.allTabsPersist();
    });

    this.allTabsPersist();
  }

  deleteTab(tabkey){
    var deleted = false;
    if(this.physicalLngTabs.length > 1){
      for (var i = this.physicalLngTabs.length - 1; i >= 0; --i) {
        if(!deleted && this.physicalLngTabs[i].tabName == tabkey){
          this.physicalLngTabs.splice(i,1);
        }
      }
      if(this.physicalLngTabs.length>0){
        this.tabSelected(this.physicalLngTabs[0])
      }
    }
  }

  allTabsPersist(){
    var allTabs = this.physicalLngTabs.map(tab =>{
      return tab.tabName;
    });
    this.localStore.saveData("ALL::LNG_DEALCASE_DESDES_TABS::", allTabs.join('\t'));
  }

  onTabDblClick(tabItem: PhysicalLngCalculateDesDesMain){
    if (tabItem.current){
      tabItem.dblClicked = true;
      this.changedTabName = tabItem.tabName;
      setTimeout(() => {
        let elementReference = document.querySelector('#inpTabName');
        if (elementReference instanceof HTMLInputElement) {
          elementReference.focus();
          elementReference.select();
        }
      }, 100);
    }
  }

  onTabBlur(tabItem: PhysicalLngCalculateDesDesMain){
    let elementReference = document.querySelector('#inpTabName');
    if (elementReference instanceof HTMLInputElement) {
      var oldTabName = tabItem.tabName;
      tabItem.tabName = this.changedTabName;
      var localStoredString = this.localStore.getData(oldTabName) || '';
      if(localStoredString.length>0){
        this.localStore.saveData(this.changedTabName, localStoredString);
      }         
      this.localStore.removeData(oldTabName);
      this.allTabsPersist();
      tabItem.dblClicked = false;
    }
  }

  add() {
    this.tabName="DealCase_DesDes " + (++this.physicalLngCalcs);
    this.next();
  }

  tabSelected(tabItem: PhysicalLngCalculateDesDesMain) {
    if (!tabItem.current){
      /* Session Storage Get */
      if(tabItem){
        var localStoredString = this.localStore.getData(tabItem.tabName) || '';
        if(localStoredString.length>0){
          // tabItem.tradeSummaryDesdes = JSON.parse(localStoredString);
        }         
      }
      this.physicalLngTabs.forEach(x => {
        x.current = (x.tabName == tabItem.tabName);
      });
      this.container.clear();
      var component = this.container.createComponent<PhysicalLngDesdesComponent>(this.input);
      component.instance.tabRefName = tabItem.tabName;
      component.instance.deleteTheTab.subscribe( v => {
        this.deleteTab(v);
        this.allTabsPersist();
      });
    }
  }

}

