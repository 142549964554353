<div class="em-l-grid">
  <div class="em-l-grid__item">
    <h3>Freight Price Curve</h3>
  </div>
  <div class="em-l-grid__item">
    <div>
      <mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
        <mat-label>Trade date</mat-label>
        <input matInput type="date" [(ngModel)]="tradeDate" (ngModelChange)="fetchChartData()">
      </mat-form-field>

      <mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
        <mat-label>Route(s)</mat-label>
        <mat-select multiple [(ngModel)]="selectedRoutes" (ngModelChange)="plotChartData()">
          <mat-option *ngFor="let route of routes" [value]="route">{{route}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="em-l-grid__item em-u-margin-bottom">
    <canvas baseChart
            [data]="chartData"
            [options]="lineChartOptions"
            type="line">
    </canvas>
  </div>
</div>
