import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'negativeParens'
})
export class NegativeParensPipe implements PipeTransform {

  transform(value: string): string {
    if (value.startsWith('-')) {
      return `(${value.substring(1)})`;
    }
    return value;
  }

}
