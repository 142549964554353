import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { FileSaverService } from 'ngx-filesaver';
import { finalize } from 'rxjs/operators';
import { SecurityInfo } from '../models/security-info.model';
import { TradeLeg } from '../models/trade-leg.model';
import { DateHelperService } from '../services/date-helper.service';
import { MonitoringService } from '../services/monitoring.service';
import { RiskMetricsService } from '../services/risk-metrics.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-var-calc',
  templateUrl: './var-calc.component.html',
  styleUrls: ['./var-calc.component.scss']
})
export class VarCalcComponent implements OnInit {
  nextWeekdayStr: string;
  businessDays: number;

  displayedColumns: string[] = ['security', 'position', 'lot', 'quantity', 'eodPrice', 'intradayPrice', 'strip', 'delete'];

  defaultLeg: TradeLeg = new TradeLeg(new SecurityInfo('NG', 'Henry Hub', 'Flat'), 'long', 1, 10000, '$', 0, 0);
  defaultHHLeg: TradeLeg = new TradeLeg(new SecurityInfo('HH', 'Henry Hub', 'Flat'), 'long', 0, 0, '$', 0, 0);

  tradeLegs: TradeLeg[] = [];
  refLegs: TradeLeg[] = [];

  securityInfo: SecurityInfo[] = [];
  filteredSecurityInfo: SecurityInfo[] = [];

  alphaCI: string;
  dateHorizon: string;
  currency: string;

  canPullLiveData = false;
  calculatedIntradayVar = 0;
  calculatedEODVar = 0;
  isCalculating = false;
  showCalculatingError = false;
  showRecalcWarning = false;
  firstRun = true;
  uploadError = false;
  initialized = false;
  defaultErrorMsg = 'There was an error in calculating the value at risk for the given trade. Please try again.';
  errorMessage = '';

  intradayPriceRefreshTime: Date;
  showPriceWarning = false;

  @ViewChild(MatTable) table: MatTable<any>;

  constructor(private riskMetricsService: RiskMetricsService, private dateHelperService: DateHelperService, private monitoringService: MonitoringService,
    private fileSaverService: FileSaverService, private userService: UserService, private titleService: Title, private notificationService: MatSnackBar) {
    this.titleService.setTitle('Trading Feature Preview')

    const defaultDate = new Date();
    defaultDate.setMonth(defaultDate.getMonth() + 1);
    const defaultStrip = `${defaultDate.getUTCFullYear()}-${defaultDate.getUTCMonth() + 1}`
    this.defaultLeg.strip = defaultStrip;
    this.defaultHHLeg.strip = defaultStrip;
    this.defaultLeg.unit = 'MMBTU';
    this.defaultHHLeg.unit = 'MMBTU';
  }

  ngOnInit(): void {
    this.alphaCI = '.95';
    this.currency = 'USD';

    const nextWeekday = this.dateHelperService.getNextWeekday(new Date());

    this.dateHorizon = this.dateHelperService.getDateyyyyMMdd(nextWeekday.toLocaleDateString('en-CA'));
    this.nextWeekdayStr = this.dateHorizon;
    this.businessDays = 1;

    this.canPullLiveData = this.userService.hasIceAccess();
    this.setupInitialLeg();
  }

  setupInitialLeg(): void {
    this.riskMetricsService.getSecuritiesInfo()
      .pipe(finalize(() => this.initialized = true))
      .subscribe(res => {
        this.securityInfo = res.map(x => new SecurityInfo(x.security, x.hub, x.type, x.region_office)).sort((a, b) => a.hub.localeCompare(b.hub));
        this.filteredSecurityInfo = this.securityInfo;

        this.addLeg();
      });
  }

  addLeg(): void {
    const lastLeg = this.tradeLegs[this.tradeLegs.length - 1];

    let legToAdd: TradeLeg = null;
    if (!lastLeg) {
      legToAdd = this.defaultLeg.clone();
      this.initializeLeg(legToAdd);
    } else {
      legToAdd = lastLeg.clone();
      //auto-increment the month to save a user a click when adding a new leg
      if (legToAdd.strip != legToAdd.maxStripDate) {
        var dateStrip = new Date(legToAdd.strip);
        dateStrip.setUTCMonth(dateStrip.getUTCMonth() + 1);
        legToAdd.strip = `${dateStrip.getUTCFullYear()}-${(dateStrip.getUTCMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 })}`;
        this.updatePrices(legToAdd);
        this.updateLotSize(legToAdd);
      }
    }

    this.tradeLegs.push(legToAdd);
    if (legToAdd.securityInfo.type === 'Basis') {
      this.syncRefLegs();
    }

    this.refreshTable();
  }

  initializeLeg(leg: TradeLeg) {
    this.updateUnits(leg);
    this.updateStripRange(leg);
  }

  insertLeg(leg: TradeLeg, index: number) {
    this.tradeLegs.splice(index, 0, leg);
    this.refreshTable();
  }

  deleteLeg(leg: TradeLeg) {
    if (this.tradeLegs.length == 1) {
      this.notificationService.open('Cannot delete the only leg.', 'Dismiss');
      return;
    }

    const index = this.tradeLegs.indexOf(leg);
    if (index > -1) {
      this.tradeLegs.splice(index, 1);
      this.refreshTable();
    }

    this.syncRefLegs();
  }

  copyEODPriceToIntraday(leg: TradeLeg) {
    leg.intradayPrice = leg.eodPrice;
  }

  getAllLegs() {
    return this.tradeLegs.concat(this.refLegs);
  }

  refreshTable() {
    this.showRecalcWarning = true;
    this.table?.renderRows();
  }

  calculateVaR() {
    this.isCalculating = true;
    this.showCalculatingError = false;
    const varInputs = {
      alpha: this.alphaCI,
      date_horizon: this.dateHorizon,
      currency: this.currency.startsWith('GBX') ? 'GBp' : this.currency,
      legs: []
    };
    this.getAllLegs().forEach(leg => {
      varInputs['legs'].push({
        code: leg.securityInfo.security,
        position: leg.position,
        intraday_price: leg.intradayPrice,
        quantity: leg.quantity,
        strip: `${leg.strip}-01`
      });
    });

    this.riskMetricsService.calculateVaR(varInputs)
      .pipe(finalize(() => {
        this.isCalculating = false;
        this.firstRun = false;
        this.showRecalcWarning = false;
        this.monitoringService.trackEvent({ name: 'Ran var calculator' });
      }))
      .subscribe(
        res => {
          this.calculatedEODVar = res.VaR.EOD;
          this.calculatedIntradayVar = res.VaR.Intra;
        },
        (err: HttpErrorResponse) => {
          this.errorMessage = this.defaultErrorMsg;
          if (err.status === 400) {
            this.errorMessage = 'There was an error in calculating the value at risk for the given trade. Check the inputs for the calculation and try again.';
          }

          this.showCalculatingError = true;
          this.calculatedEODVar = 0;
          this.calculatedIntradayVar = 0;
        }
      );
  }

  getCurrencyCode(): string {
    return this.currency.substring(0, 3);
  }

  currencyChanged() {
    this.calculateVaR();
  }

  pullIntradayPrices() {
    this.intradayPriceRefreshTime = null;
    this.showPriceWarning = false;

    this.tradeLegs.forEach(leg => {
      this.riskMetricsService.getIntradayPrice(leg.securityInfo.security, leg.strip).subscribe(res => {
        if (leg.intradayPrice !== res.intraday_price) {
          this.showRecalcWarning = true;
        }

        leg.intradayPrice = res.intraday_price;
        if (res.time_stamp) {
          var timeStamp = new Date(res.time_stamp);

          if (!this.intradayPriceRefreshTime || timeStamp > this.intradayPriceRefreshTime) {
            this.intradayPriceRefreshTime = timeStamp;
          }
        }

        if (res.warning) {
          this.showPriceWarning = true;
        }
      });
    });
  }

  useEODPrice() {
    this.tradeLegs.forEach(leg => {
      leg.intradayPrice = leg.eodPrice;
    });
  }

  checkSecurity(securityInfo: any) {
    return typeof securityInfo === 'string';
  }

  hideError() {
    this.showCalculatingError = false;
  }

  markLegTouched() {
    this.showRecalcWarning = true;
  }

  securityValueChanged(changeValue: any, leg: TradeLeg) {
    if (typeof changeValue === 'string') {
      this.filteredSecurityInfo = this.securityInfo.filter(x =>
        x.toString().toLowerCase().includes(changeValue.toLowerCase()));
    }
    else {  // indicates that a security has been selected from the dropdown
      // reset filtered security information to full list
      this.filteredSecurityInfo = this.securityInfo;

      this.showRecalcWarning = true;

      this.initializeLeg(leg);
    }
  }

  lotChanged(leg: TradeLeg) {
    leg.quantity = leg.lot * leg.lotSize;
    this.markLegTouched();
  }

  quantityChanged(leg: TradeLeg) {
    leg.lot = leg.quantity / leg.lotSize;
    this.markLegTouched();
  }

  timeHorizonChanged() {
    this.markLegTouched();

    const dateHorizonArr = this.dateHorizon.split('-');
    const today = new Date();
    // have to do this to strip out time zones and current hours
    this.businessDays = this.dateHelperService.getWeekdaysBetween(
      new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      new Date(+dateHorizonArr[0], +dateHorizonArr[1] - 1, +dateHorizonArr[2]));
  }

  stripChanged(leg: TradeLeg) {
    this.showRecalcWarning = true;

    this.updateLotSize(leg);
    this.updatePrices(leg);
    this.syncRefLegs();
  }

  saveLegs() {
    this.fileSaverService.saveText(JSON.stringify(this.getAllLegs()), 'trade.json');
  }

  uploadLegs(files: FileList) {
    if (files.length < 1) {
      return;
    }

    const reader: FileReader = new FileReader();
    reader.readAsText(files[0]);
    reader.onload = (e: any) => {
      const legs = JSON.parse(e.target.result);
      const loadedLegs: TradeLeg[] = [];

      try {
        legs.forEach(leg => {
          loadedLegs.push(TradeLeg.fromJson(leg));
        });
      } catch {
        this.uploadError = true;
      }

      if (loadedLegs.length > 0 && !this.uploadError) {
        this.tradeLegs = [];
        this.refLegs = [];

        loadedLegs.forEach(leg => {
          if (!leg.isReferenceLeg) {
            this.tradeLegs.push(leg);
          }
          else {
            this.refLegs.push(leg);
          }
        });

        this.notificationService.open(`${files[0].name} successfully loaded.`, 'Dismiss');
      }
      else {
        this.notificationService.open('Error on file upload, ensure correct file formatting.', 'Dismiss');
      }

      // clear flag
      this.uploadError = false;
    };
  }

  private updateUnits(leg: TradeLeg): void {
    this.riskMetricsService.getSecurityUnits(leg.securityInfo.security).subscribe(res => {
      leg.currency = res.currency_symbol;
      leg.unit = res.unit;
    });
  }

  private updateLotSize(leg: TradeLeg): void {
    this.riskMetricsService.getLotSize(leg.securityInfo.security, leg.strip).subscribe(
      res => {
        leg.lotSize = res;
        if (!leg.lot) {
          leg.lot = 1;
        }

        leg.quantity = leg.lot * leg.lotSize;
      },
      () => {
        leg.lotSize = undefined;
        leg.quantity = undefined;
      });
  }

  private updateStripRange(leg: TradeLeg): void {
    this.riskMetricsService.getEODPriceSummary(leg.securityInfo.security).subscribe(
      res => {
        if (res.length > 0) {
          leg.minStripDate = res.reduce((a, b) => a.STRIP < b.STRIP ? a : b).STRIP.substring(0, 7);
          leg.maxStripDate = res.reduce((a, b) => a.STRIP > b.STRIP ? a : b).STRIP.substring(0, 7);

          leg.strip = leg.minStripDate;

          this.updateLotSize(leg);
          this.updatePrices(leg);
          this.syncRefLegs();
        }
      },
      () => {
        leg.maxStripDate = leg.minStripDate;
        leg.strip = undefined;
        leg.lotSize = undefined;
        leg.quantity = undefined;
        leg.eodPrice = undefined;
        leg.intradayPrice = undefined;
        this.syncRefLegs();
      });
  }

  private updatePrices(leg: TradeLeg): void {
    this.riskMetricsService.getEODPrice(leg.securityInfo.security, leg.strip).subscribe(
      res => {
        leg.eodPrice = res.end_of_date_price;
        if (!this.canPullLiveData) {
          leg.intradayPrice = leg.eodPrice;
        }
      },
      () => leg.eodPrice = null
    );

    if (this.canPullLiveData) {
      this.riskMetricsService.getIntradayPrice(leg.securityInfo.security, leg.strip).subscribe(
        res => leg.intradayPrice = res.intraday_price,
        () => leg.intradayPrice = null);
    }
  }

  private syncRefLegs(): void {
    var basisLegStrips = this.tradeLegs.filter(leg => leg.securityInfo.type === 'Basis' && leg.securityInfo.region_office === 'AGOT').map(leg => leg.strip);

    var refLegsToDelete: TradeLeg[] = [];
    this.refLegs.forEach(leg => {
      if (!basisLegStrips.find(strip => strip === leg.strip)) {
        refLegsToDelete.push(leg);
      }
    });

    // first delete reference legs for which no basis legs exist
    refLegsToDelete.forEach(leg => {
      const index = this.refLegs.indexOf(leg);
      this.refLegs.splice(index, 1);
    });

    // then add new reference leg for every basis leg who's strip doesn't exist
    basisLegStrips.forEach(stripDate => {
      if (!this.refLegs.find(leg => leg.strip === stripDate)) {
        const newRefLeg = this.defaultHHLeg.clone();
        newRefLeg.strip = stripDate;
        newRefLeg.isReferenceLeg = true;
        this.updatePrices(newRefLeg);
        this.refLegs.push(newRefLeg);
      }
    });

    this.refreshTable();
  }
}
