import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { NetbackSettings } from "../global-arb/netback/netback-settings/netback-settings.model";
import { convertb64toBlob } from "../helpers/convert-to-blob";
import { DateHelperService } from '../services/date-helper.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalArbService {
  private defaultSettings: NetbackSettings =
    {
      ship_info: {
        size: 174000,
        speed: 17,
        fillable: 98.5,
        boiloff_rate: .1,
        additional_fuel: 40
      },
      canal_info: [
        {
          canal_name: "Suez",
          cost: 1076204,
          waiting_days: 3
        },
        {
          canal_name: "Panama Canal",
          cost: 1024561,
          waiting_days: 3
        }
      ],
      charter_cost_multiplier: 1.0,
      insurance_piracy_prevention: 0.2,
      departure_price_multiplier: 1.15,
      departure_price_addon: 3,
      lag_info: "0",
      minimize_idling: true
    }

  public departurePortSettingsCache$: Observable<any>;
  private departurePortSettings;

  private readonly storageKey = 'netbackSettings';
  private readonly storageVersionKey = 'netbackSettingsVersion';
  // Settings version needs to be updated with every update (adds/deletes/changes) to settings to ensure new defaults are picked up
  private readonly netbackSettingsVersion = 1;

  private currentDeparturePort: string = 'Corpus';
  private currentSettings: NetbackSettings = { ...this.defaultSettings };

  netbackSettings = new BehaviorSubject<NetbackSettings>(null);
  departurePort = new BehaviorSubject<string>(this.currentDeparturePort);

  constructor(private http: HttpClient, private dateHelperService: DateHelperService) {
    var today = new Date();
    var sdate = dateHelperService.getDateyyyyMMdd(today.toLocaleDateString());
    this.getDeparturePortSettings(sdate).subscribe(settings => {
      this.departurePortSettings = settings;
      this.loadSettings();
    });
  }

  loadSettings(): void {
    const savedSettingsVersion = localStorage.getItem(this.storageVersionKey);
    const savedSettings = localStorage.getItem(this.originStorageKey);

    this.currentSettings.departure_price_multiplier = this.departurePortSettings[this.currentDeparturePort].slope;
    this.currentSettings.departure_price_addon = this.departurePortSettings[this.currentDeparturePort].add_on;

    if (Number(savedSettingsVersion) == this.netbackSettingsVersion && savedSettings) {
      this.currentSettings = JSON.parse(savedSettings);
    }

    this.netbackSettings.next(this.currentSettings);
  }

  saveSettings(settings: NetbackSettings) {
    this.currentSettings = settings;
    localStorage.setItem(this.originStorageKey, JSON.stringify(this.currentSettings));
    localStorage.setItem(this.storageVersionKey, JSON.stringify(this.netbackSettingsVersion));

    this.netbackSettings.next(this.currentSettings);
  }

  calculateNetback(date: string): Observable<any> {
    const settingsCopy = this.getNetbackSettingsForPayload();
    var sdate = this.dateHelperService.getDateyyyyMMdd(date);
    const url = `${environment.riskMetricsEndpoint}/calculate-netback`;
    const data = { trade_date: sdate, departure_port: this.currentDeparturePort, ...settingsCopy };
    return this.http.post<any>(url, data);
  }

  downloadNetback(date: string, departurePort: string): Observable<any> {
    const settingsCopy = this.getNetbackSettingsForPayload();
    var sdate = this.dateHelperService.getDateyyyyMMdd(date);
    const url = `${environment.riskMetricsEndpoint}/download-netback`;
    const data = { trade_date: sdate, departure_port: departurePort, ...settingsCopy };
    return this.http.post<any>(url, data).pipe(map(res => {
      return {
        fileName: res.file_name,
        blob: convertb64toBlob(res.result[0])
      }
    }));
  }

  getPriceCurves(date: string, symbols: string, unit?: string): Observable<any> {
    var sdate = this.dateHelperService.getDateyyyyMMdd(date);
    const url = `${environment.riskMetricsEndpoint}/price-curves`;
    var params = new HttpParams().set('trade_date', sdate).set('price_symbols', symbols);
    if (unit) {
      params = params.set('output_unit', unit);
    }
    return this.http.get<any>(url, { params: params });
  }

  getDeparturePortSettings(date: string): Observable<any> {
    if (!this.departurePortSettingsCache$) {
      var sdate = this.dateHelperService.getDateyyyyMMdd(date);
      const url = `${environment.riskMetricsEndpoint}/get-departure-port-settings`;
      var params = new HttpParams().set('trade_date', sdate);
      this.departurePortSettingsCache$ = this.http.get<any>(url, { params: params }).pipe(shareReplay(1));
    }

    return this.departurePortSettingsCache$;
  }

  updateDeparturePort(origin: string): void {
    this.currentDeparturePort = origin;

    this.loadSettings();

    this.departurePort.next(origin);
  }

  // We have to clone to create a deep copy with the behavior subject
  cloneNetbackSettings(settings: NetbackSettings): NetbackSettings {
    return JSON.parse(JSON.stringify(settings));
  }

  getDefaultSettings(): NetbackSettings {
    return JSON.parse(JSON.stringify(this.defaultSettings));
  }

  private get originStorageKey(): string {
    return `${this.currentDeparturePort}_${this.storageKey}`;
  }

  private getNetbackSettingsForPayload(): NetbackSettings {
    // the API takes these percentage values as decimals
    let settingsCopy = this.cloneNetbackSettings(this.currentSettings);
    settingsCopy.ship_info.fillable /= 100;
    settingsCopy.ship_info.boiloff_rate /= 100;

    return settingsCopy;
  }
}
