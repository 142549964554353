import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { GlobalArbService } from '../../../services/global-arb.service';
import { NetbackSettings } from './netback-settings.model';

@Component({
  selector: 'app-netback-settings',
  templateUrl: './netback-settings.component.html',
  styleUrls: ['./netback-settings.component.scss']
})
export class NetbackSettingsComponent implements OnInit {

  shipSizes = [145000, 160000, 174000];
  originPorts = [];

  settings: NetbackSettings;

  priceMultiplierHub = '';

  private subscription: Subscription;

  constructor(private globalArbService: GlobalArbService) { }

  ngOnInit(): void {
  // We have to clone to create a deep copy with the behavior subject, otherwise we'll overwrite the subject reference
    this.subscription = this.globalArbService.netbackSettings.subscribe(settings => {
      if (settings) {
        this.settings = this.globalArbService.cloneNetbackSettings(settings);
      }
    });
    var today = new Date().toLocaleDateString('en-CA');
    this.subscription.add(this.globalArbService.departurePort.pipe(concatMap(port => this.globalArbService.getDeparturePortSettings(today).pipe(map(settings => [port, settings]))))
      .subscribe(([port, settings]) => this.priceMultiplierHub = settings[port].hub));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  saveSettings(settingsForm: NgForm): void {
    settingsForm.form.markAsPristine();
    this.globalArbService.saveSettings(this.settings);
  }
}
