<div class="em-c-loader spinner" *ngIf="!initialized">
  <img src="unity/images/icon-spinner.svg" alt="Loading" />
</div>
<div class="em-c-alert em-c-alert--error" role="alert" *ngIf="showCalculatingError">
  <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#circle-exclamation-mark"></use>
  </svg>
  <div class="em-c-alert__body">
    {{errorMessage}}
  </div>
  <div class="em-c-alert__actions">
    <button class="em-c-text-btn em-c-alert--error" (click)="hideError()">
      Close
    </button>
  </div>
</div>
<div style="display: flex; justify-content: space-between">
  <div>
    <mat-form-field class="em-u-padding-top-none em-u-padding-right small-field">
      <mat-label>Confidence level</mat-label>
      <mat-select [(value)]="alphaCI" (selectionChange)="markLegTouched()">
        <mat-option value=".90">90%</mat-option>
        <mat-option value=".95">95%</mat-option>
        <mat-option value=".99">99%</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="em-u-padding-right em-u-padding-top-none medium-field">
      <mat-label>Time horizon</mat-label>
      <input matInput type="date" [(ngModel)]="dateHorizon" [min]="nextWeekdayStr" (ngModelChange)="timeHorizonChanged()">
    </mat-form-field>

    <mat-form-field class="em-u-padding-top-none small-field">
      <mat-label>Business days</mat-label>
      <input matInput type="number" class="em-u-text-align-right" [(ngModel)]="businessDays" disabled>
    </mat-form-field>
  </div>
  <div>
    <div *ngIf="isCalculating" style="width: 500px">
      <div class="em-c-field em-c-progress-bar em-c-progress-bar--indeterminate">
        <div class="em-c-progress-bar__label">
          <span class="em-u-margin-left-half"></span>
        </div>
        <div class="em-c-progress-bar__bar"> <span class="em-c-progress-bar--indeterminate__value"></span></div>
      </div>
    </div>

    <div class="em-c-toolbar__item em-is-aligned-right em-u-margin-none" *ngIf="!isCalculating">
      <svg class="em-c-icon em-c-icon--medium em-u-margin-right" *ngIf="!firstRun && showRecalcWarning" matTooltip="Inputs have changed, please re-run risk calculation.">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#warning"></use>
      </svg>

      <button class="em-c-btn em-c-btn--primary big-button em-u-margin-right-half" (click)="calculateVaR()" matTooltip="Run Value at Risk calculation">
        <svg class="em-c-icon em-c-icon--medium em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/48/em-icons.svg#rocket"></use>
        </svg>
      </button>

      <mat-form-field class="em-u-padding-none em-u-margin-right-half xsmall-field" appearance="fill">
        <mat-label>Currency</mat-label>
        <mat-select [(ngModel)]="currency" (ngModelChange)="currencyChanged()">
          <mat-option value="USD">USD</mat-option>
          <mat-option value="EURO">EUR</mat-option>
          <mat-option value="GBX ">GBX</mat-option>
          <mat-option value="GBP">GBP</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="em-u-padding-none em-u-margin-right-half medium-field" appearance="fill">
        <mat-label>EOD</mat-label>
        <input matInput class="em-u-text-align-right" type="text" [ngModel]="calculatedEODVar | currency : getCurrencyCode() : 'symbol' : '1.0-0'" readonly>
      </mat-form-field>

      <mat-form-field class="em-u-padding-none medium-field" appearance="fill">
        <mat-label>Intraday</mat-label>
        <input matInput class="em-u-text-align-right" type="text" [ngModel]="calculatedIntradayVar | currency : getCurrencyCode() : 'symbol' : '1.0-0'" readonly>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="em-c-table-object">
  <div class="em-c-table-object__header">
    <div class="em-c-toolbar ">
      <div class="em-c-toolbar__item">
        <div class="em-c-btn-group ">
          <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="addLeg()" [disabled]="!initialized || isCalculating" [class.em-c-btn--disabled]="!initialized || isCalculating"
                  matTooltip="Add leg" matTooltipPosition="above">
            <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#plus"></use>
            </svg>
          </button>

          <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="useEODPrice()" [disabled]="!initialized || isCalculating" [class.em-c-btn--disabled]="!initialized || isCalculating"
                  matTooltip="Use EOD price" matTooltipPosition="above">
            <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#moon"></use>
            </svg>
          </button>

          <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="pullIntradayPrices()" [disabled]="!initialized || isCalculating || !canPullLiveData" [class.em-c-btn--disabled]="!initialized || isCalculating || !canPullLiveData"
                  matTooltip="Pull intraday price" matTooltipPosition="above">
            <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#refresh"></use>
            </svg>
          </button>

          <div *ngIf="intradayPriceRefreshTime" class="em-u-margin-top-half">
            Updated: {{ intradayPriceRefreshTime | date : 'short'}} CST
          </div>
          <div *ngIf="showPriceWarning">
            <svg class="em-c-icon em-c-icon--small em-u-margin-half">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#info"></use>
            </svg>
          </div>
          <div *ngIf="showPriceWarning" class="em-u-margin-top-half">
            Unavailable intraday price(s) have been replaced with EOD price.
          </div>
        </div>
      </div>
      <div class="em-c-toolbar__item em-is-aligned-right">
        <div class="em-c-btn-group ">
          <input type="file" accept=".json, .txt" (change)="uploadLegs($event.target.files)" mulitple="false" #uploader hidden>
          <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="saveLegs()" [disabled]="!initialized" [class.em-c-btn--disabled]="!initialized"
                  matTooltip="Save table data" matTooltipPosition="above">
            <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#save-disk"></use>
            </svg>
          </button>
          <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="uploader.click()" [disabled]="!initialized" [class.em-c-btn--disabled]="!initialized"
                  matTooltip="Upload data" matTooltipPosition="above">
            <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#upload"></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
  <table mat-table [dataSource]="getAllLegs()" class="em-c-table">
    <ng-container matColumnDef="security">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> Security </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="outline" class="xlarge-field em-u-margin-top em-u-margin-right">
          <input type="text" matInput [(ngModel)]="element.securityInfo" (ngModelChange)="securityValueChanged($event, element)" [matAutocomplete]="auto" [disabled]="element.isReferenceLeg" required>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [panelWidth]="350">
            <mat-option *ngFor="let secInfo of filteredSecurityInfo" [value]="secInfo">
              {{secInfo.hub}} {{secInfo.type}} ({{secInfo.security}})
            </mat-option>
          </mat-autocomplete>
          <mat-hint class="mat-error" *ngIf="checkSecurity(element.securityInfo)">Select a valid security.</mat-hint>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> Position </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field floatLabel="never" appearance="outline" class="medium-field em-u-margin-top em-u-margin-right" *ngIf="!element.isReferenceLeg">
          <mat-select [(ngModel)]="element.position" (ngModelChange)="markLegTouched()" [disabled]="element.isReferenceLeg" required>
            <mat-option value="long">Long</mat-option>
            <mat-option value="short">Short</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="medium-field em-u-margin-top em-u-margin-right" *ngIf="element.isReferenceLeg">
          <input class="em-u-text-align-center" matInput type="text" readonly value="------------" tabindex="-1" />
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="lot">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> Lot </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="outline" class="medium-field em-u-margin-top em-u-margin-right" *ngIf="!element.isReferenceLeg">
          <input class="em-u-text-align-right" matInput type="number" [(ngModel)]="element.lot" (ngModelChange)="lotChanged(element)" min="0" required>
          <mat-error *ngIf="element.lot < 0">Value must be positive.</mat-error>
          <mat-error *ngIf="!element.lot">Enter a valid value.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="medium-field em-u-margin-top em-u-margin-right" *ngIf="element.isReferenceLeg">
          <input class="em-u-text-align-center" matInput type="text" readonly value="------------" tabindex="-1" />
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> Quantity </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="outline" class="medium-field em-u-margin-top em-u-margin-right" *ngIf="!element.isReferenceLeg">
          <input matInput currencyMask [(ngModel)]="element.quantity" (ngModelChange)="quantityChanged(element)" min="0" required>
          <span matSuffix>&nbsp;{{element.unit}}</span>
          <mat-error *ngIf="!element.quantity">Enter a value.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="medium-field em-u-margin-top em-u-margin-right" *ngIf="element.isReferenceLeg">
          <input class="em-u-text-align-center" matInput type="text" readonly value="----------------" tabindex="-1" />
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="eodPrice">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> EOD Price </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="outline" class="small-field em-u-margin-top">
          <input matInput type="number" class="em-u-text-align-right" tabindex="-1" step="any" [(ngModel)]="element.eodPrice" disabled>
          <span matPrefix>{{element.currency}}</span>
          <mat-error *ngIf="!element.eodPrice">Price load failed.</mat-error>
        </mat-form-field>
        <button class="em-c-btn em-c-btn--tertiary em-u-padding-none" (click)="copyEODPriceToIntraday(element)" tabindex="-1">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#caret-right"></use>
          </svg>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="intradayPrice">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> Intraday Price </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="outline" class="small-field em-u-margin-top em-u-margin-right">
          <input matInput type="number" class="em-u-text-align-right" [(ngModel)]="element.intradayPrice" (ngModelChange)="markLegTouched()" step="any" required>
          <span matPrefix>{{element.currency}}</span>
          <mat-error *ngIf="!element.intradayPrice">Enter a price.</mat-error>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="strip">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> Strip </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="outline" class="large-field em-u-margin-top">
          <input matInput type="month" [(ngModel)]="element.strip" [min]="element.minStripDate" [max]="element.maxStripDate" (ngModelChange)="stripChanged(element)" [disabled]="element.isReferenceLeg">
          <mat-error *ngIf="!element.strip">Select a strip.</mat-error>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> </th>
      <td mat-cell *matCellDef="let element" class="center">
        <button class="em-c-btn em-c-btn--tertiary" (click)="deleteLeg(element)" [disabled]="element.isReferenceLeg" [class.em-c-btn--disabled]="element.isReferenceLeg" tabindex="-1">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#trashcan"></use>
          </svg>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
