
<div class="em-c-table-object__header">
  <div class="em-c-toolbar" style="margin: 0px;padding: 4px;">
    <div class="em-c-toolbar__item">
      <div class="em-c-btn-group ">
        <button type="button" class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="add()"
        matTooltip="Add Option" matTooltipPosition="right" [disabled]="!isReady" 
        [class.em-c-btn--disabled]="!isReady" style="height:24px;margin-top:4px;">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#plus"></use>
          </svg>
        </button>
      </div>
    </div>

    <div class="em-c-toolbar__item em-is-aligned-right">
      <div class="em-c-btn-group ">
        <button title="delete" class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="deleteTab()" tabindex="-1">
          <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#trashcan"></use>
          </svg>
      </button>
      </div>
    </div>
  </div>
</div>
<div style="display: flex;">
  <div class="em-l-grid__item">
    <mat-form-field>
      <mat-label>Price Date</mat-label>
      <input matInput type="date" [(ngModel)]="asOfDate" (ngModelChange)="dateOrSecurityChange()">
    </mat-form-field>
  </div>
  <div class="em-l-grid__item">
    <mat-form-field>
        <mat-label>Underlying</mat-label>
        <mat-select [(ngModel)]="selectedAsset" (ngModelChange)="dateOrSecurityChange()">
            <mat-option *ngFor="let secInfo of securityInfo" [value]="secInfo.security">
              {{secInfo.hub}}
            </mat-option>
        </mat-select>
    </mat-form-field>
  </div>
  <div class="em-l-grid__item">
    <mat-form-field>
        <mat-label>Currency</mat-label>
        <mat-select [(ngModel)]="selectedQuote">
            <mat-option value="USD">USD</mat-option>
        </mat-select>
    </mat-form-field>
  </div>
  <div class="em-l-grid__item">
    <mat-form-field>
      <mat-label>Model</mat-label>
      <mat-select [(ngModel)]="selectedModel" (ngModelChange)="modelChange()">
          <mat-option value="Black76">Black76</mat-option>
          <mat-option value="Bachelier">Bachelier</mat-option>
      </mat-select>
    </mat-form-field>  
  </div>
</div>

<div style="display: flex;">
  <ng-template #container></ng-template>
</div>