<!-- <h3>Physical LNG Flow (FOB-DES)</h3> -->
<div class="em-c-loader spinner" *ngIf="initialized">
    <img src="unity/images/icon-spinner.svg" />
  </div>
<div class="em-c-table-object__header">
    <div class="em-c-toolbar ">
      <div class="em-c-toolbar__item">
        <div class="em-c-btn-group ">
            <!-- "Calculate" button -->
            <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="calculatePhysicalLNG()"
                    matTooltip="Calculate" matTooltipPosition="right">
                <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#refresh"></use>
                </svg>
            </button>
            <!-- "Download Excel" button -->
            <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="downloadPhysicalDeal()"
                    matTooltip="Download as Excel" matTooltipPosition="right">
                <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#save-disk"></use>
                </svg>
            </button>
            <!-- "Delete Tab" button -->
            <button class="em-c-btn em-c-btn--secondary em-c-btn--small" (click)="deleteTab()"
                    matTooltip="Delete" matTooltipPosition="right">
                <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#trashcan"></use>
                </svg>
            </button>

        </div>
      </div>
    </div>

</div>
  
<div class="em-l-grid em-l-grid--1up">
    <div class="flex-container">
        <div class="em-l-grid__item flex-child em-physical-lng-fobdes-class">
            <div class="em-l-grid__item em-l-bgblackwhite em-l-setheight">
                <div class="">
                    Trade Summary
                </div>
            </div>

            <div class="em-l-grid__item">
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Seller</mat-label>
                            <input matInput type="text" [(ngModel)]="selectedSeller">
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Buyer</mat-label>
                            <input matInput type="text" [(ngModel)]="selectedBuyer">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="em-l-grid__item">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Loading at</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput title="{{lngInputs.route.load_port}}" [(ngModel)]="lngInputs.route.load_port" disabled>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="selectedFOB">
                                <mat-option value="FOB">FOB</mat-option>
                                <mat-option value="DES">DES</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="date" [(ngModel)]="selectedLoadingDate" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>


            <div class="em-l-grid__item">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Discharge at</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput title="{{lngInputs.route.discharge_port}}" [(ngModel)]="lngInputs.route.discharge_port" disabled>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="selectedDES">
                                <mat-option value="FOB">FOB</mat-option>
                                <mat-option value="DES">DES</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="date" [(ngModel)]="selectedDischargeDate" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Vessel</mat-label>
                            <input matInput type="text" [(ngModel)]="lngInputs.vessel_logistics.vessel_name" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label><b>Net Realisation ($/MMBtu)</b></mat-label>
                            <b><input matInput type="text" [ngModel]="tradeSummary.netRealisationPerUnit | currency" readonly></b>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label><b>Net Realisation</b></mat-label>
                            <b><input matInput type="text" [ngModel]="tradeSummary.netRealisationAmount | currency" readonly></b>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <!--VOLUME BALANCES-->
            <div class="em-l-grid__item">
                <div class="em-l-bgblackwhite">
                    Volume Balances
                </div>
            </div>

            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Volume Balances (MMBtu)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>M³</mat-label>
                    </div>
                </div>
            </div>
            <!--1.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Heel Bought</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="volumeBalances.heelBoughtMMBtu | number : '1.1-1'" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="volumeBalances.heelBoughtM3 | number : '1.1-1'" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!--2.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Load Quantity</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="volumeBalances.loadQuantityMMBtu | number : '1.1-1'" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="volumeBalances.loadQuantityM3 | number : '1.1-1'" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!--3.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Discharge Quantity</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="volumeBalances.dischargeQtyMMBtu | number : '1.1-1'" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="volumeBalances.dischargeQtyM3 | number : '1.1-1'" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!--4.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Heel Sold</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="volumeBalances.heelSoldMMBtu | number : '1.1-1'" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="volumeBalances.heelSoldM3 | number : '1.1-1'" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <!--Shipping Cost Breakdown-->
            <div class="em-l-grid__item">
                <div class="em-l-bgblackwhite">
                    Shipping Cost Breakdown
                </div>
            </div> 
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Charter Cost</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.charterCostPerMMBtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.charterCostUsd | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Fuel Cost</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.fuelCostPerMMBtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.fuelCostUsd | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Ports Canal & Canal Charges</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.portsCanalChargeMMBtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.portsCanalChargeM3 | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>        
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Cool-down Total Cost</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.coolDownCostPerMMBtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.coolDownCostUsd | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>      			 
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Insurance</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.insuranceMMBtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.insuranceM3 | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>      			 
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Misc & Bunker Costs</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.miscAndBunkerCostPerMMBtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.miscAndBunkerCostUsd | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>      			 
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Boil-Off Cost</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.boilOffCostPerMMBtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.boilOffCostUsd | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>      			 
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Total Shipping Cost</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.totalShippingCostPerMMBtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.totalShippingCostUsd | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <!--Deal Economics Breakdown-->
            <div class="em-l-grid__item">
                <div class="em-l-bgblackwhite">
                    Deal Economics Breakdown
                </div>
            </div> 
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Cargo Loaded/Bought</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomics.cargoLoadedMMBtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomics.cargoLoadedM3 | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>	
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Cargo Discharged/Sold</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomics.cargoDischargedMMBtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomics.cargoDischargedM3 | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Slot Cost/Premium</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomics.slotCostPremiumMMBtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomics.slotCostPremiumM3 | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>    			 
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Shipping Cost (excl. BO)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomics.shippingCostPerMMBtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomics.shippingCostUsd | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>	
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Cost of Capital</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomics.costOfCapitalMMBtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="dealEconomics.costOfCapitalM3 | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>	 
            <!--Residual Volume-->
            <div class="em-l-grid__item">
                <div class="em-l-bgblackwhite">
                    Residual Volume
                </div>
            </div> 
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Residual Volume</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    <mat-label>M³</mat-label>
                    </div>
                </div>
            </div>	 
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>LNG Onboard end of voyage (excl. Owner's heel)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" value="(0.0)">
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" value="(0)">
                        </mat-form-field>
                    </div>
                </div>
            </div>	
                        
                        
            <!--Net Realisation-->
            <div class="em-l-grid__item">
                <div class="em-l-bgblackwhite">
                    Net Realisation
                </div>
            </div> 
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>FOB/DES</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>$/MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.totalShippingCostPerMMBtu | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="shippingCost.totalShippingCostUsd | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>	
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Net Realisation</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>$/MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="tradeSummary.netRealisationPerUnit | currency" readonly>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="text" [ngModel]="tradeSummary.netRealisationAmount | currency" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>	 
        </div>

        <!-- inputs -->
        <div class="em-l-grid__item flex-child em-physical-lng-fobdes-class" *ngIf="visibleIn">
            <!--LNG PRICES-->
            <div class="em-l-grid__item">
                <div class="em-l-bgblackwhite">
                    LNG Prices
                </div>
            </div>
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Purchase/FOB Price</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>$/ MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="lngInputs.lng_prices.purchase_fob_price">
                        </mat-form-field>
                    </div>
                    <!-- <div class="em-l-grid__item">
                        <mat-form-field>
                            <input disabled matInput type="number" [(ngModel)]="lngInputs.lng_prices.purchase_fob_price">
                        </mat-form-field>
                    </div> -->
                </div>
            </div>
            <!--2.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Sale/DES Price</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>$/ MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="lngInputs.lng_prices.sale_des_price">
                        </mat-form-field>
                    </div>
                    <!-- <div class="em-l-grid__item">
                        <mat-form-field>
                            <input disabled matInput type="number" [(ngModel)]="lngInputs.lng_prices.sale_des_price">
                        </mat-form-field>
                    </div> -->
                </div>
            </div>
            <!--3.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Heel Purchase Price</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>$/ MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="lngInputs.lng_prices.heel_purchase_price">
                        </mat-form-field>
                    </div>
                    <!-- <div class="em-l-grid__item">
                        <mat-form-field>
                            <input disabled matInput type="number"  [(ngModel)]="lngInputs.lng_prices.heel_purchase_price">
                        </mat-form-field>
                    </div> -->
                </div>
            </div>
            <!--4.-->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>Heel Sale Price</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>$/ MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="lngInputs.lng_prices.heel_sale_price">
                        </mat-form-field>
                    </div>
                    <!-- <div class="em-l-grid__item">
                        <mat-form-field>
                            <input disabled matInput type="number" [(ngModel)]="lngInputs.lng_prices.heel_sale_price">
                        </mat-form-field>
                    </div> -->
                </div>
            </div>
            
            <!--FUEL PRICES-->
            <div class="em-l-grid__item">
                <div class="em-l-bgblackwhite">
                    Fuel Prices
                </div>
            </div>
            <!-- HFO Price -->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>HFO Price</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>$/ t</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="lngInputs.fuel_prices.hfo">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- MGO Price -->
            <div class="em-l-grid__item em-al-right">
                <div class="em-l-grid em-l-grid--4up">
                    <div class="em-l-grid__item">
                        <mat-label>MGO Price</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>$/ t</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="lngInputs.fuel_prices.mdo">
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="em-l-grid__item">
                <div class="em-l-bgblackwhite">
                    Route
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Positioning Port</mat-label>
                            <mat-select [(ngModel)]="lngInputs.route.positioning_port">
                                <mat-option value=""></mat-option>
                                <mat-option *ngFor="let portname of apiPorts" [value]="portname">{{portname}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Positioning Canal</mat-label>
                            <mat-select [(ngModel)]="lngInputs.route.positioning_route_canal">
                                <mat-option value="-"></mat-option>
                                <mat-option value="Direct">Direct</mat-option>
                                <mat-option value="Panama">Panama</mat-option>
                                <mat-option value="Suez">Suez</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Load Port</mat-label>
                            <mat-select [(ngModel)]="lngInputs.route.load_port">
                                <mat-option value=""></mat-option>
                                <mat-option *ngFor="let portname of apiPorts" [value]="portname">{{portname}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Load Canal</mat-label>
                            <mat-select [(ngModel)]="lngInputs.route.load_port_canal">
                                <mat-option value=""></mat-option>
                                <mat-option value="Direct">Direct</mat-option>
                                <mat-option value="Panama">Panama</mat-option>
                                <mat-option value="Suez">Suez</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Discharge Port</mat-label>
                            <mat-select [(ngModel)]="lngInputs.route.discharge_port">
                                <mat-option value=""></mat-option>
                                <mat-option *ngFor="let portname of apiPorts" [value]="portname">{{portname}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Discharge Canal</mat-label>
                            <mat-select [(ngModel)]="lngInputs.route.discharge_port_canal">
                                <mat-option value=""></mat-option>
                                <mat-option value="Direct">Direct</mat-option>
                                <mat-option value="Panama">Panama</mat-option>
                                <mat-option value="Suez">Suez</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-label>Repositioning Port</mat-label>
                            <mat-select [(ngModel)]="lngInputs.route.repositioning_port">
                                <mat-option value=""></mat-option>
                                <mat-option *ngFor="let portname of apiPorts" [value]="portname">{{portname}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                </div>

                <div class="em-l-bgblackwhite">
                    Dates
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label>Positioning Date</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="datetime-local" [(ngModel)]="lngInputs.deal_dates.positioning_date">
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Latest</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input disabled matInput type="datetime-local" [(ngModel)]="computeDates.positioning_latest">
                    </div>
                </div>
                <!--load date-->
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label>Load Date</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput (change)="setSummaryDates()" type="datetime-local" [(ngModel)]="lngInputs.deal_dates.loading_date">
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Earliest</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input disabled matInput type="datetime-local" [(ngModel)]="computeDates.loading_earliest">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Latest</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input disabled matInput type="datetime-local" [(ngModel)]="computeDates.loading_latest">
                    </div>
                </div>
                <!--discharge date-->
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label>Discharge Date</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput (change)="setSummaryDates()" type="datetime-local" [(ngModel)]="lngInputs.deal_dates.discharge_date">
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Earliest</mat-label>
                    </div>
                    <div class="em-l-grid__item em-l-no-pad-border">
                        <input disabled matInput type="datetime-local" [(ngModel)]="computeDates.discharge_earliest">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Latest</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input disabled matInput type="datetime-local" [(ngModel)]="computeDates.discharge_latest">
                    </div>
                </div>
                <!--Repositioning-->
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label>Repositioning Date</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <input matInput type="datetime-local" [(ngModel)]="lngInputs.deal_dates.respositioning_date_input">
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Earliest</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input disabled matInput type="datetime-local"[(ngModel)]="computeDates.repositioning_earliest">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--2up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Latest</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input disabled matInput type="datetime-local">
                    </div>
                </div>

                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label>Laytime (FOB Load)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>Days (1=24 hrs)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input type="number" [(ngModel)]="lngInputs.deal_dates.laytime_fob_load">
                    </div>
                </div>

                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label>Laytime (DES Discharge)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>Days (1=24 hrs)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input type="number" [(ngModel)]="lngInputs.deal_dates.laytime_des_discharge">
                    </div>
                </div>

                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label>ROTATION (TOTAL - FULL CHARTER DAYS)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label>Days</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input class="em-al-right" disabled type="number" [(ngModel)]="rotationTotalFullCharterDays">
                    </div>
                </div>

                <!-- Vessel & Logistics -->
                <div class="em-l-bgblackwhite">
                    Vessel & Logistics
                </div>
                <div class="em-l-grid__item">
                    <mat-form-field>
                        <mat-label>Vessel</mat-label>
                        <mat-select [(ngModel)]="lngInputs.vessel_logistics.vessel_name" (ngModelChange)="onVesselNameSelect()">
                            <mat-option *ngFor="let vesselData of apiVesselTypes" [value]="vesselData.vessel_name">{{vesselData.vessel_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Size (100%)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">m³</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input class="em-al-right" disabled type="number" [(ngModel)]="vesselSize">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Ship Type</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right"></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input class="em-al-right" disabled type="text" [(ngModel)]="vesselType">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Charter Rate</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$/day</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input class="em-al-right" type="number" [(ngModel)]="lngInputs.vessel_logistics.charter_rate">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Positioning Speed</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">knots</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputs.vessel_logistics.positioning_speed">
                                <mat-option *ngFor="let vessel_speed of apiVesselSpeeds" [value]="vessel_speed">{{vessel_speed}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Laden Speed</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">knots</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputs.vessel_logistics.laden_speed">
                                <mat-option *ngFor="let vessel_speed of apiVesselSpeeds" [value]="vessel_speed">{{vessel_speed}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Repositioning Speed</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">knots</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputs.vessel_logistics.repositioning_speed">
                                <mat-option *ngFor="let vessel_speed of apiVesselSpeeds" [value]="vessel_speed">{{vessel_speed}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                 
                <!-- Fuel Decision -->
                <div class="em-l-grid__item">
                    <b>Fuel Decision</b>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Positioning Leg</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputs.vessel_logistics.fuel_decision_positioning">
                                <mat-option value="---Dual Fuel---">---Dual Fuel---</mat-option>
                                <mat-option value="---Gas Only---">---Gas Only---</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Laden Leg</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputs.vessel_logistics.fuel_decision_laden">
                                <mat-option value="---Dual Fuel---">---Dual Fuel---</mat-option>
                                <mat-option value="---Gas Only---">---Gas Only---</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Repositioning Leg</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputs.vessel_logistics.fuel_decision_repositioning">
                                <mat-option value="---Dual Fuel---">---Dual Fuel---</mat-option>
                                <mat-option value="---Gas Only---">---Gas Only---</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Idle Days</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputs.vessel_logistics.fuel_decision_idle">
                                <mat-option value="---Dual Fuel---">---Dual Fuel---</mat-option>
                                <mat-option value="---Gas Only---">---Gas Only---</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="em-l-grid__item">
                    <b>Boil-Off</b>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">BOR Laden</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">From Ship Data (%/day)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input disabled class="em-al-right" type="text" [ngModel]="vesselBORLaden | number : '1.1-3'">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">BOR Ballast</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">From Ship Data (%/day)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input disabled class="em-al-right" type="text" [ngModel]="vesselBORBallast | number : '1.1-3'">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">BOR Idle</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">From Ship Data (%/day)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input disabled class="em-al-right" type="text" [ngModel]="vesselBORLaden | number : '1.1-3'">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">OVERRIDE BOR Laden</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">(%/day)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.override_bor_laden">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">OVERRIDE BOR Ballast</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">(%/day)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.override_bor_ballast">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">OVERRIDE BOR Idle</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">(%/day)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.override_bor_idle">
                    </div>
                </div>
                
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label>Heel Onboard at Delivery of Ship</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">m³</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.heel_onboard_redelivery_of_ship">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label>Heel Onboard at Redelivery of Ship (Owner's Option)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">m³</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.heel_onboard_redelivery_of_ship_owners_option">
                    </div>
                </div>
                
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label>Load Override (choose either MMBtu or M³)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="selectedLoadOverride">
                                <mat-option value=""></mat-option>
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.load_override_mmbtu">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">m³</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.load_override_m3">
                    </div>
                </div>
                
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label>Discharge Override (choose either MMBtu or M³)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="selectedDischargeOverride">
                                <mat-option value=""></mat-option>
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">MMBtu</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.discharge_override_mmbtu">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">m³</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.discharge_override_m3">
                    </div>
                </div>
                
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Canal Waiting/Delay (per transit)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Days</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.canal_delay_per_transit">
                    </div>
                </div>
                
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Charter Period Override</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Days</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.charter_period_override">
                    </div>
                </div>

                <!-- Include Bunker for Reliq -->
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label><b>Include Bunker for Reliq?</b></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputs.vessel_logistics.include_bunker_operation_for_reliq">
                                <mat-option value="No">No</mat-option>
                                <mat-option value="Yeosu">Yeosu</mat-option>
                                <mat-option value="HongKong">HongKong</mat-option>
                                <mat-option value="Singapore">Singapore</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="em-l-grid__item">
                        <mat-label>Fixed Costs / Barges, etc</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.fixed_costs_barges_etc">
                    </div>

                    <div class="em-l-grid__item">
                        <mat-label>Days at anchorage for bunkering</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Days</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.days_at_anchorage_for_bunker">
                    </div>
                </div>

                <!--Positioning Bonus-->
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label><b>Positioning Bonus</b></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="positioningBonus">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Charter Rate</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">%</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.positioning_bonus_details.display_charter_rate"
                        (ngModelChange)="lngInputs.vessel_logistics.positioning_bonus_details.charterRateChanged()">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Fuel Rate</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">%</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.positioning_bonus_details.display_fuel_rate"
                        (ngModelChange)="lngInputs.vessel_logistics.positioning_bonus_details.fuelRateChanged()">
                    </div>
                </div>		
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Fuel Agreed (LNG or HFO)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right"></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputs.vessel_logistics.positioning_bonus_details.fuel_type_agreed">
                                <mat-option value="LNG">LNG</mat-option>
                                <mat-option value="HFO">HFO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Speed</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">knots</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputs.vessel_logistics.positioning_bonus_details.speed">
                                <mat-option *ngFor="let vessel_speed of apiVesselSpeeds" [value]="vessel_speed">{{vessel_speed}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">or Lump-Sum</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">US$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.positioning_bonus_details.lumpsum">
                    </div>
                </div>

                <!--Ballast (Repositioning) Bonus-->
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label><b>Ballast (Repositioning) Bonus</b></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="repositioningBonus">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Charter Rate</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">%</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.repositioning_bonus_details.display_charter_rate" 
                        (ngModelChange)="lngInputs.vessel_logistics.repositioning_bonus_details.charterRateChanged()">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Fuel Rate</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">%</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.repositioning_bonus_details.display_fuel_rate" 
                        (ngModelChange)="lngInputs.vessel_logistics.repositioning_bonus_details.fuelRateChanged()">
                    </div>
                </div>		
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Fuel Agreed (LNG or HFO)</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right"></mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputs.vessel_logistics.repositioning_bonus_details.fuel_type_agreed">
                                <mat-option value="LNG">LNG</mat-option>
                                <mat-option value="HFO">HFO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Speed</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">knots</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-form-field>
                            <mat-select [(ngModel)]="lngInputs.vessel_logistics.repositioning_bonus_details.speed">
                                <mat-option *ngFor="let vessel_speed of apiVesselSpeeds" [value]="vessel_speed">{{vessel_speed}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">or Lump-Sum</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">US$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.vessel_logistics.repositioning_bonus_details.lumpsum">
                    </div>
                </div>
            
                <!-- Slot, Fixed & Misc Costs -->
                <div class="em-l-bgblackwhite">
                    Slot, Fixed & Miscellaneous Costs
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Commercial Premium Paid for Discharge Slot</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.slot_fixed_miscellaneous_cost.commercial_premium_paid_for_discharge_slot">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Cargo Insurance</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.slot_fixed_miscellaneous_cost.cargo_insurance">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Other Insurance</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.slot_fixed_miscellaneous_cost.other_insurance">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Cost of Capital</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">%</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.slot_fixed_miscellaneous_cost.display_cost_of_capital_apr"
                        (ngModelChange)="lngInputs.slot_fixed_miscellaneous_cost.costOfCapitalChanged()">
                    </div>
                </div>
                <div class="em-l-grid em-l-grid--3up">
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">Miscellaneous Cost</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <mat-label class="em-al-right">$</mat-label>
                    </div>
                    <div class="em-l-grid__item">
                        <input  class="em-al-right" type="text" [(ngModel)]="lngInputs.slot_fixed_miscellaneous_cost.miscellaneous_cost">
                    </div>
                </div>
            
            </div>
        </div>
    </div>
</div>
