import { Component, Input, OnInit } from '@angular/core';
import { RiskMetricsService } from '../services/risk-metrics.service';
import { DateHelperService } from '../services/date-helper.service';
import { SecurityInfo } from '../models/security-info.model';
import { finalize } from 'rxjs/operators';
import { optionCalcResult, OptionsCalculateItem } from './options-calculator.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-options-calculatoritem',
  templateUrl: './options-calc-item.component.html',
  styleUrls: ['./options-calc-item.component.scss']
})

export class OptionsCalcItem implements OnInit {
  donotshow: boolean = true;
  constructor(private riskMetricsService: RiskMetricsService, private dateHelperService: DateHelperService) {
      
  }
  isChildReady: Subject<boolean> = new Subject();
  deleteChild: Subject<string> = new Subject();

  securityInfo: SecurityInfo[] = [];
  selectedType: string = "European";

  initialized: boolean = false;
  public optionResult = new optionCalcResult();
  public optionCalcItem = new OptionsCalculateItem();

  ngOnInit(): void {
    this.securityFill();
    if(!this.optionCalcItem.initialized){
      this.initialized = true;
      this.getforwardPrice();
      this.optionCalcItem.initialized = true;
    }
  }

  securityFill(): void{
    this.securityInfo = []
    this.securityInfo.push(new SecurityInfo("H", "Henry Hub", "Flat", "AGOT"));
    this.securityInfo.push(new SecurityInfo("", "Unknown", "Flat", "EGOT"));
  }

  getforwardPrice(): void{
    if(this.optionCalcItem.selectedAsset != ''){
      var stripDate = this.dateHelperService.getDateyyyyMMdd(this.optionCalcItem.expiryDate).substring(0,7);
      this.riskMetricsService.getEODPrice(this.optionCalcItem.selectedAsset, stripDate).pipe(finalize(() => {
        this.getVolatility();
      })).subscribe(
        res => {
          this.optionCalcItem.strikePrice = res.end_of_date_price;
          this.optionCalcItem.forwardPrice = res.end_of_date_price;
          this.optionCalcItem.currentPriceF0 = this.optionCalcItem.forwardPrice;
        });
    } else {
      this.initialized = false;
      this.isChildReady.next(true);
    }
  }

  getVolatility(): void{
    if(this.optionCalcItem.selectedModel=='Black76'){
      var pricedate = new Date(this.dateHelperService.getDateddMMyyyy(this.optionCalcItem.priceDate));
      pricedate =  this.dateHelperService.getDateyyyyMMdd(this.dateHelperService.getLastWeekday(pricedate).toLocaleDateString('en-US'));
      var secInfo = this.securityInfo.find(x => x.security == this.optionCalcItem.selectedAsset);
      var inputs = {
        region: secInfo.region_office,
        trade_date: pricedate,
        vol_type: 'implied'
      }
      this.riskMetricsService.getVolatilityTable(inputs)
        .pipe(finalize(() =>  {
          this.getOptionsCalc();
      })).subscribe(
        res => {
          if(secInfo){
            var securityVol = res.find(x => x.hub == "Henry Penultimate");
            //var securityVol = res.find(x => x.hub == secInfo.hub);
            if(securityVol){
              var tradeDate = new Date(this.dateHelperService.getDateddMMyyyy(this.optionCalcItem.expiryDate));
              var monthDay = tradeDate.toLocaleDateString('en-us', { year:"numeric", month:"short"}).replace("20", "");
              if(securityVol[monthDay]){
                this.optionCalcItem.annualVolatility = this.ToFixedNum(securityVol[monthDay] * 100, 3);
              }
            }
          }
      });
    } else {
      this.optionCalcItem.annualVolatility = 1;
      this.getOptionsCalc();
    }
  }

  ToFixedNum(val: any, decp:number): number{
    var num = parseFloat(val);
    return parseFloat(num.toFixed(decp));
  }

  prepareInputs(): any{
    var pricedate = new Date(this.dateHelperService.getDateddMMyyyy(this.optionCalcItem.priceDate));
    var expirydate = new Date(this.dateHelperService.getDateddMMyyyy(this.optionCalcItem.expiryDate));
    var days = this.dateHelperService.getDayDiff(expirydate, pricedate);
    var t = days / 365; //TODO:: leap year 366 may be (check with Dennis)
    var Ft = this.optionCalcItem.forwardPrice;
    var K = this.optionCalcItem.strikePrice;
    var r = this.optionCalcItem.usdDepo / 100;
    var divider = (this.optionCalcItem.selectedModel=='Black76') ? 100 : 1;
    var annual_vol = this.optionCalcItem.annualVolatility / divider;

    var inputs = {};
    inputs["T"] = t;
    inputs["Ft"] = Ft;
    inputs["F0"] = Ft;
    inputs["K"] = K;
    inputs["r"] = r;
    inputs["annual_vol"] = annual_vol;
    inputs["option_type"] = this.optionCalcItem.selectedCallPut;
    inputs["model_name"] = this.optionCalcItem.selectedModel;
    return inputs;
  }

  getOptionsCalc(): void {
    var optionInputs = this.prepareInputs();
    if(optionInputs["Ft"] && optionInputs["F0"] && !isNaN(optionInputs["annual_vol"]) && !isNaN(optionInputs["r"])){
      this.initialized = true;
      this.riskMetricsService.getOptionsCalculate(optionInputs).pipe(finalize(() => {
        this.initialized = false;
      })).subscribe(res => { 
        this.optionResult = JSON.parse(res.model_valuation);
        this.optionCalcItem.result = this.optionResult;
        this.isChildReady.next(true);
      });
    }
  }

  expiryDateChange(){
    var stripDate = this.dateHelperService.getDateyyyyMMdd(this.optionCalcItem.expiryDate).substring(0,7);
    this.riskMetricsService.getEODPrice(this.optionCalcItem.selectedAsset, stripDate).pipe(finalize(() => {
      this.getVolatility();
    })).subscribe(
      res => {
        this.optionCalcItem.forwardPrice = res.end_of_date_price;
      });
    var today = new Date(this.dateHelperService.getDateddMMyyyy(this.optionCalcItem.expiryDate));
    today = this.dateHelperService.getNextMonth(today);
    this.optionCalcItem.deliveryDate = this.dateHelperService.getDateyyyyMMdd(today.toLocaleDateString('en-US'));
  }

  deleteItem(){
    this.deleteChild.next(this.optionCalcItem.uniqueKey);
  }

}

