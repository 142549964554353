import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { FileSaverService } from 'ngx-filesaver';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ChartSettings } from '../../helpers/chart-settings';
import { GlobalArbService } from '../../services/global-arb.service';
import { NetbackCalculationComponent } from './netback-calculation/netback-calculation.component';
import { DateHelperService } from '../../services/date-helper.service';

export interface destClass{
  area: string;
  ordinal:number;
  names:string[];
}

@Component({
  selector: 'app-netback',
  templateUrl: './netback.component.html',
  styleUrls: ['./netback.component.scss']
})
export class NetbackComponent implements OnInit, OnDestroy {

  tradeDate: string;
  today: string;

  dataSource = new MatTableDataSource();
  displayedColumns: string[];

  isCalculating = false;
  isDownloading = false;

  showNetbackSettings = true;

  netbackData: any;

  selectedOrigin: string;
  allOrigins: string[] = [];
  allDestinations: string[] = [];
  selectedDestinations: string[] = [];
  defaultSelectedDest =  ['NWE DES (CME)', 'SWE DES (ICE)', 'TTF', 'Futtsu'];

  destinationsTree: destClass[] = [
    { area: "East Asia", ordinal:1, names:["Futtsu"] },
    { area: "NW Europe", ordinal:2, names:["PEG", "NWE Avg", "TTF", "NBP", "ZTP"] },
    { area: "SW Europe", ordinal:3, names:["PVB", "PSV"] },
    { area: "DES", ordinal:4, names:["NWE DES (CME)", "NWE DES (ICE)", "SWE DES (ICE)"]}
  ];

  diffHub: string;

  netbackProfitChartData: ChartConfiguration['data'];
  netbackProfitChartOptions: ChartConfiguration['options'];

  priceSpreadChartData: ChartConfiguration['data'];
  priceSpreadChartOptions: ChartConfiguration['options'];

  shippingDecompChartData: ChartConfiguration['data'];
  shippingDecompChartOptions: ChartConfiguration['options'];

  netbackLagOneChartData: ChartConfiguration['data'];
  netbackLagOneChartOptions: ChartConfiguration['options'];
  netbackLagTwoChartData: ChartConfiguration['data'];
  netbackLagTwoChartOptions: ChartConfiguration['options'];

  netbackLegTwoChartData: ChartConfiguration['data'];
  netbackLegTwoChartOptions: ChartConfiguration['options'];

  @ViewChildren(BaseChartDirective) charts?: QueryList<BaseChartDirective>;

  private subscription: Subscription;

  constructor(private globalArbService: GlobalArbService, private fileSaverService: FileSaverService, private dialog: MatDialog, private titleService: Title, private dateHelperService: DateHelperService) {
    this.titleService.setTitle('Trading Preview UI | Netback');
    this.initializeChartSettings();
  }

  ngOnInit(): void {
    this.tradeDate =  this.dateHelperService.getDateyyyyMMdd(new Date().toLocaleDateString('en-CA'));
    this.today = this.dateHelperService.getDateyyyyMMdd(new Date().toLocaleDateString('en-CA'));
    this.globalArbService.getDeparturePortSettings(this.tradeDate).subscribe(portSettings => { this.allOrigins = Object.keys(portSettings) });
    this.subscription = this.globalArbService.departurePort.subscribe(port => this.selectedOrigin = port);
    this.subscription.add(this.globalArbService.netbackSettings.subscribe(settings => {
      if (settings) {
        this.calculateNetback();
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateOrigin(): void {
    // Calling this will update the departure port, which will trigger a change to the netbackSettings in globalArbService,
    // which will trigger a call to calculateNetback
    this.selectedDestinations = [];
    this.globalArbService.updateDeparturePort(this.selectedOrigin);
  }

  tradeDateChange(): void{
    this.globalArbService.departurePortSettingsCache$ = null;
    this.calculateNetback();
  }

  calculateNetback(): void {
    this.isCalculating = true;
    this.globalArbService.getDeparturePortSettings(this.tradeDate).subscribe(portSettings => { this.allOrigins = Object.keys(portSettings) });
    this.globalArbService.calculateNetback(this.tradeDate).pipe(finalize(() => this.isCalculating = false)).subscribe(res => {
      this.netbackData = res;

      if (this.netbackData.netback?.length > 0) {
        this.allDestinations = Object.keys(this.netbackData.netback.find(Boolean)).slice(1).sort();
        
        this.destinationsTree = [];
        this.allDestinations.forEach((ele) =>{
          if(['Futtsu'].includes(ele)){
            this.destinationsTree.push({ area: "East Asia", ordinal:1, names:["Futtsu"] })
          } else if(["PEG", "TTF", "NBP", "ZTP" ].includes(ele)){
            this.pushOrAddDestTree('NW Europe', ele, 2);
          } else if(["PVB", "PSV"].includes(ele)){
            this.pushOrAddDestTree('SW Europe', ele, 3);
          } else if(["NWE DES (CME)", "NWE DES (ICE)", "SWE DES (ICE)"].includes(ele)){
            this.pushOrAddDestTree('DES', ele, 4);
          }
        });
        if(this.allDestinations.includes("NWE Avg")){
          this.pushOrAddDestTree('NW Europe', "NWE Avg", 2);
        }
        this.destinationsTree = this.destinationsTree.sort((a,b) => a.ordinal-b.ordinal);
        this.defaultSelectedDest.forEach((ele) => {
          if(this.allDestinations.find(x => x === ele)){
            this.selectedDestinations.push(ele);
          }
        });
        this.updateData();
      }
      else {
        this.reset();
      }

    }, err => {
      this.reset();
    });
  }

  pushOrAddDestTree(area:string, name:string, order:number): void {
    var dcNW = this.destinationsTree.find(dc => dc.area == area);
    if(typeof dcNW == "undefined"){
      var newIdx = this.destinationsTree.push({ area: area, ordinal:order, names:[] });
      this.destinationsTree[newIdx-1].names.push(name);
    } else {
      dcNW.names.push(name);
    }
  }

  downloadNetback(): void {
    this.isDownloading = true;
    this.globalArbService.downloadNetback(this.tradeDate, this.selectedOrigin)
      .pipe(finalize(() => this.isDownloading = false))
      .subscribe(res => this.fileSaverService.save(res.blob, res.fileName));
  }

  updateNetbackTable(): void {
    this.dataSource.data = this.netbackData?.netback;
    if (this.dataSource.data.length > 0) {
      this.displayedColumns = Object.keys(this.dataSource.data.find(Boolean)).filter((x, index) => {
        return index === 0 || (this.selectedDestinations.includes(x) && x != this.diffHub);
      });
    } else {
      this.displayedColumns = [];
    }
  }

  public chartColors = {
    "Futtsu": '#949fb1',
    "TTF": '#f5894b',
    "NBP":'#75d6ec',
    "ZTP":'#ed363a',
    "PEG":'#19294c',
    "PVB":'#0091e2',
    "PSV":'#008d44',
    'NWE Avg':'#d2e271',
    "NWE DES (CME)": '#800080',
    "NWE DES (ICE)":'#0ff',
    "SWE DES (ICE)": '#f0f',
    };

  updateNetbackChart(): void {
    this.netbackProfitChartData.datasets = [];

    const data = this.dataSource.data;
 
    for (const col of this.displayedColumns.slice(1)) {
      if (col != this.diffHub) {

        const profitData = !this.diffHub ? data.map(x => x[col]) : data.map(x => x[col] - x[this.diffHub]);
        this.netbackProfitChartData.datasets.push({
          data: profitData,
          label: col,
          backgroundColor: this.chartColors[col],
          borderColor: this.chartColors[col],
          pointBackgroundColor: this.chartColors[col],
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: this.chartColors[col]
        });
      }
    }

    this.netbackProfitChartData.labels = data.map(x => x['Delivery Month']);

    let title = 'Netback Profit';
    if(this.diffHub)
      title = `Netback Profit (Difference with ${this.diffHub})`;

    this.netbackProfitChartOptions.plugins.title.text = title;
    this.charts.first.options.plugins.title.text = title;
    this.charts.first.render();
  }

  updatePriceSpreadChart(): void {
    this.priceSpreadChartData.datasets = [];

    let diffSpreadData = [];
    if (this.diffHub) {
      diffSpreadData = Object.values(this.netbackData.price_spread[this.diffHub]);
    }

    this.selectedDestinations.forEach(dest => {
      if (dest === this.diffHub)
        return;

      const priceSpread = this.netbackData.price_spread[dest];
      let spreadData: number[] = Object.values(priceSpread);
      if (this.diffHub) {
        spreadData = spreadData.map((p, index) => p - diffSpreadData[index]);
      }

      this.priceSpreadChartData.labels = Object.keys(priceSpread);

      this.priceSpreadChartData.datasets.push({
        data: spreadData,
        label: dest,
        backgroundColor: this.chartColors[dest],
        borderColor: this.chartColors[dest],
        pointBackgroundColor: this.chartColors[dest],
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: this.chartColors[dest]
      });
    });

    let title = 'Destination Price - FOB Spread';
    if(this.diffHub)
      title = `Destination Price - ${this.diffHub} Spread`;

    this.priceSpreadChartOptions.plugins.title.text = title;
    this.charts.get(1).options.plugins.title.text = title;
    this.charts.get(1).render();
  }

  updateDecompChart(): void {
    this.shippingDecompChartData.datasets = [];

    let diffDecompData = {};
    if (this.diffHub) {
      diffDecompData = Object.values(this.netbackData?.shipping_cost_decomposition[this.diffHub]);
    }

    this.allDestinations.forEach(dest => {
      if (this.selectedDestinations.findIndex(x => dest.includes(x) && !dest.includes(this.diffHub)) < 0)
        return;

      const decompData = this.netbackData?.shipping_cost_decomposition[dest];

      this.shippingDecompChartData.labels = Object.keys(decompData);

      const costGroup = Object.keys(Object.values(decompData).find(x => Object.keys(x).length > 0));
      costGroup.forEach((cost, index) => {
        let data = !this.diffHub ?
          Object.values(decompData).map(x => x[cost]) :
          Object.values(decompData).map((x, index) => x[cost] - diffDecompData[index][cost]);

        this.shippingDecompChartData.datasets.push({
          data: data,
          label: cost,
          stack: dest,
          backgroundColor: ChartSettings.colors[index+1],
          borderColor: ChartSettings.highlightColors[index+1],
          hoverBackgroundColor: ChartSettings.highlightColors[index+1]
        });
      });
    });

    let title = 'Shipping Cost Decomposition';
    if(this.diffHub)
      title = `Shipping Cost Decomposition (Difference with ${this.diffHub})`;

    this.shippingDecompChartOptions.plugins.title.text = title;
    this.charts.get(2).options.plugins.title.text = title;
    this.charts.get(2).render();
  }

  
  updateLagChart(lagmon: string): void {
    let data = (lagmon == 'One') ? this.netbackData?.lag_by_one : this.netbackData?.lag_by_two;
    
    let tempDataset = [];
    for (const col of this.displayedColumns.slice(1)) {
      if (col != this.diffHub) {
        const profitData = !this.diffHub ? data.map(x => x[col]) : data.map(x => x[col] - x[this.diffHub]);
        tempDataset.push({
          data: profitData,
          label: col,
          backgroundColor: this.chartColors[col],
          borderColor: this.chartColors[col],
          pointBackgroundColor: this.chartColors[col],
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: this.chartColors[col]
        });
      }
    }

    let title = lagmon + ' Month Cargo Float Incentive';
    if(this.diffHub)
      title = `${lagmon} Month Cargo Float Incentive (Difference with ${this.diffHub})`;
    if(lagmon === 'One'){
      this.netbackLagOneChartData.datasets = tempDataset;
      this.netbackLagOneChartData.labels = data.map(x => x['Delivery Month']);

      this.netbackLagOneChartOptions.plugins.title.text = title;
      this.charts.get(3).options.plugins.title.text = title;
      this.charts.get(3).render();
    } else if(lagmon === 'Two'){
      this.netbackLagTwoChartData.datasets = tempDataset;
      this.netbackLagTwoChartData.labels = data.map(x => x['Delivery Month']);
  
      this.netbackLagTwoChartOptions.plugins.title.text = title;
      this.charts.last.options.plugins.title.text = title;
      this.charts.last.render();
    }
  }

  updateData(): void {
    this.updateNetbackTable();
    this.updateNetbackChart();
    this.updatePriceSpreadChart();
    this.updateDecompChart();
    this.updateLagChart('One');
    this.updateLagChart('Two');
  }

  clearDiffHub(): void {
    this.diffHub = null;
    this.updateData();
  }

  clearCharts(): void {
    this.netbackProfitChartData.datasets = [];
    this.priceSpreadChartData.datasets = [];
    this.shippingDecompChartData.datasets = [];
    this.netbackLagOneChartData.datasets = [];
    this.netbackLagTwoChartData.datasets = [];
    this.charts.forEach(c => c.update());
  }

  reset(): void {
    this.netbackData = null;
    this.updateNetbackTable();
    this.clearCharts();
  }

  isNaN(value: any): boolean {
    return isNaN(value);
  }

  openNetbackCalculationImage() {
    this.dialog.open(NetbackCalculationComponent, {
      width: '80vw',
      height: '80vh'
    })
  }

  private initializeChartSettings(): void {
    this.netbackProfitChartData = { datasets: [] };
    this.netbackProfitChartOptions = {
      scales: {
        x: {
          title: {
            text: 'Delivery Month',
            display: true
          },
          ...ChartSettings.defaultTickSettings,
          ...ChartSettings.defaultGridSettings
        },
        y: {
          title: {
            text: '$/MMBTU',
            display: true
          },
          ...ChartSettings.defaultGridSettings
        }
      },
      aspectRatio: 4,
      elements: {
        line: {
          borderWidth: 2
        }
      },
      plugins: {
        title: {
          display: true,
          text: 'Netback Profit'
        }
      }
    };

    this.netbackLagOneChartData = { datasets: [] };
    this.netbackLagOneChartOptions = {
      scales: {
        x: {
          title: {
            text: 'Delivery Month',
            display: true
          },
          ...ChartSettings.defaultTickSettings,
          ...ChartSettings.defaultGridSettings
        },
        y: {
          title: {
            text: '$/MMBTU',
            display: true
          },
          ...ChartSettings.defaultGridSettings
        }
      },
      aspectRatio: 4,
      elements: {
        line: {
          borderWidth: 2
        }
      },
      plugins: {
        title: {
          display: true,
          text: 'Netback Difference If Depart One Month Earlier'
        }
      }
    };

    this.netbackLagTwoChartData = { datasets: [] };
    this.netbackLagTwoChartOptions = {
      scales: {
        x: {
          title: {
            text: 'Delivery Month',
            display: true
          },
          ...ChartSettings.defaultTickSettings,
          ...ChartSettings.defaultGridSettings
        },
        y: {
          title: {
            text: '$/MMBTU',
            display: true
          },
          ...ChartSettings.defaultGridSettings
        }
      },
      aspectRatio: 4,
      elements: {
        line: {
          borderWidth: 2
        }
      },
      plugins: {
        title: {
          display: true,
          text: 'Netback Difference If Depart Two Month Earlier'
        }
      }
    };


    this.priceSpreadChartData = { datasets: [] };
    this.priceSpreadChartOptions = {
      scales: {
        x: {
          title: {
            text: 'Delivery Month',
            display: true
          },
          ...ChartSettings.defaultTickSettings,
          ...ChartSettings.defaultGridSettings
        },
        y: {
          title: {
            text: '$/MMBTU',
            display: true
          },
          ...ChartSettings.defaultGridSettings,
        },
      },
      aspectRatio: 4,
      elements: {
        line: {
          borderWidth: 2
        }
      },
      plugins: {
        title: {
          display: true,
          text: 'Destination Price - FOB Spread'
        }
      }
    }

    this.shippingDecompChartData = { datasets: [] };
    this.shippingDecompChartOptions = {
      scales: {
        x: {
          title: {
            text: 'Delivery Month',
            display: true
          },
          stacked: true,
          ...ChartSettings.defaultTickSettings,
          ...ChartSettings.defaultGridSettings
        },
        y: {
          title: {
            text: '$/MMBTU',
            display: true
          },
          ...ChartSettings.defaultGridSettings,
          stacked: true
        },
      },
      aspectRatio: 4,
      plugins: {
        legend: {
          labels: {
            boxHeight: 5,
            // Only show legend for first 6 datasets, otherwise you'll get one for each dataset
            filter: item => {
              return item.datasetIndex < 6;
            }
          },
          // disables "default" show/hide dataset on click
          onClick: null,
        },
        title: {
          display: true,
          text: 'Shipping Cost Decomposition'
        },
        tooltip: {
          callbacks: {
            beforeTitle: (context) => {
              return context.find(Boolean).dataset.stack;
            },
          }
        }
      }
    }
  }
}

