import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SecurityInfo } from '../models/security-info.model';
import { DateHelperService } from '../services/date-helper.service';
import { OptionsCalculateItem } from './options-calculator.model';
import { OptionsCalcItem } from './options-calc-item.component';
import { SessionService } from './options-calculator.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-options-calculator',
  templateUrl: './options-calculator.component.html',
  styleUrls: ['./options-calculator.component.scss']
})

export class OptionsCalculatorComponent implements OnInit {
  isReady: boolean = false;
  optionCalcs:number = 0;
  asOfDate: string;
  selectedAsset: string = "H";
  selectedQuote: string = "USD";
  securityInfo: SecurityInfo[] = [];
  localTabRefName: string;
  selectedModel: string = "Black76";
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  input = OptionsCalcItem;
  public optionCalcItems: OptionsCalculateItem[] = []; 
  deleteTheTab: Subject<string> = new Subject();
  
  
  constructor(private titleService: Title, private dateHelperService: DateHelperService, private localStore: SessionService) {
    this.titleService.setTitle('Trading Preview UI | Options Calculator');
  }

  ngOnInit(): void {
    var today = new Date();
    this.asOfDate = this.dateHelperService.getDateyyyyMMdd(today.toLocaleDateString('en-US')); 
    this.securityFill();
    
    setTimeout(() => {
      this.loadOptionCalcItems(false);
    }, 300);
  }
  
  securityFill(){
    this.securityInfo = []
    this.securityInfo.push(new SecurityInfo("H", "Henry Hub", "Flat", "AGOT"));
    this.securityInfo.push(new SecurityInfo("", "Unknown", "Flat", "EGOT"));
  }

  loadOptionCalcItems(calcPrice:boolean){
    this.container.clear();
    this.optionCalcItems.forEach(oci => {
      this.setDates(oci);
      const component = this.container.createComponent(this.input);
      component.instance.optionCalcItem = oci;
      if(calcPrice){
        component.instance.getforwardPrice();
      } else {
        component.instance.optionResult = oci.result;
      }
      component.instance.isChildReady.subscribe( v =>{
        this.isReady = v;
        this.localStore.saveData(oci.tabRefName, JSON.stringify(this.optionCalcItems));
      });
      component.instance.deleteChild.subscribe( v => {
        this.deleteItem(v);
        this.localStore.saveData(oci.tabRefName, JSON.stringify(this.optionCalcItems));
      });
    });

    if(this.optionCalcItems.length>0){
      this.selectedAsset = this.optionCalcItems[0].selectedAsset;
      this.selectedModel = this.optionCalcItems[0].selectedModel;
      this.asOfDate = this.optionCalcItems[0].priceDate;
    }
  }

  deleteItem(ukey: string){
    for (var i = this.optionCalcItems.length - 1; i >= 0; --i) {
      if (this.optionCalcItems[i].uniqueKey == ukey) {
        this.optionCalcItems.splice(i,1);
      }
    }
    this.loadOptionCalcItems(false);
  }

  add() {
    this.isReady = false;
    var nextItem = new OptionsCalculateItem();
    nextItem.uniqueKey = Math.random().toString(36).slice(2, 9);
    if(this.optionCalcItems.length>0){
      nextItem.tabRefName = this.optionCalcItems[0].tabRefName;
    } else {
      nextItem.tabRefName = this.localTabRefName;
    } 
    nextItem.priceDate = this.asOfDate;
    nextItem.selectedAsset = this.selectedAsset;
    nextItem.selectedModel = this.selectedModel;
    this.optionCalcItems.push(nextItem);
    this.loadOptionCalcItems(false);
  }

  setDates(oci: OptionsCalculateItem){
    if(!oci.initialized){
      var today = new Date(this.dateHelperService.getDateddMMyyyy(this.asOfDate));
      oci.priceDate = this.dateHelperService.getDateyyyyMMdd(today.toLocaleDateString('en-US'));

      var len = this.optionCalcItems.length;
      if(len > 1){
        today = new Date(this.dateHelperService.getDateddMMyyyy(this.optionCalcItems[len-2].expiryDate));
      }
      today = this.dateHelperService.getNextMonth(today);
      oci.expiryDate = this.dateHelperService.getDateyyyyMMdd(today.toLocaleDateString('en-US'));
      today = this.dateHelperService.getNextMonth(today);
      oci.deliveryDate = this.dateHelperService.getDateyyyyMMdd(today.toLocaleDateString('en-US'));
    }
  }

  dateOrSecurityChange(){
    this.optionCalcItems.forEach(oci => {
      oci.selectedAsset = this.selectedAsset;
      if(oci.selectedAsset == ''){
        oci.forwardPrice = null;
        oci.strikePrice = null;
        oci.annualVolatility = null;
      }
      oci.priceDate = this.asOfDate;
    });
    this.loadOptionCalcItems(true);
  }

  modelChange(){
    this.optionCalcItems.forEach(oci => {
      oci.selectedModel = this.selectedModel;
    });
    this.loadOptionCalcItems(true);
  }

  deleteTab(){
    var tabkey = this.optionCalcItems.length>0 ? this.optionCalcItems[0].uniqueKey : this.localTabRefName;
    this.deleteTheTab.next(tabkey);
    this.localStore.removeData(this.localTabRefName);
  }
}
