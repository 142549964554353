import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { FileSaverService } from 'ngx-filesaver';
import { ChartSettings } from '../../helpers/chart-settings';
import { jsonToCsv } from '../../helpers/json-to-csv';
import { DateHelperService } from '../../services/date-helper.service';
import { RiskMetricsService } from '../../services/risk-metrics.service';

@Component({
  selector: 'app-fx-curves',
  templateUrl: './fx-curves.component.html',
  styleUrls: ['./fx-curves.component.scss']
})
export class FxCurvesComponent implements OnInit {

  constructor(private riskMetricsService: RiskMetricsService, private fileSaverService: FileSaverService, private dateHelperService: DateHelperService, private titleService: Title) {
    this.titleService.setTitle('Trading Preview UI | FX Curves');
  }

  minAsOfDate: string;
  maxAsOfDate: string;
  asOfDate: string;

  fxChartData: ChartConfiguration['data'] = { datasets: [] };

  lineChartOptions: ChartConfiguration['options'] = {
    scales: {
      x: {
        ...ChartSettings.defaultTickSettings
      },
    },
  };

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;


  ngOnInit(): void {
    this.getMacroCurveDates();
  }

  getMacroCurveDates(): void {
    this.riskMetricsService.getMacroCurvesFXDates().subscribe(res => {
      this.minAsOfDate = res[0];
      this.maxAsOfDate = res[res.length - 1];
      this.asOfDate = this.maxAsOfDate;

      this.fetchChartData();
    }, err => {
      const lastWeekday = this.dateHelperService.getLastWeekday(new Date()).toLocaleDateString('en-CA');
      this.asOfDate = lastWeekday;
      this.minAsOfDate = lastWeekday;
      this.maxAsOfDate = lastWeekday;
      this.fetchChartData();
    });
  }

  fetchChartData(): void {
    this.riskMetricsService.getMacroCurvesFX(this.asOfDate).subscribe(res => {

      this.fxChartData.datasets = [];

      this.fxChartData.datasets.push({
        data: res.fx_eur?.eur_historical,
        label: 'EUR Historical',
        pointBackgroundColor: '#00af53',
        pointHoverBorderColor: '#00af53',
        borderColor: '#00af53'
      });
      this.fxChartData.datasets.push({
        data: res.fx_eur?.eur_futures,
        label: 'EUR Futures',
        pointBackgroundColor: '#b4d405',
        pointHoverBorderColor: '#b4d405',
        borderColor: '#b4d405',
        borderDash: [5, 15]
      });
      this.fxChartData.datasets.push({
        data: res.fx_gbp?.gbp_historical,
        label: 'GBP Historical',
        pointBackgroundColor: '#7a4183',
        pointHoverBorderColor: '#7a4183',
        borderColor: '#7a4183'
      });
      this.fxChartData.datasets.push({
        data: res.fx_gbp?.gbp_futures,
        label: 'GBP Futures',
        pointBackgroundColor: '#b10040',
        pointHoverBorderColor: '#b10040',
        borderColor: '#b10040',
        borderDash: [5, 15]
      });
      this.fxChartData.labels = res.fx_eur?.dates;

      this.chart.update();
    });

  }

  saveFxData(): void {
    const data = {
      date: this.fxChartData.labels,
      eurHistorical: this.fxChartData.datasets[0].data,
      eurFutures: this.fxChartData.datasets[1].data,
      gbpHistorical: this.fxChartData.datasets[2].data,
      gbpFutures: this.fxChartData.datasets[3].data
    }
    this.fileSaverService.saveText(jsonToCsv(data), `fx${this.asOfDate}.csv`);
  }
}
