import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import { DateHelperService } from '../services/date-helper.service';
import { RiskMetricsService } from '../services/risk-metrics.service';
import { MatTableDataSource } from '@angular/material/table';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ChartSettings } from '../helpers/chart-settings';
import { MatSelect } from '@angular/material/select';
import { FileSaverService } from 'ngx-filesaver';
import { ChartData, ChartOptions, ChartType, Legend } from 'chart.js';

interface TransitionInfo {
  /** Start date for transition */
  from_date: string;
  /** Length of transition */
  length_in_months: number;
}

@Component({
  selector: 'app-price-paths',
  templateUrl: './price-paths.component.html',
  styleUrls: ['./price-paths.component.scss'],
})
export class PricePathsComponent implements OnInit {
  @ViewChild('multiSelect') multiSelect: MatSelect;
  @ViewChildren(BaseChartDirective) charts?: QueryList<BaseChartDirective>;

  pricePathsData: ChartData;
  pricePathsOptions: ChartOptions;
  fileLongTerm: number[];

  dataSource = new MatTableDataSource();
  dataColumns: string[];
  nextWeekdayStr: string;
  businessDays: number;

  region: string;
  currency: string;
  alphaCI: string;
  dateHorizon: string;

  showCalculatingError = false;
  isCalculating = false;
  refreshTime: Date;
  displayedColumns: string[] = [];

  asOfDate: string;
  endMonth: string;
  minAsOfDate: string;
  maxAsOfDate: string;
  selectedCPYear: string;
  hubs: string[] = ['Brent', 'Henry', 'TTF', 'JKM', 'WIM', 'WTI'];
  selectedHubs: string[] = ['Brent'];
  exportTransposed: boolean = false;
  granularity: string = "monthly";
  transitionInfo: Record<string, TransitionInfo>;
  useVariableHalfLife: boolean = false;
  displayedCategory: string = 'all';
  displayedCurves: string[];
  initialized = true;
  transitionDefaultsFetched: boolean = false;

  constructor(
    private riskMetricsService: RiskMetricsService,
    private dateHelperService: DateHelperService,
    private fileSaverService: FileSaverService,
    private titleService: Title
  ) {
    this.titleService.setTitle('Trading Preview UI | Price Paths');
  }

  ngOnInit(): void {
    var today = this.dateHelperService.getLastWeekday(new Date());
    this.asOfDate = this.dateHelperService.getDateyyyyMMdd(
      today.toLocaleDateString('en-US')
    );
    this.minAsOfDate = this.dateHelperService
      .getDateyyyyMMdd(
        this.dateHelperService.getNextMonth(today).toLocaleDateString('en-US')
      )
      .substring(0, 7);
    this.endMonth = '2050-01';
    this.selectedCPYear = 'CP23';
    this.fileLongTerm = [];

    this.fetchNewTransitionDefaults();
    // listen for search field value changes
    this.initializeChartSettings();
  }

  resetTransitions() {
    this.transitionInfo = {};
    this.hubs.forEach((hub) => {
      this.transitionInfo[hub] = {
        from_date: null,
        length_in_months: 12,
      };
    });
  }

  filterCurvesByCategory(category: string, selectedHubs: string[] = null) {
    // Don't want to change selections when going to custom
    if (category === 'custom') {
      return;
    }

    // First, filter by selected hubs, which can be passed as a parameter or taken from the model
    const hubsToFilter =
      selectedHubs === null ? this.selectedHubs : selectedHubs;
    const hubCurves =
      this.dataColumns?.filter((col) =>
        hubsToFilter.some((hub) =>
          col.toLowerCase().includes(hub.toLowerCase())
        )
      ) ?? [];

    // Show all curves for the selected hubs
    if (category === 'all') {
      this.displayedCurves = hubCurves;
    } else {
      // Get the proper category array based on selection. Some of the user's options necessitate multiple strings to filter on.
      let categories: string[];
      if (category === 'p') {
        categories = ['p10', 'p50', 'p90'];
      } else {
        categories = [category.toLowerCase()];
      }
      const categoryCurves = hubCurves.filter((hubCurve) =>
        categories.some((cat) => hubCurve.toLowerCase().includes(cat))
      );
      this.displayedCurves = categoryCurves;
    }
    this.populateTimeSeriesChart();
  }

  toggleCurve(curve: string) {
    if (this.displayedCurves.includes(curve)) {
      this.displayedCurves.splice(this.displayedCurves.indexOf(curve), 1);
    } else {
      this.displayedCurves.push(curve);
    }
    this.displayedCategory = 'custom';
    this.populateTimeSeriesChart();
  }

  fetchNewTransitionDefaults() {
    this.transitionDefaultsFetched = false;
    this.resetTransitions()
    var inputs = {};
    inputs['asOfDate'] = this.asOfDate;
    this.riskMetricsService.getTransitionDefaults(inputs).subscribe((res) => {
      Object.keys(res).forEach((hub) => {
        this.transitionInfo[hub] = {
          from_date: this.dateHelperService.getDateyyyyMMdd(
            new Date(res[hub]['from_date']).toLocaleDateString('en-US')
          ),
          length_in_months: res[hub]['length_in_months'],
        };
      });
      this.transitionDefaultsFetched = true;
    });
  }

  tradeDateChange() {
    var today = new Date(this.dateHelperService.getDateddMMyyyy(this.asOfDate));
    this.minAsOfDate = this.dateHelperService
      .getDateyyyyMMdd(
        this.dateHelperService.getNextMonth(today).toLocaleDateString('en-US')
      )
      .substring(0, 7);

    this.fetchNewTransitionDefaults();
  }

  // For table which is no longer used
  // check_column_to_add(col, selectedHubs = this.selectedHubs) {
  //   var lowerCaseCol = col.toLowerCase();
  //   for (const hub of selectedHubs) {
  //     if (lowerCaseCol.indexOf(hub.toLowerCase()) >= 0) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  getChecked(curve: string): boolean {
    return this.displayedCurves.includes(curve);
  }

  getHubsCurves(hub: string): string[] {
    const hubCols =
      this.dataColumns?.filter((col) =>
        col.toLowerCase().includes(hub.toLowerCase())
      ) ?? [];
    return hubCols;
  }

  populateTimeSeriesChart(): void {
    this.pricePathsData.datasets = [];
    for (const col of this.displayedCurves) {
      const symbolData = this.dataSource.data.map((x) => x[col]);
      this.pricePathsData.datasets.push({
        data: symbolData,
        label: col,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        cubicInterpolationMode: 'monotone',
      });
    }
    this.pricePathsData.labels = this.dataSource.data.map(
      (x) => x['Trade Date']
    );
    this.charts.first.render();
  }

  getPricePathsData() {
    var inputs = {};
    inputs['asOfDate'] = this.asOfDate;
    inputs['end_month'] = this.endMonth;
    inputs['longTermCurve'] = this.selectedCPYear;
    inputs['hubs'] = this.selectedHubs;
    inputs['longTermCurveData'] = this.fileLongTerm;
    inputs['transitionInfo'] = this.transitionInfo;
    inputs['use_variable_half_life'] = this.useVariableHalfLife;

    this.displayedColumns = [];
    this.initialized = false;

    this.riskMetricsService
      .getPricePaths(inputs)
      .pipe(
        finalize(() => {
          this.initialized = true;
        })
      )
      .subscribe((res) => {
        const data = [];
        var idx = 0;
        while (res[idx]) {
          data.push(res[idx]);
          idx++;
        }
        this.dataSource.data = data;
        this.dataColumns = Object.keys(this.dataSource.data.find(Boolean));
        this.displayedCurves = [];
        this.filterCurvesByCategory('all');
        // for (const col of this.dataColumns) {
        //   if (this.check_column_to_add(col) == true || col == 'Trade Date') {
        //     this.displayedColumns.push(col);
        //   }
        // }
        this.populateTimeSeriesChart();
      });
  }

  exportToExcel() {
    var inputs = {};
    inputs['asOfDate'] = this.asOfDate;
    inputs['end_month'] = this.endMonth;
    inputs['longTermCurve'] = this.selectedCPYear;
    inputs['hubs'] = this.selectedHubs;
    inputs['longTermCurveData'] = this.fileLongTerm;
    inputs['transitionInfo'] = this.transitionInfo;
    inputs['exportTransposed'] = this.exportTransposed;
    inputs['granularity'] = this.granularity;
    inputs['use_variable_half_life'] = this.useVariableHalfLife;

    this.initialized = false;
    this.riskMetricsService
      .downloadPricePaths(inputs)
      .pipe(finalize(() => (this.initialized = true)))
      .subscribe((res) => this.fileSaverService.save(res.blob, res.fileName));
  }

  initializeChartSettings() {
    this.pricePathsData = { datasets: [] };
    this.pricePathsOptions = {
      responsive: true,
      scales: {
        x: {
          title: {
            text: 'Strip',
            display: true,
          },
          ...ChartSettings.defaultTickSettings,
          ...ChartSettings.defaultGridSettings,
        },
        y: {
          title: {
            text: '$',
            display: true,
          },
          ...ChartSettings.defaultGridSettings,
        },
      },
      aspectRatio: 3,
      elements: {
        line: {
          borderWidth: 2,
        },
        point: {
          radius: 0,
        },
      },
      plugins: {
        legend: {
          position: 'right',
        },
        tooltip: {
          caretPadding: 8,
        },
      },
      animation: {
        duration: 0,
      },
      animations: {
        color: {
          type: 'color',
          from: 'transparent',
          duration: 2500,
          properties: ['borderColor', 'backgroundColor'],
        },
      },
    };
  }

  // // For the table which is no longer used
  // updateHub() {
  //   this.displayedColumns = [];
  //   for (const col of this.dataColumns) {
  //     if (
  //       this.check_column_to_add(col, selectedHubs) == true ||
  //       col == 'Trade Date'
  //     ) {
  //       this.displayedColumns.push(col);
  //     }
  //   }

  //   this.populateTimeSeriesChart();
  // }

  isNaN(value: any): boolean {
    return isNaN(value);
  }

  uploadLongCurve(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length < 1) {
      return;
    }
    let file = fileList.item(0);
    this.selectedCPYear = 'CUSTOM';
    file
      .stream()
      .getReader()
      .read()
      .then((resp) => {
        this.fileLongTerm = [].slice.call(resp['value']);
      });
  }
}
