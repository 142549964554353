import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { GasConvertPrices } from './gas-converter.model';
import { UnitConverterService } from '../../services/unit-converter.service';

@Component({
  selector: 'app-gasconverter-prices',
  templateUrl: './gas-converter-price.component.html',
  styleUrls: ['./gas-converter-price.component.scss']
})

  export class GasConverterPriceComponent implements OnInit, OnChanges {

    constructor(private unitConverterService: UnitConverterService){}

    ddCurList = this.unitConverterService.ddCurList;
    ddMetricsList = this.unitConverterService.ddMetricsList;
    
    @Input('p1row1') p1r1 = new GasConvertPrices(0, 'EUR', 'MWh');
    @Input('p1row2') p1r2 = new GasConvertPrices(0, 'p', 'Therm');
    @Input('p1row3') p1r3 = new GasConvertPrices(0, 'USD', 'MBTU');

    @Input('priceBoxName') PriceBoxName = '';
    @Input('isDisabled') isDisabled : boolean = false;
    @Input() refreshCurrencyCalc: boolean;
    @Input() calcRowNumber: number;
    @Output() childToParent = new EventEmitter();
    @Input()
    set refreshPrice2MinusP1(val: boolean) {
      this.calcR1();
    }
    
    ngOnChanges() {
       if(this.calcRowNumber == 1){
        this.calcR1();
       } else if (this.calcRowNumber == 2){
        this.calcR2();
       } else if (this.calcRowNumber == 3){
        this.calcR3();
       }
    }   

    ngOnInit(): void {
      this.calcR1();
    }

    sendToParent(){
      this.childToParent.emit();
    }

    calcR1(): void {
      this.p1r2.price = this.unitConverterService.ToPrice(this.p1r1, this.p1r2);
      this.p1r3.price = this.unitConverterService.ToPrice(this.p1r2, this.p1r3);
      this.sendToParent();
    }
    calcR2(): void{
      this.p1r1.price = this.unitConverterService.ToPrice(this.p1r2, this.p1r1);
      this.p1r3.price = this.unitConverterService.ToPrice(this.p1r2, this.p1r3);
      this.sendToParent();
    }
    calcR3(): void{
      this.p1r1.price = this.unitConverterService.ToPrice(this.p1r3, this.p1r1);
      this.p1r2.price = this.unitConverterService.ToPrice(this.p1r3, this.p1r2);
      this.sendToParent();
    }
}