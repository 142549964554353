<header
  class="em-c-header em-c-header--vertical"
  role="banner"
  style="
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  "
>
  <div class="em-c-header__inner">
    <div class="em-c-header__band">
      <div class="em-c-header__body">
        <div class="em-c-header__title-container">
          <h2 class="em-c-header__title">
            <a routerLink="/" class="em-c-header__title-logo"
              >Trading Preview Features</a
            >
          </h2>
        </div>
        <button
          class="em-c-btn em-c-btn--small em-c-btn--inverted em-c-header__nav-btn em-js-nav-trigger"
        >
          <div class="em-c-btn__inner">
            <svg
              class="em-c-icon em-c-icon--small em-c-btn__icon em-js-btn-icon"
              data-em-btn-toggle-text="Close"
              data-em-icon-path="unity/images/em-icons.svg"
            >
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="unity/images/em-icons.svg#hamburger"
              ></use>
            </svg>
            <svg
              class="em-c-icon em-c-icon--small em-c-btn__icon em-u-is-hidden em-js-btn-swap-icon"
              data-em-icon-path="unity/images/em-icons.svg"
            >
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="unity/images/em-icons.svg#x-filled"
              ></use>
            </svg>
            <span class="em-c-btn__text em-js-btn-label">Menu</span>
          </div>
        </button>
      </div>
    </div>
    <div class="em-c-header__nav-container em-js-nav-panel">
      <nav class="em-c-tree" role="navigation">
        <ul
          class="em-c-tree__list em-c-tree__list--flush"
          role="tablist"
          aria-multiselectable="true"
        >
          <li class="em-c-tree__item">
            <a
              href="#"
              class="em-c-tree__link em-c-tree__link--has-children em-js-tree-dropdown-trigger em-is-active"
              role="tab"
              aria-controls=""
              aria-expanded="false"
              aria-selected="false"
            >
              <span class="em-c-tree__text">Quantitative Analytics</span>
              <svg class="em-c-icon em-c-icon--small em-c-tree__icon">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="unity/images/em-icons.svg#caret-right"
                ></use>
              </svg>
            </a>
            <ul
              class="em-c-tree__list em-js-tree-dropdown em-is-active"
              id=""
              role="tabpanel"
            >
              <li class="em-c-tree__item">
                <a
                  routerLink="/optionscalculator"
                  class="em-c-tree__link"
                  routerLinkActive="em-is-current"
                >
                  <mat-icon aria-hidden="false">calculate</mat-icon>
                  <span class="em-c-tree__text">Options Calculator</span>
                </a>
              </li>
              <li class="em-c-tree__item">
                <a
                  routerLink="/global-arb/netback"
                  class="em-c-tree__link"
                  routerLinkActive="em-is-current"
                >
                  <mat-icon aria-hidden="false">travel_explore</mat-icon>
                  <span class="em-c-tree__text">Netback</span>
                </a>
              </li>
              <li class="em-c-tree__item">
                <a
                  href="#"
                  class="em-c-tree__link em-c-tree__link--has-children em-js-tree-dropdown-trigger em-is-active"
                  role="tab"
                  aria-controls=""
                  aria-expanded="false"
                  aria-selected="false"
                >
                  <mat-icon aria-hidden="false">directions_boat</mat-icon>
                  <span class="em-c-tree__text">Physical LNG Flow</span>
                  <!-- <svg class="em-c-icon em-c-icon--small em-c-tree__icon">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#caret-right"></use>
                  </svg> -->
                </a>
                <ul
                  class="em-c-tree__list em-js-tree-dropdown em-is-active"
                  id=""
                  role="tabpanel"
                  aria-labeledby=""
                  aria-hidden="true"
                >
                  <li class="em-c-tree__item">
                    <a
                      routerLink="/global-arb/physical-lng-flow/physical-lng-summary"
                      class="em-c-tree__link"
                      routerLinkActive="em-is-current"
                    >
                      <mat-icon aria-hidden="false">directions_boat</mat-icon>
                      <span class="em-c-tree__text">Summary Page</span>
                    </a>
                  </li>
                  <li class="em-c-tree__item">
                    <a
                      routerLink="/global-arb/physical-lng-flow/physical-lng-fobdes"
                      class="em-c-tree__link"
                      routerLinkActive="em-is-current"
                    >
                      <mat-icon aria-hidden="false">directions_boat</mat-icon>
                      <span class="em-c-tree__text">BaseCase: FOB-DES</span>
                    </a>
                  </li>
                  <li class="em-c-tree__item">
                    <a
                      routerLink="/global-arb/physical-lng-flow/physical-lng-desdes"
                      class="em-c-tree__link"
                      routerLinkActive="em-is-current"
                    >
                      <mat-icon aria-hidden="false">directions_boat</mat-icon>
                      <span class="em-c-tree__text">BaseCase: DES-DES</span>
                    </a>
                  </li>
                  <li class="em-c-tree__item">
                    <a
                      routerLink="/global-arb/physical-lng-flow/physical-lng-tco"
                      class="em-c-tree__link"
                      routerLinkActive="em-is-current"
                    >
                      <mat-icon aria-hidden="false">directions_boat</mat-icon>
                      <span class="em-c-tree__text">BaseCase: TCO</span>
                    </a>
                  </li>
                  <li class="em-c-tree__item">
                    <a
                      routerLink="/global-arb/physical-lng-flow/physical-lng-dealcase-fobdes"
                      class="em-c-tree__link"
                      routerLinkActive="em-is-current"
                    >
                      <mat-icon aria-hidden="false">directions_boat</mat-icon>
                      <span class="em-c-tree__text">DealCase: FOB-DES</span>
                    </a>
                  </li>
                  <li class="em-c-tree__item">
                    <a
                      routerLink="/global-arb/physical-lng-flow/physical-lng-dealcase-desdes"
                      class="em-c-tree__link"
                      routerLinkActive="em-is-current"
                    >
                      <mat-icon aria-hidden="false">directions_boat</mat-icon>
                      <span class="em-c-tree__text">DealCase: DES-DES</span>
                    </a>
                  </li>
                  <li class="em-c-tree__item">
                    <a
                      routerLink="/global-arb/physical-lng-flow/physical-lng-dealcase-tco"
                      class="em-c-tree__link"
                      routerLinkActive="em-is-current"
                    >
                      <mat-icon aria-hidden="false">directions_boat</mat-icon>
                      <span class="em-c-tree__text">DealCase: TCO</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="em-c-tree__item">
                <a
                  routerLink="/tools/curve-download"
                  class="em-c-tree__link"
                  routerLinkActive="em-is-current"
                >
                  <mat-icon aria-hidden="false">timeline</mat-icon>
                  <span class="em-c-tree__text">Foward Curves Manager</span>
                  <!-- <span style="background-color: #00a3e0; color: white; padding: 1px 5px; font-weight: bold;">BETA</span></span> -->
                </a>
              </li>
              <li class="em-c-tree__item">
                <a
                  routerLink="/portfolio-var-custom/agot"
                  class="em-c-tree__link"
                  routerLinkActive="em-is-current"
                >
                  <mat-icon aria-hidden="false">calculate</mat-icon>
                  <span class="em-c-tree__text">AGOT Portfolio VaR</span>
                  <!-- <span style="background-color: #00a3e0; color: white; padding: 1px 5px; font-weight: bold;">BETA</span></span> -->
                </a>
              </li>

              <li class="em-c-tree__item">
                <a
                  href="#"
                  class="em-c-tree__link em-c-tree__link--has-children em-js-tree-dropdown-trigger em-is-active"
                  role="tab"
                  aria-controls=""
                  aria-expanded="false"
                  aria-selected="false"
                >
                  <mat-icon aria-hidden="false">timeline</mat-icon>
                  <span class="em-c-tree__text">Price Paths</span>
                  <!-- <svg class="em-c-icon em-c-icon--small em-c-tree__icon">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#caret-right"></use>
                  </svg> -->
                </a>
                <ul
                  class="em-c-tree__list em-js-tree-dropdown em-is-active"
                  id=""
                  role="tabpanel"
                  aria-labeledby=""
                  aria-hidden="true"
                >
                  <li class="em-c-tree__item">
                    <a
                      routerLink="/price-paths"
                      class="em-c-tree__link"
                      routerLinkActive="em-is-current"
                    >
                      <mat-icon aria-hidden="false">timeline</mat-icon>
                      <span class="em-c-tree__text">Long-Term</span>
                      <!-- <span style="background-color: #00a3e0; color: white; padding: 1px 5px; font-weight: bold;">BETA</span></span> -->
                    </a>
                  </li>
                  <li class="em-c-tree__item">
                    <a
                      routerLink="/corr-price-paths"
                      class="em-c-tree__link"
                      routerLinkActive="em-is-current"
                    >
                      <mat-icon aria-hidden="false">timeline</mat-icon>
                      <span class="em-c-tree__text">Short-Term</span>
                      <!-- <span style="background-color: #00a3e0; color: white; padding: 1px 5px; font-weight: bold;">BETA</span></span> -->
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="em-u-text-align-right em-u-margin-double em-u-margin-top-none">
    <button
      class="em-c-btn em-c-btn--tertiary em-c-btn--small"
      (click)="login()"
      *ngIf="!user"
    >
      <div class="em-c-btn__inner">
        <svg
          class="em-c-icon em-c-icon--small em-c-btn__icon rotate180"
          data-em-icon-path="unity/images/em-icons.svg"
        >
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="unity/images/em-icons.svg#lock-unlock"
          ></use>
        </svg>
        <span class="em-c-btn__text">Login</span>
      </div>
    </button>
    <button
      class="em-c-btn em-c-btn--tertiary em-c-btn--small"
      (click)="logout()"
      *ngIf="user"
    >
      <div class="em-c-btn__inner">
        <svg
          class="em-c-icon em-c-icon--small em-c-btn__icon"
          data-em-icon-path="unity/images/em-icons.svg"
        >
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="unity/images/em-icons.svg#link-external"
          ></use>
        </svg>
        <span class="em-c-btn__text">Logout</span>
      </div>
    </button>
  </div>
</header>
