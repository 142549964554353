import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { SecurityInfo } from '../models/security-info.model';
import { RiskMetricsService } from '../services/risk-metrics.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-tradeable-instruments',
  templateUrl: './tradeable-instruments.component.html',
  styleUrls: ['./tradeable-instruments.component.scss']
})
export class TradeableInstrumentsComponent implements OnInit, AfterViewInit {
  region: string;
  filter: string;

  tradeableSecurities: SecurityInfo[] = [];
  displayedColumns = ['security', 'type', 'hub', 'exchange'];

  dataSource: MatTableDataSource<SecurityInfo> = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;

  constructor(private riskMetricsService: RiskMetricsService, private userService: UserService, private titleService: Title) {
    this.titleService.setTitle('Trading Preview UI | Tradeable Instruments')
  }

  ngOnInit(): void {
    this.region = this.userService.getUserRegion();
    if (this.region === 'ALL') this.region = 'AGOT';

    this.riskMetricsService.getSecuritiesInfo().subscribe(res => {
      this.tradeableSecurities = res;
      this.updateTable();
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  updateTable(): void {
    this.dataSource.data = this.tradeableSecurities.filter(info => info.region_office === this.region);
  }

  filterChange(): void {
    this.dataSource.filter = this.filter;
  }

  clearFilter(): void {
    this.filter = '';
    this.filterChange();
  }
}
