import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})

export class DateHelperService {
  getLastWeekday(date: Date): Date {
    var yesterday = new Date(date.setDate(date.getDate() - 1))
    return yesterday.getDay() % 6
      ? yesterday
      : this.getLastWeekday(yesterday)
  }

  getNextWeekday(date: Date): Date {
    var tomorrow = new Date(date.setDate(date.getDate() + 1))
    return tomorrow.getDay() % 6
      ? tomorrow
      : this.getNextWeekday(tomorrow)
  }

  getWeekdaysBetween(start: Date, end: Date): number {
    var numWorkDays = 0;
    var currentDate = new Date(start)
    // start counting from next day
    currentDate.setDate(start.getDate() + 1);
    while (currentDate <= end) {
      // Skips Sunday and Saturday
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        numWorkDays++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return numWorkDays;
  }

  getDayDiff(startDate: Date, endDate: Date): number {
    const msInDay = 24 * 60 * 60 * 1000;
  
    return Math.round(
      Math.abs(Number(endDate) - Number(startDate)) / msInDay
    );
  }

  getNextMonth(asofDate: Date){
    var yr =  asofDate.getFullYear() + (asofDate.getMonth() == 11 ? 1 : 0);
    var mn = (asofDate.getMonth() + 1) % 12;
    return new Date(yr, mn, 1);
  }

  getDateyyyyMMdd(asofDate: any){
    var result = asofDate;
    if(asofDate.indexOf('/') > 0){
      var tdate = new Date(asofDate);
      var tday = tdate.getDate() < 10 ? '0'+tdate.getDate().toString() : tdate.getDate().toString();
      var tmonth = tdate.getMonth() + 1;
      var stmonth = tmonth < 10 ? '0'+tmonth : tmonth.toString();
      result = tdate.getFullYear() + '-' + stmonth + '-' + tday;
    }
    return result;
  }

  getDateddMMyyyy(asofDate: string){
    return asofDate.substring(5,7) + '/' + asofDate.substring(8,10) + '/' + asofDate.substring(0,4);
  }

  dateToString(date_Object: Date) {
    // get the year, month, date, hours, and minutes seprately and append to the string.
    var date_String = (date_Object.getMonth() + 1) + "/" + date_Object.getDate() + "/" + date_Object.getFullYear()  + " " +
       +date_Object.getHours() + ":" + date_Object.getMinutes() + ":" + date_Object.getSeconds();
    return date_String;
 }

}
