import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInsights, ICustomProperties, IEventTelemetry } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class MonitoringService {

  appInsights: ApplicationInsights;

  constructor(private router: Router) {
    if (environment.production) {
      this.appInsights = new ApplicationInsights({
        config: {
          connectionString: environment.appInsightsConnectionString,
          enableAutoRouteTracking: true
        }
      });
      this.appInsights.loadAppInsights();
    }
  }

  setUser(id: string): void {
    this.appInsights?.setAuthenticatedUserContext(id);
  }

  clearUser(): void {
    this.appInsights?.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights?.trackPageView({
      name,
      uri
    });
  }

  logException(error: Error) {
    this.appInsights?.trackException({
      exception: error
    });
  }

  trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
    this.appInsights?.trackEvent(event, customProperties);
  }
}

@Injectable()
export class ApplicationInsightsErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) { }
  handleError(error: any): void {
    if (!environment.production) {
      console.log(error);
    }
    this.injector.get<MonitoringService>(MonitoringService).logException(error);
  }
}  
