<div class="em-c-toolbar ">
  <div class="em-c-toolbar__item em-c-toolbar__item--stretch">
    <div class="em-c-search__body">
      <select class="em-c-select" [(ngModel)]="region" (ngModelChange)="updateTable()" placeholder="Region">
        <option value="AGOT">AGOT</option>
        <option value="EGOT">EGOT</option>
        <option value="LNG">LNG</option>
      </select>
      <input type="search" id="search" class="em-c-search__input" placeholder="Filter instruments..." [(ngModel)]="filter" (ngModelChange)="filterChange()" />
      <button class="em-c-btn em-c-btn--medium" (click)="clearFilter()">
        <div class="em-c-btn__inner">
          <svg *ngIf="!filter" class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/24/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#filter"></use>
          </svg>
          <svg *ngIf="filter" class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/24/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#x-filled"></use>
          </svg>
        </div>
      </button>
    </div>
  </div>
</div>

<div class="em-c-table-object ">
  <table mat-table matSort [dataSource]="dataSource" class="em-c-table">
    <ng-container matColumnDef="security">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double" mat-sort-header> Security </th>
      <td mat-cell *matCellDef="let element">
        {{element.security}}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double" mat-sort-header> Future Type </th>
      <td mat-cell *matCellDef="let element">
        {{element.type}}
      </td>
    </ng-container>

    <ng-container matColumnDef="hub">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double" mat-sort-header> Hub </th>
      <td mat-cell *matCellDef="let element">
        {{element.hub}}
      </td>
    </ng-container>

    <ng-container matColumnDef="exchange">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double" mat-sort-header> Exchange </th>
      <td mat-cell *matCellDef="let element">
        {{element.exchange}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
