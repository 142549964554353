import { ChartConfiguration } from "chart.js";

export class ChartSettings {
  static defaultGridSettings = {
    grid: {
      borderWidth: 2,
      borderColor: 'gray'
    }
  };

  static defaultTickSettings = {
    ticks: {
      autoSkipPadding: 30

    }
  }

  static percentLineChartOptions: ChartConfiguration['options'] = {
    scales: {
      x: {
        ...this.defaultTickSettings
      },
      y: {
        ticks: {
          callback: function (value) {
            return Number(value).toFixed(1) + '%';
          },
        }
      }
    }
  }

  static colors: string[] = ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'];
  static highlightColors: string[] = ['#003f5cbf', '#2f4b7cbf', '#665191bf', '#a05195bf', '#d45087bf', '#f95d6abf', '#ff7c43bf', '#ffa600bf'];
}
