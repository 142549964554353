import { SecurityInfo } from "./security-info.model";

export class TradeLeg {
  securityInfo?: SecurityInfo;
  position?: string;
  lot?: number;
  lotSize?: number;
  quantity?: number;
  eodPrice?: number;
  intradayPrice?: number;
  strip?: string;
  minStripDate?: string;
  maxStripDate?: string;

  unit?: string;
  currency?: string;

  parentLeg?: TradeLeg;
  childLeg?: TradeLeg;
  isReferenceLeg?: boolean;

  static fromJson(obj: any): TradeLeg {
    var leg = new TradeLeg();
    leg.securityInfo = new SecurityInfo(obj.securityInfo.security, obj.securityInfo.hub, obj.securityInfo.type);
    leg.lot = obj.lot;
    leg.lotSize = obj.lotSize;
    leg.position = obj.position;
    leg.quantity = obj.quantity;
    leg.unit = obj.unit;
    leg.currency = obj.currency;
    leg.eodPrice = obj.eodPrice;
    leg.intradayPrice = obj.intradayPrice;
    leg.strip = obj.strip;
    leg.minStripDate = obj.minStripDate;
    leg.maxStripDate = obj.maxStripDate;

    leg.isReferenceLeg = obj.isReferenceLeg;

    return leg;
  }

  constructor(securityInfo?: SecurityInfo, position?: string, lot?: number, quantity?: number,
    currency?: string, eodPrice?: number, intradayPrice?: number, strip?: string) {
    this.securityInfo = securityInfo;
    this.position = position;
    this.lot = lot;
    this.quantity = quantity;
    this.currency = currency;
    this.eodPrice = eodPrice;
    this.intradayPrice = intradayPrice;
    this.strip = strip;
  }

  isChildLeg(): boolean {
    return this.parentLeg != null;
  }

  hasChildLeg(): boolean {
    return this.childLeg != null;
  }

  clearChildLeg(): void {
    this.childLeg.parentLeg = null;
    this.childLeg = null;
  }

  setChildLeg(leg: TradeLeg): void {
    this.childLeg = leg;
    leg.parentLeg = this;
  }

  clone(): TradeLeg {
    const clone = Object.assign(new TradeLeg(), this);
    clone.securityInfo = this.securityInfo?.clone();
    return clone;
  }
}
