
export class TradeSummary {
    seller: string;
    buyer: string;
    loadingAt: string;
    loadingType: string = "FOB";
    loadingDate: string;
    dischargeAt: string;
    dischargeType: string = "DES";
    dischargeDate: string;
    vessel: string;
    netRealisationPerUnit: number = 0.0;
    netRealisationAmount: number = 0.0;
}

export class lngPrices {
    lngPurchaseFOB1: number;
    lngPurchaseFOB2: number;
    lngSaleFOB1: number;
    lngSaleFOB2: number;
    lngHeelPurchasePrice1: number;
    lngHeelPurchasePrice2: number;
    lngHeelSalePrice1: number;
    lngHeelSalePrice2: number;
}

export class VolumeBalances {
    heelBoughtMMBtu: number;
    heelBoughtM3: number;
    loadQuantityMMBtu: number;
    loadQuantityM3: number;
    dischargeQtyMMBtu: number;
    dischargeQtyM3: number;
    heelSoldMMBtu: number;
    heelSoldM3: number;
}

export class ShippingCost {
    charterCostPerMMBtu: number;
    charterCostUsd: number;
    fuelCostPerMMBtu: number;
    fuelCostUsd: number;
    portsCanalChargeMMBtu: number;
    portsCanalChargeM3: number;
    coolDownCostPerMMBtu: number;
    coolDownCostUsd: number;
    insuranceMMBtu: number;
    insuranceM3: number;
    miscAndBunkerCostPerMMBtu: number;
    miscAndBunkerCostUsd: number;
    boilOffCostPerMMBtu: number;
    boilOffCostUsd: number;
    totalShippingCostPerMMBtu: number;
    totalShippingCostUsd: number;
}

export class DealEconomics {
    cargoLoadedMMBtu: number;
    cargoLoadedM3: number;
    cargoDischargedMMBtu: number;
    cargoDischargedM3: number;
    slotCostPremiumMMBtu: number;
    slotCostPremiumM3: number;
    shippingCostPerMMBtu: number;
    shippingCostUsd: number;
    costOfCapitalMMBtu: number;
    costOfCapitalM3: number;
}

/* Inputs */

export class PhysicalLngInputFobdes {
    lng_prices: LngPrices;
    vessel_logistics: VesselLogistics;
    route: Route;
    deal_dates: DealDates;
    discharge_override: DischargeOverride;
    slot_fixed_miscellaneous_cost: SlotFixedMiscellaneousCost;
    fuel_prices: FuelPrices;

    constructor(){
        this.lng_prices = new LngPrices();
        this.fuel_prices = new FuelPrices();
        this.vessel_logistics = new VesselLogistics();
        this.route = new Route();
        this.deal_dates = new DealDates();
        this.discharge_override = new DischargeOverride();
        this.slot_fixed_miscellaneous_cost = new SlotFixedMiscellaneousCost();
        this.fuel_prices = new FuelPrices();
    }
    TestLoadDefaults(){
        this.lng_prices.TestDefaults();
        this.vessel_logistics.TestDefaults();
        this.route.TestDefaults();
        this.deal_dates.TestDefaults();
        this.slot_fixed_miscellaneous_cost.TestDefaults();
    }
}

export class LngPrices {
    sale_des_price: number;
    heel_sale_price:  number;
    heel_purchase_price: number;
    purchase_fob_price: number;

    TestDefaults(){
        this.sale_des_price = 50.0;
        this.heel_sale_price = 50.0;
        this.heel_purchase_price = 50.0;
        this.purchase_fob_price = 15;
    }
}

export class Route {
    positioning_port: string;
    positioning_route_canal: string;
    load_port: string;
    load_port_canal: string;
    discharge_port: string;
    discharge_port_canal: string;
    repositioning_port: string;

    TestDefaults(){
        this.positioning_port = "";
        this.positioning_route_canal = "-";
        this.load_port = "United States Cameron";
        this.load_port_canal = "Direct";
        this.discharge_port = "United Kingdom South Hook";
        this.discharge_port_canal = "Direct";
        this.repositioning_port = "United States Cameron";
    }
}

export class VesselLogistics {
    vessel_name: string;
    heel_onboard_redelivery_of_ship: number;
    heel_onboard_redelivery_of_ship_owners_option: number;
    positioning_speed: number;
    laden_speed: number;
    repositioning_speed: number;
    discharge_override_mmbtu: number;
    discharge_override_m3: number;
    ballast_repositioning_bonus: boolean;
    positioning_bonus: boolean;
    load_override_mmbtu: number;
    load_override_m3: number;
    lng_tanks_in_use_percentage: number;
    canal_delay_per_transit: number;
    charter_period_override: number;
    fuel_decision_positioning: string;
    fuel_decision_laden: string;
    fuel_decision_repositioning: string;
    fuel_decision_idle: string;
    override_bor_laden: number;
    override_bor_ballast: number;
    override_bor_idle: number;

    positioning_bonus_details: PositioningBonusDetails;
    repositioning_bonus_details: PositioningBonusDetails;

    charter_rate: number;
    lng_tank_density: number;
    lng_volume_m3: number;

    include_bunker_operation_for_reliq: string;
    fixed_costs_barges_etc: number;
    days_at_anchorage_for_bunker: number;

    constructor() { 
        this.positioning_bonus_details = new PositioningBonusDetails();
        this.repositioning_bonus_details = new PositioningBonusDetails();
    }

    TestDefaults(){
        this.vessel_name = "148 ST Vessel";
        this.heel_onboard_redelivery_of_ship = 4000;
        this.heel_onboard_redelivery_of_ship_owners_option = 4000;
        this.positioning_speed = 15.0;
        this.laden_speed = 17.0;
        this.repositioning_speed = 16.0;
        this.discharge_override_mmbtu = 0.0;
        this.discharge_override_m3 = 0.0;
        this.ballast_repositioning_bonus = false;
        this.positioning_bonus = false;
        this.load_override_mmbtu = 0.0;
        this.load_override_m3 = 0.0;
        this.lng_tanks_in_use_percentage = 100;
        this.canal_delay_per_transit = 1;
        this.charter_period_override = 0;
        this.fuel_decision_positioning = "---Dual Fuel---";
        this.fuel_decision_laden = "---Dual Fuel---";
        this.fuel_decision_repositioning = "---Dual Fuel---";
        this.fuel_decision_idle = "---Dual Fuel---";
        this.override_bor_laden = 0;
        this.override_bor_ballast = 0;
        this.override_bor_idle = 0;
    
        this.positioning_bonus_details.TestDefaults();
        this.repositioning_bonus_details.TestDefaults();
    
        this.charter_rate = 150000.0;
        this.lng_tank_density = 0.484;
        this.lng_volume_m3 = 250;
        this.include_bunker_operation_for_reliq = "No";
        this.fixed_costs_barges_etc = 25000.0;
        this.days_at_anchorage_for_bunker = 2.35;
    }
}

export class PositioningBonusDetails {
    charter_rate: number;
    display_charter_rate: number;
    fuel_rate: number;
    display_fuel_rate: number;
    fuel_type_agreed: string;
    speed: number;
    //lumpsum: number;
    
    charterRateChanged(): void {
        this.charter_rate = this.display_charter_rate/100;
    }
    
    fuelRateChanged(): void {
    this.fuel_rate = this.display_fuel_rate/100;
    }

    TestDefaults(){
        this.charter_rate = 1.0;
        this.fuel_rate = 1.0;
        this.fuel_type_agreed = "HFO";
        this.speed = 15.0;
        //this.lumpsum = 0.0;
    }
}

export class DealDates {
    laytime_fob_load: number = 1.0;
    laytime_des_discharge: number = 1.5;
    discharge_date: string;
    loading_date: string;
    positioning_date: string = "";
    respositioning_date_input: string = "";

    TestDefaults(){
        this.laytime_fob_load = 1.0;
        this.laytime_des_discharge = 1.5;
        this.loading_date = "2023-02-16T06:00";
        this.discharge_date = "2023-03-01T06:00";
        this.positioning_date = "";
        this.respositioning_date_input = "";
    }
}

export class VoyageLength {
    discharge_port_repositioning_point_distance: number;
    positioning_load_port_distance: number;
    load_port_discharge_port_distance: number;
    charter_bonus_positioning: number;
    charter_bonus_repositioning: number;

    TestDefaults(){
        this.discharge_port_repositioning_point_distance = 4600;
        this.positioning_load_port_distance = 0.0;
        this.load_port_discharge_port_distance = 4600;
        this.charter_bonus_positioning = 0.0;
        this.charter_bonus_repositioning = 0.0;
    }
}

export class SlotFixedMiscellaneousCost {
    commercial_premium_paid_for_discharge_slot: number = 0.0;
    cargo_insurance: number = 50000.0;
    other_insurance: number = 250.0;
    miscellaneous_cost: number = 0.01;
    cost_of_capital_apr: number = 0.045;
    display_cost_of_capital_apr: number = 4.5;

    costOfCapitalChanged(): void {
        this.cost_of_capital_apr = this.display_cost_of_capital_apr/100;
    }
    TestDefaults(){
        this.commercial_premium_paid_for_discharge_slot = 0.0;
        this.cargo_insurance = 50000.0;
        this.other_insurance = 250.0;
        this.miscellaneous_cost = 0.01;
        this.cost_of_capital_apr = 0.045;
    }
}

export class DischargeOverride {
    boil_off_incurred_multiplier: number = 0;
}

export class FuelPrices {
    hfo: number = 550.0;
    mdo: number = 750.0;
}

export class VesselInfo {
    vessel_name?:string;
    vessel_type?:string;
    capacity?: number;
    in_charter_rate?:number;
    boiloffrate_laden?:number;
    boiloffrate_ballast?:number;
    constructor(){

    }
}

export class ComputedDates {
    positioning_latest?:string;
    loading_earliest?:string;
    loading_latest?:string;
    discharge_earliest?:string;
    discharge_latest?:string;
    repositioning_earliest?:string;
}