export class OptionsCalculateMain {
  public tabName: string;
  public optionCalcItems: OptionsCalculateItem[] = [];
  public current:boolean;
  public dblClicked:boolean;
  public uniqueKey:string;
  constructor(tabname: string, selected:boolean){
    this.tabName = tabname; 
    this.current = selected;
    this.uniqueKey = Math.random().toString(36).slice(2, 9);
  }
}

export class OptionsCalculateItem {
  public tabRefName:string;
  public uniqueKey:string;
  public priceDate:string;
  public initialized:boolean;
  public expiryDate: string;
  public deliveryDate: string;
  public selectedAsset: string = '';
  public selectedCallPut: string = 'Call';
  public selectedQuote: string = 'USD';
  public selectedModel: string = 'Black76';
  public strikePrice: number;
  public usdDepo:number = 4;
  public forwardPrice: number;
  public annualVolatility: number;
  public currentPriceF0: number;
  public result: optionCalcResult

  constructor() { 
    this.result = new optionCalcResult();
    this.uniqueKey = Math.random().toString(36).slice(2, 9);
  }
}

export class optionCalcResult { 
  public price: 0; 
  public payoff: 0; 
  public delta: 0; 
  public gamma: 0; 
  public vega: 0; 
  public theta: 0; 
  public rho: 0;
  constructor(){} 
}

