<div class="em-c-alert em-c-alert--error" role="alert" *ngIf="showCalculatingError">
  <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#circle-exclamation-mark"></use>
  </svg>
  <div class="em-c-alert__body">
    There was an error in fetching the Volatility values for the selected criteria.
  </div>
  <div class="em-c-alert__actions">
    <button class="em-c-text-btn em-c-alert--error" (click)="showCalculatingError=false">
      Close
    </button>
  </div>
</div>
<div class="em-c-loader spinner" *ngIf="isCalculating">
  <img src="unity/images/icon-spinner.svg" />
</div>
<div class="em-l-grid">
  <div class="em-l-grid__item">
    <div>
      <mat-form-field class="em-u-padding-top-none em-u-padding-right small-field">
        <mat-label>Region</mat-label>
        <mat-select [(ngModel)]="region" (ngModelChange)="updateVolatility()">
          <mat-option value="AGOT">AGOT</mat-option>
          <mat-option value="EGOT">EGOT</mat-option>
          <mat-option value="LNG">LNG</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="em-u-padding-top-none em-u-padding-right medium-field">
        <mat-label>Trade Date</mat-label>
        <input matInput type="date" [(ngModel)]="tradeDate" (ngModelChange)="updateVolatility()" [max]="today">
      </mat-form-field>

      <mat-form-field class="em-u-padding-top-none em-u-padding-right large-field">
        <mat-label>Type</mat-label>
        <mat-select multiple [(ngModel)]="volType" (ngModelChange)="updateVolatility()" [ngModelOptions]="{updateOn: 'blur'}">
          <mat-option value="ewma">EWMA</mat-option>
          <mat-option value="sma15">SMA 15 day</mat-option>
          <mat-option value="sma60">SMA 60 day</mat-option>
          <mat-option value="implied">Implied</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="em-c-table-object ">
  <div class="em-c-table-object__header">
    <div class="em-c-toolbar ">
      <div class="em-c-toolbar__item em-c-toolbar__item--stretch">
        <div class="em-c-search__body">
          <input type="search" id="search" class="em-c-search__input" placeholder="Filter rows..." [(ngModel)]="filter" (ngModelChange)="filterChange()" />
          <button class="em-c-btn em-c-btn--medium" (click)="clearFilter()">
            <div class="em-c-btn__inner">
              <svg *ngIf="!filter" class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/24/em-icons.svg">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#filter"></use>
              </svg>
              <svg *ngIf="filter" class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/24/em-icons.svg">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#x-filled"></use>
              </svg>
            </div>
          </button>
        </div>
      </div>

      <div class="em-c-toolbar__item em-is-aligned-right">
        <div class="em-c-field em-c-field--inline">
          <label class="em-c-field__label ">Threshold: </label>
          <div class="em-c-field__body">
            <input type="number" class="em-c-input em-js-input w-68" [(ngModel)]="threshold" min="0" max="199" />
          </div>
          <label class="em-c-field__label ">&nbsp;%</label>
        </div>
      </div>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" class="em-c-table">
    <ng-container matColumnDef="hub">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> Hub </th>
      <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top">
        {{ element.hub }}
      </td>
    </ng-container>

    <ng-container matColumnDef="volatility_type">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double"> Type </th>
      <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top">
        {{ element.volatility_type }}
      </td>
    </ng-container>

    <ng-container *ngFor="let col of dataColumns" [matColumnDef]="col">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double w-85 em-u-text-align-right"> {{ col }} </th>
      <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top w-85 em-u-text-align-right" [ngClass]="{'highlight': shouldHighlight(element, col) }">
        {{ isNumber(getVolValue(element, col)) ? (getVolValue(element, col) | percent:'1.1-1') : 'N/A' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<h6 *ngIf="!isCalculating">
  Note: Volatilities listed are annualized and expressed percentually, covering balmonth and fixed price contract at the specified hub.
</h6>
