<h3>
    Summary - P&L
</h3>

<div style="display: flex;">
  <div class="em-l-grid__item">
    <mat-form-field>
      <mat-label> <b>BaseCase Total PnL:</b> </mat-label>
      <b> <input matInput type="text" [ngModel]="overall_pnl | currency" readonly> </b>
    </mat-form-field>
  </div>
  <div class="em-l-grid__item">
    <mat-form-field>
      <mat-label> <b>DealCase Total PnL:</b> </mat-label>
      <b> <input matInput type="text" [ngModel]="overall_pnl_dealcase | currency" readonly> </b>
    </mat-form-field>
  </div>
  <div class="em-l-grid__item">
    <mat-form-field>
      <mat-label> <b>Uplift:</b> </mat-label>
      <b> <input matInput type="text" [ngModel]="uplift | currency" readonly> </b>
    </mat-form-field>
  </div>
</div>
<p></p>

<div class="em-l-grid__item">
  <mat-label> <b> BaseCase PnL breakdown: </b> </mat-label>
  <mat-table [dataSource]="datasource_pnl_tally">

    <!-- Column definitions -->
    <ng-container matColumnDef="ItemCol">
      <mat-header-cell *matHeaderCellDef> Item </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{ item.item_name }} </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="PnlCol">
      <mat-header-cell *matHeaderCellDef> PnL ($) </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{ item.pnl | currency }} </mat-cell>
    </ng-container>    

    <!-- Row definitions -->
    <mat-row *matRowDef="let row; columns: ['ItemCol', 'PnlCol']"></mat-row>
    
  </mat-table>
</div>

<div class="em-l-grid__item">
  <mat-label> <b> DealCase PnL breakdown: </b> </mat-label>
  <mat-table [dataSource]="datasource_pnl_tally_dealcase">

    <!-- Column definitions -->
    <ng-container matColumnDef="ItemCol">
      <mat-header-cell *matHeaderCellDef> Item </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{ item.item_name }} </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="PnlCol">
      <mat-header-cell *matHeaderCellDef> DealCase_PnL ($) </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{ item.pnl | currency }} </mat-cell>
    </ng-container>    

    <!-- Row definitions -->
    <mat-row *matRowDef="let row; columns: ['ItemCol', 'PnlCol']"></mat-row>
    
  </mat-table>
</div>
