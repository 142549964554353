<h3>AGOT Custom Portfolio VaR</h3>
<div class="em-c-loader spinner" *ngIf="!initialized">
  <img src="unity/images/icon-spinner.svg" />
</div>

<div>
  <div *ngIf="isCalculating" style="width: 500px">
    <div class="em-c-field em-c-progress-bar em-c-progress-bar--indeterminate">
      <div class="em-c-progress-bar__label">
        <span class="em-u-margin-left-half"></span>
      </div>
      <div class="em-c-progress-bar__bar"> <span class="em-c-progress-bar--indeterminate__value"></span></div>
    </div>
  </div>

  <div class="em-c-toolbar__item em-is-aligned-right em-u-margin-none" *ngIf="!isCalculating">
    <svg class="em-c-icon em-c-icon--medium em-u-margin-right" *ngIf="!firstRun && showRecalcWarning" matTooltip="Inputs have changed, please re-run risk calculation.">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/24/em-icons.svg#warning"></use>
    </svg>

    <button class="em-c-btn em-c-btn--primary big-button em-u-margin-right-half" (click)="calculatePortfolioVarAgot()" matTooltip="Run Value at Risk calculation">
      <svg class="em-c-icon em-c-icon--medium em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/48/em-icons.svg#rocket"></use>
      </svg>
    </button>

    <mat-form-field class="em-u-padding-none em-u-margin-right-half large-field" appearance="fill">
      <mat-label>EOD AGOT Portfolio VaR</mat-label>
      <input matInput class="em-u-text-align-right" type="text" [ngModel]="calculatedEODVar" readonly>
    </mat-form-field>

  </div>

</div>

<div class="em-l-grid__item width-100">
  <mat-card>
    <mat-card-content>
      
      <div class="em-l-grid__item width-100">
        <div class="em-c-field unity-file-upload-override">
          <div class="em-c-field__body">
          
            <svg class="em-c-icon em-c-icon--large em-c-field__block-icon">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#upload"></use>
            </svg>
            
            <input type="file" name="file[]" id="file" class="em-c-file-upload" placeholder="Placeholder" (change)="attachedFileChange($event.target.files)" [disabled]="isReadOnly"
                  accept="image/*, .csv, .json"/>
            

            <ul class="em-c-field__list">
              <li class="em-c-field__item">Drag and drop a CSV file containing the new AGOT trades or click to choose the file. </li>

              <li class="em-c-field__item">
                {{ attachedSupportingFile?.name ?? tradeInfo.supportingFileName }}
                <button class="em-c-btn em-c-btn--small em-c-btn--tertiary" *ngIf="tradeInfo.supportingFileName" (click)="downloadFile()">
                  <div class="em-c-btn__inner">
                    <svg class="em-c-icon em-c-icon--small em-c-btn__icon " data-em-icon-path="unity/images/em-icons.svg">
                      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#download-cloud"></use>
                    </svg>
                  </div>
                </button>
              </li>

              <li class="em-c-field__item em-c-field__item--small">
                Below is an example file with two new trades for Henry Hub.
                <br>
                <br>
                <style>
                  table, th, td {
                    border:1px solid black;
                    text-align:center;
                  }
                </style>
                
                <table style="width:100%">
                  <tr>
                    <th>strip</th>
                    <th>H</th>
                  </tr>
                  <tr>
                    <td>4/1/2026</td>
                    <td>2983804.272</td>
                  </tr>
                  <tr>
                    <td>5/1/2026</td>
                    <td>2155817.3</td>
                  </tr>
                </table>


              </li>
            </ul>
            
          </div>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
</div>


<button class="em-c-btn em-c-btn--primary em-u-margin em-u-margin-top-none" input type="submit" (click)="submit()" [disabled]="isSubmitting || !validate()" [class.em-c-btn--disabled]="isSubmitting || !validate()">
  Generate Portfolio VaR Report
</button>



<table mat-table [dataSource]="dataSource" class="em-c-table em-u-margin-top">
  <ng-container *ngFor="let col of displayedColumns; let i = index " [matColumnDef]="col">
    <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double em-u-text-align-center" [ngClass]="{'em-u-text-align-right': i > 0}" [width]="100/displayedColumns.length + '%'"> {{ col }} </th>
    <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top em-u-text-align-center" [ngClass]="{'em-u-text-align-right': i > 0}">
      {{ isNaN(element[col]) ? element[col] : (element[col] | number) }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="text-align: center;"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>