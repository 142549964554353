import { AfterViewInit } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { Trade } from '../../models/trade.model';
import { TradeBlotterService } from '../../services/trade-blotter.service';
import { TradeBlotterComponent } from '../trade-blotter.component';

@Component({
  selector: 'app-new-trade',
  templateUrl: './new-trade.component.html',
  styleUrls: ['./new-trade.component.scss']
})
export class NewTradeComponent implements AfterViewInit {

  @ViewChild(TradeBlotterComponent) blotter: TradeBlotterComponent;

  createdTradeId: number;
  isSubmitting = false;

  tradeInfo: Trade = {};

  constructor(private tradeBlotterService: TradeBlotterService, private router: Router, private route: ActivatedRoute, private titleService: Title) {
    this.titleService.setTitle('Trading Preview UI | Trade Blotter: Submit new trade');
  }

  ngAfterViewInit(): void {
    this.route.paramMap
      .pipe(map(() => window.history.state)).subscribe(
        res => {
          if (res.tradeInfo) {
            this.tradeInfo = res.tradeInfo;
            this.resetTradeData();
          }
        }
      );
  }

  validate(): boolean {
    return this.blotter?.validate();
  }

  submit(): void {
    this.isSubmitting = true;
    this.tradeBlotterService.createDeal(this.blotter.tradeInfo)
      .pipe(finalize(() => this.isSubmitting = false))
      .pipe(switchMap(tradeId => {
        this.createdTradeId = +tradeId;

        if (this.blotter.attachedSupportingFile) {
          let file = this.tradeBlotterService.uploadSupportingFile(this.createdTradeId, this.blotter.attachedSupportingFile);
          let submit = this.tradeBlotterService.submitDealForApproval(this.createdTradeId);
          return forkJoin(file, submit)
        }
        else {
          return this.tradeBlotterService.submitDealForApproval(this.createdTradeId);
        }
      })).subscribe(
        () => this.router.navigate(['success'], { state: { tradeId: this.createdTradeId }, relativeTo: this.route }),
        () => this.router.navigate(['/error'])
      );
  }

  resetTradeData(): void {
    this.blotter.tradeInfo.status = 'NEW';
    this.blotter.tradeInfo.id = null;
    this.blotter.tradeInfo.supportingFileName = null;
    this.blotter.tradeInfo.comment = null;
  }
}
