<div class="em-c-table-object em-u-align-center em-u-width-100" matSort>
  <table mat-table [dataSource]="pvDataSource" class="em-c-table em-u-margin-bottom" *ngIf="pvDataSource?.filteredData.length">
    <ng-container matColumnDef="trade_type">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double" mat-sort-header> </th>
      <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top">
        {{ element.trade_type }}
      </td>
    </ng-container>
    <ng-container matColumnDef="tradebook">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double" mat-sort-header> Tradebook </th>
      <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top">
        {{ element.tradebook }}
      </td>
    </ng-container>
    <ng-container matColumnDef="strategy">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double" mat-sort-header> Strategy </th>
      <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top">
        {{ element.strategy }}
      </td>
    </ng-container>
    <ng-container matColumnDef="regional_office">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-u-padding-top-double" mat-sort-header> Regional Office </th>
      <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top">
        {{ element.regional_office }}
      </td>
    </ng-container>
    <ng-container matColumnDef="intraday_var">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-c-table__header-cell-lower em-u-padding-top-double"
          arrowPosition="before" mat-sort-header>
        VaR
      </th>
      <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top em-u-text-align-right">
        {{ isNumber(element.intraday_var) ? (element.intraday_var | currency:'USD':'symbol':'1.0') : element.intraday_var }}
      </td>
    </ng-container>
    <ng-container matColumnDef="book_cvar">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-c-table__header-cell-lower em-u-padding-top-double"
          arrowPosition="before" mat-sort-header>
        Book Level CVaR
      </th>
      <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top em-u-text-align-right" [class.highlight]="element.book_cvar < 0">
        {{ isNumber(element.book_cvar) ? (element.book_cvar | currency:'USD':'symbol':'1.0' | negativeParens ) : element.book_cvar }}
      </td>
    </ng-container>
    <ng-container matColumnDef="program_cvar">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-c-table__header-cell-lower em-u-padding-top-double"
          arrowPosition="before" mat-sort-header>
        Program Level CVaR
      </th>
      <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top em-u-text-align-right" [class.highlight]="element.program_cvar < 0">
        {{ isNumber(element.program_cvar) ? (element.program_cvar | currency:'USD':'symbol':'1.0' | negativeParens) : element.program_cvar }}
      </td>
    </ng-container>
    <ng-container matColumnDef="regional_cvar">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-c-table__header-cell-lower em-u-padding-top-double"
          arrowPosition="before" mat-sort-header>
        {{ portfolioVarData[0]?.region_office }} Level CVaR
      </th>
      <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top em-u-text-align-right" [class.highlight]="element.regional_cvar < 0">
        {{ isNumber(element.regional_cvar) ? (element.regional_cvar | currency:'USD':'symbol':'1.0' | negativeParens) : element.regional_cvar }}
      </td>
    </ng-container>
    <ng-container matColumnDef="var_change_wrt_prior_bd">
      <th mat-header-cell *matHeaderCellDef class="em-c-table__header-cell header-cell em-c-table__header-cell-lower em-u-padding-top-double"
          arrowPosition="before" mat-sort-header>
        VaR Change
        <svg class="em-c-icon em-c-icon--small em-u-margin-none em-u-margin-left-half" matTooltip="Change in VaR w.r.t. previous business day">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="unity/images/em-icons.svg#info"></use>
        </svg>
      </th>
      <td mat-cell *matCellDef="let element" class="em-c-table__cell em-u-padding-top em-u-text-align-right" [class.highlight]="element.var_change_wrt_prior_bd < 0">
        {{ isNumber(element.var_change_wrt_prior_bd) ? (element.var_change_wrt_prior_bd | currency:'USD':'symbol':'1.0' | negativeParens) : element.var_change_wrt_prior_bd }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
